import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import { Box, Tooltip, Typography } from "@mui/material";
import { useAppSelector } from "src/hooks/stateHooks";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { selectDenormalizedWorkout } from "src/slices/workoutSlice";

interface Props {
  userId?: string;
}

export default function WorkoutDuration({ userId }: Props) {
  const workout = useAppSelector(selectDenormalizedWorkout);
  const client = useAppSelector((state) =>
    state.client.client?.user_id === userId ? state.client.client : undefined,
  );

  if (!workout) {
    return null;
  }

  return (
    <Tooltip title="Estimated duration">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TimerRoundedIcon sx={{ mr: 1 }} />
        <Box>
          <Typography sx={{ fontWeight: "bold", lineHeight: 1 }}>
            {Math.floor(workoutLib.workouts.getWorkoutDuration(workout) / 60)}m
            est.
          </Typography>
          {client?.workout_duration && (
            <Typography variant="overline" sx={{ lineHeight: 1 }}>
              {client.workout_duration} preferred
            </Typography>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
}
