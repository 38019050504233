import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import { Avatar, Box, Link, Stack, Typography } from "@mui/material";
import type { MessageWorkoutPushed } from "@trainwell/features/legacy";
import { useAppSelector } from "src/hooks/stateHooks";

interface Props {
  message: MessageWorkoutPushed;
  combineBottom: boolean;
}

export function WorkoutPushedMessage({ message, combineBottom }: Props) {
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );

  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.background.default,
        p: 1,
        display: "flex",
        alignItems: "center",
        border: 1,
        borderColor: "divider",
      }}
    >
      <Stack
        direction={"column"}
        spacing={1}
        sx={{
          whiteSpace: "pre-line",
          wordWrap: "break-word",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.backgroundTertiary.main,
              mr: 1,
              width: 20,
              height: 20,
              borderRadius: "6px",
            }}
          >
            <RedoRoundedIcon
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: 16,
              }}
            />
          </Avatar>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Workout pushed
            </Typography>
          </Box>
        </Box>
        <Typography>
          -{" "}
          <Link
            href={
              isChatFullscreen
                ? ""
                : `/clients/${message.from_id}/workouts/${message.content.workout_id}`
            }
            sx={{
              color: (theme) => theme.palette.text.primary,
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {message.content.workout_name}
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
