import {
  Box,
  Popover,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useState } from "react";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import ClientProfilePicture from "./ClientProfilePicture";
import ClientCard from "./name-tag/ClientCard";

interface Props {
  sx?: SxProps<Theme>;
}

export function ClientHeader({ sx = [] }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!client) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          onMouseEnter={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
        >
          <ClientProfilePicture dimension={48} userId={client.user_id} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h1" sx={{ mr: 2 }}>
              👋 {client.full_name}
            </Typography>
            {client.goal ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>{client.goal.name_custom}</Typography>
              </Box>
            ) : (
              <Typography
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                No goal yet
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              maxWidth: 650,
            },
          },
        }}
      >
        <ClientCard />
      </Popover>
    </Box>
  );
}
