import type { ActionItem, OfficeHour } from "@trainwell/features/legacy";
import { addHours, getDay, isBefore, parse, subDays } from "date-fns";

export const defaultOfficeHours: OfficeHour[][] = [
  [],
  [
    {
      time_start: "13:00",
      time_end: "23:30",
      buffer_end: "23:59",
    },
  ],
  [
    {
      time_start: "13:00",
      time_end: "23:30",
      buffer_end: "23:59",
    },
  ],
  [
    {
      time_start: "13:00",
      time_end: "23:30",
      buffer_end: "23:59",
    },
  ],
  [
    {
      time_start: "13:00",
      time_end: "23:30",
      buffer_end: "23:59",
    },
  ],
  [
    {
      time_start: "13:00",
      time_end: "23:30",
      buffer_end: "23:59",
    },
  ],
  [
    {
      time_start: "13:00",
      time_end: "23:30",
      buffer_end: "23:59",
    },
  ],
];

export type OfficeHoursStatus = "ok" | "warn" | "error";

export function isInOfficeHours() {
  const today = new Date();

  const todaysOfficeHours = defaultOfficeHours[getDay(today)] ?? [];

  for (const date of todaysOfficeHours) {
    const officeHoursDate = parse(date.time_start, "HH:mm", today);
    const officeHoursEndDate = addHours(
      parse(date.time_start, "HH:mm", today),
      1,
    );

    if (
      today.getTime() > officeHoursDate.getTime() &&
      today.getTime() < officeHoursEndDate.getTime()
    ) {
      return true;
    }
  }

  return false;
}

export function getOfficeHourStatus(date: string): OfficeHoursStatus {
  const now = new Date();

  // Sorted from earlier OH to later OH
  const todaysOfficeHours = [...(defaultOfficeHours[getDay(now)] ?? [])].sort(
    (a, b) => a.time_start.localeCompare(b.time_start),
  );

  // We need to look at the most recent office hour from before today
  // This is because we need to guarentee we evaluate at least one OH that has passed, and todays OH aren't guarenteed to have happened yet
  // Thus, we need to convert the time-only OH to use a Date since we cross the day barrier
  const convertedTodaysOfficeHours: { startDate: Date; endDate: Date }[] = [];

  todaysOfficeHours.forEach((officeHour) => {
    convertedTodaysOfficeHours.push({
      startDate: parse(officeHour.time_start, "HH:mm", now),
      endDate: parse(officeHour.time_end, "HH:mm", now),
    });
  });

  // Here we walk backwards through the trainer's OH times starting at yesterday
  // If we find OH (ex. not Sunday), then we take the latest OH, get the date it would have occured, and add it to convertedTodaysOfficeHours
  // It is guarenteed to have happened already
  for (let subDayCount = 1; subDayCount < 8; subDayCount++) {
    const mostRecentOHBeforeToday = [
      ...(defaultOfficeHours.at(getDay(now) - subDayCount) ?? []),
    ].sort((a, b) => b.time_start.localeCompare(a.time_start));

    if (mostRecentOHBeforeToday.length > 0) {
      const baseDay = subDays(new Date(), subDayCount);
      convertedTodaysOfficeHours.unshift({
        startDate: parse(
          mostRecentOHBeforeToday[mostRecentOHBeforeToday.length - 1]
            .time_start,
          "HH:mm",
          baseDay,
        ),
        endDate: parse(
          mostRecentOHBeforeToday[mostRecentOHBeforeToday.length - 1].time_end,
          "HH:mm",
          baseDay,
        ),
      });

      // Found our OH, break the loop
      break;
    }
  }

  // Evaluate office hours starting from the earliest OH and moving later
  for (const officeHour of convertedTodaysOfficeHours) {
    if (date.localeCompare(officeHour.startDate.toISOString()) < 0) {
      // The date is before this OH, evaluate if it should be a color

      if (now.getTime() > officeHour.endDate.getTime()) {
        // This OH has ended, error

        return "error";
      } else if (now.getTime() > officeHour.startDate.getTime()) {
        // This OH is in progress, warn

        return "warn";
      }

      // If we get here, the OH hasn't happened yet
      // We then move on to evaluate an earlier OH
    }
  }

  return "ok";
}

export function getActionItemStatus(actionItem: ActionItem): OfficeHoursStatus {
  if (actionItem.type !== "custom" && !actionItem.date_finished) {
    return getOfficeHourStatus(
      (actionItem.date_to_send ?? actionItem.date_created) as string,
    );
  } else {
    return "ok";
  }
}

export function willNeedToDoActionItemToday(actionItem: ActionItem): boolean {
  if (actionItem.type !== "custom" && !actionItem.date_finished) {
    const date = new Date(actionItem.date_to_send ?? actionItem.date_created);

    return willNeedToDoToday(date);
  } else {
    return false;
  }
}

export function willNeedToDoToday(date: Date): boolean {
  const today = new Date();

  if (!defaultOfficeHours[today.getDay()][0]) {
    return false;
  }

  return isBefore(
    date,
    parse(defaultOfficeHours[today.getDay()][0].time_start, "HH:mm", today),
  );
}

export function getNeedsResponseStatus(
  earliestMessageResponseDate: undefined | string,
): OfficeHoursStatus {
  if (earliestMessageResponseDate) {
    return getOfficeHourStatus(earliestMessageResponseDate);
  }

  return "ok";
}

export function getUnreadStatus(
  oldestUnreadMessageFromClient: undefined | string,
): OfficeHoursStatus {
  if (oldestUnreadMessageFromClient) {
    return getOfficeHourStatus(oldestUnreadMessageFromClient);
  }

  return "ok";
}
