import MoveDownRoundedIcon from "@mui/icons-material/MoveDownRounded";
import { Box, Typography } from "@mui/material";
import type { MessageWorkoutsMoved } from "@trainwell/features/legacy";
import { format } from "date-fns";
import { getDateFromDatabase } from "src/lib/date";

interface Props {
  message: MessageWorkoutsMoved;
  combineBottom: boolean;
}

export function WorkoutsMovedMessage({ message, combineBottom }: Props) {
  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        p: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <MoveDownRoundedIcon
          sx={{
            fontSize: 16,
            color: (theme) => theme.palette.text.primary,
            mr: 1,
          }}
        />
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            Moved workouts
          </Typography>
        </Box>
      </Box>
      {message.content.make_permanent && (
        <Typography sx={{ fontWeight: "bold" }}>
          Client requests this is permanent
        </Typography>
      )}
      <Typography>{message.content.justification}</Typography>
      {message.content.moves.map((move, index) => (
        <Typography key={index}>
          {format(getDateFromDatabase(move.from_date), "MMM do")} to{" "}
          {format(getDateFromDatabase(move.to_date), "MMM do")}
        </Typography>
      ))}
    </Box>
  );
}
