import SortRoundedIcon from "@mui/icons-material/SortRounded";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";

export type ActionItemSort =
  | "newest_first"
  | "oldest_first"
  | "client_grouped"
  | "client_all";

const actionItemSortOptions: { id: ActionItemSort; name: string }[] = [
  {
    id: "newest_first",
    name: "Newest first",
  },
  {
    id: "oldest_first",
    name: "Oldest first",
  },
  {
    id: "client_grouped",
    name: "By client (grouped)",
  },
  {
    id: "client_all",
    name: "By client (all)",
  },
];

interface Props {
  value: ActionItemSort;
  onChange: (sort: ActionItemSort) => void;
}

export default function ActionItemSortButton({ value, onChange }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Sort" enterDelay={1000}>
        <Button
          size="small"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          variant="text"
          sx={{
            minWidth: "20px",
          }}
        >
          <SortRoundedIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {actionItemSortOptions.map((sortOption) => (
          <MenuItem
            onClick={() => {
              onChange(sortOption.id);
              setAnchorEl(null);
            }}
            key={sortOption.id}
            selected={value === sortOption.id}
          >
            {sortOption.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
