import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Button } from "@mui/material";
import { useParams } from "@tanstack/react-router";
import ControlBar from "src/components/misc/ControlBar";
import { useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";

export default function ClientControlBar() {
  const { userId } = useParams({ strict: false });
  const realCoach = useAppSelector((state) => state.trainer.trainer);

  console.log("Render: client control bar");

  return (
    <>
      <ControlBar
        leftContent={
          <>
            <Button
              variant="contained"
              href="/"
              startIcon={<ArrowBackRoundedIcon />}
              size="small"
            >
              Home
            </Button>
            {trainerHasAccess(realCoach?.access_roles, "client_list") && (
              <Button
                variant="text"
                startIcon={<AdminPanelSettingsRoundedIcon />}
                href={`/admin/company/clients/${userId}`}
                sx={{ mx: 1 }}
                size="small"
              >
                Admin dash
              </Button>
            )}
          </>
        }
      />
    </>
  );
}
