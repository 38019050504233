import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import PauseCircleRoundedIcon from "@mui/icons-material/PauseCircleRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SwitchRightRoundedIcon from "@mui/icons-material/SwitchRightRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import type { TrainerNotification } from "@trainwell/features/trainer-notifications";
import { type JSX } from "react";

interface TrainerNotificationDetails {
  name: string;
  notes?: string;
  icon: JSX.Element;
}

export function getTrainerNotificationDetails(
  trainerNotification: TrainerNotification,
): TrainerNotificationDetails {
  switch (trainerNotification.type) {
    case "switched_coach_away":
      return {
        name: "Switched away from you",
        notes: trainerNotification.notes,
        icon: (
          <SwitchRightRoundedIcon
            color={trainerNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    case "client_cancelled":
      return {
        name: "Cancelled",
        notes: trainerNotification.notes,
        icon: (
          <RemoveCircleRoundedIcon
            color={trainerNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    case "client_paused":
      return {
        name: "Paused",
        notes: trainerNotification.notes,
        icon: (
          <PauseCircleRoundedIcon
            color={trainerNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    case "client_hidden":
      return {
        name: "Hidden",
        notes: trainerNotification.notes,
        icon: (
          <VisibilityOffRoundedIcon
            color={trainerNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    case "inactive_client":
      return {
        name: "Inactive",
        notes: trainerNotification.notes,
        icon: (
          <HotelRoundedIcon
            color={trainerNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    default:
      return {
        name: "Unknown",
        notes: "The dash doesn't know how to display this action item",
        icon: (
          <QuestionMarkRoundedIcon
            color={trainerNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };
  }
}
