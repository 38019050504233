import MonitorHeartRoundedIcon from "@mui/icons-material/MonitorHeartRounded";
import { Box, Card, Divider, Typography, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import type { Client } from "@trainwell/features/legacy";
import { useMemo } from "react";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { exerciseMap } from "src/lib/exercises";
import {
  getExerciseDisplayName,
  getExerciseIconURL,
} from "src/lib/mediaUtility";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { useGetLogQuery } from "src/slices/api/logApi";
import LogSetChips from "./LogSetChips";

interface Props {
  logId: string;
}

export default function LogGraph({ logId }: Props) {
  const theme = useTheme();
  const client = useAppSelector((state) => state.client.client) as Client;

  const { data: log } = useGetLogQuery(logId ?? "", { skip: !logId });

  const userMaxHR = 220.0 - (client?.age ?? 0);

  const logStartTime = log
    ? new Date(log.log_summary.date_started).getTime()
    : 0;

  const setIdsToExerciseSourceIds = useMemo(() => {
    if (!log) {
      return {};
    }

    return workoutLib.workouts.mapSetIdsToExerciseSourceIds(log);
  }, [log]);

  /** Sorted first to last */
  const sets = useMemo(() => {
    if (!log) {
      return [];
    }

    return log.sections
      .map((section) =>
        section.cycles.map((cycle) =>
          cycle.exercises.map((exercise) => exercise.sets),
        ),
      )
      .flat(3)
      .sort((a, b) => {
        return (
          new Date(b.set_start_date!).getTime() -
          new Date(a.set_start_date!).getTime()
        );
      });
  }, [log]);

  const data: { x: number; y: number }[] = useMemo(() => {
    if (
      log?.log_summary.biometrics?.heartrate &&
      log.log_summary.biometrics.heartrate.values.length > 0
    ) {
      const newData = log.log_summary.biometrics.heartrate.values.map(
        (hr, index) => {
          return {
            x: log.log_summary.biometrics!.heartrate.times[index] / 60000,
            y: hr,
          };
        },
      );

      newData.sort((a, b) => a.x - b.x);

      return newData;
    } else {
      return [];
    }
  }, [log?.log_id]);

  if (!log || !exerciseMap) {
    return <LoadingComponent message="Looking for log" />;
  }

  return (
    <>
      <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
        <MonitorHeartRoundedIcon fontSize="inherit" />
        <Box sx={{ ml: 1 }}>
          <Typography variant="h4">Heart rate</Typography>
          <Typography>
            {log.log_summary.metrics.heartrate_max
              ? `Peak: ${log.log_summary.metrics.heartrate_max} bpm (${Math.round(
                  (log.log_summary.metrics.heartrate_max / userMaxHR) * 100,
                )}% of max)`
              : "--"}
          </Typography>
        </Box>
      </Box>
      <LineChart
        colors={[theme.palette.primary.main]}
        xAxis={[
          {
            dataKey: "x",
            valueFormatter: (value) => Math.round(value).toString(),
            label: "Time (m)",
            hideTooltip: true,
            max: Math.max(...data.map((d) => d.x)),
          },
        ]}
        yAxis={[
          {
            min: Math.min(...data.map((d) => d.y), 50),
          },
        ]}
        series={[
          {
            dataKey: "y",
            label: "bpm",
            showMark: false,
          },
        ]}
        dataset={data}
        height={120}
        margin={{ top: 10, right: 0 }}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        slots={{
          axisContent: ({ dataIndex }) => {
            const point = data.at(dataIndex as number);

            if (!point) {
              return null;
            }

            const hoverTime = point.x * 60000;

            const hoverSet = sets.find((set) => {
              const setStartTime =
                new Date(set.set_start_date!).getTime() - logStartTime;

              return hoverTime >= setStartTime;
            });

            return (
              <Card variant="outlined" sx={{ py: 1, ml: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
                  <Box
                    sx={{
                      width: theme.spacing(1),
                      height: theme.spacing(1),
                      borderRadius: "50%",
                      backgroundColor: theme.palette.primary.main,
                      borderColor: theme.palette.background.paper,
                      border: `solid ${theme.palette.background.paper} ${theme.spacing(0.25)}`,
                      boxSizing: "content-box",
                    }}
                  />
                  <Box sx={{ ml: 1 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {point.y} bpm
                    </Typography>
                    <Typography variant="overline">
                      {Math.round(point.x)}m
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                {hoverSet && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      maxWidth: 300,
                      px: 2,
                    }}
                  >
                    <img
                      src={
                        getExerciseIconURL(
                          setIdsToExerciseSourceIds[hoverSet.set_id],
                        ) || "/assets/profile.png"
                      }
                      alt={setIdsToExerciseSourceIds[hoverSet.set_id] ?? ""}
                      width={36}
                      height={36}
                      style={{
                        borderRadius: 8,
                      }}
                    />
                    <Box sx={{ ml: 1 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {getExerciseDisplayName(
                          setIdsToExerciseSourceIds[hoverSet.set_id] ?? "",
                        )}
                      </Typography>
                      <LogSetChips
                        enablePreview={false}
                        exerciseMasterID={
                          setIdsToExerciseSourceIds[hoverSet.set_id]
                        }
                        preferredWeightSystem={
                          client.preferred_weight_system ?? "imperial"
                        }
                        setLog={hoverSet}
                      />
                    </Box>
                  </Box>
                )}
              </Card>
            );
          },
        }}
        skipAnimation
        disableLineItemHighlight
      />
    </>
  );
}
