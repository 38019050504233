import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import { useAppSelector } from "src/hooks/stateHooks";
import { isWeekPlanCurrentWeek, isWeekPlanInPast } from "src/lib/habits";
import { selectWeekPlanById } from "src/slices/clientSlice";
import HabitWeek from "./HabitWeek";

interface Props {
  habitWeekId: string;
  weekPlanId: string;
  index: number;
}

export const HabitWeekDraggable = memo(function HabitWeekDraggable({
  habitWeekId,
  weekPlanId,
  index,
}: Props) {
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const weekPlan = useAppSelector((state) =>
    selectWeekPlanById(state, weekPlanId),
  );
  const habitWeek = useAppSelector(
    (state) =>
      selectWeekPlanById(state, weekPlanId).habit_weeks.find(
        (w) => w.id === habitWeekId,
      )!,
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "habit_week",
      week_plan_id: weekPlanId,
      index: index,
    };

    return combine(
      draggable({
        element: element,
        getInitialData: () => data,
        onDragStart: () => {
          setDragging(true);
        },
        onDrop: () => {
          setDragging(false);
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
        canDrag: () => {
          return (
            !isWeekPlanCurrentWeek(weekPlan.date) &&
            !isWeekPlanInPast(weekPlan.date)
          );
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            source.data.type === "habit_week" ||
            source.data.type === "habit_week_source"
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            setClosestEdge(closestEdge);

            return;
          }

          if (source.data.week_plan_id !== weekPlanId) {
            setClosestEdge(closestEdge);

            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [weekPlanId, index, weekPlan.date]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <HabitWeek
            isDragging={dragging}
            habitWeekId={habitWeekId}
            weekPlanId={weekPlanId}
          />
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(<DragPreview text={habitWeek.name} />, previewContainer)
        : null}
    </>
  );
});
