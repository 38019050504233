import { useTests } from "@trainwell/features/tests";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export function useIsCpt2Trainer() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { data: tests } = useTests();

  const cpt2 = tests?.find((test) =>
    test.variants.some(
      (v) => v.id === "call_missed_workout_clients_treatment_2",
    ),
  );

  return Boolean(
    cpt2?.is_active &&
      cpt2.supported_trainer_ids?.includes(trainer?.trainer_id ?? ""),
  );
}
