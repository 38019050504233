import { Box, Typography } from "@mui/material";
import ExerciseIcon from "src/components/misc/ExerciseIcon";
import SetHistoryButton from "src/features/workout-builder/SetHistoryButton";
import { getExerciseById } from "src/lib/exercises";
import { getExerciseDisplayName } from "src/lib/mediaUtility";

interface Props {
  exerciseMasterId: string;
  userId: string;
  weightSystem: "imperial" | "metric";
}

export default function ExerciseProgressCell({
  exerciseMasterId,
  userId,
  weightSystem,
}: Props) {
  const exerciseSource = getExerciseById(exerciseMasterId);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 1,
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ExerciseIcon exerciseMasterId={exerciseMasterId} />
        <Typography sx={{ ml: 1 }}>
          {getExerciseDisplayName(exerciseMasterId)}
        </Typography>
      </Box>
      <SetHistoryButton
        exerciseName={exerciseSource?.key_readable_copilot ?? "Unknown"}
        exerciseId={exerciseMasterId}
        userId={userId}
        weightSystem={weightSystem}
        exerciseSource={exerciseSource!}
      />
    </Box>
  );
}
