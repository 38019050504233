import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
} from "@mui/material";
import type { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  resetWorkout,
  saveWorkout,
  selectWorkoutValidity,
} from "src/slices/workoutSlice";
import WorkoutBuilderParent from "./WorkoutBuilderParent";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      timeout={{ enter: 150, exit: 100 }}
      ref={ref}
      {...props}
    />
  );
});

interface Props {
  open: boolean;
  onClose: () => void;
  workoutId?: string;
  templateId?: string;
}

export default function WorkoutBuilderDialog({
  open,
  onClose,
  workoutId,
  templateId,
}: Props) {
  const dispatch = useAppDispatch();
  const localWorkoutId = useAppSelector(
    (state) => state.workout.workoutNormalized?.result.workout_id,
  );
  const workoutName = useAppSelector(
    (state) => state.workout.workoutNormalized?.result.name,
  );
  const saveStatus = useAppSelector((state) => state.workout.saveStatus);
  const workoutValidity = useAppSelector(selectWorkoutValidity, shallowEqual);

  useEffect(() => {
    if (open && localWorkoutId !== workoutId && localWorkoutId !== templateId) {
      dispatch(resetWorkout());
    }
  }, [open, localWorkoutId, dispatch, workoutId, templateId]);

  function handleClose() {
    onClose();
    dispatch(resetWorkout());
  }

  function save() {
    if (!localWorkoutId) {
      return;
    }

    dispatch(saveWorkout(localWorkoutId))
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((error: unknown) => {
        if (error instanceof Error) {
          console.error(error.name, error.message);
        }
      });
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      slotProps={{
        paper: {
          sx: {
            backgroundColor: (theme) => theme.palette.background.default,
            height: "100%",
            mb: 0,
            mx: 3,
            mt: 3,
            maxHeight: "calc(100% - 24px)",
            width: "calc(100% - 48px)",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxWidth: "100%",
          },
        },
      }}
      slots={{
        transition: Transition,
      }}
      sx={{ height: "100%" }}
      onClose={() => {
        if (saveStatus === "saving") {
          return;
        }

        save();
      }}
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onMouseMove={(event) => {
        event.stopPropagation();
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          height: "38px",
        }}
      >
        <Button
          disabled={saveStatus === "saving"}
          variant="text"
          onClick={() => {
            handleClose();
          }}
          startIcon={<CloseRoundedIcon />}
          size="small"
        >
          Close
        </Button>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FitnessCenterRoundedIcon
            fontSize="inherit"
            sx={{ mr: 1, color: (theme) => theme.palette.blue.main }}
          />
          <Typography variant="h6">{workoutName}</Typography>
        </Box>
        <Button
          variant="contained"
          size="small"
          loading={saveStatus === "saving"}
          disabled={workoutValidity.status === "error"}
          onClick={() => {
            if (saveStatus === "saving") {
              return;
            }

            save();
          }}
          startIcon={
            workoutValidity.status === "valid" ? (
              <SaveRoundedIcon />
            ) : workoutValidity.status === "warn" ? (
              <WarningRoundedIcon />
            ) : (
              <ErrorRoundedIcon />
            )
          }
        >
          {workoutValidity.status === "error"
            ? "Errors present"
            : workoutValidity.status === "warn"
              ? "Save with warnings"
              : "Save and close"}
        </Button>
      </Box>
      <DialogContent sx={{ p: 0, display: "flex" }}>
        <WorkoutBuilderParent workoutId={workoutId} templateId={templateId} />
      </DialogContent>
    </Dialog>
  );
}
