import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import type { TextFieldProps } from "@mui/material";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  TextField,
} from "@mui/material";
import type { Status } from "@trainwell/features/legacy";
import { useRef, useState } from "react";

type Props = {
  status: Status;
  dropdownOptions?: string[];
  fieldId?: string;
  allowDecimal?: boolean;
  onChange?: (value: string) => void;
  onStep?: (
    direction: "up" | "down",
    setLocalValue?: (value: string) => void,
  ) => void;
  stepUpDisabled?: boolean;
  stepDownDisabled?: boolean;
} & Omit<TextFieldProps, "onChange">;

export default function SetTextField({
  status,
  dropdownOptions,
  fieldId,
  allowDecimal,
  onChange,
  onStep,
  stepUpDisabled,
  stepDownDisabled,
  ...textFieldProps
}: Props) {
  const textInput = useRef<null | HTMLInputElement>(null);
  const [localValue, setLocalValue] = useState<string | null>(null);

  function safeSetLocalValue(value: string) {
    if (
      document.activeElement &&
      document.activeElement === textInput.current
    ) {
      setLocalValue(value);
    }
  }

  if (dropdownOptions && dropdownOptions.length > 0) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Autocomplete
          fullWidth
          freeSolo
          disableClearable
          filterOptions={(options) => options}
          options={dropdownOptions}
          id={fieldId}
          value={
            allowDecimal
              ? (localValue ?? textFieldProps.value)
              : (textFieldProps.value as any)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={textFieldProps.label}
              name={textFieldProps.name}
              variant="outlined"
              size="small"
              sx={{
                width: "110px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: (theme) =>
                    status === "error"
                      ? theme.palette.error.main
                      : status === "warn"
                        ? theme.palette.warning.main
                        : undefined,
                  borderWidth: status === "valid" ? undefined : "2px",
                },
                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                  py: 0.5,
                  fontFamily: "'Azeret Mono', monospace;",
                  fontWeight: 300,
                },
                "& .MuiFormLabel-filled": {
                  color: (theme) =>
                    status === "error"
                      ? theme.palette.error.main
                      : status === "warn"
                        ? theme.palette.warning.main
                        : undefined,
                },
              }}
              onClick={(event) => {
                if ("select" in event.target) {
                  (event.target as HTMLInputElement).select();
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  (event.target as HTMLInputElement).blur();
                }
              }}
              onBlur={() => {
                if (allowDecimal) {
                  setLocalValue(null);
                }
              }}
              onFocus={() => {
                if (allowDecimal) {
                  setLocalValue(textFieldProps.value as any);
                }
              }}
              ref={textInput}
            />
          )}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          getOptionLabel={(option) => option.toString()}
          // onChange={(event: any, newValue: string | null) => {
          //   setNewHabitMovementType(
          //     (newValue as MovementHabitType) ?? "any"
          //   );
          // }}
          color={
            status === "error"
              ? "error"
              : status === "warn"
                ? "warning"
                : undefined
          }
          size="small"
          sx={{
            "& .MuiAutocomplete-input": {
              py: "0 !important",
            },
          }}
          onChange={(_event, value) => {
            onChange?.(value ?? "");
          }}
          onInputChange={(event, value) => {
            if (!event) {
              return;
            }

            onChange?.(value);
          }}
        />
        {onStep && (
          <ButtonGroup
            variant="outlined"
            orientation="vertical"
            sx={{ ml: "2px" }}
          >
            <Button
              size="small"
              disabled={stepUpDisabled}
              onClick={() => {
                onStep("up", safeSetLocalValue);
              }}
              sx={{ p: 0, minWidth: "20px !important" }}
            >
              <KeyboardArrowUpRoundedIcon sx={{ fontSize: 12 }} />
            </Button>
            <Button
              size="small"
              disabled={stepDownDisabled}
              onClick={() => {
                onStep("down", safeSetLocalValue);
              }}
              sx={{ p: 0, minWidth: "20px !important" }}
            >
              <KeyboardArrowDownRoundedIcon sx={{ fontSize: 12 }} />
            </Button>
          </ButtonGroup>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        color={
          status === "error"
            ? "error"
            : status === "warn"
              ? "warning"
              : undefined
        }
        size="small"
        variant="outlined"
        onClick={(event) => {
          if ("select" in event.target) {
            (event.target as HTMLInputElement).select();
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            (event.target as HTMLInputElement).blur();
          }
        }}
        onChange={(event) => {
          onChange?.(event.target.value);

          if (allowDecimal) {
            setLocalValue(event.target.value);
          }
        }}
        {...textFieldProps}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: (theme) =>
              status === "error"
                ? theme.palette.error.main
                : status === "warn"
                  ? theme.palette.warning.main
                  : undefined,
            borderWidth: status === "valid" ? undefined : "2px",
          },
          "& .MuiOutlinedInput-input": {
            py: 0.5,
            fontFamily: "'Azeret Mono', monospace;",
            fontWeight: 300,
          },
          "& .MuiFormLabel-filled": {
            color: (theme) =>
              status === "error"
                ? theme.palette.error.main
                : status === "warn"
                  ? theme.palette.warning.main
                  : undefined,
          },
          ...textFieldProps.sx,
        }}
        onBlur={() => {
          if (allowDecimal) {
            setLocalValue(null);
          }
        }}
        onFocus={() => {
          if (allowDecimal) {
            setLocalValue(textFieldProps.value as any);
          }
        }}
        value={
          allowDecimal
            ? (localValue ?? textFieldProps.value)
            : textFieldProps.value
        }
        id={fieldId}
        ref={textInput}
      />
      {onStep && (
        <ButtonGroup
          variant="outlined"
          orientation="vertical"
          sx={{ ml: "2px" }}
        >
          <Button
            size="small"
            disabled={stepUpDisabled}
            onClick={() => {
              onStep("up", safeSetLocalValue);
            }}
            sx={{
              p: 0,
              minWidth: "20px !important",
            }}
          >
            <KeyboardArrowUpRoundedIcon sx={{ fontSize: 12 }} />
          </Button>
          <Button
            size="small"
            disabled={stepDownDisabled}
            onClick={() => {
              onStep("down", safeSetLocalValue);
            }}
            sx={{
              p: 0,
              minWidth: "20px !important",
            }}
          >
            <KeyboardArrowDownRoundedIcon sx={{ fontSize: 12 }} />
          </Button>
        </ButtonGroup>
      )}
    </Box>
  );
}
