import { TagList } from "src/components/misc/TagList";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { toggleClientTag } from "src/slices/clientsSlice";

interface Props {
  userId: string;
}

export default function ClientTags({ userId }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) =>
    state.client.client?.user_id === userId ? state.client.client : undefined,
  );
  const possibleTags = useAppSelector((state) => state.clients.possibleTags);

  if (!client) {
    return null;
  }

  return (
    <TagList
      value={(client.tags ?? []).map((t) => ({ id: t, label: t }))}
      options={possibleTags.map((t) => ({ id: t, label: t }))}
      onAdd={(tag) => {
        dispatch(
          toggleClientTag({
            tag: tag,
            userId: userId,
          }),
        );
      }}
      onRemove={(tag) => {
        dispatch(
          toggleClientTag({
            tag: tag,
            userId: userId,
          }),
        );
      }}
    />
  );
}
