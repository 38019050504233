import CallRoundedIcon from "@mui/icons-material/CallRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useMilestones } from "@trainwell/features/milestones";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import LoadingPage from "src/components/miscPages/LoadingPage";
import SendPrecallSurveyButton from "src/features/chat/SendPrecallSurveyButton";
import { useAppSelector } from "src/hooks/stateHooks";
import { useIsInNutritionV2Test } from "src/hooks/useIsInNutritionV2Test";
import { getClientStatusColor, getClientSummary } from "src/lib/client";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import { selectUpcomingCallForUserId } from "src/slices/clientsSlice";
import { CallClientButton } from "../CallClientButton";
import ClientName from "../ClientName";
import ClientProfilePicture from "../ClientProfilePicture";
import ClientTags from "../ClientTags";
import { ClientChips } from "./ClientChips";
import { ClientCommunication } from "./ClientCommunication";
import { ClientPreferenceChips } from "./ClientPreferenceChips";
import { Consistency } from "./Consistency";
import { Milestones } from "./Milestones";
import { Nutrition } from "./Nutrition";

interface Props {
  sx?: SxProps<Theme>;
  reduceInfo?: boolean;
}

export default function ClientCard({ sx = [], reduceInfo }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const upcomingCall = useAppSelector((state) =>
    selectUpcomingCallForUserId(state, client?.user_id ?? ""),
  );
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [profilePictureOpen, setProfilePictureOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const hasCallSurveyActionItem = useAppSelector((state) =>
    selectActionItemsForClient(state, client?.user_id ?? "").some(
      (actionItem) => actionItem.type === "send_pre_check_in_survey",
    ),
  );
  const isInNutritionV2Test = useIsInNutritionV2Test();
  const milestonesQuery = useMilestones({ userId: client?.user_id ?? "" });
  const hasMilestones = Boolean(milestonesQuery.data?.length);
  const hasIncompleteMilestones = Boolean(
    milestonesQuery.data?.filter((m) => !m.date_achieved).length,
  );
  const clientIsCpt =
    client?.tests?.includes("call_missed_workout_clients_treatment_2") ||
    client?.tests?.includes("call_missed_workout_clients_treatment_2_fake");
  const shouldShowMilestones = hasMilestones || clientIsCpt;

  if (!client) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  const clientSummary = getClientSummary(client.account);

  return (
    <Card variant="outlined" sx={{ p: 2, ...sx }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              setProfilePictureOpen(true);
            }}
          >
            <ClientProfilePicture dimension={88} userId={client.user_id} />
          </Box>
          <Box sx={{ ml: 2 }}>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              <Typography variant="h1" sx={{ mr: 1 }}>
                👋
              </Typography>
              <ClientName variant="h1" userId={client.user_id} />
            </Box>
            {client.goal ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>{client.goal.name_custom}</Typography>
              </Box>
            ) : (
              <Typography
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                No goal yet
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{
                color: (theme) => getClientStatusColor(client, theme),
              }}
            >
              {clientSummary.text}
            </Typography>
            {client.account.plan.brand !== "copilot" && (
              <Typography variant="overline">
                {client.account.plan.brand === "af"
                  ? "Anytime Fitness"
                  : client.account.plan.brand === "echelon"
                    ? "Echelon"
                    : client.account.plan.brand === "gf"
                      ? "GlobalFit / Husk"
                      : "Unknown brand"}
              </Typography>
            )}
            <ClientChips sx={{ mb: 1, mt: 1 }} />
            <ClientTags userId={client.user_id} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();

              setMoreAnchorEl(event.currentTarget);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            <MoreVertRoundedIcon sx={{ fontSize: 18 }} />
          </IconButton>
          {upcomingCall && (
            <Card
              variant="outlined"
              sx={{
                p: 1,
                backgroundColor: (theme) => theme.palette.successSurface.main,
                borderColor: (theme) => theme.palette.success.main,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VideocamRoundedIcon />
                <Typography sx={{ ml: 1 }}>
                  {upcomingCall.content.call_type === "onboarding"
                    ? "Onboarding"
                    : upcomingCall.content.call_type === "live_training"
                      ? "Live training"
                      : "Check-in"}{" "}
                  call
                </Typography>
              </Box>
              <Tooltip
                title={
                  "Client's time: " +
                  format(
                    toZonedTime(
                      new Date(upcomingCall.content.date_of_call),
                      client?.timezone ?? "America/New_York",
                    ),
                    "MMMM do 'at' hh:mm aaa",
                  )
                }
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {format(
                    new Date(upcomingCall.content.date_of_call),
                    "MMMM do 'at' hh:mm aaa",
                  )}
                </Typography>
              </Tooltip>
              {hasCallSurveyActionItem && (
                <SendPrecallSurveyButton sx={{ mt: 1 }} />
              )}
            </Card>
          )}
        </Box>
      </Box>
      {!reduceInfo && (
        <>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
            {!isInNutritionV2Test && (
              <Card variant="outlined" sx={{ p: 1 }}>
                <ClientCommunication />
              </Card>
            )}
            <Card
              variant="outlined"
              sx={{
                p: 1,
              }}
            >
              <Consistency />
            </Card>
            {client.tests?.includes("nov_nutrition_treatment") &&
              client.disable_nutrition_messages !== true && (
                <Card
                  variant="outlined"
                  sx={{
                    p: 1,
                  }}
                >
                  <Nutrition />
                </Card>
              )}
            {shouldShowMilestones && (
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                  borderColor: (theme) =>
                    client.account.dashboard.date_onboarded &&
                    !hasIncompleteMilestones
                      ? theme.palette.error.main
                      : undefined,
                }}
              >
                <Milestones />
              </Card>
            )}
          </Box>
        </>
      )}
      {isInNutritionV2Test && <ClientPreferenceChips sx={{ mt: 2 }} />}
      {!client.account.dashboard.date_onboarded && (
        <CallClientButton
          clientPhoneNumber={client.phone_number ?? ""}
          sx={{ mt: 2 }}
        />
      )}
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            navigator.clipboard
              .writeText(
                `https://account.trainwell.net/schedule?user_id=${client.user_id}`,
              )
              .then(() => {
                enqueueSnackbar("Copied to clipboard", {
                  variant: "success",
                });
              });

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Copy client's calendar link" />
        </MenuItem>
        <CallClientButton
          clientPhoneNumber={client.phone_number!}
          button={
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <ListItemIcon>
                <CallRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Call" />
            </MenuItem>
          }
        />
      </Menu>
      <Dialog
        onClose={() => {
          setProfilePictureOpen(false);
        }}
        open={profilePictureOpen}
        maxWidth="lg"
      >
        <DialogTitleWithClose
          onClose={() => {
            setProfilePictureOpen(false);
          }}
        >
          {client.full_name}
        </DialogTitleWithClose>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <img
              height={500}
              width={500}
              src={client?.headshot_url || "/assets/profile.png"}
              alt="client profile picture"
              style={{
                maxWidth: "100%",
                display: "block",
                objectFit: "contain",
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
