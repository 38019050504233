import { Box, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "src/hooks/stateHooks";
import {
  updatePhaseTemplateEditing,
  type DayDraggable,
} from "src/slices/phaseTemplatesSlice";
import { duplicateTemplate } from "src/slices/templatesSlice";
import DayDroppable from "./DayDroppable";
import { NewPhaseDay } from "./NewPhaseDay";

interface Props {
  days: DayDraggable[];
  disabled?: boolean;
}

export default function PhaseTemplateBuilderCard({ days, disabled }: Props) {
  const dispatch = useAppDispatch();

  return (
    <Box sx={{ pb: 4 }}>
      <Typography variant="h3" sx={{ mb: 1 }}>
        Workouts
      </Typography>
      <Box
        sx={{
          flex: 1,
          p: 2,
          borderRadius: 1,
          border: 1,
          borderColor: (theme) => theme.palette.divider,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Stack direction="column">
          {days.map((day, dayIndex) => {
            return (
              <DayDroppable
                key={day.draggable_id}
                day={day}
                dayIndex={dayIndex}
                onDeleteWorkoutTemplate={(workoutTemplateId) => {
                  const newDays = [...days];
                  const day = newDays[dayIndex];
                  const newTemplates = day.templates.filter(
                    (template) => template.template_id !== workoutTemplateId,
                  );
                  newDays[dayIndex] = {
                    ...day,
                    templates: newTemplates,
                  };

                  dispatch(
                    updatePhaseTemplateEditing({
                      days_draggable: newDays,
                    }),
                  );
                }}
                onCopyWorkoutTemplate={async (workoutTemplateId) => {
                  const { workout_template } = await dispatch(
                    duplicateTemplate(workoutTemplateId),
                  ).unwrap();

                  const newDays = [...days];
                  const day = newDays[dayIndex];

                  const originalTemplateIndex = day.templates.findIndex(
                    (template) => template.template_id === workoutTemplateId,
                  );

                  if (originalTemplateIndex === -1) {
                    return;
                  }

                  const newTemplates = [...day.templates];

                  newTemplates.splice(originalTemplateIndex + 1, 0, {
                    draggable_id: crypto.randomUUID(),
                    template_id: workout_template.template_id,
                  });

                  newDays[dayIndex] = {
                    ...day,
                    templates: newTemplates,
                  };

                  dispatch(
                    updatePhaseTemplateEditing({
                      days_draggable: newDays,
                    }),
                  );
                }}
                onDelete={() => {
                  const newDays = [...days];
                  newDays.splice(dayIndex, 1);
                  dispatch(
                    updatePhaseTemplateEditing({
                      days_draggable: newDays,
                    }),
                  );
                }}
                disabled={disabled}
              />
            );
          })}
          {days.length <= 14 && !disabled && <NewPhaseDay />}
        </Stack>
      </Box>
    </Box>
  );
}
