import HotelIcon from "@mui/icons-material/Hotel";
import PollRoundedIcon from "@mui/icons-material/PollRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import { Avatar, Box, LinearProgress, Stack, Typography } from "@mui/material";
import type { MessageMondaySurveyResponse } from "@trainwell/features/legacy";

interface Props {
  message: MessageMondaySurveyResponse;
  combineBottom: boolean;
}

export function MondaySurveyMessage({ message, combineBottom }: Props) {
  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        border: 1,
        borderColor: "divider",
        overflow: "hidden",
        // backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        py: 1,
        px: 1.5,
        width: "fit-content",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
        <Avatar
          sx={{
            backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
            mr: 1,
            width: 24,
            height: 24,
          }}
        >
          <PollRoundedIcon
            sx={{
              fontSize: 24 * 0.6,
              color: (theme) => theme.palette.text.primary,
            }}
          />
        </Avatar>
        <Typography
          variant="h6"
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
        >
          Monday survey
        </Typography>
      </Box>
      <Stack direction={"column"} spacing={0.5}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {message.content.has_blockers && (
            <PriorityHighRoundedIcon fontSize="inherit" />
          )}
          <Typography
            sx={{
              fontWeight: message.content.has_blockers ? "bold" : undefined,
            }}
          >
            {message.content.has_blockers ? "Has blockers" : "No blockers"}
          </Typography>
        </Stack>
        {message.content.blockers && (
          <Typography>{message.content.blockers}</Typography>
        )}
        <Typography
          variant="overline"
          sx={{
            textAlign: "center",
            pt: 1,
          }}
        >
          Energy level
        </Typography>
        <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
          <HotelIcon />
          <LinearProgress
            variant="determinate"
            value={message.content.energy_level}
            sx={{
              width: "100%",
            }}
          />
          <SportsMartialArtsIcon />
        </Stack>
      </Stack>
    </Box>
  );
}
