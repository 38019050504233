import { Box } from "@mui/material";
import type { StreakState } from "@trainwell/features/legacy";
import { CompletedRestDayIcon } from "./CompletedRestDayIcon";
import { CompletedStepsIcon } from "./CompletedStepsIcon";
import { CompletedWorkoutAndStepsIcon } from "./CompletedWorkoutAndStepsIcon";
import { CompletedWorkoutIcon } from "./CompletedWorkoutIcon";
import { StreakBrokenStepsIcon } from "./StreakBrokenStepsIcon";
import { StreakBrokenWorkoutIcon } from "./StreakBrokenWorkoutIcon";
import { StreakFrozenIcon } from "./StreakFrozenIcon";
import { StreakInDangerStepsIcon } from "./StreakInDangerStepsIcon";
import { StreakInDangerWorkoutIcon } from "./StreakInDangerWorkoutIcon";
import { UpcomingRestDayIcon } from "./UpcomingRestDayIcon";
import { UpcomingStepsIcon } from "./UpcomingStepsIcon";
import { UpcomingWorkoutIcon } from "./UpcomingWorkoutIcon";

export function StreakIcon(streakState: StreakState) {
  switch (streakState) {
    case "completed_workout":
      return <CompletedWorkoutIcon />;
    case "completed_steps":
      return <CompletedStepsIcon />;
    case "completed_steps_and_workout":
      return <CompletedWorkoutAndStepsIcon />;
    case "incomplete_broken":
      return <StreakBrokenWorkoutIcon />;
    case "incomplete_broken_steps":
      return <StreakBrokenStepsIcon />;
    case "completed_rest":
      return <CompletedRestDayIcon />;
    case "upcoming_rest":
      return <UpcomingRestDayIcon />;
    case "incomplete_in_danger":
      return <StreakInDangerWorkoutIcon />;
    case "incomplete_in_danger_steps":
      return <StreakInDangerStepsIcon />;
    case "upcoming_workout":
      return <UpcomingWorkoutIcon />;
    case "upcoming_steps":
      return <UpcomingStepsIcon />;
    case "frozen":
      return <StreakFrozenIcon />;
    default:
      return (
        <Box
          sx={{
            width: 18,
            height: 18,
          }}
        />
      );
  }
}
