import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Trainer } from "@trainwell/features/legacy";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/slices/store";
import { updateTrainerEdit } from "./trainerEditSlice";
import { updateTrainerLocal } from "./trainerSlice";

export const fetchAllTrainers = createAsyncThunk(
  "trainers/fetchAllTrainers",
  async () => {
    const response = await api.trainers.getTeam();
    return response;
  },
);

export const fetchAllTrainerNames = createAsyncThunk(
  "trainers/fetchAllTrainerNames",
  async () => {
    const response = await api.trainers.getAllNames();
    return response;
  },
);

export const updateTrainer = createAsyncThunk(
  "trainers/updateTrainer",
  async (
    coach: Partial<Trainer> & Pick<Trainer, "trainer_id">,
    { dispatch },
  ) => {
    const response = await api.trainers.updateOne(coach);

    dispatch(updateTrainerEdit(response));
    dispatch(updateTrainerLocal(response));

    return response;
  },
);

export const terminateCoach = createAsyncThunk(
  "trainers/terminateCoach",
  async (trainerId: string, { dispatch }) => {
    const response = await api.trainers.terminate(trainerId);

    dispatch(updateTrainerEdit(response));
    dispatch(updateTrainerLocal(response));

    return response;
  },
);

export const employTrainer = createAsyncThunk(
  "trainers/employTrainer",
  async (trainerId: string, { dispatch }) => {
    const response = await api.trainers.employ(trainerId);

    dispatch(updateTrainerEdit(response));
    dispatch(updateTrainerLocal(response));

    return response;
  },
);

export const createTrainerCandidate = createAsyncThunk(
  "trainers/createTrainerCandidate",
  async (data: { email: string; firstName: string; lastName: string }) => {
    const response = await api.trainers.createOneCandidate(
      data.email,
      data.firstName,
      data.lastName,
    );

    return response;
  },
);

export const updateTrainerEmail = createAsyncThunk(
  "trainers/updateTrainerEmail",
  async (data: { email: string; trainerId: string }, { dispatch }) => {
    const response = await api.trainers.updateEmail(data.trainerId, data.email);

    dispatch(
      updateTrainerEdit({ trainer_id: data.trainerId, email: data.email }),
    );
    dispatch(
      updateTrainerLocal({ trainer_id: data.trainerId, email: data.email }),
    );

    return response;
  },
);

// Define a type for the slice state
interface TrainersState {
  trainers: Trainer[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  trainerNames: Awaited<ReturnType<(typeof api)["trainers"]["getAllNames"]>>;
  trainerNameStatus: "idle" | "loading" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: TrainersState = {
  trainers: [],
  status: "idle",
  error: undefined,
  trainerNames: [],
  trainerNameStatus: "idle",
};

export const trainersSlice = createSlice({
  name: "trainers",
  initialState,
  reducers: {
    resetCoaches: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllTrainers.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllTrainers.fulfilled, (state, action) => {
      console.log("Redux: Got all coaches");
      state.status = "succeeded";

      const coaches = action.payload;

      coaches.sort((a, b) => {
        return a.full_name > b.full_name ? 1 : -1;
      });

      state.trainers = coaches;
    });
    builder.addCase(fetchAllTrainers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(createTrainerCandidate.fulfilled, (state, action) => {
      const coach = action.payload;

      state.trainers.unshift(coach as Trainer);
    });
    builder.addCase(fetchAllTrainerNames.pending, (state) => {
      state.trainerNameStatus = "loading";
    });
    builder.addCase(fetchAllTrainerNames.fulfilled, (state, action) => {
      console.log("Redux: Got all coach names");
      state.trainerNameStatus = "succeeded";

      const trainerNames = action.payload;

      trainerNames.sort((a, b) => a.full_name.localeCompare(b.full_name));

      state.trainerNames = trainerNames;
    });
    builder.addCase(fetchAllTrainerNames.rejected, (state) => {
      state.trainerNameStatus = "failed";
    });
    builder.addCase(updateTrainer.fulfilled, (state, action) => {
      const update = action.meta.arg;

      const index = state.trainers.findIndex(
        (trainer) => trainer.trainer_id === update.trainer_id,
      );

      if (index !== -1) {
        state.trainers[index] = { ...state.trainers[index], ...update };
      }
    });
    builder.addCase(terminateCoach.fulfilled, (state, action) => {
      const update = action.payload;

      const index = state.trainers.findIndex(
        (trainer) => trainer.trainer_id === update.trainer_id,
      );

      if (index !== -1) {
        state.trainers[index] = { ...state.trainers[index], ...update };
      }
    });
    builder.addCase(employTrainer.fulfilled, (state, action) => {
      const update = action.payload;

      const index = state.trainers.findIndex(
        (trainer) => trainer.trainer_id === update.trainer_id,
      );

      if (index !== -1) {
        state.trainers[index] = { ...state.trainers[index], ...update };
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetCoaches } = trainersSlice.actions;

export default trainersSlice.reducer;

export const selectCoachById = (state: RootState, trainerID: string) =>
  state.trainers.trainers.find((trainer) => trainer.trainer_id === trainerID);

export const selectTrainerNames = (state: RootState) =>
  state.trainers.trainerNames;
