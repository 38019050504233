import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import { Divider, IconButton, Popover, Stack } from "@mui/material";
import {
  readTrainerNotifications,
  useTrainerNotifications,
} from "@trainwell/features/trainer-notifications";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { TrainerNotificationCell } from "./TrainerNotificationCell";

export function TrainerNotificationBell() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { data: notifications } = useTrainerNotifications({
    filter: { trainerId: trainer?.trainer_id ?? "" },
    queryConfig: { enabled: !!trainer?.trainer_id },
  });

  useEffect(() => {
    if (open && trainer?.trainer_id && notifications) {
      void readTrainerNotifications({
        notificationIds: notifications.map((n) => n._id),
      });
    }
  }, [open, trainer?.trainer_id]);

  return (
    <>
      <IconButton
        sx={{ ml: 1 }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        size="small"
        disabled={notifications?.length === 0}
      >
        {!notifications || notifications.length === 0 ? (
          <NotificationsNoneRoundedIcon />
        ) : (
          <NotificationsRoundedIcon />
        )}
      </IconButton>
      {open && (
        <Popover
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={() => {
            setAnchorEl(null);
          }}
          slotProps={{
            paper: {
              sx: {
                p: 2,
              },
            },
          }}
        >
          <Stack direction={"column"} spacing={1} divider={<Divider />}>
            {notifications?.map((notification) => (
              <TrainerNotificationCell
                key={notification._id}
                notification={notification}
              />
            ))}
          </Stack>
        </Popover>
      )}
    </>
  );
}
