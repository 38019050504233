import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  useCreateMilestone,
  useDeleteMilestone,
  useUpdateMilestone,
  type CreateMilestone,
  type Milestone,
  type MilestoneExercise,
  type MilestoneProgressMetric,
  type MilestoneText,
} from "@trainwell/features/milestones";
import { useProgressMetrics } from "@trainwell/features/progress-metrics";
import { exerciseLibrary } from "@trainwell/workout-lib";
import { startOfDay } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getExerciseDisplayName } from "src/lib/mediaUtility";
import { api } from "src/lib/trainwellApi";
import { updateClientLocal } from "src/slices/clientSlice";
import { updateClientInListLocal } from "src/slices/clientsSlice";

const blankMilestoneData: CreateMilestone = {
  type: "text",
  user_id: "",
  notes: "",
  text: "",
  content: null,
};

interface Props {
  userId: string;
  defaultMilestone?: Milestone;
  defaultExerciseMasterId?: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export default function MilestonePopover({
  userId,
  defaultMilestone,
  defaultExerciseMasterId,
  anchorEl,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const [milestoneData, setMilestoneData] = useState<CreateMilestone>({
    ...(defaultMilestone ?? blankMilestoneData),
    user_id: userId,
    ...(defaultExerciseMasterId && {
      type: "exercise",
      content: { exercise_master_id: defaultExerciseMasterId },
    }),
  });
  const createMilestone = useCreateMilestone();
  const updateMilestone = useUpdateMilestone();
  const { data } = useProgressMetrics();
  const client = useAppSelector((state) => state.client.client);
  const progressMetrics = (data?.progress_metrics ?? []).sort((a, b) => {
    const clientIsTrackingA =
      client?.goal?.progress_metric_ids_selected?.includes(a.id);
    const clientIsTrackingB =
      client?.goal?.progress_metric_ids_selected?.includes(b.id);

    if (clientIsTrackingA && !clientIsTrackingB) {
      return -1;
    } else if (!clientIsTrackingA && clientIsTrackingB) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  const exerciseMasterIds = useMemo(() => {
    const allExerciseMasterIds = Object.keys(exerciseLibrary).filter(
      (exerciseMasterId) => {
        const exercise = exerciseLibrary[exerciseMasterId];

        return !exercise.reps_variant_id && !exercise.not_quick_variant_id;
      },
    );

    return allExerciseMasterIds;
  }, []);
  const deleteMilestone = useDeleteMilestone();

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!open) {
      setMilestoneData({
        ...(defaultMilestone ?? blankMilestoneData),
        user_id: userId,
        ...(defaultExerciseMasterId && {
          type: "exercise",
          content: { exercise_master_id: defaultExerciseMasterId },
        }),
      });
    }
  }, [open, defaultMilestone, defaultExerciseMasterId, userId]);

  function handleClose() {
    onClose();
  }

  function handleSave() {
    if (!userId) {
      return;
    }

    if (!defaultMilestone) {
      createMilestone.mutate({
        data: milestoneData,
      });
    } else {
      updateMilestone.mutate({
        milestoneId: defaultMilestone._id,
        data: milestoneData,
      });
    }

    handleClose();
  }

  function addTrackedMetric(progressMetricId: string) {
    if (!client?.goal_id) {
      return;
    }

    api.clients
      .editGoal({
        userId: client.user_id,
        goalId: client.goal_id,
        progressMetricSummaries: [
          ...new Set([
            ...(client.goal?.progress_metric_ids_selected ?? []),
            progressMetricId,
          ]),
        ],
      })
      .then((res) => {
        dispatch(
          updateClientLocal({
            user_id: client.user_id,
            goal: res.goal,
          }),
        );
        dispatch(
          updateClientInListLocal({
            user_id: client.user_id,
            goal: res.goal,
          }),
        );
      });
  }

  function removeTrackedMetric(progressMetricId: string) {
    if (!client?.goal_id) {
      return;
    }

    api.clients
      .editGoal({
        userId: client.user_id,
        goalId: client.goal_id,
        progressMetricSummaries: (
          client.goal?.progress_metric_ids_selected ?? []
        ).filter((id) => id !== progressMetricId),
      })
      .then((res) => {
        dispatch(
          updateClientLocal({
            user_id: client.user_id,
            goal: res.goal,
          }),
        );
        dispatch(
          updateClientInListLocal({
            user_id: client.user_id,
            goal: res.goal,
          }),
        );
      });
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      slotProps={{ paper: { sx: { p: 1.5, width: 320 } } }}
    >
      <Typography variant="h3" sx={{ mb: 2 }}>
        {defaultMilestone ? "Edit" : "Create a"} milestone
      </Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Milestone"
          multiline
          value={(milestoneData as MilestoneText).text ?? ""}
          onChange={(event) => {
            setMilestoneData({
              ...milestoneData,
              text: event.target.value,
            });
          }}
          sx={{ mb: 2 }}
          required={milestoneData.type === "text"}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.stopPropagation();
              event.preventDefault();

              (event.target as HTMLInputElement).blur();
            }
          }}
        />
        <TextField
          fullWidth
          label="Details"
          size="small"
          multiline
          value={(milestoneData as MilestoneText).notes ?? ""}
          onChange={(event) => {
            setMilestoneData({
              ...milestoneData,
              notes: event.target.value,
            });
          }}
          sx={{ mb: 2 }}
        />
        {milestoneData.type === "progress_metric" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Autocomplete
              options={progressMetrics.map((m) => m.id)}
              fullWidth
              getOptionLabel={(option) =>
                progressMetrics.find((m) => m.id === option)?.name ?? ""
              }
              renderInput={(params) => (
                <TextField {...params} label="Progress metric" required />
              )}
              value={
                (milestoneData as MilestoneProgressMetric).content
                  .progress_metric_id || null
              }
              groupBy={(option) => {
                const clientTrackingThisMetric =
                  client?.goal?.progress_metric_ids_selected?.includes(option);

                return clientTrackingThisMetric
                  ? "Tracked by client"
                  : "Not tracked by client";
              }}
              renderGroup={(params) => (
                <li key={params.key}>
                  <Typography sx={{ fontWeight: "bold", padding: "4px 10px" }}>
                    {params.group}
                  </Typography>
                  {params.children}
                </li>
              )}
              renderOption={(props, option, _state, ownerState) => {
                const clientTrackingThisMetric =
                  client?.goal?.progress_metric_ids_selected?.includes(option);

                return (
                  <li {...props}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            clientTrackingThisMetric
                              ? undefined
                              : theme.palette.text.disabled,
                        }}
                      >
                        {ownerState.getOptionLabel(option)}
                      </Typography>
                      {client?.goal_id && (
                        <Button
                          size="small"
                          variant="text"
                          onClick={(event) => {
                            event.stopPropagation();

                            if (clientTrackingThisMetric) {
                              removeTrackedMetric(option);
                            } else {
                              addTrackedMetric(option);
                            }
                          }}
                          color={clientTrackingThisMetric ? "error" : undefined}
                        >
                          {!clientTrackingThisMetric ? "Track" : "Untrack"}
                        </Button>
                      )}
                    </Box>
                  </li>
                );
              }}
              onChange={(_event, newValue) => {
                if (
                  !client?.goal?.progress_metric_ids_suggested?.includes(
                    newValue,
                  )
                ) {
                  addTrackedMetric(newValue);
                }

                setMilestoneData({
                  ...milestoneData,
                  content: {
                    ...milestoneData.content,
                    progress_metric_id: newValue,
                  },
                });
              }}
              size="small"
            />
            <IconButton
              size="small"
              onClick={() => {
                setMilestoneData({
                  ...milestoneData,
                  type: "text",
                  content: null,
                });
              }}
            >
              <ClearRoundedIcon />
            </IconButton>
          </Box>
        )}
        {milestoneData.type === "exercise" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Autocomplete
              options={exerciseMasterIds}
              fullWidth
              getOptionLabel={(exerciseMasterId) =>
                getExerciseDisplayName(exerciseMasterId) ?? ""
              }
              getOptionKey={(exerciseMasterId) => exerciseMasterId}
              renderInput={(params) => (
                <TextField {...params} label="Exercise" required />
              )}
              value={
                (milestoneData as MilestoneExercise).content
                  .exercise_master_id || null
              }
              onChange={(_event, newValue) => {
                setMilestoneData({
                  ...milestoneData,
                  content: {
                    exercise_master_id: newValue,
                  },
                });
              }}
              size="small"
            />
            <IconButton
              size="small"
              onClick={() => {
                setMilestoneData({
                  ...milestoneData,
                  type: "text",
                  content: null,
                });
              }}
            >
              <ClearRoundedIcon />
            </IconButton>
          </Box>
        )}
        {milestoneData.type === "text" && (
          <Stack direction={"row"} spacing={1}>
            <Button
              variant="text"
              size="small"
              startIcon={<FitnessCenterRoundedIcon />}
              onClick={() => {
                setMilestoneData({
                  ...milestoneData,
                  type: "exercise",
                  content: {
                    exercise_master_id: "",
                  },
                });
              }}
            >
              Add exercise
            </Button>
            <Button
              variant="text"
              size="small"
              startIcon={<TimelineRoundedIcon />}
              onClick={() => {
                setMilestoneData({
                  ...milestoneData,
                  type: "progress_metric",
                  content: {
                    progress_metric_id: "",
                  },
                });
              }}
            >
              Add progress metric
            </Button>
          </Stack>
        )}
      </Box>
      <FormGroup sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(milestoneData.date_to_achieve)}
              onChange={(event) => {
                if (event.target.checked) {
                  setMilestoneData({
                    ...milestoneData,
                    date_to_achieve: startOfDay(new Date()).toISOString(),
                  });
                } else {
                  setMilestoneData({
                    ...milestoneData,
                    date_to_achieve: null,
                  });
                }
              }}
            />
          }
          label="Set a date"
        />
      </FormGroup>
      {Boolean(milestoneData.date_to_achieve) && (
        <DatePicker
          value={
            milestoneData.date_to_achieve
              ? new Date(milestoneData.date_to_achieve)
              : new Date()
          }
          onChange={(newValue) => {
            setMilestoneData({
              ...milestoneData,
              date_to_achieve: startOfDay(newValue ?? new Date()).toISOString(),
            });
          }}
          slotProps={{
            field: {
              sx: {
                width: "100%",
                mb: 2,
              },
            },
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {defaultMilestone && (
          <Button
            onClick={() => {
              onClose();

              deleteMilestone.mutate({
                milestoneId: defaultMilestone?._id,
              });
            }}
            color="error"
            variant="text"
          >
            Delete
          </Button>
        )}
        <Button
          onClick={handleSave}
          sx={{ ml: 2 }}
          disabled={
            (milestoneData.type === "exercise" &&
              !milestoneData.content.exercise_master_id) ||
            (milestoneData.type === "progress_metric" &&
              !milestoneData.content.progress_metric_id) ||
            (milestoneData.type === "text" && !milestoneData.text)
          }
        >
          Save
        </Button>
      </Box>
    </Popover>
  );
}
