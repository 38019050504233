import { Stack, Tooltip, Typography } from "@mui/material";
import { useWorkoutCompletion } from "@trainwell/features/trainers";
import { isFuture } from "date-fns";
import { useMemo } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import {
  selectAllClients,
  selectAllVisibleClients,
  selectClientLoad,
} from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export function ClientListStats() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const clientLoad = useAppSelector(selectClientLoad);
  const clientInfo = useAppSelector((state) => state.clients.clientInfo);
  const clients = useAppSelector(selectAllClients);
  const { data: workoutCompletion } = useWorkoutCompletion({
    trainerId: trainer?.trainer_id ?? "",
  });
  const visibleClientCount = useAppSelector(
    (state) => selectAllVisibleClients(state).length,
  );
  const completedClientCount = useAppSelector((state) => {
    const visibleClients = selectAllVisibleClients(state);

    return visibleClients.filter((client) => {
      const info = clientInfo[client.user_id];

      if (!info) {
        return false;
      }

      if (info.weeks >= 2 || !client.account.dashboard.date_onboarded) {
        return true;
      }

      return false;
    }).length;
  });

  const referralsInTrialCount = useMemo(() => {
    return clients.filter(
      (c) =>
        c.account.dashboard.date_onboarded &&
        c.account.plan.date_trial_end &&
        isFuture(new Date(c.account.plan.date_trial_end)) &&
        c.referral_ids?.referral_id,
    ).length;
  }, [clients]);

  const clientsInReferralPairCount = useMemo(() => {
    return clients.filter(
      (c) =>
        clientInfo[c.user_id]?.referred_clients.length > 0 ||
        c.referral_ids?.referral_id,
    ).length;
  }, [clients, clientInfo]);

  const clientsWithReferralsInTrialCount = useMemo(() => {
    return clients.filter(
      (c) => clientInfo[c.user_id]?.referred_clients_in_trial.length > 0,
    ).length;
  }, [clients, clientInfo]);

  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
      <Tooltip
        disableInteractive
        title="Referrals pre-payment - Total clients you are training that are in a trial and from a referral"
      >
        <Stack direction={"column"} alignItems={"center"}>
          <Typography>❤️</Typography>
          <Typography>{referralsInTrialCount}</Typography>
        </Stack>
      </Tooltip>
      <Tooltip
        disableInteractive
        title="Referrals sent pre-payment - Total clients you are training that sent a referral where that referral is currently in a trial"
      >
        <Stack direction={"column"} alignItems={"center"}>
          <Typography>💌</Typography>
          <Typography>{clientsWithReferralsInTrialCount}</Typography>
        </Stack>
      </Tooltip>
      <Tooltip
        disableInteractive
        title="Referral clients - Total clients you are training that are part of a referral pair (either sent or received a referral from another active user)"
      >
        <Stack direction={"column"} alignItems={"center"}>
          <Typography>💙</Typography>
          <Typography>{clientsInReferralPairCount}</Typography>
        </Stack>
      </Tooltip>
      <Tooltip disableInteractive title="Client capacity">
        <Stack direction={"column"} alignItems={"center"}>
          <Typography>👤</Typography>
          <Typography>{trainer?.client_capacity}</Typography>
        </Stack>
      </Tooltip>
      <Tooltip disableInteractive title="Client load">
        <Stack direction={"column"} alignItems={"center"}>
          <Typography>👩</Typography>
          <Typography>{clientLoad}</Typography>
        </Stack>
      </Tooltip>
      <Tooltip disableInteractive title="Workout completion">
        <Stack direction={"column"} alignItems={"center"}>
          <Typography>🏋️</Typography>
          <Typography>
            {workoutCompletion ? Math.round(workoutCompletion) : "--"}%
          </Typography>
        </Stack>
      </Tooltip>
      <Tooltip disableInteractive title="Clients complete">
        <Stack direction={"column"} alignItems={"center"}>
          <Typography>✅</Typography>
          <Typography>
            {completedClientCount} / {visibleClientCount} (
            {Math.round((completedClientCount / visibleClientCount) * 100)}
            %)
          </Typography>
        </Stack>
      </Tooltip>
    </Stack>
  );
}
