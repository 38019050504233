import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Navigate } from "@tanstack/react-router";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ActionItemSelector } from "../action-items/ActionItemSelector";
import ClientSelector from "./ClientSelector";
import { HomeControlBar } from "./HomeControlBar";

const actionDrawerColumnWidth = 300;

export default function HomePage() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const dashMode = useAppSelector((state) => state.app.dashMode);
  const actionItemsEnabled = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.action_items_enabled ?? false,
  );
  const actionItemsDrawerDefault = useAppSelector(
    (state) =>
      selectPrimaryTrainer(state)?.settings?.action_item_panel_open ?? false,
  );
  const [open, setOpen] = useState(actionItemsDrawerDefault);

  if (isPhone) {
    return (
      <Navigate
        to="/chat"
        search={{
          pwa: true,
        }}
      />
    );
  }

  console.log("Render: home");

  return (
    <>
      <HomeControlBar
        drawerOpen={open}
        onToggleDrawer={() => {
          setOpen(!open);
        }}
      />
      <Box sx={{ display: "flex" }}>
        {dashMode === "default" && actionItemsEnabled && open && (
          <Box
            sx={{
              width: actionDrawerColumnWidth,
              flexShrink: 0,
            }}
          >
            <ActionItemSelector />
          </Box>
        )}
        <main style={{ flex: 1 }}>
          <ClientSelector />
        </main>
      </Box>
    </>
  );
}
