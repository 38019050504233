import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import {
  Box,
  IconButton,
  Typography,
  type TypographyProps,
} from "@mui/material";
import { useState } from "react";

interface Props extends TypographyProps {
  value: string;
  children: React.ReactNode;
}

export function TypographyCopyable({
  children,
  value,
  ...typographyProps
}: Props) {
  const [copied, setCopied] = useState(false);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography {...typographyProps}>{children}</Typography>
      <IconButton
        size="small"
        sx={{ ml: 0.5 }}
        color={copied ? "success" : undefined}
        onClick={() => {
          void navigator.clipboard.writeText(value).then(() => {
            setCopied(true);

            setTimeout(() => {
              setCopied(false);
            }, 2000);
          });
        }}
      >
        {copied ? (
          <CheckRoundedIcon fontSize="inherit" />
        ) : (
          <ContentCopyRoundedIcon fontSize="inherit" />
        )}
      </IconButton>
    </Box>
  );
}
