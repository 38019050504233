import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import type { MessageCancelRequested } from "@trainwell/features/legacy";
import { format } from "date-fns";

interface Props {
  message: MessageCancelRequested;
  combineBottom: boolean;
}

export function CancelRequestedMessage({ message, combineBottom }: Props) {
  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        p: 1,
        width: "fit-content",
      }}
    >
      <Stack direction="column" spacing={1} alignItems={"flex-start"}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RemoveCircleRoundedIcon
            sx={{
              mr: 1,
            }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Cancel requested
            </Typography>
            {message.content.cancel_at && (
              <Typography variant="overline">
                Scheduled for {format(message.content.cancel_at, "MMM d, yyyy")}
              </Typography>
            )}
          </Box>
        </Box>
        {message.content.cancel_options && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {message.content.cancel_options.map((option, index) => {
              const isQuestionedReason =
                message.content.cancel_option_responded_to ===
                option.toLocaleLowerCase();
              return (
                <Tooltip
                  key={index}
                  disableInteractive
                  placement="right"
                  title={
                    isQuestionedReason
                      ? "The client was asked to elaborate on this option"
                      : ""
                  }
                >
                  <Chip
                    size="small"
                    label={
                      option === "Other"
                        ? `Other: ${message.content.cancel_option_custom}`
                        : option
                    }
                    icon={
                      isQuestionedReason ? (
                        <QuestionAnswerRoundedIcon />
                      ) : undefined
                    }
                  />
                </Tooltip>
              );
            })}
          </Box>
        )}
        {message.content.reason_client && (
          <Typography
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            &quot;{message.content.reason_client}&quot;
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
