import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Trainer } from "@trainwell/features/legacy";
import set from "lodash-es/set";
import { api } from "src/lib/trainwellApi";

export const fetchTrainerEdit = createAsyncThunk(
  "trainerEdit/fetchTrainerEdit",
  async (trainerID: string) => {
    const coach = await api.trainers.getOne(trainerID);
    return coach;
  },
);

// Define a type for the slice state
interface TriainerEditState {
  trainer: Trainer | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: TriainerEditState = {
  trainer: undefined,
  status: "idle",
  error: undefined,
};

export const trainerEditSlice = createSlice({
  name: "trainerEdit",
  initialState,
  reducers: {
    resetTrainerEdit: () => initialState,
    updateTrainerEdit: (
      state,
      action: PayloadAction<Partial<Trainer> & Pick<Trainer, "trainer_id">>,
    ) => {
      const update = action.payload;

      if (update.trainer_id === state.trainer?.trainer_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.trainer, key, value);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainerEdit.pending, (state) => {
      state.status = "loading";

      state.trainer = undefined;
    });
    builder.addCase(fetchTrainerEdit.fulfilled, (state, action) => {
      console.log("Redux: Got coach to edit");
      state.status = "succeeded";

      const coach = action.payload;

      state.trainer = coach;
    });
    builder.addCase(fetchTrainerEdit.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetTrainerEdit, updateTrainerEdit } = trainerEditSlice.actions;

export default trainerEditSlice.reducer;
