import { Typography } from "@mui/material";

export const TextItem = ({ text }: { text: string }) => (
  <Typography
    variant="body1"
    sx={{
      padding: "8px 10px",
      borderRadius: "8px",
      bgcolor: (theme) => theme.palette.backgroundSecondary.main,
      minHeight: "36px",
    }}
  >
    {text}
  </Typography>
);
