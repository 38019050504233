import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  setEquipmentWarningDialog,
  toggleEquipment,
  toggleIsInsertingTemplate,
} from "src/slices/workoutSlice";
import { WorkoutLogCard } from "../workout-logs/WorkoutLogCard";
import SectionCell from "./SectionCell";
import { WorkoutBuilderFooter } from "./WorkoutBuilderFooter";
import WorkoutBuilderHelper from "./WorkoutBuilderHelper";
import { WorkoutHeader } from "./WorkoutHeader";

export default function WorkoutBuilder() {
  const dispatch = useAppDispatch();
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);
  const workoutStatus = useAppSelector((state) => state.workout.status);
  const workout = useAppSelector(
    (state) => state.workout.workoutNormalized?.result,
  );
  const equipmentWarningOpen = useAppSelector(
    (state) => state.workout.equipmentWarningOpen,
  );

  useHotkeys("t", () => {
    if (!isTemplate) {
      dispatch(toggleIsInsertingTemplate());
    }
  });

  const dndScrollableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = dndScrollableRef.current;
    if (!element) {
      return;
    }

    return autoScrollForElements({
      element,
    });
  });

  if (!workout || workoutStatus !== "succeeded") {
    return <LoadingPage message="Getting your workout ready 💪" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          overflowY: "hidden",
        }}
      >
        <WorkoutHeader />
        <Box
          ref={dndScrollableRef}
          sx={{ overflowY: "auto", flex: 1, maxHeight: "100%", pb: 16 }}
        >
          <WorkoutLogCard />
          <Stack sx={{ pt: 2 }}>
            {workout.sections.map((sectionId, index) => (
              <SectionCell
                key={sectionId}
                sectionId={sectionId}
                sectionIndex={index}
                isLastSection={index === workout.sections.length - 1}
              />
            ))}
            {workout.sections.length === 0 && <EmptyWorkoutDroppable />}
          </Stack>
          <WorkoutBuilderFooter />
        </Box>
      </Box>
      <Dialog
        open={equipmentWarningOpen !== null}
        onClose={() => {
          dispatch(setEquipmentWarningDialog(null));
        }}
      >
        <DialogTitle>Check set notes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Some set notes reference equipment. Make sure they are accurate when
            swapping.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setEquipmentWarningDialog(null));
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              dispatch(
                toggleEquipment({
                  exerciseId: equipmentWarningOpen!.exerciseId,
                  equipmentPickerId: equipmentWarningOpen!.equipmentPickerId,
                }),
              );
            }}
          >
            Swap anyways
          </Button>
        </DialogActions>
      </Dialog>
      <WorkoutBuilderHelper />
    </Box>
  );
}

function EmptyWorkoutDroppable() {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "empty_workout" }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return (
          source.data.type === "template" ||
          source.data.type === "exercise_source"
        );
      },
    });
  }, []);

  return (
    <div ref={ref}>
      <Box
        sx={{
          p: 2,
          display: "flex",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: 1,
          borderColor: (theme) =>
            isDraggedOver ? theme.palette.primary.main : theme.palette.divider,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          m: 2,
          transitionProperty: "border-color, background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
        }}
      >
        <AddRoundedIcon
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        >
          Drop exercises or templates here
        </Typography>
      </Box>
    </div>
  );
}
