import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  Box,
  Card,
  CardActionArea,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { updateClient } from "src/slices/clientSlice";
import { selectWorkoutById } from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../phase-column/WorkoutPreviewPopover";

interface DraggableProps {
  workoutId: string;
  index: number;
}

export function BackupWorkoutCell({ workoutId, index }: DraggableProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId),
  );
  const client = useAppSelector((state) => state.client.client);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "backup_workout",
      workoutId: workoutId,
      index: index,
    };

    return combine(
      draggable({
        element: element,
        getInitialData: () => data,
        onDragStart: () => {
          setDragging(true);
        },
        onDrop: () => {
          setDragging(false);
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            (source.data.type === "workout" ||
              source.data.type === "workout_extra" ||
              source.data.type === "workout_task" ||
              source.data.type === "workout_task_past" ||
              source.data.type === "backup_workout") &&
            (source.data.type === "backup_workout" ||
              !client?.backup_workout_ids?.includes(
                source.data.workoutId as string,
              ))
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            setClosestEdge(closestEdge);

            return;
          }

          if (source.data.type !== "backup_workout") {
            setClosestEdge(closestEdge);

            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [client?.backup_workout_ids, index, workoutId]);

  if (!workout) {
    return null;
  }

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.5 : 1,
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
        >
          <Card variant="outlined">
            <CardActionArea
              href={`/clients/${workout.user_id}/workouts/${workout.workout_id}`}
              onContextMenu={(event) => {
                event.preventDefault();

                setAnchorEl(event.currentTarget);
              }}
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "grab",
              }}
              draggable={false}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DragIndicatorRoundedIcon />
                <Typography sx={{ ml: 1 }}>{workout.name}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {Math.floor(
                    workoutLib.workouts.getWorkoutDuration(workout) / 60,
                  )}
                  m
                </Typography>
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    dispatch(
                      updateClient({
                        user_id: workout.user_id,
                        backup_workout_ids: client?.backup_workout_ids?.filter(
                          (id) => id !== workout.workout_id,
                        ),
                      }),
                    );
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                  sx={{ ml: 1 }}
                >
                  <RemoveCircleOutlineRoundedIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </CardActionArea>
          </Card>
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview text={workout.name ?? "Workout"} />,
            previewContainer,
          )
        : null}
      {anchorEl && (
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          workoutId={workout.workout_id}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
    </>
  );
}
