import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  alpha,
  Box,
  Chip,
  Link,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import type { Workout } from "@trainwell/features/legacy";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectClientsWorkoutValidity } from "src/slices/clientSlice";
import { selectExtraWorkouts, updateWorkout } from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../phase-column/WorkoutPreviewPopover";

interface Props {
  sx?: SxProps<Theme>;
}

export function ExtraWorkouts({ sx = [] }: Props) {
  const extraWorkouts = useAppSelector(selectExtraWorkouts);

  const extraWorkoutIds = extraWorkouts.map((w) => w.workout_id);

  const hasExtraWorkouts = extraWorkoutIds.length > 0;

  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "extra_workouts" }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return (
          hasExtraWorkouts &&
          !source.data.is_extra &&
          (source.data.type === "workout" ||
            source.data.type === "backup_workout" ||
            source.data.type === "workout_task" ||
            source.data.type === "workout_task_past")
        );
      },
    });
  }, [hasExtraWorkouts]);

  return (
    <Box ref={ref} sx={sx}>
      <Box
        sx={{
          m: -1,
          p: 1,
          borderRadius: 1,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          transitionProperty: "background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 1,
          }}
        >
          <StarRoundedIcon fontSize="inherit" />
          <Typography variant="h3" sx={{ ml: 1 }}>
            Extras
          </Typography>
        </Box>
        {!extraWorkoutIds.length && <EmptyDroppable />}
        {extraWorkoutIds.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {extraWorkouts?.map(
              (w, index) =>
                w && (
                  <ExtraWorkoutLink
                    key={w.workout_id}
                    workout={w}
                    index={index}
                  />
                ),
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

function ExtraWorkoutLink({
  workout,
  index,
}: {
  workout: Workout;
  index: number;
}) {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const workoutValidity = useAppSelector((state) =>
    selectClientsWorkoutValidity(state, workout.workout_id ?? ""),
  );
  const hasError = workoutValidity?.status === "error";
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "workout_extra",
      workoutId: workout.workout_id,
      index: index,
      is_extra: true,
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onDragStart: () => {
        setDragging(true);
      },
      onDrop: () => {
        setDragging(false);
      },
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
    });
  }, [index, workout.workout_id]);

  return (
    <>
      <Tooltip disableInteractive title={workoutValidity?.message}>
        <Link
          ref={ref}
          href={`/clients/${workout.user_id}/workouts/${workout.workout_id}`}
          onContextMenu={(event) => {
            event.preventDefault();

            setAnchorEl(event.currentTarget);
          }}
        >
          <Chip
            size="small"
            label={workout.name}
            onDelete={(event) => {
              if ("stopPropagation" in event && "preventDefault" in event) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                event.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                event.preventDefault();
              }

              dispatch(
                updateWorkout({
                  workout_id: workout.workout_id,
                  is_extra: false,
                }),
              );
            }}
            sx={{
              opacity: dragging ? 0.5 : 1,
              backgroundColor: (theme) =>
                hasError ? theme.palette.errorSurface.main : undefined,
              color: (theme) =>
                hasError ? theme.palette.error.main : undefined,
              "& .MuiChip-label:hover": {
                textDecoration: "underline",
              },
            }}
          />
        </Link>
      </Tooltip>
      {previewContainer
        ? createPortal(<DragPreview text={workout.name} />, previewContainer)
        : null}
      {anchorEl && (
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          workoutId={workout.workout_id}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
    </>
  );
}

function EmptyDroppable() {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "empty_extra_workouts" }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return (
          !source.data.is_extra &&
          (source.data.type === "workout" ||
            source.data.type === "backup_workout" ||
            source.data.type === "workout_task" ||
            source.data.type === "workout_task_past")
        );
      },
    });
  }, []);

  return (
    <div ref={ref}>
      <Box
        sx={{
          p: 1,
          display: "flex",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: 1,
          borderColor: (theme) =>
            isDraggedOver ? theme.palette.primary.main : theme.palette.divider,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          // justifyContent: "center",
          // flexDirection: "column",
          alignItems: "center",
          transitionProperty: "border-color, background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
          width: "fit-content",
        }}
      >
        <AddRoundedIcon
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
            ml: 1,
          }}
        >
          Drop workouts here
        </Typography>
      </Box>
    </div>
  );
}
