import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import { Box, Button, Stack, Typography, alpha } from "@mui/material";
import { useParams } from "@tanstack/react-router";
import { useVirtualizer } from "@tanstack/react-virtual";
import type { TemplateLibraryFolder } from "@trainwell/features/legacy";
import { useEffect, useMemo, useRef, useState, type ReactNode } from "react";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  selectPhaseTemplateFolderById,
  selectPhaseTemplateFoldersByParentId,
  selectPhaseTemplatesByParentId,
  setTemplateNavigation,
} from "src/slices/phaseTemplatesSlice";
import type { PhaseTemplateLocal } from "src/types/PhaseTemplateLocal";
import FolderCell from "./FolderCell";
import PhaseRequestDialog from "./PhaseRequestDialog";
import PhaseTemplateCell from "./PhaseTemplateCell";
import WorkoutTemplateCell from "./WorkoutTemplateCell";

export default function FolderPage() {
  let { phaseTemplateFolderId } = useParams({ strict: false });
  const memoryFolderId = useAppSelector(
    (state) => state.phaseTemplates.openFolderId,
  );

  if (memoryFolderId) {
    phaseTemplateFolderId = memoryFolderId;
  }

  const phaseTemplateFolders = useAppSelector((state) =>
    selectPhaseTemplateFoldersByParentId(state, phaseTemplateFolderId ?? ""),
  );
  const phaseTemplates = useAppSelector((state) =>
    selectPhaseTemplatesByParentId(state, phaseTemplateFolderId ?? ""),
  );
  const [search, setSearch] = useState("");
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "page", folderId: phaseTemplateFolderId }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return source.data.type === "phase_template_workout_template";
      },
    });
  }, [phaseTemplateFolderId]);

  const { filteredPhaseTemplates, filteredWorkoutTemplates } = useMemo(() => {
    let newPhaseTemplates = [...phaseTemplates];

    if (search) {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.name.toLowerCase().includes(search.toLowerCase());
      });
    }

    newPhaseTemplates.sort((a, b) => {
      // Sort pinned to the top
      if (a.is_pinned && !b.is_pinned) {
        return -1;
      } else if (!a.is_pinned && b.is_pinned) {
        return 1;
      }

      // Sort by date updated
      return ((b.date_updated ?? b.date_created) as string).localeCompare(
        (a.date_updated ?? a.date_created) as string,
      );
    });

    return {
      filteredPhaseTemplates: newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.type === "multiple";
      }),
      filteredWorkoutTemplates: newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.type === "single";
      }),
    };
  }, [phaseTemplates, search]);

  const filteredPhaseTemplateFolders = useMemo(() => {
    let newPhaseTemplateFolders = [...phaseTemplateFolders];

    if (search) {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return folder.name.toLowerCase().includes(search.toLowerCase());
      });
    }

    newPhaseTemplateFolders.sort((a, b) => {
      // Sort pinned to the top
      if (a.is_pinned && !b.is_pinned) {
        return -1;
      } else if (!a.is_pinned && b.is_pinned) {
        return 1;
      }

      // Sort by name alphabetically
      return a.name.localeCompare(b.name);
    });

    return newPhaseTemplateFolders.map((folder) => {
      return { ...folder, is_folder: true };
    });
  }, [phaseTemplateFolders, search]);

  const items = useMemo(() => {
    return [
      ...filteredPhaseTemplateFolders,
      ...filteredPhaseTemplates,
      ...filteredWorkoutTemplates,
    ];
  }, [
    filteredPhaseTemplateFolders,
    filteredPhaseTemplates,
    filteredWorkoutTemplates,
  ]);

  if (!phaseTemplateFolderId) {
    return null;
  }

  return (
    <>
      <Box
        ref={ref}
        sx={{
          px: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: (theme) =>
            isDraggedOver ? alpha(theme.palette.primary.main, 0.1) : undefined,
        }}
      >
        <Header context={{ search, setSearch }} />
        <VirtualList items={items} />
      </Box>
    </>
  );
}

interface VirtualListProps {
  items: (
    | PhaseTemplateLocal
    | (TemplateLibraryFolder & { is_folder: boolean })
  )[];
}

function VirtualList({ items }: VirtualListProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 160,
    getItemKey: (index) => items[index]._id,
  });
  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={parentRef}
      style={{
        flex: 1,
        overflow: "auto",
        contain: "strict",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map((virtualItem) => {
            const item = items[virtualItem.index];

            let content: ReactNode;

            if ("is_folder" in item) {
              content = <FolderCell phaseTemplateFolderId={item._id} />;
            } else if (item.type === "multiple") {
              content = <PhaseTemplateCell phaseTemplateId={item._id} />;
            } else {
              content = <WorkoutTemplateCell phaseTemplateId={item._id} />;
            }

            return (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
              >
                {content}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

interface HeaderProps {
  context: {
    search: string;
    setSearch: (value: string) => void;
  };
}

function Header({ context: { search, setSearch } }: HeaderProps) {
  const dispatch = useAppDispatch();
  let { phaseTemplateFolderId } = useParams({ strict: false });
  const memoryFolderId = useAppSelector(
    (state) => state.phaseTemplates.openFolderId,
  );

  if (memoryFolderId) {
    phaseTemplateFolderId = memoryFolderId;
  }

  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);
  const phaseTemplateFolder = useAppSelector((state) =>
    selectPhaseTemplateFolderById(state, phaseTemplateFolderId!),
  );
  const parentPhaseTemplateFolder = useAppSelector((state) =>
    selectPhaseTemplateFolderById(
      state,
      phaseTemplateFolder?.parent_folder_id ?? "",
    ),
  );
  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);

  if (!phaseTemplateFolder) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1, pt: 1 }}>
        <Button
          variant="text"
          color="primary"
          startIcon={<ExtensionRoundedIcon />}
          href={isDialog ? "" : "/template-library"}
          onClick={() => {
            if (isDialog) {
              dispatch(
                setTemplateNavigation({
                  openFolderId: null,
                  openTagId: null,
                }),
              );
            }
          }}
        >
          Template library
        </Button>
        <ChevronRightRoundedIcon
          fontSize="small"
          sx={{ mx: 1, color: (theme) => theme.palette.text.secondary }}
        />
        {parentPhaseTemplateFolder && (
          <>
            <Button
              variant="text"
              color="primary"
              startIcon={<FolderRoundedIcon />}
              href={
                isDialog
                  ? ""
                  : `/template-library/folders/${parentPhaseTemplateFolder._id}`
              }
              onClick={() => {
                if (isDialog) {
                  dispatch(
                    setTemplateNavigation({
                      openFolderId: parentPhaseTemplateFolder._id,
                      openTagId: null,
                    }),
                  );
                }
              }}
            >
              {parentPhaseTemplateFolder.name}
            </Button>
            <ChevronRightRoundedIcon
              fontSize="small"
              sx={{ mx: 1, color: (theme) => theme.palette.text.secondary }}
            />
          </>
        )}
        <FolderRoundedIcon
          sx={{ color: (theme) => theme.palette.text.secondary }}
          fontSize="small"
        />
        <Typography sx={{ ml: 1 }}>{phaseTemplateFolder.name}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FolderRoundedIcon
          sx={{ color: (theme) => theme.palette.text.secondary }}
        />
        <Typography variant="h1" sx={{ ml: 1 }}>
          {phaseTemplateFolder.name}
        </Typography>
      </Box>
      {phaseTemplateFolder.description && (
        <Typography sx={{ mt: 1 }}>
          {phaseTemplateFolder.description}
        </Typography>
      )}
      <Stack
        direction={"row"}
        spacing={2}
        sx={{ mb: 2, mt: 2, alignItems: "center" }}
      >
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          sx={{ maxWidth: "300px" }}
          placeholder="Search folder"
        />
        {currentTab === "trainwell" && (
          <Button
            size="small"
            variant="text"
            startIcon={<CampaignRoundedIcon />}
            onClick={() => {
              setRequestDialogOpen(true);
            }}
          >
            Request
          </Button>
        )}
      </Stack>
      <PhaseRequestDialog
        open={requestDialogOpen}
        onClose={() => {
          setRequestDialogOpen(false);
        }}
      />
    </Box>
  );
}
