import {
  LinearProgress,
  Stack,
  Typography,
  type StackProps,
  type TypographyProps,
} from "@mui/material";
import type { ClientMealLog } from "@trainwell/features/nutrition";

type Size = "sm" | "lg";

interface ScoreStackProps extends StackProps {
  size: Size;
  log: ClientMealLog;
}
interface TitleProps extends TypographyProps {
  label: string;
  fractionalValue: number | null;
  size: Size;
}

const ScoreRow = ({ size, label, fractionalValue }: TitleProps) => (
  <Stack spacing={size === "sm" ? 0.25 : 0.5}>
    <Stack direction="row" justifyContent="space-between">
      <Typography variant={size === "sm" ? "overline" : "body1"}>
        {label}
      </Typography>
      <Typography variant={size === "sm" ? "overline" : "body1"}>
        {fractionalValue !== null
          ? `${Math.round(fractionalValue * 100)}%`
          : "?"}
      </Typography>
    </Stack>
    <LinearProgress
      variant="determinate"
      value={(fractionalValue ?? 0) * 100}
      sx={{
        borderRadius: 1,
      }}
    />
  </Stack>
);

export const ScoreStack = ({ log, size, sx, ...rest }: ScoreStackProps) => (
  <Stack spacing={1} sx={{ ...sx }} {...rest}>
    <ScoreRow size={size} label="Eatwell" fractionalValue={log.eatwell_score} />
    <ScoreRow
      size={size}
      label="Lightness"
      fractionalValue={1.0 - log.caloric_density_estimate}
    />
    <ScoreRow
      size={size}
      label="Fillingness"
      fractionalValue={log.satiation_estimate}
    />
    <ScoreRow
      size={size}
      label="Sentiment"
      fractionalValue={log.sentiment_rating ? log.sentiment_rating / 4 : null}
    />
  </Stack>
);
