import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import type { SxProps, Theme } from "@mui/material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Editor } from "@tiptap/core";
import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import type { MagicKeyOption } from "@trainwell/features/magic-keys";
import { useCallback, useMemo, useState } from "react";
import { makeMagicKeySuggestionOptions } from "../common/magic-keys/magicKeySuggestionOptions";
import { MagicKeyTextField } from "../common/magic-keys/MagicKeyTextField";

interface Props {
  label?: string;
  name?: string;
  values?: string[];
  previewSuffix?: string;
  onSave: (newValues: string[]) => void;
  magicKeyOptions: MagicKeyOption[];
  sx?: SxProps<Theme>;
}

export function SmartEditListMagicKeys({
  label,
  name,
  values,
  onSave,
  previewSuffix,
  magicKeyOptions,
  sx = [],
}: Props) {
  const [editDialogData, setEditDialogData] = useState<{
    editIndex: number | null;
    editValue: string;
    open: boolean;
  }>({
    editIndex: null,
    editValue: "",
    open: false,
  });

  const magicKeyOptionsFlat = useMemo(() => {
    return magicKeyOptions
      .map((magicKey) =>
        magicKey.subMagicKeys ? magicKey.subMagicKeys : magicKey,
      )
      .flat();
  }, [magicKeyOptions]);

  const magicKeySuggestionOptions = useMemo(() => {
    return makeMagicKeySuggestionOptions(magicKeyOptions);
  }, [magicKeyOptions]);

  const getPreviewText = useCallback(
    (messageHtml: string) => {
      return new Editor({
        content: messageHtml,
        extensions: [
          Document,
          Paragraph,
          Text,
          Mention.configure({
            deleteTriggerWithBackspace: true,
            suggestion: magicKeySuggestionOptions,
            HTMLAttributes: {
              class: "mention",
            },
          }),
        ],
      }).getText({
        blockSeparator: "\n",
        textSerializers: {
          mention: ({ node }) => {
            return (
              magicKeyOptionsFlat.find((leaf) => leaf.id === node.attrs.id)
                ?.example ?? "{ ERROR }"
            );
          },
        },
      });
    },
    [magicKeySuggestionOptions, magicKeyOptionsFlat],
  );

  return (
    <Box sx={Array.isArray(sx) ? sx : [sx]}>
      {label && (
        <Typography
          variant="h6"
          sx={{
            mb: 0.5,
          }}
        >
          {label}
        </Typography>
      )}
      {(values?.length ?? 0) > 0 && (
        <Box
          component="ul"
          sx={{
            m: 0,
            pl: 0,
            border: 1,
            borderColor: "divider",
            borderRadius: 1,
            mb: 1,
          }}
        >
          {values?.map((value, index) => (
            <Box
              component={"li"}
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                py: 0.5,
                px: 1,
                backgroundColor: (theme) =>
                  index % 2 === 1 ? theme.palette.action.hover : undefined,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {getPreviewText(value)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  sx={{
                    mr: 1,
                  }}
                  onClick={() => {
                    setEditDialogData({
                      editIndex: index,
                      editValue: value,
                      open: true,
                    });
                  }}
                >
                  <EditRoundedIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  size="small"
                  sx={{
                    mr: 1,
                  }}
                  onClick={() => {
                    onSave(values.filter((_, i) => i !== index));
                  }}
                >
                  <DeleteRoundedIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Button
        variant="text"
        startIcon={<AddRoundedIcon />}
        onClick={() => {
          setEditDialogData({
            editIndex: null,
            editValue: "",
            open: true,
          });
        }}
      >
        Add new
      </Button>
      <Dialog
        open={editDialogData.open}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setEditDialogData({
            editIndex: null,
            editValue: "",
            open: false,
          });
        }}
      >
        <DialogTitle>
          {editDialogData.editIndex === null
            ? `Add${name ? " " + name : ""}`
            : `Edit${name ? " " + name : ""}`}
        </DialogTitle>
        <DialogContent>
          <MagicKeyTextField
            defaultHtml={editDialogData.editValue}
            magicKeyOptions={magicKeyOptions}
            previewSuffix={previewSuffix}
            enableEmojiButton
            onUpdate={(newHtml) => {
              setEditDialogData((prev) => ({
                ...prev,
                editValue: newHtml,
              }));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setEditDialogData({
                editIndex: null,
                editValue: "",
                open: false,
              });
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (editDialogData.editIndex !== null) {
                const newValues = [...(values || [])];
                newValues[editDialogData.editIndex] = editDialogData.editValue;
                onSave(newValues);
              } else {
                onSave([...(values || []), editDialogData.editValue]);
              }
              setEditDialogData({
                editIndex: null,
                editValue: "",
                open: false,
              });
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
