import AndroidRoundedIcon from "@mui/icons-material/AndroidRounded";
import AppleIcon from "@mui/icons-material/Apple";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import CakeRoundedIcon from "@mui/icons-material/CakeRounded";
import DirectionsWalkRoundedIcon from "@mui/icons-material/DirectionsWalkRounded";
import FollowTheSignsRoundedIcon from "@mui/icons-material/FollowTheSignsRounded";
import HeightRoundedIcon from "@mui/icons-material/HeightRounded";
import ScaleRoundedIcon from "@mui/icons-material/ScaleRounded";
import SentimentSatisfiedRoundedIcon from "@mui/icons-material/SentimentSatisfiedRounded";
import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import {
  Box,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useCompany } from "@trainwell/features/company";
import { getConvertedHeight, getConvertedWeight } from "@trainwell/workout-lib";
import {
  addMonths,
  differenceInYears,
  format,
  isSameMonth,
  isThisMonth,
  isToday,
  setYear,
  startOfDay,
  subYears,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useState } from "react";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import SmartEditDateField from "src/components/misc/SmartEditDateField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  getDateForDatabase,
  getDateFromDatabase,
  getZeroedOutUtcDate,
} from "src/lib/date";
import { api } from "src/lib/trainwellApi";
import { updateClient, updateClientLocal } from "src/slices/clientSlice";

const workoutDurations: Record<string, string> = {
  "10m": "0:10",
  "15m": "0:15",
  "20m": "0:20",
  "30m": "0:30",
  "45m": "0:45",
  "60m": "1:00",
  "75m": "1:15",
  "90m": "1:30",
  "120m": "2:00",
};

interface Props {
  sx: SxProps<Theme>;
}

export function ClientChips({ sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const realTrainer = useAppSelector((state) => state.trainer.trainer);
  const { data: company } = useCompany();

  const [weightAnchorEl, setWeightAnchorEl] = useState<HTMLDivElement | null>(
    null,
  );
  const weightOpen = Boolean(weightAnchorEl);

  const [identityAnchorEl, setIdentityAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const identityOpen = Boolean(identityAnchorEl);

  const [heightAnchorEl, setHeightAnchorEl] = useState<HTMLDivElement | null>(
    null,
  );
  const heightOpen = Boolean(heightAnchorEl);

  const [watchAnchorEl, setWatchAnchorEl] = useState<HTMLDivElement | null>(
    null,
  );
  const watchOpen = Boolean(watchAnchorEl);

  const [workoutDurationAnchorEl, setWorkoutDurationAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const workoutDurationOpen = Boolean(workoutDurationAnchorEl);

  const [stepsAnchorEl, setStepsAnchorEl] = useState<HTMLDivElement | null>(
    null,
  );
  const stepsOpen = Boolean(stepsAnchorEl);

  const [ageMode, setAgeMode] = useState<"years" | "birthday">(
    client?.is_birthday_real ? "birthday" : "years",
  );
  const [ageAnchorEl, setAgeAnchorEl] = useState<HTMLDivElement | null>(null);
  const ageOpen = Boolean(ageAnchorEl);

  const [billingAnchorEl, setBillingAnchorEl] = useState<HTMLDivElement | null>(
    null,
  );
  const billingOpen = Boolean(billingAnchorEl);

  const [liveTrainingAnchorEl, setLiveTrainingAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const liveTrainingOpen = Boolean(liveTrainingAnchorEl);

  const hasUltraDiscountPlan = [
    "5d8e0b1c-ab02-4b0b-8210-a221056850c4",
    "6bcc2255-194a-4c7e-831e-7d49a9ee934a",
    "a2d1b679-7949-4b12-b7f3-73b0e23322c9",
    "839fac4d-4818-4997-83e6-09beac2cb3d0",
  ].includes(client?.account.plan.subscription_group_id ?? "");

  const sanitizedGender = [
    "Man",
    "Woman",
    "Non-binary",
    "Prefer not to say",
    "Other",
  ].includes(client?.gender ?? "--")
    ? client?.gender
    : "Other";

  if (!client) {
    return null;
  }

  const isTodayClientsBirthday =
    client.is_birthday_real &&
    client.birthday &&
    isToday(
      setYear(
        toZonedTime(client.birthday, "Etc/UTC"),
        new Date().getFullYear(),
      ),
    );

  const callClaimedThisMonth =
    client.most_recent_live_training_call_date &&
    isThisMonth(
      toZonedTime(client.most_recent_live_training_call_date, "Etc/UTC"),
    );
  const callClaimedNextMonth =
    client.most_recent_live_training_call_date &&
    isSameMonth(
      toZonedTime(client.most_recent_live_training_call_date, "Etc/UTC"),
      addMonths(new Date(), 1),
    );

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, ...sx }}>
        <Tooltip
          disableInteractive
          title={
            isTodayClientsBirthday ? "Today is the client's birthday!!!" : ""
          }
        >
          <Chip
            size="small"
            icon={<CakeRoundedIcon />}
            color={isTodayClientsBirthday ? "successSurface" : "default"}
            sx={{
              border: isTodayClientsBirthday ? 1 : undefined,
              borderColor: (theme) =>
                isTodayClientsBirthday ? theme.palette.success.main : undefined,
            }}
            label={`
          ${client.is_birthday_real && client.birthday ? "" : "~"}
          ${
            client.birthday
              ? `${differenceInYears(new Date(), client.birthday)} y`
              : client.age
                ? `${client.age?.toString()} yrs`
                : "--"
          }${isTodayClientsBirthday ? " 🎉🎉🎉" : ""}`}
            onClick={(event) => {
              setAgeAnchorEl(event.currentTarget);
            }}
          />
        </Tooltip>
        <Chip
          size="small"
          icon={<HeightRoundedIcon />}
          label={
            client.height === undefined
              ? "--"
              : client.preferred_weight_system === "metric"
                ? `${getConvertedHeight({
                    height: client.height ?? 0,
                    fromSystem: "imperial",
                    toSystem: client.preferred_weight_system,
                    round: true,
                  })}cm`
                : client.height
                  ? Math.floor(client.height / 12) +
                    "' " +
                    Math.round(client.height % 12) +
                    '"'
                  : "--"
          }
          onClick={(event) => {
            setHeightAnchorEl(event.currentTarget);
          }}
        />
        <Chip
          size="small"
          icon={<ScaleRoundedIcon />}
          label={
            Math.round(
              getConvertedWeight({
                weight: client.weight,
                fromSystem: "imperial",
                toSystem: client.preferred_weight_system,
                round: true,
              }),
            ) +
            " " +
            (client.preferred_weight_system === "imperial" ? "lbs" : "kg")
          }
          onClick={(event) => {
            setWeightAnchorEl(event.currentTarget);
          }}
        />
        <Chip
          size="small"
          icon={<SentimentSatisfiedRoundedIcon />}
          label={client.gender}
          onClick={(event) => {
            setIdentityAnchorEl(event.currentTarget);
          }}
        />
        <Tooltip
          disableInteractive
          title={`Current: ${client.app_version || "unknown"}. Latest: ${
            client.device_platform === "android"
              ? company?.latest_version_android
              : company?.latest_version_ios
          }`}
        >
          <Chip
            size="small"
            icon={
              client.device_platform === "android" ? (
                <AndroidRoundedIcon />
              ) : (
                <AppleIcon />
              )
            }
            label={`${client.device_platform === "android" ? "Android" : "iOS"}${client.owns_smart_watch ? " and watch" : ", no watch"}`}
            onClick={(event) => {
              setWatchAnchorEl(event.currentTarget);
            }}
          />
        </Tooltip>
        <Tooltip disableInteractive title={"Preferred workout duration"}>
          <Chip
            size="small"
            icon={<TimerRoundedIcon />}
            label={
              client.workout_duration
                ? `${workoutDurations[client.workout_duration]} hrs`
                : "--"
            }
            onClick={(event) => {
              setWorkoutDurationAnchorEl(event.currentTarget);
            }}
          />
        </Tooltip>
        {client.tests?.includes("sep_steps_treatment") && (
          <Tooltip title="Daily step goal">
            <Chip
              size="small"
              icon={<DirectionsWalkRoundedIcon />}
              label={
                client.daily_metric_goals?.steps.value
                  ? `${client.daily_metric_goals.steps.value} steps`
                  : "No steps goal"
              }
              onClick={(event) => {
                setStepsAnchorEl(event.currentTarget);
              }}
            />
          </Tooltip>
        )}
        {[
          "b8cd9ba5-e73e-428a-9920-f655899d00c9",
          "f44d390c-3d61-4d54-b897-59319cb0cfea",
          "a913be62-665e-4b3e-b68b-eecaaf994c1b",
          "c892245f-fbfa-492d-8f56-6c551d836d53",
          "4c4fdbfe-10e1-4081-8127-1acb98df5570",
          "abf9a536-a1b9-4be8-9500-f8b9245daac0",
          "e953f2a4-196e-4529-945e-f046d0524a2d",
        ].includes(realTrainer!.trainer_id) && (
          <Chip
            size="small"
            icon={<AttachMoneyRoundedIcon />}
            label={hasUltraDiscountPlan ? "Ultra discount plan" : "Normal plan"}
            onClick={(event) => {
              setBillingAnchorEl(event.currentTarget);
            }}
          />
        )}
        {client.account.plan.id === "live_training" && (
          <Chip
            size="small"
            icon={<FollowTheSignsRoundedIcon />}
            label={`Live training - ${callClaimedThisMonth ? "Call claimed" : callClaimedNextMonth ? "Call claimed next month" : "No call claimed"}`}
            onClick={(event) => {
              setLiveTrainingAnchorEl(event.currentTarget);
            }}
          />
        )}
      </Box>
      <Popover
        open={heightOpen}
        anchorEl={heightAnchorEl}
        onClose={() => {
          setHeightAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 250,
            },
          },
        }}
      >
        <AutosaveTextField
          label="Height"
          defaultValue={getConvertedHeight({
            height: client.height ?? 0,
            fromSystem: "imperial",
            toSystem: client.preferred_weight_system,
            round: true,
          }).toString()}
          key={"height-" + client.preferred_weight_system}
          disableGutter
          onlySaveOnBlur
          blurOnEnter
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  {client.preferred_weight_system === "metric"
                    ? "cm"
                    : "inches"}
                </InputAdornment>
              ),
            },
          }}
          onSave={(value) => {
            const convertedHeight = getConvertedHeight({
              height: Number(value),
              fromSystem: client.preferred_weight_system,
              toSystem: "imperial",
            });

            dispatch(
              updateClient({
                user_id: client.user_id,
                height: convertedHeight,
              }),
            );

            setHeightAnchorEl(null);
          }}
        />
        <ToggleButtonGroup
          color="primary"
          value={client.preferred_weight_system ?? "imperial"}
          exclusive
          sx={{ mt: 2 }}
          size="small"
          onChange={(_event, newValue) => {
            if (newValue === null) {
              return;
            }

            dispatch(
              updateClient({
                user_id: client.user_id,
                preferred_weight_system: newValue,
              }),
            );
          }}
        >
          <ToggleButton value="imperial">Imperial (lbs)</ToggleButton>
          <ToggleButton value="metric">Metric (kg)</ToggleButton>
        </ToggleButtonGroup>
      </Popover>
      <Popover
        open={weightOpen}
        anchorEl={weightAnchorEl}
        onClose={() => {
          setWeightAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 275,
            },
          },
        }}
      >
        {client.date_last_manual_bodyweight_log ? (
          <>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 6,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Starting weight
                </Typography>
                <Typography>
                  {getConvertedWeight({
                    weight: client.starting_weight,
                    fromSystem: "imperial",
                    toSystem: client.preferred_weight_system,
                    round: true,
                  })}{" "}
                  {client.preferred_weight_system === "metric" ? "kg" : "lbs"}
                </Typography>
                <Typography variant="overline">
                  {format(
                    client.account.membership.date_account_created ??
                      new Date(),
                    "MMM d, yyyy",
                  )}
                </Typography>
              </Grid>
              <Grid
                size={{
                  xs: 6,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Current weight
                </Typography>
                <Typography>
                  {getConvertedWeight({
                    weight: client.weight,
                    fromSystem: "imperial",
                    toSystem: client.preferred_weight_system,
                    round: true,
                  })}{" "}
                  {client.preferred_weight_system === "metric" ? "kg" : "lbs"}
                </Typography>
                <Typography variant="overline">
                  {format(
                    client.date_last_manual_bodyweight_log,
                    "MMM d, yyyy",
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="overline" sx={{ mt: 1 }}>
              Once a client logs their weight, only they can change it
            </Typography>
          </>
        ) : (
          <AutosaveTextField
            label="Weight"
            key={"weight-" + client.preferred_weight_system}
            defaultValue={getConvertedWeight({
              weight: client.weight,
              fromSystem: "imperial",
              toSystem: client.preferred_weight_system,
              round: true,
            }).toString()}
            disableGutter
            onlySaveOnBlur
            blurOnEnter
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {client.preferred_weight_system === "metric" ? "kg" : "lbs"}
                  </InputAdornment>
                ),
              },
            }}
            onSave={(value) => {
              const convertedWeight = getConvertedWeight({
                weight: Number(value),
                fromSystem: client.preferred_weight_system,
                toSystem: "imperial",
              });

              dispatch(
                updateClient({
                  user_id: client.user_id,
                  weight: convertedWeight,
                  starting_weight: convertedWeight,
                }),
              );

              setWeightAnchorEl(null);
            }}
          />
        )}
        <ToggleButtonGroup
          color="primary"
          value={client.preferred_weight_system ?? "imperial"}
          exclusive
          sx={{ mt: 2 }}
          size="small"
          onChange={(_event, newValue) => {
            if (newValue === null) {
              return;
            }

            dispatch(
              updateClient({
                user_id: client.user_id,
                preferred_weight_system: newValue,
              }),
            );
          }}
        >
          <ToggleButton value="imperial">Imperial (lbs)</ToggleButton>
          <ToggleButton value="metric">Metric (kg)</ToggleButton>
        </ToggleButtonGroup>
      </Popover>
      <Popover
        open={ageOpen}
        anchorEl={ageAnchorEl}
        onClose={() => {
          setAgeAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 250,
            },
          },
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={ageMode}
          exclusive
          sx={{ mb: 2 }}
          size="small"
          onChange={(_event, newValue) => {
            if (newValue === null) {
              return;
            }

            setAgeMode(newValue);
          }}
        >
          <ToggleButton value="years" sx={{ py: 0.25 }}>
            Years old
          </ToggleButton>
          <ToggleButton value="birthday" sx={{ py: 0.25 }}>
            Birthday (preferred)
          </ToggleButton>
        </ToggleButtonGroup>
        {ageMode === "years" ? (
          <AutosaveTextField
            label="Age"
            defaultValue={client.age?.toString() ?? "0"}
            disableGutter
            onlySaveOnBlur
            blurOnEnter
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">years</InputAdornment>
                ),
              },
            }}
            onSave={(value) => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  age: Number(value),
                  birthday: getZeroedOutUtcDate(
                    getDateForDatabase(
                      startOfDay(
                        subYears(new Date(), Number(value)),
                      ).toISOString(),
                    ),
                  ),
                  is_birthday_real: false,
                }),
              );

              setAgeAnchorEl(null);
            }}
          />
        ) : (
          <SmartEditDateField
            label="Birthday"
            value={
              client.birthday
                ? getDateFromDatabase(client.birthday as string)
                : new Date()
            }
            onSave={(value) => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  birthday: getZeroedOutUtcDate(
                    getDateForDatabase(startOfDay(value!).toISOString()),
                  ),
                  age: differenceInYears(new Date(), value!),
                  is_birthday_real: true,
                }),
              );
            }}
          />
        )}
      </Popover>
      <Popover
        open={identityOpen}
        anchorEl={identityAnchorEl}
        onClose={() => {
          setIdentityAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 250,
            },
          },
        }}
      >
        <FormControl fullWidth>
          <InputLabel>Gender identity</InputLabel>
          <Select
            label="Gender identity"
            value={sanitizedGender}
            onChange={(event) => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  gender: event.target.value,
                }),
              );

              setIdentityAnchorEl(null);
            }}
          >
            {[
              { value: "Woman", label: "Woman" },
              { value: "Man", label: "Man" },
              { value: "Non-binary", label: "Non-binary" },
              { value: "Prefer not to say", label: "Prefer not to say" },
              { value: "Other", label: "Other" },
            ].map((possibleValue) => (
              <MenuItem key={possibleValue.value} value={possibleValue.value}>
                {possibleValue.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Popover>
      <Popover
        open={watchOpen}
        anchorEl={watchAnchorEl}
        onClose={() => {
          setWatchAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 250,
            },
          },
        }}
      >
        <FormControl fullWidth>
          <InputLabel>Smart watch</InputLabel>
          <Select
            label="Smartwatch"
            value={Number(client.owns_smart_watch ?? false).toString()}
            onChange={(event) => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  owns_smart_watch: Boolean(Number(event.target.value)),
                }),
              );

              setWatchAnchorEl(null);
            }}
          >
            {[
              { value: "1", label: "Has smartwatch" },
              { value: "0", label: "Phone only" },
            ].map((possibleValue) => (
              <MenuItem key={possibleValue.value} value={possibleValue.value}>
                {possibleValue.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Popover>
      <Popover
        open={workoutDurationOpen}
        anchorEl={workoutDurationAnchorEl}
        onClose={() => {
          setWorkoutDurationAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 250,
            },
          },
        }}
      >
        <FormControl fullWidth>
          <InputLabel>Preferred workout duration</InputLabel>
          <Select
            label="Preferred workout duration"
            value={client.workout_duration}
            onChange={(event) => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  workout_duration: event.target.value,
                }),
              );

              setWorkoutDurationAnchorEl(null);
            }}
          >
            {[
              { value: "10m", label: "0:10" },
              { value: "15m", label: "0:15" },
              { value: "20m", label: "0:20" },
              { value: "30m", label: "0:30" },
              { value: "45m", label: "0:45" },
              { value: "60m", label: "1:00" },
              { value: "75m", label: "1:15" },
              { value: "90m", label: "1:30" },
              { value: "120m", label: "2:00" },
            ].map((possibleValue) => (
              <MenuItem key={possibleValue.value} value={possibleValue.value}>
                {possibleValue.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Popover>
      <Popover
        open={billingOpen}
        anchorEl={billingAnchorEl}
        onClose={() => {
          setBillingAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 300,
            },
          },
        }}
      >
        <FormControl
          fullWidth
          sx={{
            mb: 2,
          }}
        >
          <InputLabel>Plan</InputLabel>
          <Select
            label="Default plan"
            value={client.account.plan.subscription_group_id ?? ""}
            onChange={(event) => {
              const newSubscriptionGroupId = event.target.value || null;

              api.analytics.trackEvent({
                trainerId: realTrainer?.trainer_id,
                eventType: "subscription_group_changed",
                platform: "coach_dash",
                sessionId: "",
                eventContent: {
                  reason: "trainer_manual",
                  old_subscription_group_id:
                    client.account.plan.subscription_group_id,
                  new_subscription_group_id: newSubscriptionGroupId,
                },
              });

              dispatch(
                updateClient({
                  user_id: client.user_id,
                  // @ts-expect-error
                  "account.plan.subscription_group_id":
                    event.target.value || null,
                }),
              );
            }}
          >
            {[
              {
                value: "5d8e0b1c-ab02-4b0b-8210-a221056850c4",
                label: "$69/mo charged every 1 month",
              },
              {
                value: "6bcc2255-194a-4c7e-831e-7d49a9ee934a",
                label: "$69/mo charged every 3 months",
              },
              {
                value: "a2d1b679-7949-4b12-b7f3-73b0e23322c9",
                label: "$65/mo charged every 6 months",
              },
              {
                value: "839fac4d-4818-4997-83e6-09beac2cb3d0",
                label: "$59/mo charged every 12 months",
              },
              {
                value: "e12e32c3-475f-44d5-bdc7-66a993d04843",
                label: "Standard",
              },
            ].map((possibleValue) => (
              <MenuItem key={possibleValue.value} value={possibleValue.value}>
                {possibleValue.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Popover>
      <Popover
        open={stepsOpen}
        anchorEl={stepsAnchorEl}
        onClose={() => {
          setStepsAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 250,
            },
          },
        }}
      >
        <AutosaveTextField
          label="Daily step goal"
          defaultValue={
            client.daily_metric_goals?.steps.value
              ? client.daily_metric_goals.steps.value.toString()
              : "0"
          }
          disableGutter
          // onlySaveOnBlur
          blurOnEnter
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">steps</InputAdornment>
              ),
            },
          }}
          onSave={(value) => {
            const steps = Math.round(Number(value));

            api.clients
              .updateDailyMetricGoals(client.user_id, {
                steps: steps,
              })
              .then(() => {
                dispatch(
                  updateClientLocal({
                    user_id: client.user_id,
                    daily_metric_goals: Object.assign(
                      {},
                      client.daily_metric_goals,
                      { steps: { value: steps } },
                    ),
                  }),
                );
              });

            setStepsAnchorEl(null);
          }}
        />
      </Popover>
      <Popover
        open={liveTrainingOpen}
        anchorEl={liveTrainingAnchorEl}
        onClose={() => {
          setLiveTrainingAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 250,
            },
          },
        }}
      >
        <SmartEditDateField
          label="Most recent live training date"
          value={
            client.most_recent_live_training_call_date
              ? getDateFromDatabase(
                  client.most_recent_live_training_call_date as string,
                )
              : undefined
          }
          onSave={(value) => {
            dispatch(
              updateClient({
                user_id: client.user_id,
                most_recent_live_training_call_date: getZeroedOutUtcDate(
                  getDateForDatabase(startOfDay(value!).toISOString()),
                ),
              }),
            );
          }}
          minDate={
            client.account.plan.date_trial_end
              ? new Date(client.account.plan.date_trial_end)
              : undefined
          }
        />
      </Popover>
    </>
  );
}
