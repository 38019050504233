import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { logClick } from "src/slices/analyticsSlice";
import {
  bumpWorkoutsUp,
  createBlankWeekPlan,
  fetchMoreClientData,
  fetchWeekPlans,
  selectWeekPlanIds,
} from "src/slices/clientSlice";
import { ClientHeader } from "../ClientHeader";
import ClientSchedule from "../ClientSchedule";
import ClientCard from "../name-tag/ClientCard";
import { BackupWorkouts } from "./BackupWorkouts";
import { CalendarAutoSave } from "./CalendarAutoSave";
import { ExtraWorkouts } from "./ExtraWorkouts";
import { WeekPlan } from "./WeekPlan";

interface Props {
  userId: string;
}

export function Calendar({ userId }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const habitPlanIds = useAppSelector(selectWeekPlanIds);
  const loadMoreStatus = useAppSelector((state) => state.client.loadMoreStatus);
  const addWeekStatus = useAppSelector((state) => state.client.addWeekStatus);
  const weekPlansStatus = useAppSelector(
    (state) => state.client.weekPlansStatus,
  );
  const phasesStatus = useAppSelector((state) => state.phases.status);
  const [isSticky, setIsSticky] = useState(false);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const isInCpt2Test = useAppSelector(
    (state) =>
      state.client.client?.tests?.includes(
        "call_missed_workout_clients_treatment_2",
      ) ||
      state.client.client?.tests?.includes(
        "call_missed_workout_clients_treatment_2_fake",
      ),
  );

  useLayoutEffect(() => {
    const prevScrollPosition = JSON.parse(
      sessionStorage.getItem(`calendar-scroll-${userId}`) ?? "0",
    ) as number;

    if (prevScrollPosition > 0) {
      scrollableRef.current?.scrollTo(0, prevScrollPosition);
    }

    const handleScroll = () => {
      sessionStorage.setItem(
        `calendar-scroll-${userId}`,
        scrollableRef.current?.scrollTop.toString() ?? "0",
      );
      setIsSticky((scrollableRef.current?.scrollTop ?? 0) > 75);
    };

    scrollableRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      scrollableRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (weekPlansStatus === "idle") {
      dispatch(fetchWeekPlans());
    }
  }, [weekPlansStatus, dispatch]);

  useEffect(() => {
    const element = scrollableRef.current;
    if (!element) {
      return;
    }

    return autoScrollForElements({
      element,
    });
  });

  console.log("Render: plan builder");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 38px)",
        overflowY: "auto",
        width: "100%",
      }}
      ref={scrollableRef}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        {isSticky && (
          <ClientHeader
            sx={{
              px: 2,
              py: 1,
              backgroundColor: (theme) => theme.palette.background.paper,
              borderBottom: 1,
              borderColor: "divider",
            }}
          />
        )}
      </Box>
      <Box>
        <ClientCard sx={{ m: 2 }} />
      </Box>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Box sx={{ mb: 2, display: "flex", alignItems: "flex-end" }}>
              <Typography variant="h1" sx={{ mr: 1 }}>
                Calendar
              </Typography>
              <CalendarAutoSave />
            </Box>
            {phasesStatus === "succeeded" && (
              <Box
                sx={{
                  maxWidth: 600,
                }}
              >
                <ExtraWorkouts sx={{ mb: 4, width: "100%" }} />
                {isInCpt2Test && (
                  <BackupWorkouts sx={{ mb: 4, width: "100%" }} />
                )}
              </Box>
            )}
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(createBlankWeekPlan(userId));

                  dispatch(
                    logClick({
                      elementName: "plan_builder.new_week",
                    }),
                  );
                }}
                startIcon={<AddRoundedIcon />}
                loading={
                  addWeekStatus === "loading" || addWeekStatus === "failed"
                }
              >
                New week
              </Button>
              <Button
                variant="text"
                size="small"
                startIcon={<ArrowUpwardRoundedIcon />}
                sx={{ ml: 2 }}
                onClick={() => {
                  dispatch(bumpWorkoutsUp())
                    .unwrap()
                    .catch((errorMessage: unknown) => {
                      if (typeof errorMessage === "string") {
                        enqueueSnackbar(errorMessage, {
                          variant: "error",
                        });

                        return;
                      }

                      enqueueSnackbar("Not enough weeks", {
                        variant: "error",
                      });
                    });

                  dispatch(
                    logClick({
                      elementName: "plan_builder.bump_workouts_up",
                    }),
                  );
                }}
              >
                Bump workouts up
              </Button>
              <Button
                variant="text"
                size="small"
                startIcon={<TodayRoundedIcon />}
                sx={{ ml: 2 }}
                onClick={() => {
                  document
                    .getElementById("today")
                    ?.scrollIntoView({ behavior: "smooth", block: "start" });

                  dispatch(
                    logClick({
                      elementName: "plan_builder.scroll_to_today",
                    }),
                  );
                }}
              >
                Scroll to today
              </Button>
            </Box>
          </Box>
          <ClientSchedule variant="outlined" />
        </Box>
      </Box>
      <Stack spacing={4} direction={"column"} sx={{ px: 1 }}>
        {habitPlanIds.map((habitPlanId, i) => (
          <WeekPlan
            key={habitPlanId}
            weekPlanId={habitPlanId}
            isMostRecent={i === 0}
          />
        ))}
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
          mb: 8,
        }}
      >
        <Button
          variant="contained"
          loading={loadMoreStatus === "loading"}
          loadingIndicator="Loading..."
          disabled={habitPlanIds.length <= 0 || loadMoreStatus !== "idle"}
          onClick={() => {
            dispatch(fetchMoreClientData());
          }}
          startIcon={<ArrowDownwardRoundedIcon />}
        >
          {loadMoreStatus === "done" ? "Done" : "Load more"}
        </Button>
      </Box>
    </Box>
  );
}
