import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Typography, alpha } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export function NewPhaseDay() {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "new_phase_template_day" }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return (
          source.data.type === "workout_template" ||
          source.data.type === "phase_template_workout_template"
        );
      },
    });
  }, []);

  return (
    <div ref={ref}>
      <Box
        sx={{
          mt: 8,
          p: 1,
          display: "flex",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: 1,
          borderColor: (theme) =>
            isDraggedOver ? theme.palette.primary.main : theme.palette.divider,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          transitionProperty: "border-color, background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
        }}
      >
        <AddRoundedIcon
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        >
          Drop to add a new day
        </Typography>
      </Box>
    </div>
  );
}
