import { Box, Divider, Typography } from "@mui/material";
import { CompletedRestDayIcon } from "./CompletedRestDayIcon";
import { CompletedWorkoutIcon } from "./CompletedWorkoutIcon";
import { StreakBrokenWorkoutIcon } from "./StreakBrokenWorkoutIcon";
import { StreakFrozenIcon } from "./StreakFrozenIcon";
import { StreakInDangerWorkoutIcon } from "./StreakInDangerWorkoutIcon";
import { UpcomingRestDayIcon } from "./UpcomingRestDayIcon";
import { UpcomingWorkoutIcon } from "./UpcomingWorkoutIcon";

export function StreakInfoNoSteps() {
  return (
    <>
      <Typography variant="h3" sx={{ mt: 2 }}>
        How streaks work
      </Typography>
      <Typography>
        Your streak is the number of days you&apos;ve gone without missing a
        workout day (a day with a scheduled trainwell workout).
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CompletedWorkoutIcon sx={{ mr: 0.75 }} />
        <Typography fontWeight={"bold"}>Completed workout</Typography>
        <UpcomingWorkoutIcon
          sx={{
            ml: 2,
            mr: 0.75,
          }}
        />
        <Typography fontWeight={"bold"}>Upcoming workout</Typography>
      </Box>
      <Typography>
        On workout days, you can complete any trainwell workout (including
        extras) to keep your streak alive.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StreakInDangerWorkoutIcon sx={{ mr: 0.75 }} />
        <Typography fontWeight={"bold"}>Streak in danger</Typography>
      </Box>
      <Typography>
        If a workout day is ending and you haven&apos;t done any trainwell
        workout, you&apos;ll receive a reminder to save your streak.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StreakFrozenIcon
          sx={{
            mr: 0.75,
          }}
        />
        <Typography fontWeight={"bold"}>Streak freezes</Typography>
      </Box>
      <Typography>
        If you don&apos;t do any trainwell workout on a workout day one of your
        streak freezes will be used to keep your streak.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StreakBrokenWorkoutIcon
          sx={{
            mr: 0.75,
          }}
        />
        <Typography fontWeight={"bold"}>Streak broken</Typography>
      </Box>
      <Typography>
        If you don&apos;t do any trainwell workout on a workout day and you have
        no more streak freezes, you&apos;ll break your streak.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CompletedRestDayIcon sx={{ mr: 0.75 }} />
        <Typography fontWeight={"bold"}>Completed rest</Typography>
        <UpcomingRestDayIcon
          sx={{
            ml: 2,
            mr: 0.75,
          }}
        />
        <Typography fontWeight={"bold"}>Upcoming rest</Typography>
      </Box>
      <Typography>Enjoy your rest days in between workout days.</Typography>
    </>
  );
}
