import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import { Box, Stack, Typography } from "@mui/material";
import type {
  MessageMealLog,
  MessageMealLogSentiment,
} from "@trainwell/features/legacy";
import type { ClientMealLog } from "@trainwell/features/nutrition";
import { useCallback, useMemo, useState } from "react";
import MealLogCard from "src/features/nutrition/MealLogCard";
import MealLogModal from "src/features/nutrition/MealLogModal";
import { useAppDispatch } from "src/hooks/stateHooks";
import { updateMessageLocal } from "src/slices/chatSlice";

interface Props {
  message: MessageMealLog | MessageMealLogSentiment;
  combineBottom: boolean;
}

export function MealLogMessage({ message, combineBottom }: Props) {
  const log = useMemo(() => message.content.meal_log, [message]);
  const [showModal, setShowModal] = useState(false);

  const title = useMemo(
    () => (message.type === "meal_log" ? "Meal Logged" : "Meal Sentiment"),
    [message],
  );

  const dispatch = useAppDispatch();

  const handleLogUpdated = useCallback(
    (log: ClientMealLog) => {
      dispatch(
        updateMessageLocal({
          ...message,
          content: {
            ...message.content,
            meal_log: log,
          },
        } as MessageMealLog),
      );
    },
    [dispatch, updateMessageLocal],
  );

  return (
    <>
      <Box
        sx={{
          maxWidth: "300px",
          borderRadius: `10px 10px 10px ${!combineBottom ? "0px" : "10px"}`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
          p: 1,
          display: "flex",
          alignItems: "center",
          width: "200px",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <Stack
          direction={"column"}
          spacing={1}
          flex={1}
          alignItems={"flex-start"}
          sx={{ width: "fit-content" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RestaurantRoundedIcon />
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
                ml: 1,
              }}
            >
              {title}
            </Typography>
          </Box>
          <MealLogCard log={log} />
        </Stack>
      </Box>
      <MealLogModal
        log={showModal ? log : null}
        onClose={() => {
          setShowModal(false);
        }}
        onLogUpdated={handleLogUpdated}
      />
    </>
  );
}
