import { Box, Chip, Typography, styled } from "@mui/material";
import type { LogSet } from "@trainwell/features/legacy";
import { getConvertedWeight } from "@trainwell/workout-lib";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getExerciseById } from "src/lib/exercises";
import { getFormattedTime } from "src/lib/misc";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { highlightSet } from "src/slices/workoutSlice";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

interface Props {
  firstExerciseId?: string;
  firstSetId?: string;
  setLog: LogSet;
  exerciseMasterID: string;
  enablePreview: boolean;
  preferredWeightSystem: "metric" | "imperial";
}

export default function LogSetChips({
  firstExerciseId,
  firstSetId,
  setLog,
  exerciseMasterID,
  enablePreview,
  preferredWeightSystem,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const exerciseExists = useAppSelector(
    (state) =>
      firstExerciseId &&
      Boolean(
        state.workout.workoutNormalized?.entities.exercises[firstExerciseId],
      ),
  );
  const dispatch = useAppDispatch();
  const exerciseSource = getExerciseById(exerciseMasterID);

  if (!exerciseSource) {
    return <Typography>Loading...</Typography>;
  }

  const values = workoutLib.exercises.getValuesForExercise(exerciseSource);

  const timeSeconds = Math.round(
    (new Date(setLog.set_end_date!).getTime() -
      new Date(setLog.set_start_date!).getTime()) /
      1000,
  );

  function focusSet(attributeToFocus?: "reps" | "weight" | "time" | "rest") {
    if (!firstExerciseId || !firstSetId) {
      return;
    }

    dispatch(
      highlightSet({
        firstExerciseId: firstExerciseId,
        firstSetId: firstSetId,
        setId: setLog.set_id,
        attributeToFocus: attributeToFocus,
      }),
    );

    if (!exerciseExists) {
      enqueueSnackbar("Set not found. Maybe the workout changed");
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0,
        mx: -1,
        my: 0,
      }}
      component="ul"
    >
      {values.weight && (
        <ListItem>
          <Chip
            size="small"
            color={
              !workoutLib.workoutLogs.isAcceptableWeight(setLog)
                ? "error"
                : undefined
            }
            label={`${getConvertedWeight({
              weight: setLog.weight_performed!,
              fromSystem: "imperial",
              toSystem: preferredWeightSystem,
              round: true,
            })} 
              / 
              ${getConvertedWeight({
                weight: setLog.weight_target!,
                fromSystem: "imperial",
                toSystem: preferredWeightSystem,
                round: true,
              })} ${preferredWeightSystem === "metric" ? "kg" : "lbs"}`}
            sx={{ fontSize: 12 }}
            variant="outlined"
            onClick={
              !enablePreview
                ? undefined
                : () => {
                    focusSet("weight");
                  }
            }
          />
        </ListItem>
      )}
      {values.reps && (
        <ListItem>
          <Chip
            size="small"
            color={
              !workoutLib.workoutLogs.isAcceptableReps(setLog)
                ? "error"
                : undefined
            }
            label={`${setLog.reps_performed} / ${setLog.reps_target} reps`}
            sx={{ fontSize: 12 }}
            variant="outlined"
            onClick={
              !enablePreview
                ? undefined
                : () => {
                    focusSet("reps");
                  }
            }
          />
        </ListItem>
      )}
      {!values.time && (
        <ListItem>
          <Chip
            size="small"
            label={`Time: ${getFormattedTime(timeSeconds)}`}
            sx={{ fontSize: 12 }}
            variant="outlined"
            onClick={
              !enablePreview
                ? undefined
                : () => {
                    focusSet();
                  }
            }
          />
        </ListItem>
      )}
      {values.time && (
        <ListItem>
          <Chip
            size="small"
            color={
              !workoutLib.workoutLogs.isAcceptableTime(setLog)
                ? "error"
                : undefined
            }
            label={`Time: ${getFormattedTime(
              setLog.time_performed!,
            )} / ${getFormattedTime(setLog.time_target!)}`}
            sx={{ fontSize: 12 }}
            variant="outlined"
            onClick={
              !enablePreview
                ? undefined
                : () => {
                    focusSet("time");
                  }
            }
          />
        </ListItem>
      )}
      {setLog.rest_performed !== undefined &&
        setLog.rest_performed !== null &&
        setLog.rest_performed !== 0 && (
          <ListItem>
            <Chip
              size="small"
              color={
                !workoutLib.workoutLogs.isAcceptableRest(setLog)
                  ? "error"
                  : undefined
              }
              label={`Rest: ${getFormattedTime(
                setLog.rest_performed,
              )} / ${getFormattedTime(setLog.rest_target)}`}
              sx={{ fontSize: 12 }}
              variant="outlined"
              onClick={
                !enablePreview
                  ? undefined
                  : () => {
                      focusSet("rest");
                    }
              }
            />
          </ListItem>
        )}
    </Box>
  );
}
