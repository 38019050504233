import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import type { WorkoutLog } from "@trainwell/features/legacy";
import { useEffect, useState } from "react";
import {
  getExerciseDisplayName,
  getExerciseIconURL,
} from "src/lib/mediaUtility";
import { api } from "src/lib/trainwellApi";
import { SetLogCell } from "../workout-builder/SetLogCell";
import LogIconGrid from "./LogIconGrid";
import { WorkoutLogClientNotes } from "./WorkoutLogClientNotes";
import { WorkoutLogDifficulty } from "./WorkoutLogDifficulty";

interface Props {
  workoutLogId: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export function WorkoutLogPreviewPopover({
  workoutLogId,
  anchorEl,
  onClose,
}: Props) {
  const [workoutLog, setWorkoutLog] = useState<WorkoutLog>();

  const open = Boolean(anchorEl);

  useEffect(() => {
    async function runAsync() {
      const fetchedWorkoutLog = await api.workoutLogs.getOne(workoutLogId);
      setWorkoutLog(fetchedWorkoutLog);
    }

    if (anchorEl) {
      runAsync();
    }
  }, [anchorEl]);

  if (!workoutLog) {
    return null;
  }

  return (
    <Popover
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          // @ts-expect-error
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          event.stopPropagation();
        }

        onClose();
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onMouseMove={(event) => {
        event.stopPropagation();
      }}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
    >
      <Box
        sx={{
          maxHeight: "650px",
          width: "350px",
          overflowY: "auto",
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1,
          }}
        >
          <Typography variant="h3">{workoutLog.name}</Typography>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClose();
            }}
            size="small"
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        {workoutLog.log_summary.user_feedback2?.difficulty && (
          <Box sx={{ px: 6, mb: 2 }}>
            <WorkoutLogDifficulty log={workoutLog} />
          </Box>
        )}
        <LogIconGrid log={workoutLog} size="small" sx={{ mb: 2, mx: 1 }} />
        <WorkoutLogClientNotes logId={workoutLogId} sx={{ mb: 2, mx: 1 }} />
        <Stack direction={"column"} spacing={1} sx={{ px: 1, mb: 2 }}>
          {workoutLog.sections.map((section) => (
            <Card variant="outlined" key={section.section_id} sx={{ p: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  mb: 1,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: (theme) =>
                      section.section_name
                        ? theme.palette.text.primary
                        : theme.palette.error.main,
                  }}
                >
                  {!section.section_name ? "No name" : section.section_name}
                </Typography>
                {section.cycles.length >= 1 && (
                  <Typography variant="overline" sx={{ ml: 1 }}>
                    x{section.cycles.length}
                  </Typography>
                )}
              </Box>
              <Stack direction={"column"} spacing={2} divider={<Divider />}>
                {section.cycles[0].exercises.map((exercise, exerciseIndex) => (
                  <Box
                    key={exercise.exercise_id}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box sx={{ mr: 1 }}>
                      <img
                        src={
                          getExerciseIconURL(exercise.exercise_master_id) ||
                          "/assets/profile.png"
                        }
                        width={40}
                        height={40}
                        alt="workoutLog"
                        style={{
                          borderRadius: 8,
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {getExerciseDisplayName(exercise.exercise_master_id)}
                      </Typography>
                      {section.cycles.map((cycle, cycleIndex) => (
                        <>
                          {section.cycles.length > 1 && (
                            <Typography variant="overline">
                              Cycle {cycleIndex + 1}
                            </Typography>
                          )}
                          {cycle.exercises[exerciseIndex].sets.map((setLog) => (
                            <SetLogCell
                              key={setLog.set_log_id}
                              setLog={setLog}
                              exerciseMasterId={exercise.exercise_master_id}
                              dense
                            />
                          ))}
                        </>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Card>
          ))}
        </Stack>
      </Box>
    </Popover>
  );
}
