import { Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";

export const TitledSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <Stack spacing="10px">
    <Typography variant="h3">{title}</Typography>
    {children}
  </Stack>
);
