import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import type { TextFieldProps } from "@mui/material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { forwardRef } from "react";

type Props = {
  onChange: (value: string) => void;
  onClear: () => void;
} & Omit<TextFieldProps, "onChange">;

const SearchField = forwardRef(function SearchField(
  { onChange, onClear, ...textFieldProps }: Props,
  ref: any,
) {
  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder={"Search"}
      value={textFieldProps.value}
      fullWidth
      // autoFocus
      onChange={(event) => {
        onChange(event.target.value);
      }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon
                sx={{ color: (theme) => theme.palette.text.disabled }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search"
                size="small"
                onClick={() => {
                  onClear();
                }}
              >
                <ClearRoundedIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            pr: 0.5,
            pl: 1,
          },
        },
      }}
      {...textFieldProps}
      inputRef={ref}
    />
  );
});

export default SearchField;
