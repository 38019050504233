import type { MagicKeyOption } from "@trainwell/features/magic-keys";

export const chatMagicKeySuggestions: MagicKeyOption[] = [
  {
    label: "Client name",
    id: "client_name",
    subMagicKeys: [
      {
        label: "Client first name",
        id: "first_name",
        example: "Steve",
      },
      {
        label: "Client full name",
        id: "full_name",
        example: "Steve Jobs",
      },
    ],
  },
  {
    label: "Next workout",
    id: "next_workout",
    subMagicKeys: [
      {
        label: "Next workout day",
        id: "next_workout_day",
        example: "Wednesday",
      },
      {
        label: "Next workout date",
        id: "next_workout_date",
        example: "7th",
      },
      {
        label: "Next workout name",
        id: "next_workout_name",
        example: "Full Body A",
      },
      {
        label: "Next workout duration",
        id: "next_workout_duration",
        example: "33 minutes",
      },
    ],
  },
  {
    label: "Last missed workout",
    id: "last_missed_workout",
    subMagicKeys: [
      {
        label: "Last missed workout day",
        id: "last_missed_workout_day",
        example: "Wednesday",
      },
      {
        label: "Last missed workout date",
        id: "last_missed_workout_date",
        example: "7th",
      },
      {
        label: "Last missed workout name",
        id: "last_missed_workout_name",
        example: "Full Body A",
      },
      {
        label: "Last missed workout duration",
        id: "last_missed_workout_duration",
        example: "33 minutes",
      },
    ],
  },
  {
    label: "Missed workout streak length",
    id: "missed_workout_streak_length",
    example: "2",
  },
  {
    label: "Upcoming workout days",
    id: "upcoming_workout_days",
    tooltip: "Excludes today if the client did a workout today",
    subMagicKeys: [
      {
        label: "This week's upcoming workout days",
        id: "upcoming_workout_days_this_week",
        example: "Monday, Wednesday, Friday",
      },
      {
        label: "Next week's upcoming workout days",
        id: "upcoming_workout_days_next_week",
        example: "Monday, Wednesday, Friday",
      },
    ],
  },
  {
    label: "Links",
    id: "links",
    subMagicKeys: [
      {
        label: "Change trainer",
        id: "url_change_coach",
        example: "https://account.trainwell.net",
      },
      {
        label: "Schedule call",
        id: "url_schedule_call",
        example: "https://account.trainwell.net",
      },
      {
        label: "Billing portal",
        id: "url_billing_portal",
        example: "https://account.trainwell.net",
      },
    ],
  },
];
