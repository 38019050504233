import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import type { TemplateLibraryFolder } from "@trainwell/features/legacy";
import { useEffect, useMemo, useRef, useState, type ReactNode } from "react";
import SearchField from "src/components/misc/SearchField";
import { useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import { selectUsedPhaseTemplateTags } from "src/slices/phaseTemplatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import type { PhaseTemplateLocal } from "src/types/PhaseTemplateLocal";
import FolderCell from "./FolderCell";
import PhaseRequestDialog from "./PhaseRequestDialog";
import PhaseTemplateCell from "./PhaseTemplateCell";
import WorkoutTemplateCell from "./WorkoutTemplateCell";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

export default function TemplateLibraryHomePage() {
  const [search, setSearch] = useState("");
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const phaseTemplateFolders = useAppSelector(
    (state) => state.phaseTemplates.templateLibraryFolders,
  );
  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const phaseTemplates = useAppSelector(
    (state) => state.phaseTemplates.phaseTemplates,
  );
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "page", folderId: null }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return source.data.type === "phase_template_workout_template";
      },
    });
  }, []);

  const { filteredPhaseTemplates, filteredWorkoutTemplates } = useMemo(() => {
    let newPhaseTemplates = [...phaseTemplates].filter(
      (phaseTemplate) => !phaseTemplate.deleted,
    );

    if (currentTab === "trainwell") {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.trainer_id === "copilot";
      });
    } else {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.trainer_id === trainerId;
      });
    }

    if (search || filterTags.length > 0) {
      if (search) {
        newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
          return phaseTemplate.name
            .toLowerCase()
            .includes(search.toLowerCase());
        });
      }

      if (filterTags.length > 0) {
        newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
          for (const tag of filterTags) {
            if (!phaseTemplate.tags?.includes(tag)) {
              return false;
            }
          }

          return true;
        });
      }
    } else {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return !phaseTemplate.parent_folder_id;
      });
    }

    newPhaseTemplates.sort((a, b) => {
      // Sort pinned to the top
      if (a.is_pinned && !b.is_pinned) {
        return -1;
      } else if (!a.is_pinned && b.is_pinned) {
        return 1;
      }

      // Sort by date updated
      return ((b.date_updated ?? b.date_created) as string).localeCompare(
        (a.date_updated ?? a.date_created) as string,
      );
    });

    return {
      filteredPhaseTemplates: newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.type === "multiple";
      }),
      filteredWorkoutTemplates: newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.type === "single";
      }),
    };
  }, [phaseTemplates, search, filterTags, currentTab, trainerId]);

  const filteredPhaseTemplateFolders = useMemo(() => {
    let newPhaseTemplateFolders = [...phaseTemplateFolders];

    if (filterTags.length > 0) {
      return [];
    }

    if (currentTab === "trainwell") {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return folder.trainer_id === "copilot";
      });
    } else {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return folder.trainer_id === trainerId;
      });
    }

    if (search) {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return folder.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return !folder.parent_folder_id;
      });
    }

    newPhaseTemplateFolders.sort((a, b) => {
      // Sort pinned to the top
      if (a.is_pinned && !b.is_pinned) {
        return -1;
      } else if (!a.is_pinned && b.is_pinned) {
        return 1;
      }

      // Sort by name alphabetically
      return a.name.localeCompare(b.name);
    });

    return newPhaseTemplateFolders.map((folder) => {
      return { ...folder, is_folder: true };
    });
  }, [phaseTemplateFolders, search, filterTags, currentTab, trainerId]);

  const items = useMemo(() => {
    return [
      ...filteredPhaseTemplateFolders,
      ...filteredPhaseTemplates,
      ...filteredWorkoutTemplates,
    ];
  }, [
    filteredPhaseTemplateFolders,
    filteredPhaseTemplates,
    filteredWorkoutTemplates,
  ]);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          px: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: (theme) =>
            isDraggedOver ? alpha(theme.palette.primary.main, 0.1) : undefined,
        }}
      >
        <Header context={{ search, setSearch, filterTags, setFilterTags }} />
        <VirtualList items={items} />
      </Box>
    </>
  );
}

interface VirtualListProps {
  items: (
    | PhaseTemplateLocal
    | (TemplateLibraryFolder & { is_folder: boolean })
  )[];
}

function VirtualList({ items }: VirtualListProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 160,
    getItemKey: (index) => items[index]._id,
  });
  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={parentRef}
      style={{
        flex: 1,
        overflow: "auto",
        contain: "strict",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map((virtualItem) => {
            const item = items[virtualItem.index];

            let content: ReactNode;

            if ("is_folder" in item) {
              content = <FolderCell phaseTemplateFolderId={item._id} />;
            } else if (item.type === "multiple") {
              content = <PhaseTemplateCell phaseTemplateId={item._id} />;
            } else {
              content = <WorkoutTemplateCell phaseTemplateId={item._id} />;
            }

            return (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
              >
                {content}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

interface HeaderProps {
  context: {
    search: string;
    setSearch: (value: string) => void;
    filterTags: string[];
    setFilterTags: (value: string[]) => void;
  };
}

function Header({
  context: { search, setSearch, filterTags, setFilterTags },
}: HeaderProps) {
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const possibleTags = useAppSelector((state) =>
    selectUsedPhaseTemplateTags(
      state,
      currentTab === "trainer" ? trainerId : "copilot",
    ),
  );
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pt: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ExtensionRoundedIcon />
          <Typography variant="h1" sx={{ ml: 1 }}>
            {currentTab === "trainer" ? "My" : "trainwell"} template library
          </Typography>
        </Box>
        {currentTab === "trainwell" && (
          <Button
            size="small"
            variant="text"
            startIcon={<CampaignRoundedIcon />}
            onClick={() => {
              setRequestDialogOpen(true);
            }}
          >
            Request
          </Button>
        )}
      </Box>
      <Stack direction={"row"} spacing={1} sx={{ mb: 2, alignItems: "center" }}>
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          sx={{ maxWidth: "250px" }}
          placeholder="Search everything"
        />
        <Autocomplete
          multiple
          size="small"
          options={possibleTags}
          disableCloseOnSelect
          getOptionLabel={(tag) => {
            const tagDetails = getSmartTagDetails(tag);

            return tagDetails.label;
          }}
          value={filterTags}
          onChange={(_event, newValues) => {
            setFilterTags(newValues);
          }}
          renderOption={(props, tag, { selected }) => {
            const tagDetails = getSmartTagDetails(tag);

            return (
              <li
                {...props}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {tagDetails.icon && (
                  <tagDetails.icon.type
                    {...tagDetails.icon.props}
                    sx={{ fontSize: "inherit", mr: 1 }}
                  />
                )}
                {tagDetails.label}
              </li>
            );
          }}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Tags" />}
        />
      </Stack>
      <PhaseRequestDialog
        open={requestDialogOpen}
        onClose={() => {
          setRequestDialogOpen(false);
        }}
      />
    </Box>
  );
}
