import { Box, Typography } from "@mui/material";
import { getFormattedTimeFromMinutes } from "src/lib/misc";

interface Props {
  value?: string;
  type: string;
}

export function TopLevelMetric({ value, type }: Props) {
  let metricString = "--";

  if (value && type) {
    if (type === "percent") {
      metricString = value + "%";
    } else if (type === "duration") {
      metricString = getFormattedTimeFromMinutes(Math.round(Number(value)));
    } else {
      metricString = value;
    }
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 19 }}>
        {metricString}
      </Typography>
    </Box>
  );
}
