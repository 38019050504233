import { Button, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import type { NormalizedSection } from "src/lib/workoutNormalization";
import { renameSection, selectSectionValidity } from "src/slices/workoutSlice";

interface Props {
  section: NormalizedSection;
}

export default function SectionName({ section }: Props) {
  const sectionId = section.section_id;

  const client = useAppSelector((state) => state.client.client);
  const [newSectionName, setNewSectionName] = useState<string>();
  const dispatch = useAppDispatch();
  const sectionValidity = useAppSelector(
    (state) => selectSectionValidity(state, sectionId, client),
    shallowEqual,
  );

  if (newSectionName === undefined) {
    return (
      <Tooltip
        title={"Click to " + (!section.section_name ? "add name" : "edit")}
        disableInteractive
      >
        <Button
          onClick={() => {
            setNewSectionName(section.section_name ?? "");
          }}
          variant="text"
          sx={{
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
            marginRight: "16px",
            fontStyle: section.section_name ? "normal" : "italic",
            color: (theme) =>
              sectionValidity.status === "error"
                ? theme.palette.error.main
                : sectionValidity.status === "warn"
                  ? theme.palette.warning.main
                  : section.section_name
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
          }}
        >
          {!section.section_name ? "no name" : section.section_name}
        </Button>
      </Tooltip>
    );
  }

  return (
    <TextField
      value={newSectionName}
      onChange={(e) => {
        setNewSectionName(e.target.value);
      }}
      autoFocus={true}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          // @ts-expect-error
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          event.target.blur();
        }
      }}
      onBlur={() => {
        dispatch(
          renameSection({
            newName: newSectionName,
            sectionId: sectionId,
          }),
        );
        setNewSectionName(undefined);
      }}
      variant="outlined"
      size="small"
      label="Section name"
      name="sectionCell.name"
      sx={{
        mr: 2,
      }}
      helperText={newSectionName.length < 15 ? null : "15 characters max"}
      slotProps={{
        htmlInput: {
          maxLength: 15,
        },
      }}
    />
  );
}
