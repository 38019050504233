import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import { Box, Chip, Tooltip, type SxProps, type Theme } from "@mui/material";
import { type JSX } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { updateClient } from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

interface Props {
  sx?: SxProps<Theme>;
}

export function ClientPreferenceChips({ sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const trainer = useAppSelector(selectPrimaryTrainer);

  if (!client) {
    return null;
  }

  // I just do this in order to sort the chips :)
  const preferenceChips: {
    chip: JSX.Element;
    active: boolean;
    label: string;
  }[] = [
    {
      label: "Missed habit messages",
      active: Boolean(client.send_missed_habit_messages),
      chip: (
        <Tooltip
          key={"habit_messages"}
          disableInteractive
          title={
            "You will get a message each night if your client misses any habits. Also enables the Missed Habit Check In Mode."
          }
        >
          <Chip
            size="small"
            icon={<LoopRoundedIcon />}
            variant={!client.send_missed_habit_messages ? "outlined" : "filled"}
            color={
              !client.send_missed_habit_messages ? "default" : "purpleSurface"
            }
            label="Missed habit messages"
            onClick={() => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  send_missed_habit_messages:
                    !client.send_missed_habit_messages,
                }),
              );
            }}
          />
        </Tooltip>
      ),
    },
  ];

  if (client.tests?.includes("nov_nutrition_treatment")) {
    const disableNutrition = client.disable_nutrition_messages ?? false;

    preferenceChips.push({
      label: "Nutrition",
      active: !disableNutrition,
      chip: (
        <Chip
          key="nutrition"
          size="small"
          icon={<RestaurantRoundedIcon />}
          variant={disableNutrition ? "outlined" : "filled"}
          color={disableNutrition ? "default" : "purpleSurface"}
          label="Nutrition"
          onClick={() => {
            let slackMessage = "";
            if (disableNutrition) {
              slackMessage = `Trainer <https://trainer.trainwell.net/admin/company/team/${trainer?.trainer_id}|${trainer?.full_name}> has turned *on* nutrition for client <https://trainer.trainwell.net/clients/${client.user_id}|${client.full_name}>`;
            } else {
              slackMessage = `Trainer <https://trainer.trainwell.net/admin/company/team/${trainer?.trainer_id}|${trainer?.full_name}> has turned *off* nutrition for client <https://trainer.trainwell.net/clients/${client.user_id}|${client.full_name}>`;
            }

            api.slack.sendMessage({
              channelId: "C07QZ9FMV0A",
              message: slackMessage,
            });

            dispatch(
              updateClient({
                user_id: client.user_id,
                disable_nutrition_messages: !disableNutrition,
              }),
            );
          }}
        />
      ),
    });
  }

  preferenceChips.sort((a, b) => {
    if (a.active && !b.active) {
      return -1;
    }

    if (!a.active && b.active) {
      return 1;
    }

    return a.label.localeCompare(b.label);
  });

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, ...sx }}>
      {preferenceChips.map(({ chip }) => chip)}
    </Box>
  );
}
