import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type { Ticket, TicketCategory } from "@trainwell/features/legacy";
import type { NormalizedTickets } from "src/lib/ticketNormalization";
import { upsertTickets as dataUpsertTickets } from "src/lib/ticketNormalization";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/slices/store";
import { selectTicketChats, updateChat } from "./chatSlice";
import { selectPrimaryTrainer } from "./trainerSlice";

export const fetchTickets = createAsyncThunk(
  "tickets/fetchTickets",
  async (data: { type: Ticket["type"]; state: Ticket["state"] }) => {
    const { type, state } = data;

    const response = await api.tickets.getMany({
      type,
      state,
      createdBefore: new Date().toISOString(),
    });

    return response;
  },
);

export const fetchTicket = createAsyncThunk(
  "tickets/fetchTicket",
  async (ticketId: string) => {
    const response = await api.tickets.getOne(ticketId);

    return response;
  },
);

export const fetchTicketCategories = createAsyncThunk(
  "tickets/fetchTicketCategories",
  async () => {
    const response = await api.tickets.getCategories();

    return response;
  },
);

export const updateTicket = createAsyncThunk(
  "tickets/updateTicket",
  async (data: Partial<Ticket> & Pick<Ticket, "id">) => {
    const response = await api.tickets.updateOne(data);

    return response;
  },
);

export const fetchMoreTickets = createAsyncThunk(
  "tickets/fetchMoreTickets",
  async (
    data: { type: Ticket["type"]; state: Ticket["state"] },
    { getState },
  ) => {
    const { type, state } = data;
    const appState = getState() as RootState;

    const lastTicketId =
      appState.tickets.tickets.ids[appState.tickets.tickets.ids.length - 1];
    const lastTicket = appState.tickets.tickets.entities[lastTicketId];

    const response = await api.tickets.getMany({
      type,
      state,
      createdBefore: lastTicket.date_created as string,
    });

    return response;
  },
);

export const createCategory = createAsyncThunk(
  "tickets/createCategory",
  async (name: string) => {
    const response = await api.tickets.createCategory(name);

    return response;
  },
);

export const removeCategory = createAsyncThunk(
  "tickets/removeCategory",
  async (categoryKey: string) => {
    const response = await api.tickets.removeCategory(categoryKey);

    return response;
  },
);

export const renameCategory = createAsyncThunk(
  "tickets/renameCategory",
  async (data: { categoryKey: string; newName: string }) => {
    const response = await api.tickets.updateCategoryName(
      data.categoryKey,
      data.newName,
    );

    return response;
  },
);

export const createSubCategory = createAsyncThunk(
  "tickets/createSubCategory",
  async (data: { parentCategoryKey: string; name: string }) => {
    const response = await api.tickets.createSubCategory(
      data.parentCategoryKey,
      data.name,
    );

    return response;
  },
);

export const removeSubCategory = createAsyncThunk(
  "tickets/removeSubCategory",
  async (data: { parentCategoryKey: string; subCategoryKey: string }) => {
    const response = await api.tickets.removeSubCategory(
      data.parentCategoryKey,
      data.subCategoryKey,
    );

    return response;
  },
);

export const renameSubCategory = createAsyncThunk(
  "tickets/renameSubCategory",
  async (data: {
    parentCategoryKey: string;
    subCategoryKey: string;
    newName: string;
  }) => {
    const response = await api.tickets.updateSubCategoryName(
      data.parentCategoryKey,
      data.subCategoryKey,
      data.newName,
    );

    return response;
  },
);

export const addMemberToTicket = createAsyncThunk(
  "tickets/addMemberToTicket",
  async (
    data: {
      ticketId: string;
      trainerId: string;
    },
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const ticketChats = selectTicketChats(state);

    if (!trainer) {
      return;
    }

    const response = await api.tickets.addMember(
      data.ticketId,
      trainer.trainer_id,
      data.trainerId,
    );

    const chatIndex = ticketChats.findIndex(
      (chat) => chat.ticketId === data.ticketId,
    );

    if (chatIndex !== -1) {
      dispatch(
        updateChat({
          id: state.chat.chats[ticketChats[chatIndex].id].id,
          memberIds: [
            ...(state.chat.chats[ticketChats[chatIndex].id].memberIds ?? []),
            data.trainerId,
          ],
        }),
      );
    }

    return response;
  },
);

// Define a type for the slice state
interface TicketsState {
  tickets: NormalizedTickets;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  categories: TicketCategory[] | null;
  categoriesStatus: "idle" | "loading" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: TicketsState = {
  tickets: { ids: [], entities: {} },
  status: "idle",
  error: undefined,
  categories: null,
  categoriesStatus: "idle",
};

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    upsertTickets: (
      state,
      action: PayloadAction<{ tickets: Ticket[]; addToIds?: boolean }>,
    ) => {
      const { tickets, addToIds } = action.payload;

      dataUpsertTickets(state.tickets, tickets, addToIds);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTickets.pending, (state) => {
      state.status = "loading";
      // state.tickets = null;
    });
    builder.addCase(fetchTickets.fulfilled, (state, action) => {
      console.log("Redux: Got tickets");
      state.status = "succeeded";

      const tickets = action.payload;

      state.tickets.ids = [];

      dataUpsertTickets(state.tickets, tickets, true);
    });
    builder.addCase(fetchMoreTickets.fulfilled, (state, action) => {
      console.log("Redux: Got tickets");
      state.status = "succeeded";

      const tickets = action.payload;

      dataUpsertTickets(state.tickets, tickets, true);
    });
    builder.addCase(fetchTickets.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchTicket.fulfilled, (state, action) => {
      console.log("Redux: Got ticket");

      const ticket = action.payload;

      dataUpsertTickets(state.tickets, [ticket]);
    });
    builder.addCase(fetchTicketCategories.pending, (state) => {
      state.categoriesStatus = "loading";
    });
    builder.addCase(fetchTicketCategories.fulfilled, (state, action) => {
      console.log("Redux: Got ticket categories");
      state.categoriesStatus = "succeeded";

      let categories = action.payload;

      categories = categories.map((category) => {
        return {
          ...category,
          specifics: category.specifics.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
          ),
        };
      });

      state.categories = categories;
    });
    builder.addCase(fetchTicketCategories.rejected, (state) => {
      state.categoriesStatus = "failed";
    });
    builder.addCase(createCategory.fulfilled, (state, action) => {
      console.log("Redux: Created ticket category");
      const newCategory = action.payload;

      state.categories?.push(newCategory);
    });
    builder.addCase(removeCategory.fulfilled, (state, action) => {
      console.log("Redux: Removed ticket category");
      const categoryKey = action.meta.arg;

      const categoryIndex = state.categories?.findIndex(
        (category) => category.key === categoryKey,
      );

      if (categoryIndex !== undefined && categoryIndex !== -1) {
        state.categories?.splice(categoryIndex, 1);

        // state.tickets?.forEach((ticket, index) => {
        //   if (ticket.category === categoryKey) {
        //     state.tickets![index].category = "none";
        //     state.tickets![index].category_specific = "none";
        //   }
        // });
      }
    });
    builder.addCase(renameCategory.fulfilled, (state, action) => {
      console.log("Redux: Renamed ticket category");
      const { categoryKey, newName } = action.meta.arg;

      const categoryIndex = state.categories?.findIndex(
        (category) => category.key === categoryKey,
      );

      if (categoryIndex !== undefined && categoryIndex !== -1) {
        state.categories![categoryIndex].name = newName;
      }
    });
    builder.addCase(createSubCategory.fulfilled, (state, action) => {
      console.log("Redux: Created ticket sub category");
      const newCategories = action.payload;

      state.categories = newCategories;
    });
    builder.addCase(removeSubCategory.fulfilled, (state, action) => {
      console.log("Redux: Created ticket sub category");
      const newCategories = action.payload;

      state.categories = newCategories;
    });
    builder.addCase(renameSubCategory.fulfilled, (state, action) => {
      console.log("Redux: Created ticket sub category");
      const newCategories = action.payload;

      state.categories = newCategories;
    });
    builder.addCase(updateTicket.fulfilled, (state, action) => {
      const newTicket = action.payload;

      if (!state.tickets) {
        return;
      }

      state.tickets.entities[newTicket.id] = newTicket;
    });
  },
});

// Action creators are generated for each case reducer function
export const { upsertTickets } = ticketsSlice.actions;

export default ticketsSlice.reducer;

export const selectTicketById = (
  state: RootState,
  ticketId: string,
): Ticket | undefined => state.tickets?.tickets.entities[ticketId];

export const selectTicketCategories = createSelector(
  [(state: RootState) => state.tickets.categories],
  (categories) =>
    categories
      ? [...categories, { name: "None", key: "none", specifics: [] }]
      : undefined,
);
