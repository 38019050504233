import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import EmojiEmotionsRoundedIcon from "@mui/icons-material/EmojiEmotionsRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputBase,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import type { Message } from "@trainwell/features/legacy";
import type { KeyboardEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { EmojiPicker } from "src/components/misc/EmojiPicker";
import ClientProfilePicture from "src/features/client/ClientProfilePicture";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import {
  fetchMoreMessages,
  selectChatById,
  selectMessageByChatId,
  setMessage,
} from "src/slices/chatSlice";
import { updateClient } from "src/slices/clientSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { MessageContent } from "../messages/MessageContent";

interface Props {
  chatId: string;
  color?: "error" | "default";
  onMouseOverLinger?: () => void;
  onSendMessage?: (message: string) => void;
}

export function ThreadCard({
  chatId,
  color,
  onMouseOverLinger,
  onSendMessage,
}: Props) {
  const dispatch = useAppDispatch();
  const textfieldRef = useRef<HTMLInputElement>(null);
  const chat = useAppSelector((state) => selectChatById(state, chatId));
  const message = useAppSelector((state) =>
    selectMessageByChatId(state, chatId),
  );
  const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const isEmojiPickerOpen = Boolean(emojiPickerAnchorEl);
  const socketConnected = useAppSelector((state) => state.app.socketConnected);
  const hoverTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [chatNotes, setChatNotes] = useState("");
  const [editingChatNotes, setEditingChatNotes] = useState(false);
  const clientChatNotes = useAppSelector(
    (state) => selectClientById(state, chatId)?.chat_notes,
  );
  const hasUnreadWorkoutActionItems = useAppSelector(
    (state) =>
      selectActionItemsForClient(state, chatId).filter(
        (ai) => ai.type === "workout_review" && ai.is_internal,
      ).length > 0,
  );

  function closeEmojiPicker() {
    setEmojiPickerAnchorEl(null);

    setTimeout(() => {
      textfieldRef.current?.focus();
    }, 100);
  }

  const addEmoji = (emoji: string) => {
    const position = textfieldRef.current?.selectionStart;

    let newMessage = message;

    if (position !== null && position !== undefined) {
      newMessage =
        newMessage.substring(0, position) +
        emoji +
        newMessage.substring(position);
    } else {
      newMessage = newMessage + emoji;
    }

    dispatch(setMessage({ message: newMessage, chatId: chatId }));
    closeEmojiPicker();
  };

  useEffect(() => {
    return () => {
      if (hoverTimerRef.current) {
        clearTimeout(hoverTimerRef.current);
      }
    };
  }, []);

  function onKeyDown(
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const key = event.key;

    if (
      key === "Enter" &&
      !(event.shiftKey || event.ctrlKey || event.altKey || event.metaKey)
    ) {
      event.preventDefault();

      if (message.length > 0) {
        if (onSendMessage) {
          onSendMessage(message);
        }
      }
    }
  }

  const chatDisabled = !socketConnected || Boolean(chat.forceTrainerId);

  const hasUnreadMessage =
    Boolean(chat.oldestUnreadMessageFromClient) || hasUnreadWorkoutActionItems;

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: (theme) =>
          color === "error"
            ? theme.palette.errorSurface.main
            : theme.palette.backgroundSecondary.main,
        // width: "400px",
        boxShadow: 1,
        height: "540px",
        display: "flex",
        flexDirection: "column",
      }}
      onMouseEnter={() => {
        if (onMouseOverLinger) {
          hoverTimerRef.current = setTimeout(onMouseOverLinger, 1000);
        }
      }}
      onMouseLeave={() => {
        if (hoverTimerRef.current) {
          clearTimeout(hoverTimerRef.current);
        }
      }}
    >
      <Box
        sx={{
          p: 1,
          backgroundColor: (theme) => theme.palette.background.paper,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: "5px",
                opacity: hasUnreadMessage ? undefined : 0,
                backgroundColor: (theme) => theme.palette.primary.main,
                mr: 1,
              }}
            />
            <Button
              variant="text"
              size="small"
              href={`/clients/${chat.id}`}
              onClick={() => {
                if (onMouseOverLinger) {
                  onMouseOverLinger();
                }
              }}
            >
              <ClientProfilePicture dimension={32} userId={chat.id} />
              <Box sx={{ ml: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {chat.clientName}
                </Typography>
              </Box>
            </Button>
          </Box>
          <Button
            variant="text"
            size="small"
            loading={chat.loadingState === "loading"}
            onClick={() => {
              dispatch(fetchMoreMessages({ chatId: chat.id }));
            }}
          >
            Load more
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 1,
          py: 0.25,
          backgroundColor: (theme) => theme.palette.background.paper,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tooltip
          title={editingChatNotes ? "Save chat notes" : "Chat notes"}
          disableInteractive
        >
          <IconButton
            size="small"
            onClick={() => {
              if (!editingChatNotes) {
                setChatNotes(clientChatNotes ?? "");
                setEditingChatNotes(true);
              } else {
                dispatch(
                  updateClient({
                    user_id: chat?.id ?? "",
                    chat_notes: chatNotes,
                  }),
                );

                setEditingChatNotes(false);
              }
            }}
            sx={{ mr: 1 }}
          >
            {editingChatNotes ? (
              <SaveRoundedIcon />
            ) : (
              <ChatBubbleOutlineRoundedIcon fontSize="inherit" />
            )}
          </IconButton>
        </Tooltip>
        {!editingChatNotes && clientChatNotes ? (
          <Typography
            sx={{
              overflowWrap: "break-word",
              overflowX: "hidden",
            }}
          >
            {clientChatNotes}
          </Typography>
        ) : editingChatNotes ? (
          <TextField
            size="small"
            fullWidth
            multiline
            value={chatNotes}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.stopPropagation();
                event.preventDefault();

                dispatch(
                  updateClient({
                    user_id: chat?.id ?? "",
                    chat_notes: chatNotes,
                  }),
                );

                setEditingChatNotes(false);
              }
            }}
            onChange={(event) => {
              setChatNotes(event.target.value);
            }}
          />
        ) : (
          <Typography variant="overline">Add chat notes</Typography>
        )}
      </Box>
      <Divider />
      <Box sx={{ px: 1, flex: 1, height: "100%" }}>
        <VirtualList items={chat.messages} chatId={chatId} />
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderTop: 1,
          borderColor: "divider",
          p: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={(event) => {
            setEmojiPickerAnchorEl(event.currentTarget);
          }}
          size="small"
          sx={{ mr: 1 }}
          disabled={chatDisabled}
        >
          <EmojiEmotionsRoundedIcon fontSize="small" />
        </IconButton>
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            backgroundColor: (theme) => theme.palette.background.default,
            borderRadius: 1,
            maxHeight: "300px",
            border: 1,
            borderColor: "divider",
          }}
        >
          <InputBase
            inputRef={textfieldRef}
            value={message}
            sx={{ p: 0.5 }}
            fullWidth
            placeholder={"Message"}
            multiline={true}
            minRows={2}
            onChange={(e) => {
              dispatch(
                setMessage({ message: e.target.value, chatId: chat.id }),
              );
            }}
            onFocus={() => {
              if (onMouseOverLinger) {
                onMouseOverLinger();
              }
            }}
            onKeyDown={(event) => {
              onKeyDown(event);
            }}
            disabled={chatDisabled}
            name="chat_input"
          />
        </Box>
      </Box>
      <Popover
        open={isEmojiPickerOpen}
        anchorEl={emojiPickerAnchorEl}
        onClose={closeEmojiPicker}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <EmojiPicker onSelect={addEmoji} />
      </Popover>
    </Card>
  );
}

interface ShortUrlListProps {
  items: Message[];
  chatId: string;
}

function VirtualList({ items, chatId }: ShortUrlListProps) {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const chat = useAppSelector((state) => selectChatById(state, chatId));
  const forceSmsDisabled = useAppSelector(
    (state) => selectClientById(state, chatId)?.settings.disable_sms ?? false,
  );

  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 10,
    getItemKey: (index) => items[index].message_id,
    overscan: 5,
  });
  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={parentRef}
      style={{
        height: "100%",
        overflow: "auto",
        contain: "strict",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map((virtualRow) => {
            const messageId = virtualRow.key as string;
            const index = virtualRow.index;
            const message = items[index];

            let combineBottom = false;
            const convertedIndex = index - chat.firstChatIndex;

            if (
              convertedIndex >= 0 &&
              convertedIndex < chat.messages.length - 1 &&
              chat.messages[convertedIndex + 1].from_id === message.from_id &&
              chat.messages[convertedIndex + 1].type !== "notification"
            ) {
              combineBottom = true;
            }

            const fromMe = chat.isTrainwell
              ? message.from_id === "copilot"
              : message.from_id === trainer?.trainer_id ||
                message.from_id === chat.oldTrainerId ||
                (message.trainer_id_interim === trainer?.trainer_id &&
                  message.from_id !== chat.id);

            const actuallyFromThisCoach =
              !message.trainer_id_interim &&
              message.from_id === trainer?.trainer_id;

            return (
              <div
                key={messageId}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                <MessageContent
                  key={message.message_id}
                  message={message}
                  isFromMe={fromMe}
                  isFromOtherCoach={Boolean(
                    fromMe &&
                      !actuallyFromThisCoach &&
                      (message.trainer_id_interim === chat.oldTrainerId ||
                        message.trainer_id_interim !== trainer?.trainer_id),
                  )}
                  combineBottom={combineBottom}
                  forceSmsDisabled={forceSmsDisabled}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
