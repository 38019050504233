import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "src/hooks/stateHooks";

interface Props {
  userId: string;
  freeFloating?: boolean;
}

export default function LevelPanel({ userId, freeFloating = false }: Props) {
  const level = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.level
        : undefined,
    shallowEqual,
  );

  return (
    <Accordion
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🏆 Level</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {level ? (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={level.media_url}
                width={70}
                height={70}
                alt="level image"
              />
              <Box sx={{ ml: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Level {level.level}
                </Typography>
                <Typography variant="overline">
                  Achieved{" "}
                  {format(new Date(level.date_achieved), "MMMM do, yyyy")}
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
              {level.points_achieved} points
            </Typography>
            <LinearProgress
              sx={{ height: "8px", borderRadius: "4px" }}
              variant="determinate"
              value={
                ((level.points_achieved - level.points_start) /
                  (level.points_end - level.points_start)) *
                100
              }
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{level.points_start}</Typography>
              <Typography>{level.points_end}</Typography>
            </Box>
          </Box>
        ) : (
          <Typography>No level info found</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
