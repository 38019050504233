import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter } from "@tanstack/react-router";
import { updateApiOptions } from "@trainwell/features";
import "@uppy/audio/dist/style.min.css";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/screen-capture/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import { getAuth } from "firebase/auth";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "src/app/Router";
import {
  TRAINWELL_API_DOMAIN,
  TRAINWELL_API_KEY,
  TRAINWELL_CHAT_API_DOMAIN,
} from "src/config/config";
import { store } from "src/slices/store";
import "src/theme/theme.css";
import { routeTree } from "../routeTree.gen";

updateApiOptions({
  prefixUrl: TRAINWELL_API_DOMAIN,
  headers: {
    "api-key": TRAINWELL_API_KEY,
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        const auth = getAuth();

        const tokenString = await auth.currentUser?.getIdToken();

        request.headers.set("Authorization", `Bearer ${tokenString}`);
      },
    ],
  },
});

const queryClient = new QueryClient();

export const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  scrollRestoration: true,
  defaultStructuralSharing: true,
  pathParamsAllowedCharacters: ["@"],
});

Sentry.init({
  dsn: "https://ac27ec071dfc432095c04538fd936e9b@o505736.ingest.sentry.io/6224093",
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [[400, 599]],
      failedRequestTargets: [TRAINWELL_API_DOMAIN],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    TRAINWELL_API_DOMAIN,
    TRAINWELL_CHAT_API_DOMAIN,
  ],

  // Capture Replay for 1% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,

  ignoreErrors: [
    "ResizeObserver loop completed with undelivered notifications.",
  ],
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Could not find root element");
}

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Router />
        </Provider>
      </QueryClientProvider>
    </Suspense>,
  );
}
