import { createFileRoute } from "@tanstack/react-router";
import { ChatPage } from "src/features/chat/ChatPage";
import { z } from "zod";

const searchSchema = z.object({
  chat_id: z.string().optional(),
});

export const Route = createFileRoute("/_layout/chat")({
  validateSearch: searchSchema,
  component: ChatPage,
});
