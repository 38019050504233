import { useNavigate } from "@tanstack/react-router";
import { getAuth, signOut } from "firebase/auth";
import { useEffect } from "react";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch } from "src/hooks/stateHooks";
import { firebaseApp } from "src/lib/firebase";
import { resetActionItems } from "src/slices/actionItemSlice";
import { resetApp } from "src/slices/appSlice";
import { resetClient } from "src/slices/clientSlice";
import { resetClients } from "src/slices/clientsSlice";
import { resetPhaseTemplates } from "src/slices/phaseTemplatesSlice";
import { resetTemplates } from "src/slices/templatesSlice";
import { resetTrainerEdit } from "src/slices/trainerEditSlice";
import { resetCoach } from "src/slices/trainerSlice";
import { resetCoaches } from "src/slices/trainersSlice";
import { resetWorkout } from "src/slices/workoutSlice";

export default function LogoutPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("Firebase app: ", firebaseApp.name);

    handleLogout();
  }, []);

  function handleLogout() {
    console.log("Auth: handling logout from /logout");

    const auth = getAuth();

    signOut(auth)
      .then(() => {
        console.log("Auth: logout successful from /logout");

        localStorage.clear();
        sessionStorage.clear();

        dispatch(resetTemplates());
        dispatch(resetPhaseTemplates());
        dispatch(resetActionItems());
        dispatch(resetClient());
        dispatch(resetClients());
        dispatch(resetCoach());
        dispatch(resetApp());
        dispatch(resetWorkout());

        dispatch(resetCoaches());
        dispatch(resetTrainerEdit());

        navigate({ to: "/login" });

        location.reload();
      })
      .catch((error: unknown) => {
        console.error("Auth: logout failed from /logout");

        throw error;
      });
  }

  return <LoadingPage message="Logging out" />;
}
