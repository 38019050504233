import { Box } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import type { HabitType } from "@trainwell/features/legacy";
import { getHabitColor } from "src/lib/habits";

interface Props {
  type: HabitType;
  complete?: boolean;
  widthAndHeight?: number;
  size?: "small" | "large";
  sx?: SxProps<Theme>;
}

export default function HabitDot({
  type,
  complete,
  widthAndHeight,
  size = "large",
  sx = [],
}: Props) {
  const dimensions = widthAndHeight ?? (size === "small" ? 8 : 10);
  const borderWidth = size === "small" ? 2 : 3;

  return (
    <Box
      sx={[
        {
          width: `${dimensions}px`,
          height: `${dimensions}px`,
          borderRadius: `${dimensions / 2}px`,
          backgroundColor: complete ? getHabitColor(type) : undefined,
          border: complete ? undefined : borderWidth,
          borderColor: getHabitColor(type),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}
