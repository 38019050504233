import type { Ticket } from "@trainwell/features/legacy";

export interface NormalizedTickets {
  ids: string[];
  entities: Record<string, Ticket>;
}

export function upsertTickets(
  normalTickets: NormalizedTickets,
  tickets: Ticket[],
  addToIds?: boolean,
) {
  for (const ticket of tickets) {
    if (addToIds && !normalTickets.ids.includes(ticket.id)) {
      normalTickets.ids.push(ticket.id);
    }

    normalTickets.entities[ticket.id] = ticket;
  }
}
