import { createFileRoute } from "@tanstack/react-router";
import { getInfluencerQueryOptions } from "@trainwell/features/influencers";

export const Route = createFileRoute(
  "/_layout/admin/influencers/$influencerId",
)({
  loader: ({ context: { queryClient }, params: { influencerId } }) => {
    return queryClient.prefetchQuery(getInfluencerQueryOptions(influencerId));
  },
});
