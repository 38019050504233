import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import { alpha, Avatar, type SxProps, type Theme } from "@mui/material";

interface Props {
  sx?: SxProps<Theme>;
}

export function UpcomingWorkoutIcon({ sx = [] }: Props) {
  return (
    <Avatar
      sx={{
        width: 18,
        height: 18,
        backgroundColor: (theme) => alpha(theme.palette.text.disabled, 0.1),
        ...sx,
      }}
    >
      <FitnessCenterRoundedIcon
        sx={{
          fontSize: 14,
          color: (theme) => theme.palette.text.disabled,
        }}
      />
    </Avatar>
  );
}
