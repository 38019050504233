import { alpha, Box, type SxProps, type Theme } from "@mui/material";

interface Props {
  sx?: SxProps<Theme>;
}

export function UpcomingRestDayIcon({ sx = [] }: Props) {
  return (
    <Box
      sx={{
        width: 18,
        height: 18,
        borderRadius: 9,
        backgroundColor: (theme) => alpha(theme.palette.text.disabled, 0.1),
        ...sx,
      }}
    />
  );
}
