import { useTheme } from "@mui/material";
import {
  ChartContainer,
  ChartsReferenceLine,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
} from "@mui/x-charts";

interface Props {
  lengths: number[];
  idealPacing?: number;
  isBadPacing?: boolean;
}

export function PacingChart({ lengths, idealPacing, isBadPacing }: Props) {
  const theme = useTheme();

  const data = lengths.map((y, x) => ({
    x: x,
    y: y,
  }));

  return (
    <ChartContainer
      width={300}
      height={300}
      colors={
        isBadPacing ? [theme.palette.error.main] : [theme.palette.primary.main]
      }
      xAxis={[
        {
          dataKey: "x",
          valueFormatter: (value) => `Rep ${Math.round((value as number) + 1)}`,
        },
      ]}
      yAxis={[
        {
          label: "Rep Length (s)",
          min: 0,
        },
      ]}
      series={[
        {
          type: "line",
          dataKey: "y",
        },
      ]}
      dataset={data}
    >
      <LinePlot />
      <MarkPlot />
      {idealPacing && (
        <ChartsReferenceLine
          y={idealPacing}
          label="Ideal"
          lineStyle={{
            stroke: theme.palette.success.main,
          }}
        />
      )}
      <ChartsXAxis />
      <ChartsYAxis />
    </ChartContainer>
  );
}
