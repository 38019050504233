import { Box, Popover, Stack, Typography } from "@mui/material";
import type { LogExercise } from "@trainwell/features/legacy";
import { useState } from "react";
import ExerciseMedia from "src/components/misc/ExerciseMedia";
import TrackingStatus from "src/components/misc/TrackingStatus";
import {
  getExerciseDisplayName,
  getExerciseIconURL,
} from "src/lib/mediaUtility";
import LogSetCell from "./LogSetCell";

interface Props {
  logId: string;
  exercise: LogExercise;
  firstExercise?: LogExercise;
  enablePreview: boolean;
}

export default function LogExerciseCell({
  logId,
  exercise,
  firstExercise,
  enablePreview,
}: Props) {
  const [animationAnchor, setAnimationAnchor] =
    useState<HTMLImageElement | null>(null);
  const animationOpen = Boolean(animationAnchor);
  const [popupExercise, setPopupExercise] = useState<string>();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyItems: "start",
          alignItems: "center",
          textAlign: "start",
          mb: 2,
        }}
      >
        <img
          src={
            getExerciseIconURL(exercise.exercise_master_id) ||
            "/assets/profile.png"
          }
          alt={exercise.exercise_master_id}
          width={46}
          height={46}
          style={{
            borderRadius: "8px",
          }}
          onMouseEnter={(e) => {
            document.documentElement.style.cursor = "none";
            setPopupExercise(exercise.exercise_master_id);
            setAnimationAnchor(e.currentTarget);
          }}
          onMouseLeave={() => {
            document.documentElement.style.cursor = "auto";
            setAnimationAnchor(null);
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              mr: 1,
            }}
          >
            {getExerciseDisplayName(exercise.exercise_master_id)}
          </Typography>
          <TrackingStatus exerciseMasterId={exercise.exercise_master_id} />
        </Box>
      </Box>
      <Stack direction={"column"} spacing={0.5}>
        {exercise.sets.map((setLog, setIndex) => (
          <LogSetCell
            key={setLog.set_log_id ?? setIndex}
            logId={logId}
            setLog={setLog}
            exerciseMasterID={exercise.exercise_master_id}
            setIndex={setIndex}
            firstExerciseId={firstExercise?.exercise_id}
            firstSetId={firstExercise?.sets[setIndex].set_id}
            enablePreview={enablePreview}
          />
        ))}
      </Stack>
      <Popover
        style={{ pointerEvents: "none", padding: "1em", cursor: "none" }}
        open={animationOpen}
        anchorEl={animationAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 1, maxWidth: 600 }}>
          <Typography variant="h3" sx={{ textAlign: "center", mb: 2 }}>
            {getExerciseDisplayName(popupExercise!)}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ExerciseMedia
              exerciseMasterId={popupExercise!}
              width={300}
              height={300}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
