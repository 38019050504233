import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useGoogleForms } from "@trainwell/features/google-forms";
import { format } from "date-fns";
import { useState } from "react";

interface Props {
  userId: string;
}

export default function SurveysPanel({ userId }: Props) {
  const [expanded, setExpanded] = useState(false);
  const { data: googleForms } = useGoogleForms({
    filter: { userId },
    queryConfig: {
      enabled: expanded,
    },
  });

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">📢 Surveys</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ height: "300px", overflowY: "auto" }}>
          <Stack spacing={2} direction="column" divider={<Divider />}>
            {googleForms?.map((googleForm) => {
              return (
                <Box key={googleForm.id}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Survey sent on{" "}
                    {format(new Date(googleForm.date_sent), "MMM do, yyyy")}
                  </Typography>
                  <Typography>
                    {googleForm.date_completed
                      ? `Completed on ${format(
                          new Date(googleForm.date_completed),
                          "MMM do, yyyy",
                        )}`
                      : "Not answered"}
                  </Typography>
                  {googleForm.response_url && (
                    <Button
                      endIcon={<OpenInNewRoundedIcon />}
                      size="small"
                      target="_blank"
                      href={googleForm.response_url}
                    >
                      Answers
                    </Button>
                  )}
                </Box>
              );
            })}
          </Stack>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
