import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const searchSchema = z.object({
  tab: z
    .union([
      z.literal("info"),
      z.literal("tickets"),
      z.literal("audits"),
      z.literal("events"),
    ])
    .catch("info"),
});

export const Route = createFileRoute("/_layout/admin/company/clients/$userId")({
  validateSearch: searchSchema,
});
