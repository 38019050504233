import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import { Avatar, Box, Typography } from "@mui/material";
import type { MessageHabitsMissed } from "@trainwell/features/legacy";
import HabitDot from "src/components/misc/HabitDot";

interface Props {
  message: MessageHabitsMissed;
  combineBottom: boolean;
}

export default function HabitsMissedMessage({ message, combineBottom }: Props) {
  const missedHabits = message.content.missed_habits;

  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        p: 1,
        width: "fit-content",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
        <Avatar
          sx={{
            backgroundColor: (theme) => theme.palette.backgroundTertiary.main,
            mr: 1,
            width: 22,
            height: 22,
          }}
        >
          <LoopRoundedIcon
            sx={{
              fontSize: 22 * 0.6,
              color: (theme) => theme.palette.error.main,
            }}
          />
        </Avatar>
        <Typography
          variant="h6"
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
        >
          Missed habit{missedHabits.length <= 1 ? "" : "s"}
        </Typography>
      </Box>
      {missedHabits.map((habit) => (
        <Box
          key={habit.id}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <HabitDot type={habit.type} size="small" />
          <Typography key={habit.id} sx={{ ml: 1 }}>
            {habit?.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
