import type {
  Client,
  HabitTemplate,
  HabitWeek,
} from "@trainwell/features/legacy";
import { addDays, isBefore, isSameWeek, startOfWeek, subDays } from "date-fns";
import { getCurrentWeekStartDate, getLocalDate } from "src/lib/date";

export function getHabitColor(habitType: HabitWeek["type"]) {
  if (habitType === "mindfulness") {
    return "#ffdd99";
  } else if (habitType === "movement") {
    return "#ffb589";
  } else if (habitType === "nutrition") {
    return "#b3e5b6";
  } else if (habitType === "recovery") {
    return "#a7b2e8";
  } else if (habitType === "progress_metric_measurement") {
    return "#ffb8f4";
  }
}

export function getHabitTypeLabel(habitType: HabitWeek["type"]) {
  if (habitType === "mindfulness") {
    return "Mindfulness";
  } else if (habitType === "movement") {
    return "Movement";
  } else if (habitType === "nutrition") {
    return "Nutrition";
  } else if (habitType === "recovery") {
    return "Recovery";
  }
}

export function getHabitDetails(habit: HabitWeek) {
  const details: string[] = [];

  if (habit.steps_required) {
    details.push(`${habit.steps_required} steps`);
  }

  details.push(
    (habit.schedule_intended ?? "custom").charAt(0).toUpperCase() +
      (habit.schedule_intended ?? "custom").slice(1),
  );

  if (habit.suggested_time) {
    details.push(habit.suggested_time);
  }

  return details.join(", ");
}

export const trainwellWorkoutHabit: HabitTemplate = {
  id: "copilot_workout",
  trainer_id: null,
  user_id: null,
  created_by_trainer_id: "copilot",
  date_created: new Date().toISOString(),
  name: "Workouts",
  type: "movement",
  notes_coach_default: null,
  suggested_time: null,
  steps_required: null,
  schedule: "daily",
  schedule_intended: "daily",
  custom_days: null,
  custom_days_intended: null,
  movement_type: "copilot_workout",
  date_deleted: null,
  metric_completion: null,
};

export function getSelectedDays(
  schedule: HabitWeek["schedule"],
  customDays: HabitWeek["custom_days"],
): boolean[] {
  let selectedDays = [false, false, false, false, false, false, false];

  if (schedule === "daily") {
    selectedDays = [true, true, true, true, true, true, true];
  } else if (schedule === "weekdays") {
    selectedDays = [false, true, true, true, true, true, false];
  } else if (schedule === "weekends") {
    selectedDays = [true, false, false, false, false, false, true];
  } else if (schedule === "custom" && customDays) {
    selectedDays = customDays;
  }

  return selectedDays;
}

/**
 * Takes intended schedule and returns a trimmed schedule.
 * Trims past days and anchored days, if applicable
 */
export function getHabitSchedule(
  scheduleIntended: HabitWeek["schedule_intended"],
  customDaysIntended: HabitWeek["custom_days_intended"],
  planDate: string | Date,
  anchoredWorkoutDays?: HabitWeek["anchored_workout_days"],
): {
  schedule: HabitWeek["schedule_intended"];
  custom_days: HabitWeek["custom_days_intended"];
} {
  let result = {
    schedule: scheduleIntended,
    custom_days: customDaysIntended,
  };

  if (
    isWeekPlanCurrentWeek(planDate) &&
    scheduleHasConflictsWithPastDay(
      scheduleIntended,
      new Date(planDate),
      customDaysIntended,
    )
  ) {
    // Trim schedule intended into schedule
    const prevSelectedDays = getSelectedDays(
      scheduleIntended,
      customDaysIntended,
    );

    const newSelectedDays = [false, false, false, false, false, false, false];

    prevSelectedDays.forEach((day, i) => {
      if (day && !isBefore(addDays(new Date(planDate), i + 1), new Date())) {
        newSelectedDays[i] = true;
      }
    });

    result = {
      schedule: "custom",
      custom_days: newSelectedDays,
    };
  } else {
    result = {
      schedule: scheduleIntended,
      custom_days: customDaysIntended,
    };
  }

  // Filter out anchored days, if any
  const newSelectedDays = [false, false, false, false, false, false, false];

  if (result.custom_days) {
    result.custom_days.forEach((day, i) => {
      if (day && !anchoredWorkoutDays?.[i]) {
        newSelectedDays[i] = true;
      }
    });
  }

  result.custom_days = newSelectedDays;

  return result;
}

function scheduleHasConflictsWithPastDay(
  schedule: HabitWeek["schedule"],
  planDate: string | Date,
  customDays?: HabitWeek["custom_days"],
): boolean {
  const selectedDays = getSelectedDays(schedule, customDays ?? []);

  for (let i = 0; i < 7; i++) {
    if (
      selectedDays[i] &&
      isBefore(addDays(getLocalDate(planDate), i), new Date())
    ) {
      return true;
    }
  }

  return false;
}

export function isProgramHabit(habit: HabitWeek | HabitTemplate) {
  return habit.type === "movement" && habit.movement_type === "copilot_workout";
}

export function isWeekPlanCurrentWeek(habitPlanDate: string | Date) {
  return isSameWeek(startOfWeek(new Date()), getLocalDate(habitPlanDate));
}

export function isWeekPlanInPast(
  habitPlanDate: string | Date,
  offsetOneWeek?: boolean,
) {
  return isBefore(
    new Date(habitPlanDate),
    subDays(getCurrentWeekStartDate(), offsetOneWeek ? 8 : 0),
  );
}

export function isHabitDayInPast(
  habitPlanDate: string | Date,
  dayIndex: number,
) {
  return isBefore(
    addDays(getLocalDate(habitPlanDate), dayIndex + 1),
    new Date(),
  );
}

export function habitWeekFromHabitTemplate(data: {
  habitTemplate: HabitTemplate;
  clientWorkoutTimes: Client["workout_times"];
  planDate: string | Date;
}) {
  const { habitTemplate, clientWorkoutTimes, planDate } = data;

  const newHabitWeek: HabitWeek = {
    id: crypto.randomUUID(),
    name: habitTemplate.name,
    date_created: habitTemplate.date_created,
    type: habitTemplate.type,
    movement_type: habitTemplate.movement_type,
    anchored_workout_days: null,
    bumped_workout_days: null,
    pulled_workout_days: null,
    notes_coach_default: habitTemplate.notes_coach_default || null,
    suggested_time: habitTemplate.suggested_time,
    steps_required: habitTemplate.steps_required,
    schedule: habitTemplate.schedule_intended,
    schedule_intended: habitTemplate.schedule_intended,
    custom_days: habitTemplate.custom_days_intended,
    custom_days_intended: habitTemplate.custom_days_intended,
    date_deleted: null,
    notes_coach_days: [null, null, null, null, null, null, null],
    metric_completion: null,
  };

  if (isProgramHabit(habitTemplate)) {
    if (clientWorkoutTimes) {
      newHabitWeek.schedule_intended = "custom";
      newHabitWeek.custom_days_intended = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];

      clientWorkoutTimes.forEach((day, i) => {
        if (day.has_time) {
          newHabitWeek.custom_days_intended![i] = true;
        }
      });
    } else {
      newHabitWeek.schedule_intended = "weekdays";
      newHabitWeek.custom_days_intended = null;
    }
  }

  if (
    scheduleHasConflictsWithPastDay(
      newHabitWeek.schedule_intended,
      planDate,
      newHabitWeek.custom_days_intended,
    )
  ) {
    // Trim schedule intended into schedule
    const prevSelectedDays = getSelectedDays(
      newHabitWeek.schedule_intended,
      newHabitWeek.custom_days_intended,
    );

    const newSelectedDays = [false, false, false, false, false, false, false];

    prevSelectedDays.forEach((day, i) => {
      if (
        day &&
        !isBefore(addDays(getLocalDate(planDate), i + 1), new Date())
      ) {
        newSelectedDays[i] = true;
      }
    });

    newHabitWeek.custom_days = newSelectedDays;
    newHabitWeek.schedule = "custom";
  } else {
    newHabitWeek.custom_days = newHabitWeek.custom_days_intended;
    newHabitWeek.schedule = newHabitWeek.schedule_intended;
  }

  return newHabitWeek;
}
