import type { QueryClient } from "@tanstack/react-query";
import {
  createRootRouteWithContext,
  retainSearchParams,
} from "@tanstack/react-router";
import { Root } from "src/app/Root";
import { z } from "zod";

const searchSchema = z.object({
  trainer_id: z.string().optional(),
  pwa: z.boolean().optional(),
});

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  validateSearch: searchSchema,
  component: Root,
  search: {
    middlewares: [retainSearchParams(["pwa"])],
  },
});
