import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  message?: string;
}

export default function LoadingPage({ message }: Props) {
  const [showTroubleshoot, setShowTroubleshoot] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowTroubleshoot(true);
    }, 5000);
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        px: 2,
        pb: 10,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress sx={{ mb: 2 }} />
        <Typography>
          {message ? message : "Our tech is warming up 🔥 😎"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          opacity: showTroubleshoot ? 1 : 0,
          transitionProperty: "opacity",
          transitionDuration: "0.15s",
          transitionTimingFunction: "ease-in-out",
        }}
      >
        <Button
          href="/logout"
          startIcon={<LogoutRoundedIcon />}
          variant="text"
          size="small"
        >
          Taking too long to load? Click here to logout
        </Button>
        <Typography variant="overline">
          Logging out and back in can often fix issues and is a good place to
          start troubleshooting
        </Typography>
      </Box>
    </Box>
  );
}
