import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import { Box, Chip, Popover, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import useActionItem from "src/hooks/useActionItem";
import { getActionItemDetails } from "src/lib/actionItem";
import {
  makeSelectActionItemsForClient,
  selectActionItemById,
} from "src/slices/actionItemSlice";
import { getActionItemStatus } from "./lib/officeHours";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

interface Props {
  userId: string;
  showChatBackground?: boolean;
  border?: boolean;
  background?: boolean;
}

export default function ActionItemRow({
  userId,
  showChatBackground,
  border,
  background,
}: Props) {
  const selectActionItemsForClient = useMemo(
    makeSelectActionItemsForClient,
    [],
  );
  const actionItems = useAppSelector((state) =>
    selectActionItemsForClient(state, userId).filter(
      (actionItem) => actionItem.type !== "custom",
    ),
  );

  if (actionItems.length <= 0) {
    return null;
  }

  const collapseIntoScrollable = actionItems.length >= 5;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: collapseIntoScrollable ? "nowrap" : "wrap",
        overflowX: collapseIntoScrollable ? "auto" : undefined,
        listStyle: "none",
        px: showChatBackground ? 0.5 : undefined,
        py: showChatBackground ? 0.25 : undefined,
        borderBottom: showChatBackground || border ? 1 : undefined,
        borderTop: border ? 1 : undefined,
        borderColor: "divider",
        backgroundColor: (theme) =>
          showChatBackground || background
            ? theme.palette.background.paper
            : undefined,
      }}
    >
      {actionItems.map((actionItem) => (
        <ActionItemChip key={actionItem.id} actionItemId={actionItem.id} />
      ))}
    </Box>
  );
}

interface PropsChip {
  actionItemId: string;
}

function ActionItemChip({ actionItemId }: PropsChip) {
  const { handleActionItemClick, handleActionItemClearButton } =
    useActionItem();
  const actionItem = useAppSelector((state) =>
    selectActionItemById(state, actionItemId),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );

  const open = Boolean(anchorEl);

  if (!actionItem) {
    return null;
  }

  const status = getActionItemStatus(actionItem);
  const details = getActionItemDetails(actionItem);

  const color: "default" | "errorSurface" | "warningSurface" =
    status === "ok"
      ? "default"
      : status === "warn"
        ? "warningSurface"
        : "errorSurface";

  return (
    <>
      <ListItem>
        <Chip
          color={color}
          label={details.name}
          onClick={(event) => {
            event.stopPropagation();

            setAnchorEl(null);

            if (isChatFullscreen) {
              return;
            }

            handleActionItemClick(actionItem);
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
          onMouseEnter={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
          deleteIcon={
            details.clearTypes?.includes("clear_button") ? (
              <TaskAltRoundedIcon />
            ) : undefined
          }
          onDelete={
            details.clearTypes?.includes("clear_button")
              ? (event) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  event.stopPropagation();

                  setAnchorEl(null);

                  handleActionItemClearButton(actionItem);
                }
              : undefined
          }
        />
      </ListItem>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
      >
        <Box sx={{ p: 1, maxWidth: "400px" }}>
          <Stack spacing={0.5} direction="column">
            {details.notes && <Typography>{details.notes}</Typography>}
            <Typography variant="overline">
              Sent{" "}
              {format(
                new Date(actionItem.date_created),
                "iiii, MMM d, yyyy 'at' h:mm a",
              )}
            </Typography>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
