import { Box, Typography } from "@mui/material";
import { useClient } from "@trainwell/features/clients";
import type { TrainerNotification } from "@trainwell/features/trainer-notifications";
import { getTrainerNotificationDetails } from "src/lib/trainerNotification";

interface Props {
  notification: TrainerNotification;
}

export function TrainerNotificationCell({ notification }: Props) {
  const { data: client } = useClient({ userId: notification.user_id });

  const details = getTrainerNotificationDetails(notification);

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      {details.icon}
      <Box sx={{ ml: 1 }}>
        <Typography sx={{ lineHeight: 1 }}>
          {client?.full_name || "Loading client name..."}
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>{details.name}</Typography>
        {details.notes && (
          <Typography variant="body2">{details.notes}</Typography>
        )}
      </Box>
    </Box>
  );
}
