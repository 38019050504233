import { useSearch } from "@tanstack/react-router";
import { useEffect } from "react";
import { setPwa } from "src/slices/appSlice";
import { useAppDispatch } from "./stateHooks";

export default function usePwa() {
  const dispatch = useAppDispatch();
  const { pwa } = useSearch({ strict: false });

  useEffect(() => {
    if (pwa) {
      dispatch(setPwa(true));
    }
  }, [pwa, dispatch]);

  return;
}
