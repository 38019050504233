import DataArrayRoundedIcon from "@mui/icons-material/DataArrayRounded";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
  debounce,
} from "@mui/material";
import type { Message } from "@trainwell/features/legacy";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import SearchField from "src/components/misc/SearchField";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectSelectedChat } from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import TextMessage from "./messages/TextMessage";

interface Props {
  userId: string;
  open: boolean;
  onClose: () => void;
}

export function ChatSearchDialog({ userId, open, onClose }: Props) {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const selectedChat = useAppSelector(selectSelectedChat);
  const [messages, setMessages] = useState<Message[] | null>(null);
  const [search, setSearch] = useState("");

  const searchMessages = useCallback(
    (query: string) => {
      console.log(`Search:`, query, userId);

      api.messages
        .fuzzySearch({
          userId,
          query: query,
        })
        .then((res) => {
          console.log(res.messages);
          setMessages(
            res.messages.sort((a, b) =>
              (a.send_date as string).localeCompare(b.send_date as string),
            ),
          );
        });
    },
    [userId],
  );

  const debouncedSearch = useMemo(() => {
    return debounce(searchMessages, 750);
  }, [searchMessages]);

  useEffect(() => {
    if (userId && search.length >= 3) {
      setMessages(null);

      debouncedSearch(search);
    }
  }, [userId, search, debouncedSearch]);

  useEffect(() => {
    setMessages(null);
    setSearch("");
  }, [userId, open]);

  if (!selectedChat) {
    return null;
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
        <DialogTitleWithClose onClose={onClose}>
          Search chat
        </DialogTitleWithClose>
        <DialogContent
          dividers
          sx={{
            p: 0,
            //   display: "flex",
            //   flexDirection: "column",
          }}
        >
          <Box
            sx={{
              p: 2,
              pb: 1,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <SearchField
              value={search}
              onChange={(value) => {
                setSearch(value);
              }}
              onClear={() => {
                setSearch("");
              }}
              autoFocus
              sx={{ mb: 1 }}
            />
            <Typography variant="overline">
              Newest at the top. Only includes messages that match your search.
            </Typography>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              height: "550px",
              backgroundColor: (theme) =>
                theme.palette.backgroundSecondary.main,
              px: 2,
              pt: 2,
              pb: 16,
            }}
          >
            {messages === null && search.length >= 3 && (
              <LoadingComponent message="Searching messages 🕵️" />
            )}
            {messages?.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  p: 1,
                  height: "100%",
                }}
              >
                <DataArrayRoundedIcon
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                />
                <Typography
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  No messages found
                </Typography>
              </Box>
            )}
            <Stack spacing={2} divider={<Divider />}>
              {messages?.map((message) => {
                const fromMe =
                  message.from_id === trainer?.trainer_id ||
                  message.from_id === selectedChat.oldTrainerId ||
                  (message.trainer_id_interim === trainer?.trainer_id &&
                    message.from_id !== selectedChat.id);

                const actuallyFromThisCoach =
                  !message.trainer_id_interim &&
                  message.from_id === trainer?.trainer_id;

                return (
                  <Box
                    key={message.message_id}
                    sx={{
                      alignItems: fromMe ? "flex-end" : "flex-start",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextMessage
                      text={message.text!}
                      isFromMe={fromMe}
                      isFromOtherCoach={Boolean(
                        fromMe &&
                          !actuallyFromThisCoach &&
                          (message.trainer_id_interim ===
                            selectedChat.oldTrainerId ||
                            message.trainer_id_interim !== trainer?.trainer_id),
                      )}
                      combineBottom={false}
                    />
                    <Typography variant="overline" sx={{ mt: 1 }}>
                      {format(
                        new Date(message.send_date),
                        "MMM d, yyyy 'at' h:mm a",
                      )}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
