import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useUpdateMealLog,
  type ClientMealLog,
} from "@trainwell/features/nutrition";
import { formatDistanceToNow } from "date-fns";
import { useSnackbar } from "notistack";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type FormEvent,
} from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { ScoreStack } from "./shared/ScoreStack";
import { TextItem } from "./shared/TextItem";
import { TitledSection } from "./shared/TitledSection";

interface Props {
  log: ClientMealLog | null;
  onClose: () => void;
  onLogUpdated: (log: ClientMealLog) => void;
}

export default function MealLogModal({ log, onClose, onLogUpdated }: Props) {
  const [trainerNote, setTrainerNote] = useState<string | null>(null);
  const hasChanges = useMemo(
    () => (log?.trainer_note ?? null) !== trainerNote && trainerNote !== "",
    [log, trainerNote],
  );
  const [showImageModal, setShowImageModal] = useState(false);

  const updateMealLog = useUpdateMealLog();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTrainerNote(log?.trainer_note ?? null);
  }, [log, setTrainerNote]);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      try {
        if (!log) throw new Error("Log is required to submit.");
        if (!trainerNote)
          throw new Error("Trainer Note is required to submit.");
        const { meal_log: updatedMealLog } = await updateMealLog.mutateAsync({
          mealLogId: log._id,
          data: {
            updates: { trainer_note: trainerNote },
          },
        });
        onLogUpdated(updatedMealLog);
        enqueueSnackbar({
          variant: "success",
          message: "Updated successfully.",
          autoHideDuration: 1_000,
        });
      } catch (err: any) {
        enqueueSnackbar({
          variant: "error",
          message: err.message ?? "Something went wrong",
          autoHideDuration: 1_000,
        });
      }
    },
    [log, trainerNote, onLogUpdated],
  );

  if (!log) return null;
  return (
    <>
      <Dialog
        open={log !== null}
        onClose={onClose}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <form onSubmit={handleSubmit} style={{ display: "contents" }}>
          <DialogTitleWithClose onClose={onClose}>
            Meal Log
          </DialogTitleWithClose>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Avatar
                  src={log.image_url}
                  variant="rounded"
                  sx={{
                    width: "160px",
                    height: "240px",
                    aspectRatio: "1",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowImageModal(true);
                  }}
                />
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <TitledSection title="Scores">
                    <ScoreStack log={log} size="lg" />
                  </TitledSection>
                  <TitledSection title="Logged">
                    <Typography>
                      {formatDistanceToNow(log.date, { addSuffix: true })}
                    </Typography>
                  </TitledSection>
                </Stack>
              </Stack>
              <Divider />
              <TitledSection title="AI Explanation">
                <TextItem text={log.explanation ?? ""} />
              </TitledSection>
              <TitledSection title="AI Recommendations">
                <TextItem text={log.recommendation ?? ""} />
              </TitledSection>
              <Divider />
              <TitledSection title="Client Note">
                <TextItem text={log.client_note ?? ""} />
              </TitledSection>
              <TitledSection title="Trainer Note">
                <TextField
                  variant="outlined"
                  placeholder="Try adding more vegetables next time!"
                  fullWidth
                  multiline={true}
                  value={trainerNote ?? ""}
                  onChange={(e) => {
                    setTrainerNote(e.target.value);
                  }}
                />
              </TitledSection>
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              type="submit"
              loading={updateMealLog.isPending}
              disabled={!hasChanges || updateMealLog.isPending}
            >
              Submit
            </Button>
          </DialogActions>
        </form>

        <Dialog
          open={showImageModal}
          onClose={() => {
            setShowImageModal(false);
          }}
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogTitleWithClose
            onClose={() => {
              setShowImageModal(false);
            }}
          >
            Image
          </DialogTitleWithClose>
          <Box
            component={"img"}
            src={log.image_url}
            sx={{
              width: "100%",
              aspectRatio: 0.66,
              maxHeight: "100%",
              objectFit: "cover",
            }}
          />
        </Dialog>
      </Dialog>
    </>
  );
}
