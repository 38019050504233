import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import { Box, Button, Typography } from "@mui/material";

interface Props {
  message?: string;
  eventId?: string;
  onResetError?: () => void;
}

export default function ErrorComponent({
  message,
  eventId,
  onResetError,
}: Props) {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <BugReportRoundedIcon sx={{ mr: 1 }} />
        <Typography variant="h1">Dash Error</Typography>
      </Box>
      {eventId && <Typography variant="overline">{eventId}</Typography>}
      <Typography sx={{ mb: 2, mt: 1 }}>
        {message ? message : "Something went wrong 😬"}
      </Typography>
      {onResetError && <Button onClick={onResetError}>Try to fix</Button>}
      <Typography variant="overline" sx={{ mt: 8 }}>
        Your dash has already notified the tech team
      </Typography>
      <Typography variant="overline">
        If you feel like this is a serious issue, please let us know on slack
      </Typography>
    </Box>
  );
}
