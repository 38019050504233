import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useEffect, useRef, useState } from "react";
import SearchField from "src/components/misc/SearchField";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  sortChatsByNewest,
  sortChatsByUnanswered,
  sortChatsByUnread,
} from "src/lib/chat";
import type { Chat } from "src/slices/chatSlice";
import {
  fetchOfficialChats,
  selectOfficialTrainwellChats,
} from "src/slices/chatSlice";
import { selectPrimaryCoachTrainerId } from "src/slices/trainerSlice";
import ChatCell from "./ChatCell";

type SortMode = "unread" | "unanswered" | "newest";

export function PanelOfficialTrainwellChats() {
  const dispatch = useAppDispatch();
  const officialTrainwellChats = useAppSelector(selectOfficialTrainwellChats);
  const [displayChats, setDisplayChats] = useState<Chat[]>([]);
  const [search, setSearch] = useState("");
  const trainerId = useAppSelector(selectPrimaryCoachTrainerId);
  const [chatSort, setChatSort] = useState<SortMode>("unread");
  const officialChatsStatus = useAppSelector(
    (state) => state.chat.officialChatsStatus,
  );

  useEffect(() => {
    if (officialChatsStatus === "idle") {
      dispatch(fetchOfficialChats());
    }
  }, [officialChatsStatus, dispatch]);

  useEffect(() => {
    let newChats = JSON.parse(JSON.stringify(officialTrainwellChats)) as Chat[];

    if (search) {
      // Convert special characters to normal characters
      const modifiedSearch = search
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase();

      newChats = newChats.filter((chat) =>
        chat.clientName
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
          .includes(modifiedSearch),
      );
    } else {
      if (chatSort === "unread") {
        newChats.sort(sortChatsByUnread);
      } else if (chatSort === "newest") {
        newChats.sort(sortChatsByNewest);
      } else if (chatSort === "unanswered") {
        newChats.sort((a, b) => {
          return sortChatsByUnanswered(a, b, trainerId!);
        });
      }
    }

    setDisplayChats(newChats);
  }, [search, chatSort, officialTrainwellChats, trainerId]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          p: 1,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <SearchField
          value={search}
          onChange={(newValue) => {
            setSearch(newValue);
          }}
          onClear={() => {
            setSearch("");
          }}
        />
        <FormControl
          size="small"
          sx={{
            ml: 2,
          }}
          fullWidth
        >
          <InputLabel id="chat-sort">Sort</InputLabel>
          <Select
            labelId="chat-sort"
            label="Sort"
            value={chatSort}
            onChange={(event) => {
              setChatSort(event.target.value as SortMode);
            }}
          >
            <MenuItem value={"unanswered" as SortMode}>Unanswered</MenuItem>
            <MenuItem value={"unread" as SortMode}>Unread</MenuItem>
            <MenuItem value={"newest" as SortMode}>Newest</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ flex: 1 }}>
        {officialChatsStatus === "loading" ? (
          <LoadingComponent message="Fetching chats" />
        ) : (
          <VirtualList chats={displayChats} />
        )}
      </Box>
    </Box>
  );
}

interface VirtualListProps {
  chats: Chat[];
}

function VirtualList({ chats }: VirtualListProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: chats.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 70,
    getItemKey: (index) => chats[index].id,
    overscan: 5,
    paddingEnd: 100,
  });
  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={parentRef}
      style={{
        height: "100%",
        overflow: "auto",
        contain: "strict",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map((virtualItem) => {
            const chatId = virtualItem.key as string;

            return (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
              >
                <ChatCell chatId={chatId} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
