import { Box, Dialog, DialogContent } from "@mui/material";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppSelector } from "src/hooks/stateHooks";
import { ClientEquipment } from "./client-column/ClientEquipment";

interface Props {
  open: boolean;
  onClose: () => void;
}

export function ClientEquipmentDialog({ open, onClose }: Props) {
  const client = useAppSelector((state) => state.client.client);

  if (!client) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitleWithClose onClose={onClose}>Equipment</DialogTitleWithClose>
      <DialogContent sx={{ pb: 16 }}>
        <Box sx={{ mt: 1 }} />
        <ClientEquipment userId={client.user_id} />
      </DialogContent>
    </Dialog>
  );
}
