import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Container } from "@mui/material";
import { Colander } from "@trainwell/colander";
import ControlBar from "src/components/misc/ControlBar";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export default function CalendarPage() {
  const trainer = useAppSelector(selectPrimaryTrainer);

  if (!trainer) {
    return null;
  }

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Container maxWidth={"md"} sx={{ p: 3 }}>
        <Colander
          coach={trainer}
          userId={trainer.user_id}
          variant={"card"}
          api={api}
        />
      </Container>
    </Box>
  );
}
