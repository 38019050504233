import { Box, Typography } from "@mui/material";
import { Outlet, useLocation } from "@tanstack/react-router";
import { Suspense } from "react";
import ActionItemHelper from "src/components/misc/ActionItemHelper";
import AppHooks from "src/components/misc/AppHooks";
import AuthHelper from "src/components/misc/AuthHelper";
import { GlobalTemplateDialog } from "src/components/misc/GlobalTemplateDialog";
import PwaHelper from "src/components/misc/PwaHelper";
import { Chat } from "src/features/chat/Chat";
import { AppProviders } from "./AppProviders";

export function Root() {
  const isDevTrainer = window.location.hostname === "devtrainer.trainwell.net";
  const location = useLocation();

  return (
    <Suspense fallback={null}>
      <AppProviders>
        {isDevTrainer && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              border: 4,
              borderColor: (theme) => theme.palette.error.main,
              zIndex: 9999,
              pointerEvents: "none",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                bottom: 4,
                right: 4,
                fontWeight: "bold",
              }}
              color="error"
            >
              Dev mode
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
          }}
        >
          {location.pathname !== "/admin/api-docs-nodejs" &&
            location.pathname !== "/admin/api-docs-python" && <Chat />}
          <Box sx={{ flex: 1, maxWidth: "100%" }}>
            <Outlet />
          </Box>
        </Box>
        <GlobalTemplateDialog />
        <AuthHelper />
        <PwaHelper />
        <AppHooks />
        <ActionItemHelper />
      </AppProviders>
    </Suspense>
  );
}
