import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type { CoachMetricTemplateSubmodule } from "@trainwell/features/legacy";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import type Metric from "src/interfaces/Metric";
import { queryMetric } from "src/lib/metrics";
import { round } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ClientBucketsPopover } from "./ClientBucketsPopover";
import Distribution from "./hovers/Distribution";
import { LineChartMetric } from "./hovers/LineChartMetric";
import List from "./hovers/List";
import Ranking from "./hovers/Ranking";

interface Props {
  metricSnapshot: any;
  coachMetrics: Metric[];
  submodule: CoachMetricTemplateSubmodule;
  companyMetrics: any;
  allCompanyMetrics: Metric[];
  window: number;
  sortedSubmoduleMetrics: any;
  trainerName: string;
  admin: boolean;
}

export default function MetricSubmodule({
  metricSnapshot,
  coachMetrics,
  submodule,
  companyMetrics,
  allCompanyMetrics,
  window,
  sortedSubmoduleMetrics,
  trainerName,
  admin,
}: Props) {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [clientBucketsAnchorEl, setClientBucketsAnchorEl] =
    useState<HTMLElement | null>(null);

  let metric = queryMetric(metricSnapshot, submodule.metric);
  let companyMetric = queryMetric(companyMetrics, submodule.metric);

  if (submodule.per_day) {
    metric /= window;
    companyMetric /= window;
  }

  metric = round(metric, submodule.precision);
  companyMetric = round(companyMetric, submodule.precision);

  if (submodule.is_percent) {
    metric += "%";
    companyMetric += "%";
  }

  return (
    <Card
      variant="outlined"
      sx={{
        p: 1,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {submodule.metric_title}
        </Typography>
        <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
          {submodule.explainer_text && (
            <Tooltip
              disableInteractive
              placement="top"
              title={submodule.explainer_text}
            >
              <IconButton size="small">
                <HelpOutlineRoundedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {admin && (
            <Tooltip
              placement="top"
              disableInteractive
              title="Send a summary of this metric to your slack"
            >
              <IconButton
                onClick={() => {
                  api.coachMetrics.sendMetricSummary(
                    trainer!.trainer_id,
                    submodule.metric,
                  );
                }}
                size="small"
              >
                <SendRoundedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {admin && submodule.client_buckets && (
            <Tooltip placement="top" disableInteractive title="Client buckets">
              <IconButton
                onClick={(event) => {
                  setClientBucketsAnchorEl(event.currentTarget);
                }}
                size="small"
              >
                <BarChartRoundedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Box>
      <Stack
        direction={"row"}
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
        sx={{ width: "100%", mb: 0.5 }}
        justifyContent={"center"}
      >
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>{metric}</Typography>
          <Typography variant="overline">{trainerName}</Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography>{companyMetric}</Typography>
          <Typography variant="overline">trainwell</Typography>
        </Box>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        {sortedSubmoduleMetrics && (
          <Ranking
            sortedSubmoduleMetrics={sortedSubmoduleMetrics}
            metrics={metricSnapshot}
            submodule={submodule}
          />
        )}
        {submodule.distribution_metric && (
          <Distribution
            metrics={metricSnapshot}
            companyMetrics={companyMetrics}
            submodule={submodule}
            window={window}
            trainerName={trainerName}
          />
        )}
        {submodule.list_metric && (
          <List metrics={metricSnapshot} submodule={submodule} />
        )}
        <LineChartMetric
          allMetrics={coachMetrics}
          allCompanyMetrics={allCompanyMetrics}
          submodule={submodule}
          window={window}
          trainerName={trainerName}
        />
      </Stack>
      <ClientBucketsPopover
        anchorEl={clientBucketsAnchorEl}
        onClose={() => {
          setClientBucketsAnchorEl(null);
        }}
        metricSnapshot={metricSnapshot}
        submodule={submodule}
      />
    </Card>
  );
}
