import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import { Box, Typography, type SxProps, type Theme } from "@mui/material";
import { useAppSelector } from "src/hooks/stateHooks";
import { useGetLogQuery } from "src/slices/api/logApi";

interface Props {
  sx?: SxProps<Theme>;
  logId: string;
}

export function WorkoutLogClientNotes({ sx = [], logId }: Props) {
  const { data: log } = useGetLogQuery(logId ?? "", {
    skip: !logId,
  });
  const client = useAppSelector((state) => state.client.client!);

  if (!log) {
    return null;
  }

  const clientNotes =
    log.log_summary.user_feedback2?.notes_user ||
    log.log_summary.user_feedback?.notes_user;

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 0.5,
        }}
      >
        {client.headshot_url && (
          <img
            style={{
              borderRadius: "8px",
              marginRight: "8px",
            }}
            src={client.headshot_url}
            width={16}
            height={16}
            alt="client headshot"
          />
        )}
        <Typography variant="overline">{client.full_name}</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.text.primary,
          borderRadius: `8px 8px 8px 2px`,
          px: 1,
          py: 0.5,
          whiteSpace: "pre-line",
          wordWrap: "break-word",
          border: 2,
          borderColor: "divider",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;",
          display: "flex",
          alignItems: "center",
        }}
      >
        <EmojiPeopleRoundedIcon fontSize="inherit" sx={{ mr: 1 }} />
        <Typography>{clientNotes}</Typography>
      </Box>
    </Box>
  );
}
