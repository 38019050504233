import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import { Box, Stack, Typography } from "@mui/material";

interface Props {
  combineBottom: boolean;
}

export function MealLogSentimentMissedMessage({ combineBottom }: Props) {
  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `10px 10px 10px ${!combineBottom ? "0px" : "10px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        p: 1,
        display: "flex",
        alignItems: "center",
        minWidth: "200px",
        // border: 1,
        // borderColor: "divider",
      }}
    >
      <Stack
        direction={"column"}
        spacing={1}
        flex={1}
        alignItems={"flex-start"}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RestaurantRoundedIcon
            sx={{
              color: (theme) => theme.palette.error.main,
            }}
          />
          <Typography
            variant="h6"
            sx={{
              color: (theme) => theme.palette.text.primary,
              ml: 1,
            }}
          >
            Meal sentiment missed
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}
