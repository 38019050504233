import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { selectTrainerNames } from "src/slices/trainersSlice";

interface Props {
  userId: string;
  freeFloating?: boolean;
}

export default function SwapPanel({ userId, freeFloating = false }: Props) {
  const trainerNames = useAppSelector(selectTrainerNames);
  const clientSwitchLog = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.coach_switch_log
        : undefined,
    shallowEqual,
  );

  const switchLog = [...(clientSwitchLog ?? [])].reverse();

  return (
    <Accordion
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      disabled={switchLog.length === 0}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🔀 Trainer swaps</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Stack direction="column">
          {switchLog.map((log, i) => (
            <Box
              key={i}
              sx={{
                py: 1,
                px: 2,
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography>
                  {getTrainerName(log.trainer_id_old, trainerNames)} -&gt;{" "}
                  <b>{getTrainerName(log.trainer_id_new, trainerNames)}</b>
                </Typography>
                {log.reason && <Typography>Reason: {log.reason}</Typography>}
                <Typography variant="overline">
                  Automatic: {log.automatic ? "true" : "false"}
                </Typography>
                <Typography variant="overline">
                  {format(new Date(log.date), "MMMM do, yyyy h:mm aaa")}
                </Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
