import { useVirtualizer, type VirtualItem } from "@tanstack/react-virtual";
import type { Client } from "@trainwell/features/legacy";
import { useEffect, useRef } from "react";
import ClientCell from "./ClientCell";

interface Props {
  clients: Client[];
  tenureType: "with_coach" | "with_trainwell";
}

export function ClientList({ clients, tenureType }: Props) {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: clients.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 75,
    getItemKey: (index) => clients[index].user_id,
    overscan: 5,
    initialOffset: Number(sessionStorage.getItem("home_scroll_offset") ?? 0),
    initialMeasurementsCache:
      (JSON.parse(
        sessionStorage.getItem("home_measurements_cache") ?? "null",
      ) as VirtualItem[] | null) ?? undefined,
  });
  const virtualClients = virtualizer.getVirtualItems();

  // Handle scroll restoration
  useEffect(() => {
    return () => {
      // Called when the component is unmounted
      const offset = (virtualizer.scrollOffset ?? 0).toString();
      const measurementsCache = JSON.stringify(virtualizer.measurementsCache);

      sessionStorage.setItem("home_scroll_offset", offset);
      sessionStorage.setItem("home_measurements_cache", measurementsCache);
    };
  }, []);

  return (
    <div
      ref={parentRef}
      style={{
        height: "100%",
        overflow: "auto",
        contain: "strict",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualClients[0]?.start ?? 0}px)`,
          }}
        >
          {virtualClients.map((virtualRow) => {
            const client = clients[virtualRow.index];

            return (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                <ClientCell userId={client.user_id} tenureType={tenureType} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
