import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TanStackRouterDevtools } from "@tanstack/react-router-devtools";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { type ReactNode } from "react";
import { CheckForDashLock } from "src/components/misc/CheckForDashLock";
import { CheckForTrainerSurvey } from "src/components/misc/CheckForTrainerSurvey";
import ErrorComponent from "src/components/miscPages/ErrorComponent";
import { ThemeProvider } from "src/theme/ThemeProvider";

interface Props {
  children: ReactNode;
}

export function AppProviders({ children }: Props) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider>
          <Sentry.ErrorBoundary
            // eslint-disable-next-line @typescript-eslint/unbound-method
            fallback={({ error, resetError, eventId }) => (
              <ErrorComponent
                message={(error as any)?.message}
                onResetError={resetError}
                eventId={eventId}
              />
            )}
          >
            <ConfirmProvider
              defaultOptions={{
                cancellationButtonProps: {
                  variant: "text",
                },
              }}
            >
              <CheckForDashLock>
                <CheckForTrainerSurvey>
                  <SnackbarProvider
                    autoHideDuration={5000}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {children}
                  </SnackbarProvider>
                </CheckForTrainerSurvey>
              </CheckForDashLock>
            </ConfirmProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </LocalizationProvider>
      <ReactQueryDevtools buttonPosition="top-right" />
      <TanStackRouterDevtools position="bottom-right" />
    </>
  );
}
