import { Box, Popover, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import { formatInTimeZone, toZonedTime } from "date-fns-tz";
import React, { useState } from "react";
import { getDifferenceBetweenTimezones } from "src/lib/date";

interface Props {
  text: string;
  date: Date;
  otherTimezone: string;
  otherTimezoneLabel: string;
}

export default function TimezoneTypography({
  text,
  date,
  otherTimezone,
  otherTimezoneLabel,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const utcDate = toZonedTime(date, "Etc/UTC");

  const tzDif = Math.round(
    getDifferenceBetweenTimezones(
      new Date(),
      otherTimezone,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ) /
      1000 /
      60 /
      60,
  );

  return (
    <>
      <Typography
        sx={{
          display: "inline",
          textDecoration: "underline",
          fontSize: "inherit",
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        component={"span"}
      >
        {text}
      </Typography>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 1, width: "475px" }}>
          <Typography sx={{ mb: 1 }}>Time conversion</Typography>
          <Grid container spacing={1} sx={{ mb: 0.5 }}>
            <Grid size={7}>
              <Typography sx={{ fontWeight: "bold" }}>Your computer</Typography>
            </Grid>
            <Grid size={5}>
              <Typography>{format(date, "MMM d, yyyy h:mm a")}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 0.5 }}>
            <Grid size={7}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {otherTimezoneLabel} •{" "}
                {tzDif === 0
                  ? "Same timezone"
                  : `${otherTimezone} (
                ${tzDif > 0 ? "+" : tzDif < 0 ? "-" : ""}
                ${tzDif}h)`}
              </Typography>
            </Grid>
            <Grid size={5}>
              <Typography>
                {formatInTimeZone(date, otherTimezone, "MMM d, yyyy h:mm a")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid size={7}>
              <Typography sx={{ fontWeight: "bold" }}>UTC</Typography>
            </Grid>
            <Grid size={5}>
              <Typography>{format(utcDate, "MMM d, yyyy h:mm a")}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
