import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CakeRoundedIcon from "@mui/icons-material/CakeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import PanToolRoundedIcon from "@mui/icons-material/PanToolRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import VolunteerActivismRoundedIcon from "@mui/icons-material/VolunteerActivismRounded";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import type {
  CommunicationStyleTag,
  TagCommunicationStyle,
  TagExperience,
  TagIdentity,
  TagSpecialty,
} from "@trainwell/features/legacy";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState, type JSX } from "react";
import { TypographyCopyable } from "src/components/common/TypographyCopyable";
import ControlBar from "src/components/misc/ControlBar";
import CustomLink from "src/components/misc/CustomLink";
import Label from "src/components/misc/Label";
import SmartEditChips from "src/components/misc/SmartEditChips";
import SmartEditList from "src/components/misc/SmartEditList";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getDateFromDatabase } from "src/lib/date";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import { TrainerVoicemailGreetingButton } from "./TrainerVoicemailGreetingButton";

const chipData: {
  key: TagCommunicationStyle;
  leftText: string;
  leftIcon: JSX.Element;
  rightText: string;
  rightIcon: JSX.Element;
}[] = [
  {
    key: "fun_to_focused",
    leftText: "Fun",
    leftIcon: <CelebrationRoundedIcon />,
    rightText: "Focused",
    rightIcon: <MenuBookRoundedIcon />,
  },
  {
    key: "empathetic_to_tough_love",
    leftText: "Empathetic",
    leftIcon: <VolunteerActivismRoundedIcon />,
    rightText: "Tough love",
    rightIcon: <PanToolRoundedIcon />,
  },
  {
    key: "emotional_to_analytical",
    leftText: "Emotional",
    leftIcon: <FavoriteRoundedIcon />,
    rightText: "Analytical",
    rightIcon: <SchoolRoundedIcon />,
  },
  {
    key: "low_touch_to_high_touch",
    leftText: "Low touch",
    leftIcon: <PersonRoundedIcon />,
    rightText: "High touch",
    rightIcon: <PeopleRoundedIcon />,
  },
];

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

const specialtiesData: PartialRecord<TagSpecialty, string> = {
  weight_loss: "Weight loss",
  eat_healthy: "Eating healthier",
  mobility_flexibility: "Mobility & flexibility",
  mental_wellness: "Mental wellness",
  reduce_fatigue: "Reducing fatigue",
  quality_of_life: "Quality of life",
  strength: "Strength training",
  sports: "Sport performance",
  general_health: "General health",
};

const experienceData: PartialRecord<TagExperience, string> = {
  older_clients: "Older clients",
  pre_postnatal: "Pre/postnatal mothers",
  obesity: "Obesity",
  adhd: "ADHD",
  anxiety: "Anxiety",
  depression: "Depression",
  body_dysmorphia: "Body dysmorphia",
  auto_immunity: "Auto-immunity",
};

const identityData: PartialRecord<TagIdentity, string> = {
  male: "Male",
  female: "Female",
  non_binary: "Non-binary",
  language_spanish: "Spanish speaking",
};

export default function ProfilePage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [sentErrors, setSentErrors] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (!trainer) {
    return null;
  }

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Container maxWidth="md" sx={{ p: 3 }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Profile
        </Typography>
        <Card variant="outlined" sx={{ p: 3, mb: 4 }}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
            <Grid>
              <img
                style={{
                  borderRadius: "25px",
                }}
                src={trainer.headshot_url || "/assets/profile.png"}
                width={50}
                height={50}
                alt="trainer headshot"
              />
            </Grid>
            <Grid>
              <Typography variant="h2">{trainer.full_name}</Typography>
              <TypographyCopyable
                value={trainer.trainer_id}
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Trainer id: {trainer.trainer_id}
              </TypographyCopyable>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <CakeRoundedIcon fontSize="inherit" sx={{ mr: 1 }} />
            <Typography>
              Birthday:{" "}
              {trainer.birthday
                ? format(getDateFromDatabase(trainer.birthday), "MMMM do, yyyy")
                : "Not found"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <MailOutlineRoundedIcon fontSize="inherit" sx={{ mr: 1 }} />
            <Typography>Email: {trainer.email}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <LocalPhoneRoundedIcon fontSize="inherit" sx={{ mr: 1 }} />
            <Typography>Phone: {trainer.phone_number}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <CalendarMonthRoundedIcon fontSize="inherit" sx={{ mr: 1 }} />
            <Typography>
              Employment started:{" "}
              {trainer.employment_start_date
                ? format(
                    getDateFromDatabase(trainer.employment_start_date),
                    "MMMM do, yyyy",
                  )
                : "Not found"}
            </Typography>
          </Box>
          <Button
            disabled={sentErrors}
            onClick={async () => {
              setSentErrors(true);

              await api.trainers.sendCalendarErrors(trainer.trainer_id);

              enqueueSnackbar("Sent summary to slack", {
                variant: "success",
              });
            }}
          >
            Send calendar error summary to Slack
          </Button>
        </Card>
        <Card variant="outlined" sx={{ p: 3, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 1.5 }}>
            Bio
          </Typography>
          <Label label="About" content={trainer.short_about} disableGutter />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid size={6}>
              <SmartEditList
                label="Experience"
                values={trainer.experience}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      experience: value,
                    }),
                  );
                }}
                disabled
              />
            </Grid>
            <Grid size={6}>
              <SmartEditList
                label="Specialties"
                values={trainer.specialties}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      specialties: value,
                    }),
                  );
                }}
                disabled
              />
            </Grid>
            <Grid size={6}>
              <SmartEditList
                label="Loves"
                values={trainer.loves}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      loves: value,
                    }),
                  );
                }}
                disabled
              />
            </Grid>
          </Grid>
        </Card>
        <Card variant="outlined" sx={{ p: 3, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 1.5 }}>
            Voicemail greeting
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Your clients will hear this when they call trainwell&apos;s phone
            number (the same phone number used for SMS). Only your clients will
            hear this.
            <br />
            <br />
            Voicemails are transcribed and sent to you as a chat message in the
            dash.
            <br />
            <br />
            Introduce yourself, say hi, and let them know you&apos;ll respond to
            them with a message.
          </Typography>
          <TrainerVoicemailGreetingButton />
        </Card>
        <Card variant="outlined" sx={{ p: 3, mb: 4 }}>
          <Typography variant="h2">Referral links</Typography>
          <Typography sx={{ my: 1.5 }}>
            Get $100 bonus when someone signs up using one of your links below
          </Typography>
          <Typography sx={{ mb: 1 }}>
            <span style={{ fontWeight: "bold" }}>
              Client can choose any trainer:{" "}
            </span>
            {trainer.referral_url_any_coach ? (
              <CustomLink
                text={trainer.referral_url_any_coach}
                href={trainer.referral_url_any_coach}
              />
            ) : (
              "Not setup yet"
            )}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            <span style={{ fontWeight: "bold" }}>
              Client will get you as a trainer:{" "}
            </span>
            {trainer.referral_url_specific_coach ? (
              <CustomLink
                text={trainer.referral_url_specific_coach}
                href={trainer.referral_url_specific_coach}
              />
            ) : (
              "Not setup yet"
            )}
          </Typography>
        </Card>
        <Card variant="outlined" sx={{ p: 3, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 1.5 }}>
            Tags
          </Typography>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid size={4}>
              <SmartEditChips
                label="Your specialties"
                values={trainer.onboarding_tags?.specialties}
                possibleValues={specialtiesData}
                onSave={(values) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      // @ts-expect-error
                      "onboarding_tags.specialties": values,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid size={4}>
              <SmartEditChips
                label="Experience working with..."
                values={trainer.onboarding_tags?.experience}
                possibleValues={experienceData}
                onSave={(values) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      // @ts-expect-error
                      "onboarding_tags.experience": values,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid size={4}>
              <SmartEditChips
                label="Your identity"
                values={trainer.onboarding_tags?.identity}
                possibleValues={identityData}
                onSave={(values) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      // @ts-expect-error
                      "onboarding_tags.identity": values,
                    }),
                  );
                }}
              />
            </Grid>
          </Grid>
          {chipData.map((tag, index) => (
            <Box key={index} sx={{ my: 2 }}>
              <Grid container>
                <Grid
                  size={{
                    xs: 6,
                    sm: 3,
                  }}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Stack spacing={2} direction="row" alignItems="center">
                    {tag.leftIcon}
                    <Typography>{tag.leftText}</Typography>
                  </Stack>
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                  order={{ xs: 3, sm: 2 }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      fullWidth
                      hiddenLabel
                      margin="none"
                    >
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="row-radio-buttons-group"
                        value={
                          (trainer.onboarding_tags?.communication_style?.find(
                            (t) => t.key === tag.key,
                          )?.value ?? 0.5) * 4
                        }
                        onChange={(event) => {
                          const value = Number(
                            (event.target as HTMLInputElement).value,
                          );
                          const oldVals = JSON.parse(
                            JSON.stringify(
                              trainer.onboarding_tags?.communication_style ??
                                [],
                            ),
                          ) as CommunicationStyleTag[];

                          const index = oldVals.findIndex(
                            (item) => item.key === tag.key,
                          );

                          if (index === -1) {
                            oldVals.push({ key: tag.key, value: value / 4 });
                          } else {
                            oldVals[index].value = value / 4;
                          }

                          dispatch(
                            updateTrainer({
                              trainer_id: trainer.trainer_id,
                              // @ts-expect-error
                              "onboarding_tags.communication_style": oldVals,
                            }),
                          );
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label=""
                          sx={{ m: 0 }}
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label=""
                          sx={{ m: 0 }}
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label=""
                          sx={{ m: 0 }}
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio />}
                          label=""
                          sx={{ m: 0 }}
                        />
                        <FormControlLabel
                          value={4}
                          control={<Radio />}
                          label=""
                          sx={{ m: 0 }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid
                  size={{
                    xs: 6,
                    sm: 3,
                  }}
                  order={{ xs: 2, sm: 3 }}
                  sx={{
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography sx={{ textAlign: "right" }}>
                      {tag.rightText}
                    </Typography>
                    {tag.rightIcon}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Card>
      </Container>
    </Box>
  );
}
