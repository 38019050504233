import { Box, Dialog, DialogContent } from "@mui/material";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppSelector } from "src/hooks/stateHooks";
import { ClientNotes } from "./ClientNotes";

interface Props {
  open: boolean;
  onClose: () => void;
}

export function ClientNotesDialog({ open, onClose }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const savingNotes = useAppSelector((state) => state.client.notesSaving);

  if (!client) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!savingNotes) {
          onClose();
        }
      }}
    >
      <DialogTitleWithClose
        onClose={() => {
          if (!savingNotes) {
            onClose();
          }
        }}
      >
        Notes
      </DialogTitleWithClose>
      <DialogContent sx={{ pb: 16 }}>
        <Box sx={{ mt: 1 }} />
        <ClientNotes userId={client.user_id} />
      </DialogContent>
    </Dialog>
  );
}
