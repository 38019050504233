import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import {
  Button,
  IconButton,
  Stack,
  TextField,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useParams } from "@tanstack/react-router";
import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectExerciseById, setExerciseReply } from "src/slices/workoutSlice";

interface Props {
  exerciseId: string;
  sx?: SxProps<Theme>;
}

export function ExerciseTrainerFeedback({ exerciseId, sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const { logId } = useParams({ strict: false });
  const exercise = useAppSelector((state) =>
    selectExerciseById(state, exerciseId),
  );
  const replyMessage = useAppSelector(
    (state) =>
      state.workout.exerciseReplies.find(
        (e) =>
          e.exerciseId &&
          (e.exerciseId === exerciseId ||
            e.exerciseId === exercise?.swapped_from_exercise_id),
      )?.message,
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const isLog = logId !== undefined;

  if (!isLog) {
    return null;
  }

  if (replyMessage === undefined) {
    return (
      <Button
        size="small"
        variant="text"
        sx={{
          ...sx,
        }}
        onClick={() => {
          dispatch(setExerciseReply({ exerciseId, message: "" }));

          setTimeout(() => {
            inputRef.current?.focus();
          }, 50);
        }}
      >
        Reply
      </Button>
    );
  }

  return (
    <Stack spacing={1} sx={sx} direction={"row"} alignItems="center">
      <TextField
        value={replyMessage ?? ""}
        onChange={(event) => {
          dispatch(
            setExerciseReply({ exerciseId, message: event.target.value }),
          );
        }}
        label="Reply"
        size="small"
        multiline
        sx={{
          ...sx,
          width: "400px",
        }}
        inputRef={inputRef}
      />
      <IconButton
        size="small"
        onClick={() => {
          dispatch(setExerciseReply({ exerciseId, message: null }));
        }}
      >
        <ClearRoundedIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
}
