import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import { useAppSelector } from "src/hooks/stateHooks";
import type { PhaseDayDraggable } from "src/slices/phasesSlice";
import PhaseWorkoutCell from "./PhaseWorkoutCell";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

interface Props {
  dayIndex: number;
  day: PhaseDayDraggable;
  onDeleteWorkout: (workoutId: string) => void;
  onCopyWorkout: (workoutId: string, type: "linked" | "not_linked") => void;
  onDelete: () => void;
}

export default function PhaseDayDraggable({
  dayIndex,
  day,
  onDeleteWorkout,
  onCopyWorkout,
  onDelete,
}: Props) {
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const dragHandleRef = useRef<HTMLButtonElement>(null);
  const selectedWorkoutScheduleDayIndexes = useAppSelector((state) =>
    (state.client.client!.workout_times ?? [])
      .map((day, index) => (day.has_time ? index : null))
      .filter((day) => day !== null)
      .sort(),
  );

  const possibleDays = selectedWorkoutScheduleDayIndexes.map(
    (dayIndex) => days[dayIndex],
  );

  let dayLabel = possibleDays[dayIndex % possibleDays.length];

  if (possibleDays.length === 0) {
    dayLabel = "No workout schedule";
  } else if (dayIndex >= possibleDays.length) {
    dayLabel += ` (${Math.floor(dayIndex / possibleDays.length) + 1})`;
  }

  useEffect(() => {
    const element = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!element || !dragHandle) {
      return;
    }

    const data = {
      type: "phase_day",
      index: dayIndex,
    };

    return combine(
      draggable({
        element: dragHandle,
        getInitialData: () => data,
        onDragStart: () => {
          setDragging(true);
        },
        onDrop: () => {
          setDragging(false);
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        onDragEnter: ({ source }) => {
          if (source.data.type !== "phase_day") {
            setIsDraggedOver(true);
          }
        },
        canDrop({ source }) {
          return source.data.type === "phase_day";
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource =
            source.element === element || source.element === dragHandle;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            return;
          }

          const isItemBeforeSource = dayIndex === sourceIndex - 1;
          const isItemAfterSource = dayIndex === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
        onDrop() {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
      }),
    );
  }, [dayIndex]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.5 : 1,
          }}
        >
          <PhaseDay
            dayIndex={dayIndex}
            day={day}
            onDelete={onDelete}
            onDeleteWorkout={onDeleteWorkout}
            onCopyWorkout={onCopyWorkout}
            isOver={isDraggedOver}
            dragHandleRef={dragHandleRef}
          />
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(<DragPreview text={dayLabel} />, previewContainer)
        : null}
    </>
  );
}

const PhaseDay = memo(function PhaseDay({
  dayIndex,
  day,
  onDeleteWorkout,
  onCopyWorkout,
  onDelete,
  isOver,
  dragHandleRef,
}: Props & {
  isOver: boolean;
  dragHandleRef: React.RefObject<HTMLButtonElement | null>;
}) {
  const selectedWorkoutScheduleDayIndexes = useAppSelector((state) =>
    (state.client.client!.workout_times ?? [])
      .map((day, index) => (day.has_time ? index : null))
      .filter((day) => day !== null)
      .sort(),
  );

  const possibleDays = selectedWorkoutScheduleDayIndexes.map(
    (dayIndex) => days[dayIndex],
  );

  let dayLabel = possibleDays[dayIndex % possibleDays.length];

  if (possibleDays.length === 0) {
    dayLabel = "No workout schedule";
  } else if (dayIndex >= possibleDays.length) {
    dayLabel += ` (${Math.floor(dayIndex / possibleDays.length) + 1})`;
  }

  return (
    <Box sx={{ py: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          mb: 0.5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            ref={dragHandleRef}
            color="default"
            sx={{
              cursor: "grab",
            }}
          >
            <DragIndicatorRoundedIcon fontSize="inherit" />
          </IconButton>
          <Typography variant="body2">{dayLabel}</Typography>
        </Box>
        <Tooltip title={day.workouts.length > 0 ? "Day must be empty" : ""}>
          <span>
            <IconButton
              size="small"
              onClick={() => {
                onDelete();
              }}
              color="error"
              disabled={day.workouts.length > 0}
            >
              <RemoveCircleRoundedIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      {!day.workouts.length ? (
        <EmptyPhaseDay dayIndex={dayIndex} />
      ) : (
        <Card
          variant="outlined"
          sx={{
            flex: 1,
            borderStyle: day.workouts.length > 0 ? "solid" : "dashed",
            borderWidth: day.workouts.length > 0 ? "1px" : "2px",
            // borderRadius: 1,
            borderColor: (theme) =>
              isOver ? theme.palette.primary.main : undefined,
            backgroundColor: (theme) =>
              isOver
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.background.paper,
            transition: "background-color 0.2s ease, border-color 0.2s ease",
            width: "100%",
            overflow: "visible",
          }}
        >
          {day.workouts.map((workout, workoutTemplateIndex) => (
            <PhaseWorkoutCell
              key={workout.workout_id}
              workoutId={workout.workout_id}
              dayIndex={dayIndex}
              index={workoutTemplateIndex}
              isFirst={workoutTemplateIndex === 0}
              isLast={workoutTemplateIndex === day.workouts.length - 1}
              onDelete={() => {
                onDeleteWorkout(workout.workout_id);
              }}
              onCopy={(type) => {
                onCopyWorkout(workout.workout_id, type);
              }}
            />
          ))}
        </Card>
      )}
    </Box>
  );
});

interface EmptyPhaseDayProps {
  dayIndex: number;
}

function EmptyPhaseDay({ dayIndex }: EmptyPhaseDayProps) {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "empty_phase_day", dayIndex }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return (
          source.data.type === "workout" ||
          source.data.type === "workout_task" ||
          source.data.type === "workout_task_past" ||
          source.data.type === "phase_workout" ||
          source.data.type === "backup_workout"
        );
      },
    });
  }, [dayIndex]);

  return (
    <div ref={ref}>
      <Box
        sx={{
          p: 1,
          display: "flex",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: 1,
          borderColor: (theme) =>
            isDraggedOver ? theme.palette.primary.main : theme.palette.divider,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          transitionProperty: "border-color, background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
        }}
      >
        <AddRoundedIcon
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        >
          Drop workouts here
        </Typography>
      </Box>
    </div>
  );
}
