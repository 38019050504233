import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import InboxRoundedIcon from "@mui/icons-material/InboxRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { fetchTicketCategories, upsertTickets } from "src/slices/ticketsSlice";
import TicketCell from "./TicketCell";

interface Props {
  userId: string;
  freeFloating?: boolean;
}

export default function TicketsPanel({ userId, freeFloating = false }: Props) {
  const dispatch = useAppDispatch();
  const [ticketIds, setTicketIds] = useState<string[] | null | "loading">(null);
  const [expanded, setExpanded] = useState(false);
  const ticketCategoriesStatus = useAppSelector(
    (state) => state.tickets.categoriesStatus,
  );

  useEffect(() => {
    if (ticketCategoriesStatus === "idle" && expanded === true) {
      dispatch(fetchTicketCategories());
    }
  }, [ticketCategoriesStatus, dispatch, expanded]);

  useEffect(() => {
    if (ticketIds === null && expanded === true) {
      setTicketIds("loading");
      api.tickets.getMany({ userId }).then((fetchedTickets) => {
        fetchedTickets.sort((a, b) =>
          b.date_created > a.date_created ? 1 : -1,
        );

        dispatch(upsertTickets({ tickets: fetchedTickets }));
        setTicketIds(fetchedTickets.map((ticket) => ticket.id));
      });
    }
  }, [userId, expanded, ticketIds, dispatch]);

  return (
    <Accordion
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🛟 Tickets</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box>
          {ticketIds === null || ticketIds === "loading" ? (
            <LoadingComponent message="Gathering tickets" />
          ) : ticketIds.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                mb: 2,
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <InboxRoundedIcon />
              <Typography sx={{ textAlign: "center" }}>No tickets</Typography>
            </Box>
          ) : (
            <Stack spacing={1} direction="column">
              {ticketIds.map((ticketId) => {
                return <TicketCell ticketId={ticketId} key={ticketId} />;
              })}
            </Stack>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
