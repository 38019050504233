import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  differenceInCalendarDays,
  differenceInWeeks,
  format,
  isPast,
} from "date-fns";
import { useSnackbar } from "notistack";
import Label from "src/components/misc/Label";
import SmartEditDateField from "src/components/misc/SmartEditDateField";
import SmartEditSwitch from "src/components/misc/SmartEditSwitch";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getClientSummary } from "src/lib/client";
import { formatCentsToDollars } from "src/lib/misc";
import { updateClient } from "src/slices/clientSlice";

interface Props {
  userId: string;
  defaultExpanded?: boolean;
  freeFloating?: boolean;
}

export default function MorePanel({
  userId,
  defaultExpanded = false,
  freeFloating = false,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const client = useAppSelector((state) =>
    state.client.client?.user_id === userId ? state.client.client : undefined,
  );
  const dispatch = useAppDispatch();

  if (!client) {
    return null;
  }

  const sortedCalls = structuredClone(client.trainer_calls ?? []).sort();

  let lastVideoMessageDate: Date | undefined = undefined;

  if ("last_trainer_video_date" in client && client.last_trainer_video_date) {
    lastVideoMessageDate = new Date(client.last_trainer_video_date);
  }

  const daysSinceVideoMessage =
    lastVideoMessageDate === undefined
      ? null
      : differenceInCalendarDays(new Date(), lastVideoMessageDate);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🔨 More</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <>
          <Box sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid size={12}>
                <SmartEditSwitch
                  label="Get notified for HealthKit logs"
                  value={client.notify_trainer_healthkit ?? false}
                  onSave={(newValue) => {
                    dispatch(
                      updateClient({
                        user_id: client.user_id,
                        notify_trainer_healthkit: newValue,
                      }),
                    );
                  }}
                />
              </Grid>
              <Grid size={12}>
                <Button
                  size="small"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        `https://account.trainwell.net/schedule?user_id=${client.user_id}`,
                      )
                      .then(() => {
                        enqueueSnackbar("Copied to clipboard", {
                          variant: "success",
                        });
                      });
                  }}
                  startIcon={<ContentCopyRoundedIcon />}
                >
                  Copy client&apos;s calendar link
                </Button>
              </Grid>
              <Grid size={6}>
                <Label label="Phone number" content={client.phone_number} />
              </Grid>
              <Grid size={6}>
                <Label label="Email" content={client.email} />
              </Grid>
              {client.owns_smart_watch && client.watch && (
                <>
                  <Grid size={6}>
                    <Label
                      label="Watch wrist"
                      content={client.watch.current_wrist_location}
                    />
                  </Grid>
                  <Grid size={6}>
                    <Label label="Watch model" content={client.watch.model} />
                  </Grid>
                </>
              )}
              <Grid size={6}>
                <Label
                  label="Lifting Experience"
                  content={
                    client.experience
                      ? client.experience < 12
                        ? "About " + client.experience + " months"
                        : "About " +
                          Math.round(client.experience / 12) +
                          " years"
                      : "None"
                  }
                />
              </Grid>
              <Grid size={6}>
                <Label
                  label="Subscription Status"
                  content={getClientSummary(client.account).text}
                />
              </Grid>
              <Grid size={6}>
                <Label
                  label={
                    !client.goals_old ||
                    (client.goals_old && client.goals_old.length === 1)
                      ? "Goal"
                      : "Goals"
                  }
                  content={
                    client.goals_old
                      ? client.goals_old.map((g) => g.toLowerCase()).join(", ")
                      : client.goal_old
                  }
                />
              </Grid>
              <Grid size={6}>
                <SmartEditDateField
                  label="Pregnancy"
                  allowNull
                  value={
                    client.date_baby_due
                      ? new Date(client.date_baby_due)
                      : new Date()
                  }
                  onSave={(value) => {
                    dispatch(
                      updateClient({
                        user_id: client.user_id,
                        // @ts-expect-error
                        date_baby_due: value ? value.toISOString() : null,
                      }),
                    );
                  }}
                  content={
                    !client.date_baby_due
                      ? "n/a"
                      : `${
                          40 -
                          differenceInWeeks(
                            new Date(client.date_baby_due),
                            new Date(),
                          )
                        } weeks`
                  }
                />
              </Grid>
              <Grid size={6}>
                <Label
                  label="Last video msg"
                  content={
                    daysSinceVideoMessage === null
                      ? "none"
                      : `${daysSinceVideoMessage} ${
                          daysSinceVideoMessage === 1 ? " day" : " days"
                        }`
                  }
                />
              </Grid>
              <Grid size={6}>
                <Label
                  label="Most recent scheduled call"
                  content={
                    sortedCalls.length > 0
                      ? format(
                          new Date(sortedCalls[sortedCalls.length - 1]),
                          "MMMM do, yyyy",
                        )
                      : "Never"
                  }
                />
              </Grid>
              <Grid size={6}>
                <Label
                  label="Onboarded"
                  content={
                    client.account.dashboard.date_onboarded
                      ? format(
                          new Date(client.account.dashboard.date_onboarded),
                          "MMMM do, yyyy",
                        )
                      : "n/a"
                  }
                />
              </Grid>
              <Grid size={6}>
                <Label
                  label={
                    client.account.plan.date_trial_end &&
                    isPast(new Date(client.account.plan.date_trial_end))
                      ? "Trial ended"
                      : "Trial will end"
                  }
                  content={
                    !client.account.dashboard.date_onboarded
                      ? `${client.account.plan.original_trial_length} days after onboarding`
                      : !client.account.plan.date_trial_end
                        ? "error"
                        : format(
                            new Date(client.account.plan.date_trial_end),
                            "MMMM do, yyyy",
                          )
                  }
                />
              </Grid>
              <Grid size={6}>
                <Label
                  label="Plan price"
                  content={formatCentsToDollars(client.account.plan.price ?? 0)}
                />
              </Grid>
              <Grid size={6}>
                <Label
                  label="Onboarding call test"
                  content={
                    client.tests?.includes("pay_to_miss_onboarding_b")
                      ? "Pay to miss"
                      : "Not in test"
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </>
      </AccordionDetails>
    </Accordion>
  );
}
