import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AltRouteRoundedIcon from "@mui/icons-material/AltRouteRounded";
import EditOffRoundedIcon from "@mui/icons-material/EditOffRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  alpha,
  Box,
  Chip,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import type { Workout } from "@trainwell/features/legacy";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  selectClientsWorkoutValidity,
  updateClient,
} from "src/slices/clientSlice";
import { selectBackupWorkouts } from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../phase-column/WorkoutPreviewPopover";
import { BackupWorkoutCell } from "./BackupWorkoutCell";

interface Props {
  sx?: SxProps<Theme>;
}

export function BackupWorkouts({ sx = [] }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const backupWorkouts = useAppSelector(selectBackupWorkouts);
  const [open, setOpen] = useState(!client?.backup_workout_ids?.length);

  const backupWorkoutIds = client?.backup_workout_ids ?? [];

  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "backup_workouts" }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return (
          !open &&
          (source.data.type === "workout" ||
            source.data.type === "workout_extra" ||
            source.data.type === "workout_task" ||
            source.data.type === "workout_task_past")
        );
      },
    });
  }, [open]);

  return (
    <Box
      ref={ref}
      sx={{
        backgroundColor: (theme) =>
          isDraggedOver
            ? alpha(theme.palette.primary.main, 0.2)
            : theme.palette.background.default,
        transitionProperty: "background-color",
        transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
        transitionDuration: "350ms",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: open || !backupWorkoutIds.length ? 1 : 0,
        }}
      >
        <AltRouteRoundedIcon fontSize="inherit" />
        <Typography variant="h3" sx={{ ml: 1 }}>
          Backups
        </Typography>
        {backupWorkoutIds.length !== 0 && (
          <IconButton
            onClick={() => {
              setOpen(!open);
            }}
            sx={{
              ml: 1,
            }}
          >
            {open ? (
              <EditOffRoundedIcon fontSize="small" />
            ) : (
              <EditRoundedIcon fontSize="small" />
            )}
          </IconButton>
        )}
      </Box>
      {!backupWorkoutIds.length && <EmptyDroppable />}
      {open && backupWorkoutIds.length !== 0 && (
        <Stack direction={"column"}>
          {backupWorkoutIds.map((workoutId, index) => (
            <BackupWorkoutCell
              key={workoutId}
              workoutId={workoutId}
              index={index}
            />
          ))}
        </Stack>
      )}
      {!open && backupWorkoutIds.length > 0 && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {backupWorkouts?.map(
            (w, index) =>
              w && (
                <BackupWorkoutLink
                  key={w.workout_id}
                  workout={w}
                  index={index}
                />
              ),
          )}
        </Box>
      )}
    </Box>
  );
}

function BackupWorkoutLink({
  workout,
  index,
}: {
  workout: Workout;
  index: number;
}) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const workoutValidity = useAppSelector((state) =>
    selectClientsWorkoutValidity(state, workout.workout_id ?? ""),
  );
  const hasError = workoutValidity?.status === "error";
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "backup_workout",
      workoutId: workout.workout_id,
      index: index,
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onDragStart: () => {
        setDragging(true);
      },
      onDrop: () => {
        setDragging(false);
      },
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
    });
  }, [index, workout.workout_id]);

  return (
    <>
      <Tooltip disableInteractive title={workoutValidity?.message}>
        <Link
          ref={ref}
          href={`/clients/${workout.user_id}/workouts/${workout.workout_id}`}
          onContextMenu={(event) => {
            event.preventDefault();

            setAnchorEl(event.currentTarget);
          }}
        >
          <Chip
            size="small"
            label={workout.name}
            onDelete={(event) => {
              if ("stopPropagation" in event && "preventDefault" in event) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                event.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                event.preventDefault();
              }

              dispatch(
                updateClient({
                  user_id: workout.user_id,
                  backup_workout_ids: client?.backup_workout_ids?.filter(
                    (id) => id !== workout.workout_id,
                  ),
                }),
              );
            }}
            sx={{
              opacity: dragging ? 0.5 : 1,
              backgroundColor: (theme) =>
                hasError ? theme.palette.errorSurface.main : undefined,
              color: (theme) =>
                hasError ? theme.palette.error.main : undefined,
              "& .MuiChip-label:hover": {
                textDecoration: "underline",
              },
            }}
          />
        </Link>
      </Tooltip>
      {previewContainer
        ? createPortal(<DragPreview text={workout.name} />, previewContainer)
        : null}
      {anchorEl && (
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          workoutId={workout.workout_id}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
    </>
  );
}

function EmptyDroppable() {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "empty_backup_workouts" }),
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
      canDrop({ source }) {
        return (
          source.data.type === "workout" ||
          source.data.type === "workout_extra" ||
          source.data.type === "workout_task" ||
          source.data.type === "workout_task_past"
        );
      },
    });
  }, []);

  return (
    <div ref={ref}>
      <Box
        sx={{
          p: 1,
          display: "flex",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: 1,
          borderColor: (theme) =>
            isDraggedOver ? theme.palette.primary.main : theme.palette.divider,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          // justifyContent: "center",
          // flexDirection: "column",
          alignItems: "center",
          transitionProperty: "border-color, background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
          width: "fit-content",
        }}
      >
        <AddRoundedIcon
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
            ml: 1,
          }}
        >
          Drop workouts here
        </Typography>
      </Box>
    </div>
  );
}
