import { useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts";

interface Props {
  speedMeans: number[];
}

export function RepVelocityChart({ speedMeans }: Props) {
  const theme = useTheme();

  const data = speedMeans.map((y, x) => ({
    x: x,
    y: y,
  }));

  return (
    <LineChart
      colors={[theme.palette.primary.main]}
      xAxis={[
        {
          dataKey: "x",
          valueFormatter: (value) => `Rep ${Math.round((value as number) + 1)}`,
          // min: Math.min(...dataPoints.map((d) => d.x)),
          // max: Math.max(...dataPoints.map((d) => d.x)),
        },
      ]}
      yAxis={[
        {
          label: "Velocity (m/s)",
          min: 0,
        },
      ]}
      series={[
        {
          dataKey: "y",
        },
      ]}
      dataset={data}
      height={300}
      width={300}
      slotProps={{
        legend: {
          hidden: true,
        },
      }}
      skipAnimation
    />
  );
}
