import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import {
  Box,
  Chip,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { differenceInCalendarDays } from "date-fns";
import { useState } from "react";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import SmartEditSwitch from "src/components/misc/SmartEditSwitch";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";

interface Props {
  sx?: SxProps<Theme>;
}

export function ClientCommunication({ sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const [settingsAnchorEl, setSettingsAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const clientIsCpt =
    client?.tests?.includes("call_missed_workout_clients_treatment_2") ||
    client?.tests?.includes("call_missed_workout_clients_treatment_2_fake");

  if (!client) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  const now = new Date();

  return (
    <Box sx={sx}>
      <Stack direction={"column"} spacing={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <QuestionAnswerRoundedIcon fontSize="inherit" />
            <Typography sx={{ ml: 1, fontWeight: "bold" }}>
              Communication
            </Typography>
          </Box>
          <IconButton
            size="small"
            onClick={(event) => {
              setSettingsAnchorEl(event.currentTarget);
            }}
            sx={{ ml: 2 }}
          >
            <SettingsRoundedIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Stack direction={"row"} spacing={4}>
          <Tooltip title="Days since their last message" disableInteractive>
            <Box>
              <Typography
                sx={{ fontWeight: "bold", fontSize: 24, lineHeight: 1 }}
              >
                {client.last_client_message_date
                  ? differenceInCalendarDays(
                      now,
                      client.last_client_message_date,
                    )
                  : "--"}
                <Typography
                  component={"span"}
                  variant="overline"
                  sx={{ display: "inline" }}
                >
                  d
                </Typography>
              </Typography>
              <Typography variant="overline">From client</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Days since your last message" disableInteractive>
            <Box>
              <Typography
                sx={{ fontWeight: "bold", fontSize: 24, lineHeight: 1 }}
              >
                {client.last_trainer_message_date
                  ? differenceInCalendarDays(
                      now,
                      client.last_trainer_message_date,
                    )
                  : "--"}
                <Typography
                  component={"span"}
                  variant="overline"
                  sx={{ display: "inline" }}
                >
                  d
                </Typography>
              </Typography>
              <Typography variant="overline">From you</Typography>
            </Box>
          </Tooltip>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          {client.send_missed_habit_messages && (
            <Chip size="small" label="Habits" />
          )}
          {client.settings.enable_workout_reminder_sms && (
            <Chip size="small" label="Workout SMS" />
          )}
        </Stack>
      </Stack>
      <Popover
        anchorEl={settingsAnchorEl}
        open={Boolean(settingsAnchorEl)}
        onClose={() => {
          setSettingsAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 300,
              maxHeight: 350,
              overflowY: "auto",
            },
          },
        }}
      >
        <Typography variant="h3" sx={{ mb: 2 }}>
          Communication settings
        </Typography>
        {client.communication_preferences && (
          <AutosaveTextField
            blurOnEnter
            defaultValue={client.communication_preferences}
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  communication_preferences: newValue,
                }),
              );
            }}
            label={"Communication preferences"}
            placeholder="Check in once a day"
            disableGutter
            sx={{ mt: 1 }}
          />
        )}
        <SmartEditSwitch
          label="Get missed habit messages"
          value={client.send_missed_habit_messages ?? false}
          subLabel="You will get a message each night if your client misses any habits. Also enables the Missed Habit Check In Mode."
          onSave={(newValue) => {
            dispatch(
              updateClient({
                user_id: client.user_id,
                send_missed_habit_messages: newValue,
              }),
            );
          }}
        />
        {clientIsCpt && (
          <>
            <SmartEditSwitch
              label="Enable morning workout reminder"
              value={!client.settings.disable_morning_workout_reminder}
              onSave={(newValue) => {
                dispatch(
                  updateClient({
                    user_id: client.user_id,
                    // @ts-expect-error
                    "settings.disable_morning_workout_reminder": !newValue,
                  }),
                );
              }}
            />
            <SmartEditSwitch
              label="Enable 10 minute workout reminder"
              value={!client.settings.disable_10_minute_workout_reminder}
              onSave={(newValue) => {
                dispatch(
                  updateClient({
                    user_id: client.user_id,
                    // @ts-expect-error
                    "settings.disable_10_minute_workout_reminder": !newValue,
                  }),
                );
              }}
            />
            <SmartEditSwitch
              label="Send workout reminders as SMS"
              value={client.settings.enable_workout_reminder_sms ?? false}
              subLabel="Workout reminders are sent in the chat. Enable this to also send as SMS."
              onSave={(newValue) => {
                dispatch(
                  updateClient({
                    user_id: client.user_id,
                    // @ts-expect-error
                    "settings.enable_workout_reminder_sms": newValue,
                  }),
                );
              }}
            />
          </>
        )}
      </Popover>
    </Box>
  );
}
