import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import CheckInDialog from "src/components/misc/CheckInDialog";
import { TrainerBannerDialog } from "src/components/misc/TrainerBannerDialog";
import { CallClientButton } from "src/features/client/CallClientButton";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { setMessage } from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { ChatMediaDialog } from "./ChatMediaDialog";
import { ChatSearchDialog } from "./ChatSearchDialog";

interface Props {
  userId: string;
}

export function ChatMoreButton({ userId }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [checkInDialogOpen, setCheckInDialogOpen] = useState(false);
  const [bannerDialogOpen, setBannerDialogOpen] = useState(false);
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const client = useAppSelector((state) => selectClientById(state, userId));
  const [loading, setLoading] = useState(false);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <IconButton
          size="small"
          onClick={(event) => {
            event.stopPropagation();

            setMoreAnchorEl(event.currentTarget);
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <MoreVertRoundedIcon fontSize="small" />
        </IconButton>
      )}
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={(event) => {
          // @ts-expect-error
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          event.stopPropagation();

          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();

            setSearchDialogOpen(true);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SearchRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();

            setMediaDialogOpen(true);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <InsertPhotoRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Media" />
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();

            setCheckInDialogOpen(true);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SendRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Check in" />
        </MenuItem>
        {client?.phone_number && (
          <CallClientButton
            clientPhoneNumber={client.phone_number}
            button={
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <ListItemIcon>
                  <CallRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Call" />
              </MenuItem>
            }
          />
        )}
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();

            setMoreAnchorEl(null);

            setBannerDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <CalendarMonthRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Set chat banner" />
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            setLoading(true);

            event.stopPropagation();

            setMoreAnchorEl(null);

            api.clients
              .getLlmTrainerMatch(userId)
              .then((response) => {
                setLoading(false);

                dispatch(
                  setMessage({
                    chatId: userId,
                    message: response.message,
                  }),
                );
              })
              .catch(() => {
                enqueueSnackbar("Failed to generate a trainer match", {
                  variant: "error",
                });

                setLoading(false);
              });
          }}
        >
          <ListItemIcon>
            <AutoAwesomeRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Generate trainer match" />
        </MenuItem>
      </Menu>
      <ChatMediaDialog
        userId={userId}
        open={mediaDialogOpen}
        onClose={() => {
          setMediaDialogOpen(false);
        }}
      />
      <CheckInDialog
        userId={userId}
        open={checkInDialogOpen}
        onClose={() => {
          setCheckInDialogOpen(false);
        }}
      />
      <TrainerBannerDialog
        open={bannerDialogOpen}
        onClose={() => {
          setBannerDialogOpen(false);
        }}
      />
      <ChatSearchDialog
        userId={userId}
        open={searchDialogOpen}
        onClose={() => {
          setSearchDialogOpen(false);
        }}
      />
    </div>
  );
}
