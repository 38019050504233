import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import { Avatar, Box, CardActionArea, Chip, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import WorkoutPreviewPopover from "src/features/client/phase-column/WorkoutPreviewPopover";
import { useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import { selectTemplateById } from "src/slices/templatesSlice";

type Props = PropsDraggable;

function TemplateSelectCell({ templateId }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const template = useAppSelector((state) =>
    selectTemplateById(state, templateId),
  );

  if (!template) {
    return <Typography>Loading</Typography>;
  }

  return (
    <>
      <CardActionArea
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          setAnchorEl(event.currentTarget);
        }}
      >
        <Box
          sx={{
            p: 1,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.blue.main,
                width: 15,
                height: 15,
                borderRadius: "4px",
              }}
            >
              <FitnessCenterRoundedIcon
                sx={{
                  fontSize: 12,
                }}
              />
            </Avatar>
            <Typography sx={{ ml: 0.5 }}>{template.name}</Typography>
          </Box>
          <Typography
            variant="overline"
            sx={{ pb: (template.tags ?? []).length > 0 ? 0.5 : 0 }}
          >
            Updated{" "}
            {formatDistanceToNow(
              new Date(
                template.metadata.dates_updated[
                  template.metadata.dates_updated.length - 1
                ] ?? template.metadata.date_created,
              ),
            )}{" "}
            ago
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0,
              m: 0,
            }}
            component="ul"
          >
            {(template.tags ?? []).map((tag, i) => {
              const tagDetails = getSmartTagDetails(tag);
              return (
                <Chip
                  key={i}
                  label={tagDetails.label}
                  icon={tagDetails.icon}
                  sx={{ mb: 0.25, mr: 0.25, fontSize: 12 }}
                  size="small"
                />
              );
            })}
          </Box>
        </Box>
      </CardActionArea>
      <WorkoutPreviewPopover
        anchorEl={anchorEl}
        templateId={templateId}
        onClose={() => {
          setAnchorEl(null);
        }}
      />
    </>
  );
}

interface PropsDraggable {
  templateId: string;
}

function TemplateSelectCellDraggable(props: PropsDraggable) {
  const ref = useRef(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const template = useAppSelector((state) =>
    selectTemplateById(state, props.templateId),
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "template",
      templateId: props.templateId,
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
    });
  }, [props.templateId]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <TemplateSelectCell {...props} />
        </div>
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview text={template?.name ?? "Template"} />,
            previewContainer,
          )
        : null}
    </>
  );
}

export default TemplateSelectCellDraggable;
