import { useNavigate, useParams } from "@tanstack/react-router";
import type { ActionItem } from "@trainwell/features/legacy";
import { useAppDispatch } from "src/hooks/stateHooks";
import { getActionItemDetails } from "src/lib/actionItem";
import { dismissActionItem } from "src/slices/actionItemSlice";
import { openChat } from "src/slices/chatSlice";

export default function useActionItem() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userId, logId } = useParams({ strict: false });

  async function handleActionItemClick(actionItem: ActionItem) {
    if (actionItem.url) {
      window.open(actionItem.url, "_blank");
    }

    const actionItemDetails = getActionItemDetails(actionItem);

    if (actionItemDetails.clearTypes?.includes("click")) {
      dispatch(
        dismissActionItem({
          actionItemId: actionItem.id,
          usedClearButton: false,
        }),
      );
    }

    if (actionItem.type === "workout_review") {
      if (logId !== actionItem.log_id) {
        navigate({
          to: "/clients/$userId/logs/$logId",
          params: {
            userId: actionItem.user_id,
            logId: actionItem.log_id,
          },
        });
      }
    } else {
      if (userId !== actionItem.user_id) {
        navigate({
          to: "/clients/$userId",
          params: {
            userId: actionItem.user_id,
          },
        });
      }
    }

    dispatch(openChat({ chatId: actionItem.user_id }));
  }

  async function handleActionItemClearButton(actionItem: ActionItem) {
    const actionItemDetails = getActionItemDetails(actionItem);

    if (actionItemDetails.clearTypes?.includes("clear_button")) {
      dispatch(
        dismissActionItem({
          actionItemId: actionItem.id,
          usedClearButton: true,
        }),
      );
    }
  }

  return {
    handleActionItemClick: handleActionItemClick,
    handleActionItemClearButton: handleActionItemClearButton,
  };
}
