import { Typography } from "@mui/material";

interface Props {
  label: string;
  content: string | undefined;
  disableGutter?: boolean;
}

export default function Label({
  label,
  content,
  disableGutter = false,
}: Props) {
  return (
    <>
      <Typography
        variant="h6"
        // sx={{ lineHeight: 1, color: (theme) => theme.palette.text.secondary }}
      >
        {label}
      </Typography>
      <Typography
        sx={{ mb: disableGutter ? 0 : 1, overflowWrap: "break-word" }}
      >
        {content}
      </Typography>
    </>
  );
}
