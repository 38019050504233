import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import HardwareRoundedIcon from "@mui/icons-material/HardwareRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useParams } from "@tanstack/react-router";
import type { WorkoutUpdate } from "@trainwell/features/workout-logs";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useUpdateWorkoutLogAlertMutation } from "src/slices/api/logApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import {
  acceptClientSwap,
  deleteSets,
  duplicateSets,
  updateSets,
} from "src/slices/workoutSlice";
import { ExerciseSwapPopover } from "./ExerciseSwapPopover";

interface Props {
  update: WorkoutUpdate;
}

export function WorkoutAlertChip({ update }: Props) {
  const { logId } = useParams({ strict: false });
  const dispatch = useAppDispatch();
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const [feedbackAnchorEl, setFeedbackAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [feedback, setFeedback] = useState("");
  const [updateWorkoutLogAlert] = useUpdateWorkoutLogAlertMutation();
  const [swapMenuInfo, setSwapMenuInfo] = useState<{
    exerciseId: string;
    mouseX: number;
    mouseY: number;
    actionId: string;
  } | null>(null);

  if (update.trigger.type === "exercise_client_notes") {
    return null;
  }

  return (
    <>
      <Box
        key={update._id}
        sx={{
          backgroundColor: (theme) =>
            update.date_addressed
              ? theme.palette.backgroundSecondary.main
              : alpha(theme.palette.error.main, 0.05),
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
          px: 1,
          py: 0.25,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: update.actions?.length ? 0.5 : 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ErrorRoundedIcon
              color={update.date_addressed ? "action" : "error"}
              fontSize="inherit"
            />
            <Box sx={{ ml: 1 }}>
              <Typography>{update.trigger.description}</Typography>
              {update.trigger.type === "exercise_swapped" &&
                update.trigger.should_replace_in_future === true && (
                  <Typography variant="overline">
                    🔄 Client wants the swap to be permanent
                  </Typography>
                )}
            </Box>
          </Box>
          <Stack direction={"row"} sx={{ ml: 1 }}>
            <Tooltip title="Share feedback" disableInteractive placement="top">
              <IconButton
                size="small"
                onClick={(event) => {
                  setFeedbackAnchorEl(event.currentTarget);
                }}
              >
                <ConstructionRoundedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            {!update.date_addressed && (
              <Tooltip
                title="No action needed"
                disableInteractive
                placement="top"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    updateWorkoutLogAlert({
                      workoutLogAlertId: update._id,
                      addressType: "dismissed",
                      logId: logId ?? "",
                      trainerId: trainerId,
                    });
                  }}
                >
                  <ClearRoundedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {update.actions?.map((action, index) => {
            return (
              <Tooltip
                key={index}
                title={action.title}
                disableInteractive
                placement="top"
              >
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(event) => {
                    if (action.type === "set_update") {
                      dispatch(
                        updateSets({
                          setIds: action.set_ids,
                          reps: action.update.reps ?? null,
                          time: action.update.time ?? null,
                          rest: action.update.rest ?? null,
                          weight: action.update.weight ?? null,
                        }),
                      );
                    } else if (action.type === "progression") {
                      dispatch(
                        updateSets({
                          setIds: action.set_ids,
                          reps: action.update.reps ?? null,
                          time: action.update.time ?? null,
                          rest: action.update.rest ?? null,
                          weight: action.update.weight ?? null,
                        }),
                      );
                    } else if (action.type === "regression") {
                      dispatch(
                        updateSets({
                          setIds: action.set_ids,
                          reps: action.update.reps ?? null,
                          time: action.update.time ?? null,
                          rest: action.update.rest ?? null,
                          weight: action.update.weight ?? null,
                        }),
                      );
                    } else if (action.type === "set_delete") {
                      dispatch(
                        deleteSets({
                          setIds: action.set_ids,
                        }),
                      );
                    } else if (action.type === "set_duplicate") {
                      dispatch(
                        duplicateSets({
                          setIds: action.set_ids,
                        }),
                      );
                    } else if (action.type === "exercise_client_swap") {
                      dispatch(
                        acceptClientSwap({
                          logId: update.log_id,
                          setId: update.trigger.triggered_sets[0].set_id,
                        }),
                      ).then(() => {
                        updateWorkoutLogAlert({
                          workoutLogAlertId: update._id,
                          acceptedActionId: action.id,
                          addressType: "action",
                          logId: logId ?? "",
                          trainerId: trainerId,
                        });
                      });
                    } else if (action.type === "exercise_generic_swap") {
                      setSwapMenuInfo(
                        swapMenuInfo === null
                          ? {
                              exerciseId: action.exercise_ids[0],
                              mouseX: event.clientX + 2,
                              mouseY: event.clientY - 6,
                              actionId: action.id,
                            }
                          : null,
                      );
                    }

                    if (
                      action.type !== "exercise_generic_swap" &&
                      action.type !== "exercise_client_swap"
                    ) {
                      updateWorkoutLogAlert({
                        workoutLogAlertId: update._id,
                        acceptedActionId: action.id,
                        addressType: "action",
                        logId: logId ?? "",
                        trainerId: trainerId,
                      });
                    }
                  }}
                  startIcon={
                    action.type === "exercise_favorite" ? (
                      <StarRoundedIcon />
                    ) : action.type === "exercise_blacklist" ? (
                      <BlockRoundedIcon />
                    ) : action.type === "chat_message" ? (
                      <SendRoundedIcon />
                    ) : action.type === "workout_note" ? (
                      <ChatBubbleRoundedIcon />
                    ) : action.type === "progression" ? (
                      <ArrowUpwardRoundedIcon />
                    ) : action.type === "regression" ? (
                      <ArrowDownwardRoundedIcon />
                    ) : action.type === "set_delete" ? (
                      <DeleteRoundedIcon />
                    ) : action.type === "set_duplicate" ? (
                      <ContentCopyRoundedIcon />
                    ) : action.type === "exercise_generic_swap" ? (
                      <SwapHorizRoundedIcon />
                    ) : action.type === "exercise_client_swap" ? (
                      <SwapHorizRoundedIcon />
                    ) : (
                      <HardwareRoundedIcon />
                    )
                  }
                  sx={{ mr: 0.5, mb: 0.5 }}
                >
                  {action.description}
                </Button>
              </Tooltip>
            );
          })}
        </Box>
      </Box>
      <Popover
        open={Boolean(feedbackAnchorEl)}
        anchorEl={feedbackAnchorEl}
        onClose={() => {
          setFeedbackAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 300,
            },
          },
        }}
      >
        <Typography variant="h3">Share feedback</Typography>
        <Typography variant="overline" sx={{ mb: 2 }}>
          Sent to the trainwell engineers to help improve Workout Alerts and
          Suggestions
        </Typography>
        <TextField
          value={feedback}
          onChange={(event) => {
            setFeedback(event.target.value);
          }}
          multiline
          rows={2}
          sx={{ mb: 2 }}
          fullWidth
        />
        <Button
          size="small"
          startIcon={<SendRoundedIcon />}
          disabled={!feedback}
          onClick={() => {
            updateWorkoutLogAlert({
              workoutLogAlertId: update._id,
              coachFeedback: feedback,
              logId: logId ?? "",
              trainerId: trainerId,
            });

            setFeedbackAnchorEl(null);
          }}
        >
          Share feedback
        </Button>
      </Popover>
      {swapMenuInfo && (
        <ExerciseSwapPopover
          position={
            swapMenuInfo
              ? { mouseX: swapMenuInfo.mouseX, mouseY: swapMenuInfo.mouseY }
              : null
          }
          exerciseId={swapMenuInfo?.exerciseId ?? ""}
          onClose={() => {
            setSwapMenuInfo(null);

            updateWorkoutLogAlert({
              workoutLogAlertId: update._id,
              acceptedActionId: swapMenuInfo?.actionId,
              addressType: "action",
              logId: logId ?? "",
              trainerId: trainerId,
            });
          }}
        />
      )}
    </>
  );
}
