import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import { EquipmentType } from "@trainwell/features/legacy";
import { type JSX } from "react";

interface TagDetails {
  label: string;
  icon?: JSX.Element;
}

export function getSmartTagDetails(tag: string): TagDetails {
  if (tag.startsWith("smart:")) {
    if (tag.startsWith("smart:equipment:")) {
      const equipment = tag.replace("smart:equipment:", "");

      if (equipment === "bodyweight") {
        return { label: "Bodyweight", icon: <FitnessCenterRoundedIcon /> };
      }

      const label =
        EquipmentType[
          tag.replace("smart:equipment:", "") as keyof typeof EquipmentType
        ];

      if (!label) {
        console.warn("Missing tag:", tag);
      }

      return { label: label, icon: <FitnessCenterRoundedIcon /> };
    } else if (tag.startsWith("smart:duration:")) {
      return {
        label: tag.replace("smart:duration:", "").replace("_min", "") + "m",
        icon: <TimerRoundedIcon />,
      };
    } else if (tag.startsWith("smart:days:")) {
      return {
        label: tag.replace("smart:days:", "") + "d",
        icon: <DateRangeRoundedIcon />,
      };
    }
  }

  return {
    label: tag,
  };
}
