import { Box } from "@mui/material";
import { useNavigate, useParams } from "@tanstack/react-router";
import { useHotkeys } from "react-hotkeys-hook";
import WorkoutBuilderParent from "./WorkoutBuilderParent";

interface Props {
  workoutId?: string;
  templateId?: string;
}

export default function WorkoutBuilderPage({ workoutId, templateId }: Props) {
  const navigate = useNavigate();
  const { userId } = useParams({ strict: false });

  function handleClose() {
    navigate({
      to: "/clients/$userId",
      params: {
        userId: userId!,
      },
    });
  }

  useHotkeys("esc", () => {
    console.log("Hotkey: esc");

    handleClose();
  });

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        overflowY: "hidden",
        height: "calc(100vh - 38px)",
      }}
    >
      <WorkoutBuilderParent workoutId={workoutId} templateId={templateId} />
    </Box>
  );
}
