import { useNavigate } from "@tanstack/react-router";
import { useHotkeys } from "react-hotkeys-hook";
import { useAppSelector } from "src/hooks/stateHooks";

interface Props {
  userIds: string[];
}

export function CheckInHotkeys({ userIds }: Props) {
  const navigate = useNavigate();
  const selectedChatId = useAppSelector((state) => state.chat.selectedChatId);

  useHotkeys("up, left", () => {
    if (userIds.length > 0) {
      const currentIndex = userIds.findIndex((id) => id === selectedChatId);
      const newIndex = currentIndex === -1 ? -1 : currentIndex - 1;
      const newChatId = userIds.at(newIndex);

      if (newChatId === undefined) {
        return;
      }

      navigate({ to: "/clients/$userId", params: { userId: newChatId } });
    }
  });

  useHotkeys("down, right", () => {
    if (userIds.length > 0) {
      const currentIndex = userIds.findIndex((id) => id === selectedChatId);
      const newIndex = currentIndex + 1;
      const newChatId = userIds.at(newIndex);

      if (newChatId === undefined) {
        return;
      }

      navigate({ to: "/clients/$userId", params: { userId: newChatId } });
    }
  });

  return null;
}
