import type {
  Workout,
  WorkoutCycle,
  WorkoutExercise,
  WorkoutSection,
  WorkoutSet,
} from "@trainwell/features/legacy";
import { denormalize, normalize, schema } from "normalizr";

const setSchema = new schema.Entity("sets", {}, { idAttribute: "set_id" });

const exerciseSchema = new schema.Entity(
  "exercises",
  {
    sets: [setSchema],
  },
  { idAttribute: "exercise_id" },
);

const cycleSchema = new schema.Entity(
  "cycles",
  {
    exercises: [exerciseSchema],
  },
  { idAttribute: "cycle_id" },
);

const sectionSchema = new schema.Entity(
  "sections",
  {
    cycles: [cycleSchema],
  },
  { idAttribute: "section_id" },
);

export const workoutSchema = { sections: [sectionSchema] };

export interface NormalizedWorkout extends Omit<Workout, "sections"> {
  sections: string[];
}

export interface NormalizedSection extends Omit<WorkoutSection, "cycles"> {
  cycles: string[];
}

export interface NormalizedCycle extends Omit<WorkoutCycle, "exercises"> {
  exercises: string[];
}

export interface NormalizedExercise extends Omit<WorkoutExercise, "sets"> {
  sets: string[];
}

export interface NormalizedWorkoutData {
  result: NormalizedWorkout;
  entities: {
    sections: Record<string, NormalizedSection>;
    cycles: Record<string, NormalizedCycle>;
    exercises: Record<string, NormalizedExercise>;
    sets: Record<string, WorkoutSet>;
  };
}

export function getExerciseIdsInSection(
  workout: NormalizedWorkoutData,
  sectionId: string,
) {
  const firstCycle = getFirstCycle(workout, sectionId);

  return firstCycle.exercises;
}

export function getNormalizedWorkout(workout: Workout) {
  const normalizedWorkout = normalize(
    workout,
    workoutSchema,
  ) as unknown as NormalizedWorkoutData;

  if (!normalizedWorkout.entities.sections) {
    normalizedWorkout.entities.sections = {};
  }

  if (!normalizedWorkout.entities.cycles) {
    normalizedWorkout.entities.cycles = {};
  }

  if (!normalizedWorkout.entities.exercises) {
    normalizedWorkout.entities.exercises = {};
  }

  if (!normalizedWorkout.entities.sets) {
    normalizedWorkout.entities.sets = {};
  }

  return normalizedWorkout as unknown as NormalizedWorkoutData;
}

export function handleDeleteExercise(
  workout: NormalizedWorkoutData,
  exerciseId: string,
) {
  const setIds = workout.entities.exercises[exerciseId].sets;

  for (const setId of setIds) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete workout.entities.sets[setId];
  }
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete workout.entities.exercises[exerciseId];

  for (const sectionId of workout.result.sections) {
    const index =
      workout.entities.cycles[
        workout.entities.sections[sectionId].cycles[0]
      ].exercises.indexOf(exerciseId);
    if (index !== -1) {
      for (const cycleId of workout.entities.sections[sectionId].cycles) {
        workout.entities.cycles[cycleId].exercises.splice(index, 1);
      }

      break;
    }
  }
}

export function handleDeleteSection(
  workout: NormalizedWorkoutData,
  sectionId: string,
) {
  for (const cycleId of workout.entities.sections[sectionId].cycles) {
    for (const exerciseId of workout.entities.cycles[cycleId].exercises) {
      handleDeleteExercise(workout, exerciseId);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete workout.entities.sections[sectionId];
  workout.result.sections = workout.result.sections.filter(
    (id) => id !== sectionId,
  );
}

export function getFullExercise(
  workout: NormalizedWorkoutData,
  exerciseId: string,
) {
  const normalExercise = workout.entities.exercises[exerciseId];
  const exercise = denormalize(
    normalExercise,
    exerciseSchema,
    workout.entities,
  );

  return exercise as WorkoutExercise;
}

export function getFullCycle(workout: NormalizedWorkoutData, cycleId: string) {
  const normalCycle = workout.entities.cycles[cycleId];
  const cycle = denormalize(normalCycle, cycleSchema, workout.entities);

  return cycle as WorkoutCycle;
}

export function getFullSection(
  workout: NormalizedWorkoutData,
  sectionId: string,
) {
  const normalSection = workout.entities.sections[sectionId];
  const section = denormalize(normalSection, sectionSchema, workout.entities);

  return section as WorkoutSection;
}

export function updateCycles(
  workout: NormalizedWorkoutData,
  sectionId: string,
  updateCycle: (cycleId: string) => any,
) {
  const section = workout.entities.sections[sectionId];

  for (const cycleId of section.cycles) {
    updateCycle(cycleId);
  }
}

export function updateExercise(
  workout: NormalizedWorkoutData,
  exerciseId: string,
  sectionId: string,
  updateExercise: (exerciseId: string) => any,
) {
  const index = getFirstCycle(workout, sectionId).exercises.indexOf(exerciseId);

  updateCycles(workout, sectionId, (cycleId) => {
    const cyclesExerciseId = workout.entities.cycles[cycleId].exercises[index];

    updateExercise(cyclesExerciseId);
  });
}

export function updateSet(
  workout: NormalizedWorkoutData,
  exerciseId: string,
  sectionId: string,
  setId: string,
  updateSet: (setId: string) => any,
) {
  const index = workout.entities.exercises[exerciseId].sets.indexOf(setId);

  updateExercise(workout, exerciseId, sectionId, (exerciseId) => {
    const exercisesSetId = workout.entities.exercises[exerciseId].sets[index];

    updateSet(exercisesSetId);
  });
}

export function updateAllSetsInExercise(
  workout: NormalizedWorkoutData,
  exerciseId: string,
  sectionId: string,
  updateSet: (setId: string) => any,
) {
  updateExercise(workout, exerciseId, sectionId, (exerciseId) => {
    for (const setId of workout.entities.exercises[exerciseId].sets) {
      updateSet(setId);
    }
  });
}

export function insertExercise(
  workout: NormalizedWorkoutData,
  exercise: WorkoutExercise,
  sectionId: string,
  index: number,
  keepFirstExerciseIds?: boolean,
) {
  const section = workout.entities.sections[sectionId];
  let firstExercise: WorkoutExercise | null = null;

  let cycleIndex = 0;

  for (const cycleId of section.cycles) {
    const newExercise =
      keepFirstExerciseIds && cycleIndex === 0
        ? JSON.parse(JSON.stringify(exercise))
        : getExerciseWithNewIds(exercise);

    if (!firstExercise) {
      firstExercise = newExercise;
    }

    const normalExercise = normalize(newExercise, { sets: [setSchema] });

    workout.entities.cycles[cycleId].exercises.splice(
      index,
      0,
      newExercise.exercise_id,
    );

    workout.entities.exercises[newExercise.exercise_id] = normalExercise.result;

    for (const setId of normalExercise.result.sets) {
      workout.entities.sets[setId] = normalExercise.entities.sets![
        setId
      ] as WorkoutSet;
    }

    cycleIndex++;
  }

  return firstExercise;
}

export function insertSet(
  workout: NormalizedWorkoutData,
  set: WorkoutSet,
  sectionId: string,
  exerciseId: string,
  index: number,
) {
  const section = workout.entities.sections[sectionId];

  const exerciseIndex = getFirstCycle(workout, sectionId).exercises.indexOf(
    exerciseId,
  );

  for (const cycleId of section.cycles) {
    const newSet = getSetWithNewIds(set);

    const exerciseId =
      workout.entities.cycles[cycleId].exercises[exerciseIndex];

    workout.entities.exercises[exerciseId].sets.splice(index, 0, newSet.set_id);

    workout.entities.sets[newSet.set_id] = newSet;
  }
}

export function getSetWithNewIds(set: WorkoutSet) {
  const setCopy = JSON.parse(JSON.stringify(set)) as WorkoutSet;

  setCopy.set_id = crypto.randomUUID();

  return setCopy;
}

export function getExerciseWithNewIds(exercise: WorkoutExercise) {
  const exerciseCopy = JSON.parse(JSON.stringify(exercise)) as WorkoutExercise;

  exerciseCopy.exercise_id = crypto.randomUUID();

  exerciseCopy.sets.forEach((set) => {
    set.set_id = crypto.randomUUID();
  });

  return exerciseCopy;
}

export function getFirstCycle(
  workout: NormalizedWorkoutData,
  sectionId: string,
) {
  return workout.entities.cycles[
    workout.entities.sections[sectionId].cycles[0]
  ];
}

export function findSectionIdForExercise(
  workout: NormalizedWorkoutData,
  exerciseId: string,
) {
  for (const sectionId of workout.result.sections) {
    const index = getFirstCycle(workout, sectionId).exercises.indexOf(
      exerciseId,
    );

    if (index !== -1) {
      return sectionId;
    }
  }
}

export function findFirstCycleSetIdFromSetId(
  workout: NormalizedWorkoutData,
  setId: string,
): string | null {
  for (const sectionId of workout.result.sections) {
    const section = workout.entities.sections[sectionId];

    for (const cycleId of section.cycles) {
      const cycle = workout.entities.cycles[cycleId];

      for (const exerciseId of cycle.exercises) {
        const exercise = workout.entities.exercises[exerciseId];

        if (exercise.sets.includes(setId)) {
          const firstCycleId = workout.entities.sections[sectionId].cycles[0];
          const exerciseIndex =
            workout.entities.cycles[cycleId].exercises.indexOf(exerciseId);
          const setIndex =
            workout.entities.exercises[exerciseId].sets.indexOf(setId);
          const firstCycleExerciseId =
            workout.entities.cycles[firstCycleId].exercises[exerciseIndex];
          const firstCycleSetId =
            workout.entities.exercises[firstCycleExerciseId].sets[setIndex];

          return firstCycleSetId;
        }
      }
    }
  }

  return null;
}

export function findIdsFromSetId(
  workout: NormalizedWorkoutData,
  setId: string,
): {
  exerciseId: string;
  sectionId: string;
  setIndex: number;
  exerciseIndex: number;
} | null {
  for (const sectionId of workout.result.sections) {
    const section = workout.entities.sections[sectionId];

    for (const cycleId of section.cycles) {
      const cycle = workout.entities.cycles[cycleId];

      for (const exerciseId of cycle.exercises) {
        const exercise = workout.entities.exercises[exerciseId];

        if (exercise.sets.includes(setId)) {
          return {
            exerciseId,
            sectionId,
            setIndex: exercise.sets.indexOf(setId),
            exerciseIndex: cycle.exercises.indexOf(exerciseId),
          };
        }
      }
    }
  }

  return null;
}

export function getFirstSectionExerciseIds(workout: NormalizedWorkoutData) {
  const exerciseIds: string[] = [];

  const sectionIds = workout.result.sections;

  for (const sectionId of sectionIds) {
    const firstCycle = getFirstCycle(workout, sectionId);

    exerciseIds.push(...firstCycle.exercises);
  }

  return exerciseIds;
}

export function insertSections(
  workout: NormalizedWorkoutData,
  sections: WorkoutSection[],
  index: number,
) {
  const newWorkoutNormalized = getNormalizedWorkout({
    sections: sections,
    workoutId: "anything",
  } as any);

  workout.result.sections.splice(
    index,
    0,
    ...newWorkoutNormalized.result.sections,
  );

  for (const [key, value] of Object.entries(
    newWorkoutNormalized.entities.sections,
  )) {
    workout.entities.sections[key] = value;
  }

  for (const [key, value] of Object.entries(
    newWorkoutNormalized.entities.cycles,
  )) {
    workout.entities.cycles[key] = value;
  }

  for (const [key, value] of Object.entries(
    newWorkoutNormalized.entities.exercises,
  )) {
    workout.entities.exercises[key] = value;
  }

  for (const [key, value] of Object.entries(
    newWorkoutNormalized.entities.sets,
  )) {
    workout.entities.sets[key] = value;
  }
}

export function insertCycle(
  workout: NormalizedWorkoutData,
  cycle: WorkoutCycle,
  sectionId: string,
  index: number,
) {
  const newWorkoutNormalized = getNormalizedWorkout({
    sections: [{ section_id: "anything", cycles: [cycle] }],
    workoutId: "anything",
  } as any);

  workout.entities.sections[sectionId].cycles.splice(
    index + 1,
    0,
    ...newWorkoutNormalized.entities.sections.anything.cycles,
  );

  for (const [key, value] of Object.entries(
    newWorkoutNormalized.entities.cycles,
  )) {
    workout.entities.cycles[key] = value;
  }

  for (const [key, value] of Object.entries(
    newWorkoutNormalized.entities.exercises,
  )) {
    workout.entities.exercises[key] = value;
  }

  for (const [key, value] of Object.entries(
    newWorkoutNormalized.entities.sets,
  )) {
    workout.entities.sets[key] = value;
  }
}
