import { Navigate } from "@tanstack/react-router";
import { useEffect } from "react";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { exitGhostMode } from "src/slices/trainerSlice";

export function ExitGhostModePage() {
  const dispatch = useAppDispatch();
  const ghostedTrainer = useAppSelector(
    (state) => state.trainer.ghostedTrainer,
  );
  const realTrainer = useAppSelector((state) => state.trainer.trainer);

  useEffect(() => {
    dispatch(exitGhostMode());
  }, []);

  if (!ghostedTrainer) {
    if (realTrainer?.is_admin) {
      return <Navigate to="/admin/company/team" />;
    } else {
      return <Navigate to="/" />;
    }
  }

  return <LoadingPage message="Exiting ghost mode" />;
}
