import { Tooltip, Typography } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { useClient } from "@trainwell/features/clients";
import { isFuture } from "date-fns";
import { type JSX } from "react";
import { removePII } from "src/config/config";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { selectClientById } from "src/slices/clientsSlice";
import { selectTrainerNames } from "src/slices/trainersSlice";

interface Props {
  userId: string;
  variant?: "h1";
  prefix?: string;
  sx?: SxProps<Theme>;
}

export default function ClientName({
  userId,
  prefix,
  variant,
  sx = [],
}: Props) {
  const clientCache = useAppSelector((state) =>
    selectClientById(state, userId),
  );
  const { data: clientQuery } = useClient({
    userId,
    queryConfig: {
      staleTime: 1000 * 60 * 15,
      enabled: !clientCache,
    },
  });
  const client = clientCache ?? clientQuery;
  const clientInfo = useAppSelector(
    (state) => state.clients.clientInfo[userId],
  );
  const trainerNames = useAppSelector(selectTrainerNames);

  const components: JSX.Element[] = [];

  if (!client) {
    return null;
  }

  if (client.is_vip) {
    components.unshift(
      <Tooltip title="VIP" key="vip">
        <span>👑 </span>
      </Tooltip>,
    );
  }

  if (clientInfo) {
    const hasRedHeart =
      client.account.plan.date_trial_end &&
      isFuture(new Date(client.account.plan.date_trial_end)) &&
      client.referral_ids?.referral_id;

    if (hasRedHeart) {
      components.unshift(
        <Tooltip
          title={`Referral pre-payment${
            clientInfo.referred_by
              ? ` Referred by ${clientInfo.referred_by.full_name}`
              : ""
          }`}
          key="rit"
        >
          <span>❤️ </span>
        </Tooltip>,
      );
    }

    const hasEnvelope = clientInfo.referred_clients_in_trial.length > 0;

    if (hasEnvelope) {
      components.unshift(
        <Tooltip
          title={`Sent referral${
            clientInfo.referred_clients_in_trial.length > 1 ? "s" : ""
          } pre-payment. ${clientInfo.referred_clients_in_trial
            .map((c) => c.full_name)
            .join(", ")}`}
          key="srit"
        >
          <span>💌 </span>
        </Tooltip>,
      );
    }

    if (
      !hasRedHeart &&
      !hasEnvelope &&
      (clientInfo.referred_clients.length > 0 || clientInfo.referred_by)
    ) {
      const count =
        (client.referral_ids?.referral_id ? 1 : 0) +
        clientInfo.referred_clients.length;
      const clients = [
        ...clientInfo.referred_clients,
        clientInfo.referred_by,
      ].filter(Boolean);

      components.unshift(
        <Tooltip
          title={`In referral pair with ${count} ${
            count > 1 ? "people" : "person"
          }. ${clients.map((c) => c!.full_name).join(", ")}`}
          key="rp"
        >
          <span>💙 </span>
        </Tooltip>,
      );
    }
  }

  if (client.trainer_id_interim) {
    components.unshift(
      <Tooltip
        title={`Interim client from ${getTrainerName(
          client.trainer_id,
          trainerNames,
        )}`}
        key="interim_client"
      >
        <span>🔄 </span>
      </Tooltip>,
    );
  }

  if (client.tests?.includes("call_missed_workout_clients_treatment")) {
    components.unshift(
      <Tooltip
        title={"Consistency partner test"}
        key="call_missed_workout_test"
      >
        <span>☎️ </span>
      </Tooltip>,
    );
  }

  if (client.organization?._id === "c444733b-4747-4086-990d-7a12e919b746") {
    components.unshift(
      <Tooltip title={"GLP-1"} key="glp-1-client">
        <span>💉</span>
      </Tooltip>,
    );
  }

  if (
    (client.tests?.includes("nov_nutrition_treatment") ||
      client.tests?.includes("nutrition_partnerships") ||
      client.tests?.includes("nov_nutrition_reactivation_treatment") ||
      client.tests?.includes("nov_nutrition_legacy")) &&
    client.device_platform === "ios"
  ) {
    components.unshift(
      <Tooltip title={"Nutrition test"} key="nutrition_test">
        <span>🥑 </span>
      </Tooltip>,
    );
  }

  if (client.tests?.includes("full_body_form_feedback_treatment")) {
    components.unshift(
      <Tooltip title={"Form feedback"} key="form_feedback">
        <span>🗿 </span>
      </Tooltip>,
    );
  }

  if (client.tests?.includes("rom_visualization_treatment")) {
    components.unshift(
      <Tooltip title={"ROM visualization"} key="rom_visualization">
        <span>🖼️ </span>
      </Tooltip>,
    );
  }

  if (
    client.tests?.includes("call_missed_workout_clients_treatment_2") ||
    client.tests?.includes("call_missed_workout_clients_treatment_2_fake")
  ) {
    components.unshift(
      <Tooltip
        title={"Consistency partner test 2"}
        key="call_missed_workout_test_2"
      >
        <span>📈 </span>
      </Tooltip>,
    );
  }

  if (client.tests?.includes("sep_steps_treatment")) {
    components.unshift(
      <Tooltip title={"Daily movement test"} key="daily_movement_test">
        <span>🦶 </span>
      </Tooltip>,
    );
  }

  if (client.tests?.includes("dec_one_on_one_session_treatment")) {
    components.unshift(
      <Tooltip title={"Live session test"} key="live_sessions">
        <span>🤝 </span>
      </Tooltip>,
    );
  }

  components.push(
    <span key="name">
      {prefix}
      {removePII ? "John Smith" : client.full_name}
    </span>,
  );

  return (
    <Typography variant={variant} sx={sx}>
      {components.map((c) => c)}
    </Typography>
  );
}
