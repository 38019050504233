import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { IconButton, Typography } from "@mui/material";
import ControlBar from "src/components/misc/ControlBar";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  selectCheckpointText,
  selectIsGhosting,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

interface Props {
  onToggleDrawer: () => void;
  drawerOpen: boolean;
}

export function HomeControlBar({ onToggleDrawer, drawerOpen }: Props) {
  const dispatch = useAppDispatch();
  const dashMode = useAppSelector((state) => state.app.dashMode);
  const checkpointText = useAppSelector(selectCheckpointText);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const isGhosting = useAppSelector(selectIsGhosting);

  return (
    <ControlBar
      leftContent={
        dashMode === "default" &&
        trainer?.action_items_enabled && (
          <>
            <IconButton
              aria-label="toggle drawer"
              size="small"
              onClick={() => {
                const newVal = !drawerOpen;

                onToggleDrawer();

                if (!isGhosting) {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      settings: {
                        ...trainer.settings,
                        action_item_panel_open: newVal,
                      },
                    }),
                  );
                }
              }}
              sx={{
                mr: 2,
                color: (theme) => theme.palette.backgroundDark.contrastText,
              }}
            >
              {drawerOpen ? <ChevronLeftRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
            <Typography
              sx={{
                color: (theme) => theme.palette.backgroundDark.contrastText,
              }}
            >
              {checkpointText}
            </Typography>
          </>
        )
      }
    />
  );
}
