import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CustomLink from "src/components/misc/CustomLink";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

interface Props {
  userId: string;
  defaultExpanded?: boolean;
  freeFloating?: boolean;
  condensed?: boolean;
}

export default function ReferralsPanel({
  userId,
  defaultExpanded = false,
  freeFloating = false,
  condensed,
}: Props) {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const clientsInfo = useAppSelector((state) => state.clients.clientInfo);
  const [expanded, setExpanded] = useState(defaultExpanded);

  const clientInfo = clientsInfo[userId];

  if (!clientInfo) {
    return null;
  }

  const referralClients = [
    ...clientInfo.referred_clients,
    clientInfo.referred_by,
  ].filter(Boolean);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      disabled={
        !referralClients.length &&
        !clientInfo.referred_by &&
        !clientInfo.referred_clients.length
      }
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      expanded={condensed ? false : expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">❤️{condensed ? "" : " Referrals"}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Stack direction={"column"} spacing={3} sx={{ mx: 2, mb: 2 }}>
          {clientInfo.referred_by && (
            <Card
              variant="outlined"
              sx={{
                backgroundColor: (theme) => theme.palette.successSurface.main,
                p: 1,
              }}
            >
              ❤️ Referred by{" "}
              {clientInfo.referred_by.trainer_id === trainer?.trainer_id ? (
                <CustomLink
                  key={clientInfo.referred_by.user_id}
                  href={`https://trainer.trainwell.net/clients/${clientInfo.referred_by.user_id}`}
                  text={clientInfo.referred_by.full_name}
                />
              ) : (
                clientInfo.referred_by.full_name
              )}
            </Card>
          )}
          <Box>
            <Tooltip
              disableInteractive
              title="Referrals sent pre-payment - Total clients you are training that sent a referral where that referral is currently in a trial"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                💌 Referrals sent in trial
              </Typography>
            </Tooltip>
            {clientInfo.referred_clients_in_trial.length === 0 && (
              <Typography
                variant="body2"
                sx={{ ml: 1, color: (theme) => theme.palette.text.secondary }}
              >
                None
              </Typography>
            )}
            <Stack direction={"column"} sx={{ ml: 1 }}>
              {clientInfo.referred_clients_in_trial.map((c) => {
                if (trainer?.trainer_id === c.trainer_id) {
                  return (
                    <CustomLink
                      key={c.user_id}
                      href={`https://trainer.trainwell.net/clients/${c.user_id}`}
                      text={c.full_name}
                    />
                  );
                } else {
                  return <Typography key={c.user_id}>{c.full_name}</Typography>;
                }
              })}
            </Stack>
          </Box>
          <Box>
            <Tooltip
              disableInteractive
              title="Referral clients - Total clients you are training that are part of a referral pair (either sent or received a referral from another active user)"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                💙 Referral clients
              </Typography>
            </Tooltip>
            <Stack direction={"column"} sx={{ ml: 1 }}>
              {referralClients.map((c) => {
                if (trainer?.trainer_id === c!.trainer_id) {
                  return (
                    <CustomLink
                      key={c!.user_id}
                      href={`https://trainer.trainwell.net/clients/${c!.user_id}`}
                      text={c!.full_name}
                    />
                  );
                } else {
                  return (
                    <Typography key={c!.user_id}>{c!.full_name}</Typography>
                  );
                }
              })}
            </Stack>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
