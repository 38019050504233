import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import type { WorkoutTemplate } from "@trainwell/features/legacy";
import { useRef, useState } from "react";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import {
  selectPossibleTemplateTags,
  selectTemplateLibrary,
} from "src/slices/templatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { toggleIsInsertingTemplate } from "src/slices/workoutSlice";
import TemplateSelectCell from "./TemplateSelectCell";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

export default function TemplateSelector() {
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const [tags, setTags] = useState<string[]>([]);
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const possibleTags = useAppSelector((state) =>
    selectPossibleTemplateTags(state, trainerId),
  );
  const templates = useAppSelector((state) => {
    let fetchedTemplates = selectTemplateLibrary(state).filter(
      (t) => t.trainer_id !== "copilot",
    );

    fetchedTemplates = fetchedTemplates.sort((a, b) => {
      const aDate = (a.metadata.dates_updated[
        a.metadata.dates_updated.length - 1
      ] ?? a.metadata.date_created) as string;

      const bDate = (b.metadata.dates_updated[
        b.metadata.dates_updated.length - 1
      ] ?? b.metadata.date_created) as string;

      return bDate.localeCompare(aDate);
    });

    if (search !== "") {
      fetchedTemplates = fetchedTemplates.filter((template) =>
        template.name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    if (tags.length !== 0) {
      fetchedTemplates = fetchedTemplates.filter((w) => {
        for (const tag of tags) {
          if (!w.tags?.includes(tag)) {
            return false;
          }
        }

        return true;
      });
    }

    return fetchedTemplates;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.default,
        height: "100%",
      }}
    >
      <Box
        sx={{
          p: 1,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3">Templates</Typography>
          <IconButton
            size="small"
            onClick={() => {
              dispatch(toggleIsInsertingTemplate());
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          sx={{ mb: 1 }}
        />
        <Autocomplete
          multiple
          size="small"
          options={possibleTags}
          disableCloseOnSelect
          getOptionLabel={(tag) => {
            const tagDetails = getSmartTagDetails(tag);

            return tagDetails.label;
          }}
          value={tags}
          onChange={(_event, newValues) => {
            setTags(newValues);
          }}
          renderOption={(props, tag, { selected }) => {
            const tagDetails = getSmartTagDetails(tag);

            return (
              <li
                {...props}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {tagDetails.icon && (
                  <tagDetails.icon.type
                    {...tagDetails.icon.props}
                    sx={{ fontSize: "inherit", mr: 1 }}
                  />
                )}
                {tagDetails.label}
              </li>
            );
          }}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Tags" />}
          slotProps={{
            popper: {
              style: { width: "fit-content" },
            },
          }}
        />
      </Box>
      <VirtualList templates={templates} />
    </Box>
  );
}

interface VirtualListProps {
  templates: Pick<
    WorkoutTemplate,
    | "metadata"
    | "trainer_id"
    | "tags"
    | "name"
    | "notes_trainer"
    | "template_id"
    | "phase_template_id"
  >[];
}

function VirtualList({ templates }: VirtualListProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: templates.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 120,
    getItemKey: (index) => templates[index].template_id,
    overscan: 5,
  });
  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={parentRef}
      style={{
        height: "100%",
        overflow: "auto",
        contain: "strict",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map((virtualItem) => {
            const templateId = virtualItem.key as string;

            return (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
              >
                <TemplateSelectCell templateId={templateId} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
