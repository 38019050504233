import { Box, Popover } from "@mui/material";
import TextFieldWithLimit from "src/components/misc/TextFieldWithLimit";
import { useAppDispatch } from "src/hooks/stateHooks";
import { updateWorkoutNotes } from "src/slices/workoutSlice";

interface Props {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  defaultNotes: string;
}

export default function NotesPopup({ onClose, anchorEl, defaultNotes }: Props) {
  const dispatch = useAppDispatch();
  const workoutNotesOpen = Boolean(anchorEl);

  return (
    <Popover
      open={workoutNotesOpen}
      onClose={() => {
        onClose();
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      disableRestoreFocus
    >
      <Box
        sx={{
          width: "400px",
          display: "flex",
          flexDirection: "column",
          p: 1,
        }}
      >
        <TextFieldWithLimit
          autoFocus
          characterLimit={360}
          defaultValue={defaultNotes}
          onSave={(value) => {
            dispatch(updateWorkoutNotes(value));
            onClose();
          }}
          name="workoutBuilder.workoutNotes"
          helperText="These notes will be shown to client before they start the workout"
          multiline
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
          fullWidth
        />
      </Box>
    </Popover>
  );
}
