import { attachClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Box,
  CardActionArea,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  deleteTemplateLibraryFolder,
  selectCanEditTrainwellLibrary,
  selectPhaseTemplateFolderById,
  setTemplateNavigation,
  updateTemplateLibraryFolder,
} from "src/slices/phaseTemplatesSlice";
import FolderEditDialog from "./FolderEditDialog";

interface Props {
  phaseTemplateFolderId: string;
}

export default function FolderCellDraggable({ phaseTemplateFolderId }: Props) {
  const phaseTemplateFolder = useAppSelector((state) =>
    selectPhaseTemplateFolderById(state, phaseTemplateFolderId),
  );
  const canEdit = useAppSelector(
    (state) =>
      selectCanEditTrainwellLibrary(state) ||
      selectPhaseTemplateFolderById(state, phaseTemplateFolderId)
        ?.trainer_id !== "copilot",
  );
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "folder",
      folderId: phaseTemplateFolderId,
    };

    return combine(
      draggable({
        element: element,
        getInitialData: () => data,
        onDragStart: () => {
          setDragging(true);
        },
        onDrop: () => {
          setDragging(false);
        },
        canDrag: () => canEdit,
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            (source.data.type === "folder" ||
              source.data.type === "workout_template" ||
              source.data.type === "phase_template") &&
            source.data.folderId !== phaseTemplateFolderId
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDragEnter: () => {
          setIsDraggedOver(true);
        },
        onDragLeave: () => {
          setIsDraggedOver(false);
        },
        onDrop: () => {
          setIsDraggedOver(false);
        },
      }),
    );
  }, [phaseTemplateFolderId, canEdit]);

  return (
    <div ref={ref}>
      <FolderCell
        phaseTemplateFolderId={phaseTemplateFolderId}
        isOver={isDraggedOver}
        dragging={dragging}
      />
      {previewContainer
        ? createPortal(
            <DragPreview
              text={phaseTemplateFolder?.name ?? ""}
              icon={
                <FolderRoundedIcon
                  sx={{
                    fontSize: 20,
                    color: (theme) => theme.palette.text.secondary,
                  }}
                />
              }
            />,
            previewContainer,
          )
        : null}
    </div>
  );
}

type FolderCellProps = {
  isOver: boolean;
  dragging: boolean;
} & Props;

const FolderCell = memo(function FolderCell({
  phaseTemplateFolderId,
  isOver,
  dragging,
}: FolderCellProps) {
  const dispatch = useAppDispatch();
  const phaseTemplateFolder = useAppSelector((state) =>
    selectPhaseTemplateFolderById(state, phaseTemplateFolderId),
  );
  const folderHasChildren = useAppSelector(
    (state) =>
      state.phaseTemplates.phaseTemplates.some(
        (phaseTemplate) =>
          phaseTemplate.parent_folder_id === phaseTemplateFolderId,
      ) ||
      state.phaseTemplates.templateLibraryFolders.some(
        (phaseTemplate) =>
          phaseTemplate.parent_folder_id === phaseTemplateFolderId,
      ),
  );
  const canEdit = useAppSelector(selectCanEditTrainwellLibrary)
    ? true
    : phaseTemplateFolder?.trainer_id !== "copilot";
  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    console.log("woot");
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  function handleCloseContextMenu() {
    setContextMenu(null);
    setAnchorEl(null);
  }

  if (!phaseTemplateFolder) {
    return null;
  }

  return (
    <>
      <CardActionArea
        draggable={false}
        href={
          isDialog ? "" : `/template-library/folders/${phaseTemplateFolder._id}`
        }
        onClick={() => {
          if (isDialog) {
            dispatch(
              setTemplateNavigation({
                openFolderId: phaseTemplateFolder._id,
                openTagId: null,
              }),
            );
          }
        }}
        onContextMenu={canEdit ? handleContextMenu : undefined}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          py: 0.5,
          pr: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: (theme) =>
            isOver ? alpha(theme.palette.primary.main, 0.1) : undefined,
          opacity: dragging ? 0.4 : 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {phaseTemplateFolder.is_pinned ? (
            <PushPinRoundedIcon
              sx={{
                fontSize: 12,
                width: "20px",
                color: (theme) => theme.palette.text.secondary,
              }}
            />
          ) : (
            <Box sx={{ width: "20px" }} />
          )}
          <FolderRoundedIcon
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontSize: 24,
            }}
          />
          <Box
            sx={{
              px: 2,
            }}
          >
            <Typography variant="h6">{phaseTemplateFolder.name}</Typography>
            <Typography variant="overline">
              {phaseTemplateFolder.description}
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            setAnchorEl(event.currentTarget);
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          disabled={!canEdit}
        >
          <MoreVertRoundedIcon fontSize="inherit" />
        </IconButton>
      </CardActionArea>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl) || Boolean(contextMenu)}
        onClose={() => {
          handleCloseContextMenu();
        }}
        anchorReference={contextMenu ? "anchorPosition" : "anchorEl"}
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            dispatch(
              updateTemplateLibraryFolder({
                id: phaseTemplateFolderId,
                isPinned: !phaseTemplateFolder.is_pinned,
              }),
            )
              .unwrap()
              .catch(() => {
                enqueueSnackbar("Error pinning / unpinning", {
                  variant: "error",
                });
              });

            handleCloseContextMenu();
          }}
        >
          <ListItemIcon>
            {phaseTemplateFolder.is_pinned ? (
              <RemoveCircleRoundedIcon fontSize="small" />
            ) : (
              <PushPinRoundedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={phaseTemplateFolder.is_pinned ? "Unpin" : "Pin"}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setEditDialogOpen(true);
            handleCloseContextMenu();
          }}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <Box>
          <Divider sx={{ my: 1 }} />
        </Box>
        <Tooltip title={folderHasChildren ? "Folder must be empty" : ""}>
          <span>
            <MenuItem
              disabled={folderHasChildren}
              onClick={() => {
                dispatch(
                  deleteTemplateLibraryFolder({
                    templateLibraryFolderId: phaseTemplateFolderId,
                  }),
                )
                  .unwrap()
                  .catch(() => {
                    enqueueSnackbar("Unable to delete folder", {
                      variant: "error",
                    });
                  });

                handleCloseContextMenu();
              }}
            >
              <ListItemIcon>
                <DeleteRoundedIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                sx={{ color: (theme) => theme.palette.error.main }}
              />
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
      <FolderEditDialog
        defaultPhaseTemplateFolder={phaseTemplateFolder}
        trainerId={phaseTemplateFolder.trainer_id}
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
      />
    </>
  );
});
