import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const searchSchema = z.object({
  tab: z
    .union([
      z.literal("info"),
      z.literal("metrics"),
      z.literal("vacations"),
      z.literal("client-audits"),
      z.literal("trainer-audits"),
    ])
    .catch("info"),
});

export const Route = createFileRoute("/_layout/admin/company/team/$trainerId")({
  validateSearch: searchSchema,
});
