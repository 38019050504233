import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Card, Typography } from "@mui/material";
import type { CompHistory } from "@trainwell/features/legacy";
import { useEffect, useState } from "react";
import ControlBar from "src/components/misc/ControlBar";
import LoadingPage from "src/components/miscPages/LoadingPage";
import NotFoundComponent from "src/components/miscPages/NotFoundComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import CompensationAccordion from "./CompensationAccordion";
import CompensationCalculator from "./CompensationCalculator";

export default function CompensationPage() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [compHistories, setCompHistories] = useState<
    CompHistory[] | undefined | "not_found"
  >();
  const realCoach = useAppSelector((state) => state.trainer.trainer);

  useEffect(() => {
    if (trainer?.trainer_id) {
      api.trainers
        .getCompensation(trainer.trainer_id)
        .then((foundData) => {
          const sortedCompStructures = foundData.sort((a, b) =>
            b.pay_period.paycheck_dates[0].localeCompare(
              a.pay_period.paycheck_dates[0],
            ),
          );

          setCompHistories(sortedCompStructures);
        })
        .catch(() => {
          setCompHistories("not_found");
        });
    }
  }, [trainer?.trainer_id]);

  if (!trainer) {
    return null;
  }

  if (!compHistories) {
    return (
      <>
        <ControlBar
          leftContent={
            <Button
              variant="contained"
              href="/"
              startIcon={<ArrowBackRoundedIcon />}
              size="small"
            >
              Back
            </Button>
          }
        />
        <LoadingPage message="Fetching compensation history" />
      </>
    );
  }

  if (compHistories === "not_found") {
    return (
      <>
        <ControlBar
          leftContent={
            <Button
              variant="contained"
              href="/"
              startIcon={<ArrowBackRoundedIcon />}
              size="small"
            >
              Back
            </Button>
          }
        />
        <NotFoundComponent message="Compensation data not found" />
      </>
    );
  }

  if (trainer.trainer_id !== realCoach?.trainer_id) {
    if (!trainerHasAccess(realCoach!.access_roles, "comp_structure")) {
      return (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ mb: 2 }}>
            You don&apos;t have permission to access this page
          </Typography>
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
          >
            Go home
          </Button>
        </Box>
      );
    }
  }

  const latestCompHistory =
    compHistories.find((compHistory) => !compHistory.estimated) ??
    compHistories[0];

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Box sx={{ p: 3 }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Compensation
        </Typography>
        <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Salary calculator
          </Typography>
          <CompensationCalculator
            latestClientLoad={
              latestCompHistory.comp_breakdown.n_clients_effective
            }
            latestRetention={
              Math.round(
                latestCompHistory.comp_breakdown.retention_effective *
                  100 *
                  100,
              ) / 100
            }
            latestFloor={latestCompHistory.comp_breakdown.floor}
          />
        </Card>
        <Card variant="outlined">
          <Typography variant="h3" sx={{ m: 2 }}>
            Months
          </Typography>
          {compHistories.map((compHistory, i) => (
            <CompensationAccordion key={i} compHistory={compHistory} />
          ))}
        </Card>
      </Box>
    </Box>
  );
}
