import { Box, Stack } from "@mui/material";
import type { HabitTask } from "@trainwell/features/legacy";
import { getHabitColor } from "src/lib/habits";

interface Props {
  habitTask: HabitTask;
}

export default function HabitTaskHistory({ habitTask }: Props) {
  return (
    <Stack direction={"row"}>
      {"history" in habitTask &&
        habitTask.history?.map((history, i) => (
          <Box
            key={i}
            sx={{
              width: "8px",
              height: "8px",
              m: 0.5,
              borderRadius: "4px",
              backgroundColor: (theme) =>
                history === true
                  ? getHabitColor(habitTask.type)
                  : history === null
                    ? theme.palette.background.paper
                    : undefined,
              borderColor:
                history === false
                  ? getHabitColor(habitTask.type)
                  : history === null
                    ? "divider"
                    : undefined,
              borderWidth: history === null ? 1 : 2,
              borderStyle: !history ? "solid" : undefined,
            }}
          />
        ))}
    </Stack>
  );
}
