import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import type { SxProps, Theme } from "@mui/material";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

interface Props {
  label: string;
  values?: string[];
  onSave: (newValues: string[]) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

export default function SmartEditList({
  label,
  values,
  onSave,
  sx = [],
  disabled,
}: Props) {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newValues, setNewValues] = useState(values ?? []);

  function save() {
    onSave(newValues);
    setIsEditing(false);
  }

  return (
    <Box
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
        <Typography variant="h6">{label}</Typography>
        <Box
          sx={{
            opacity: (isHovering || isEditing) && !disabled ? undefined : 0,
            pointerEvents:
              (isHovering || isEditing) && !disabled ? undefined : "none",
          }}
        >
          <Button
            variant={isEditing ? "contained" : "text"}
            size="small"
            sx={{
              ml: 1,
              lineHeight: 1.25,
              px: 1,
              py: 0.5,
              minWidth: "0px",
            }}
            onClick={() => {
              if (isEditing) {
                save();
              } else {
                setIsEditing(true);
              }
            }}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              size="small"
              sx={{
                ml: 1,
                lineHeight: 1.25,
                px: 1,
                py: 0.5,
                minWidth: "0px",
              }}
              onClick={() => {
                setIsEditing(false);
                setNewValues(values ?? []);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Box>
      {isEditing ? (
        <Stack direction="column" spacing={1} alignItems="flex-start">
          {newValues.map((value, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <TextField
                size="small"
                fullWidth
                multiline
                value={value}
                onChange={(event) => {
                  const clonedValues = JSON.parse(JSON.stringify(newValues));
                  clonedValues[index] = event.target.value;
                  setNewValues(clonedValues);
                }}
              />
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  const clonedValues = structuredClone(newValues);
                  clonedValues.splice(index, 1);
                  setNewValues(clonedValues);
                }}
                sx={{ ml: 1 }}
              >
                <DeleteRoundedIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
          <Button
            variant="text"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              const clonedValues = structuredClone(newValues);
              clonedValues.push("");
              setNewValues(clonedValues);
            }}
          >
            Add new
          </Button>
        </Stack>
      ) : (
        <ul style={{ margin: "0px", paddingLeft: "24px" }}>
          {values?.map((value, index) => (
            <li key={index}>
              <Typography sx={{ mb: 1 }}>{value}</Typography>
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
}
