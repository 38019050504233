import DataArrayRoundedIcon from "@mui/icons-material/DataArrayRounded";
import { Box, Tooltip, Typography } from "@mui/material";
import { memo, useState } from "react";
import { shallowEqual } from "react-redux";
import SearchField from "src/components/misc/SearchField";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectFilteredCheckInChats } from "src/slices/chatSlice";
import ChatCell from "./ChatCell";
import { CheckInHotkeys } from "./CheckInHotkeys";

interface Props {
  condensed?: boolean;
  userIds: string[];
}

const CheckInChatList = memo(function CheckInChatList({
  condensed,
  userIds,
}: Props) {
  const [search, setSearch] = useState("");
  const chatIds = useAppSelector(
    (state) => selectFilteredCheckInChats(state, userIds, search),
    shallowEqual,
  );

  const noMoreChats = !chatIds.length;

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Box sx={{ flex: 1 }}>
        {!condensed && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: 1,
              px: 1,
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: (theme) => theme.palette.background.default,
            }}
          >
            <SearchField
              value={search}
              onChange={(newValue) => {
                setSearch(newValue);
              }}
              onClear={() => {
                setSearch("");
              }}
              // sx={{ mr: 2 }}
            />
            {/* <ChatSortButton
              value={chatSort}
              onChange={(newSort) => {
                setChatSort(newSort);

                if (!isAuditing) {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer!.trainer_id,
                      settings: {
                        ...trainer!.settings,
                        chat_sort: newSort,
                      },
                    }),
                  );
                }
              }}
            /> */}
          </Box>
        )}
        <Box>
          {search !== "" ? (
            chatIds.map((chatId) => (
              <ChatCell key={chatId} chatId={chatId} condensed={condensed} />
            ))
          ) : (
            <>
              {noMoreChats && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    py: 4,
                  }}
                >
                  <Tooltip
                    title="No chats"
                    placement="right"
                    disableInteractive
                  >
                    <DataArrayRoundedIcon
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    />
                  </Tooltip>
                  {!condensed && (
                    <Typography
                      variant="h6"
                      sx={{
                        ml: 2,
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      No chats
                    </Typography>
                  )}
                </Box>
              )}
              {chatIds.length > 0 &&
                chatIds.map((chatId) => (
                  <ChatCell
                    key={chatId}
                    chatId={chatId}
                    condensed={condensed}
                  />
                ))}
            </>
          )}
        </Box>
      </Box>
      <CheckInHotkeys userIds={userIds} />
    </Box>
  );
});

export default CheckInChatList;
