import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Box, Button, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import type { AccessLocation } from "src/lib/accessRoles";
import { selectHasAccess } from "src/slices/trainerSlice";

interface Props {
  location: AccessLocation;
  showNoAccessMessage?: boolean;
  children?: ReactNode;
}

export default function RestrictAccess({
  location,
  showNoAccessMessage = false,
  children,
}: Props) {
  const hasAccess = useAppSelector((state) => selectHasAccess(state, location));

  if (hasAccess) {
    return children;
  }

  if (showNoAccessMessage) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ mb: 2 }}>
          You don&apos;t have permission to access this
        </Typography>
        <Button variant="contained" href="/" startIcon={<HomeRoundedIcon />}>
          Go home
        </Button>
      </Box>
    );
  }

  return null;
}
