import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import PhoneDisabledRoundedIcon from "@mui/icons-material/PhoneDisabledRounded";
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import type { MessageWorkoutsMissed } from "@trainwell/features/legacy";
import { format, subDays } from "date-fns";
import { useState } from "react";
import { CallClientButton } from "src/features/client/CallClientButton";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateMessage } from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";

interface Props {
  message: MessageWorkoutsMissed;
  combineBottom: boolean;
}

export function WorkoutsMissedMessage({ message, combineBottom }: Props) {
  const dispatch = useAppDispatch();
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const isInCallTest = useAppSelector((state) =>
    selectClientById(state, message.from_id)?.tests?.some(
      (test) =>
        test === "call_missed_workout_clients_treatment" ||
        test === "call_missed_workout_clients_treatment_2",
    ),
  );
  const isInFakeCallTest = useAppSelector((state) =>
    selectClientById(state, message.from_id)?.tests?.some(
      (test) => test === "call_missed_workout_clients_treatment_2_fake",
    ),
  );
  const clientPhoneNumber = useAppSelector(
    (state) => selectClientById(state, message.from_id)?.phone_number,
  );
  const [reasonForNotCalling, setReasonForNotCalling] = useState<null | string>(
    null,
  );

  const messages: string[] = [];

  if (isInCallTest || isInFakeCallTest) {
    if ((message.content.missed_workout_days_in_a_row ?? 0) > 1) {
      messages.push(
        `${message.content.missed_workout_days_in_a_row} days in a row`,
      );
    }

    if (
      (message.content.missed_this_day_30_days?.programmed_count ?? 0) > 1 &&
      (message.content.missed_this_day_30_days?.missed_count ?? 0) > 1
    ) {
      messages.push(
        `${message.content.missed_this_day_30_days?.missed_count ?? 0}/${message.content.missed_this_day_30_days?.programmed_count} ${format(subDays(message.send_date, 1), "EEEE")}s`,
      );
    }
  }

  const missedAllWorkouts = message.content.missed_all_internal_workouts;

  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        py: 1,
        px: 1.5,
        width: "fit-content",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
        <Avatar
          sx={{
            backgroundColor: (theme) =>
              missedAllWorkouts
                ? theme.palette.error.main
                : theme.palette.backgroundTertiary.main,
            mr: 1,
            width: 24,
            height: 24,
          }}
        >
          <FitnessCenterRoundedIcon
            sx={{
              fontSize: 24 * 0.6,
              color: (theme) =>
                missedAllWorkouts
                  ? theme.palette.error.contrastText
                  : theme.palette.text.primary,
            }}
          />
        </Avatar>
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: (theme) =>
                missedAllWorkouts
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
            }}
          >
            Missed{missedAllWorkouts ? " all" : ""} workout
            {!missedAllWorkouts && message.content.workouts.length <= 1
              ? ""
              : "s"}
          </Typography>
          {messages.length > 0 && (
            <Typography variant="caption">{messages.join(", ")}</Typography>
          )}
        </Box>
      </Box>
      {message.content.workouts.map((workout, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography>
            -{" "}
            <Link
              href={
                isChatFullscreen
                  ? ""
                  : `/clients/${message.from_id}/workouts/${workout.workout_id}`
              }
              sx={{
                color: (theme) => theme.palette.text.primary,
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {workout.name}
            </Link>
            {(workout.missed_this_workout_30_days?.missed_count ?? 0) > 1 && (
              <Typography
                component={"span"}
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {" "}
                (Missed {workout.missed_this_workout_30_days?.missed_count}/
                {workout.missed_this_workout_30_days?.programmed_count} times)
              </Typography>
            )}
          </Typography>
        </Box>
      ))}
      {message.content.call_details?.status === "called" && (
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1,
            py: 0.5,
            pt: 1,
          }}
        >
          <CheckRoundedIcon />
          <Box sx={{ ml: 1 }}>
            <Typography>Called</Typography>
            {message.content.call_details.date_set && (
              <Typography variant="overline">
                {format(message.content.call_details.date_set, "MMM d, yyyy")}
              </Typography>
            )}
          </Box>
        </Card>
      )}
      {message.content.call_details?.status === "client_explained" && (
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1,
            py: 0.5,
            pt: 1,
          }}
        >
          <PhoneDisabledRoundedIcon />
          <Box sx={{ ml: 1 }}>
            <Typography>Client explained</Typography>
            {message.content.call_details.date_set && (
              <Typography variant="overline">
                {format(message.content.call_details.date_set, "MMM d, yyyy")}
              </Typography>
            )}
          </Box>
        </Card>
      )}
      {message.content.call_details?.status === "not_called" && (
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1,
            py: 0.5,
            pt: 1,
          }}
        >
          <PhoneDisabledRoundedIcon />
          <Box sx={{ ml: 1 }}>
            <Typography>Not called</Typography>
            <Typography variant="overline">
              {message.content.call_details.reason_for_not_calling}
            </Typography>
            {message.content.call_details.date_set && (
              <Typography variant="overline">
                {format(message.content.call_details.date_set, "MMM d, yyyy")}
              </Typography>
            )}
          </Box>
        </Card>
      )}
      {isInCallTest && !message.content.call_details && missedAllWorkouts && (
        <>
          <Stack
            direction="column"
            spacing={1}
            sx={{
              pt: 1,
              alignItems: "flex-start",
            }}
          >
            <CallClientButton
              clientPhoneNumber={clientPhoneNumber!}
              onCall={() => {
                dispatch(
                  updateMessage({
                    messageId: message.message_id,
                    callDetails: {
                      status: "called",
                    },
                  }),
                );
              }}
            />
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                dispatch(
                  updateMessage({
                    messageId: message.message_id,
                    callDetails: {
                      status: "client_explained",
                    },
                  }),
                );
              }}
            >
              Client explained why
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setReasonForNotCalling("");
              }}
            >
              Don&apos;t call
            </Button>
          </Stack>
          <Dialog
            open={reasonForNotCalling !== null}
            onClose={() => {
              setReasonForNotCalling(null);
            }}
          >
            <DialogTitle>Why didn&apos;t you call the client?</DialogTitle>
            <DialogContent>
              <TextField
                label="Reason"
                multiline
                fullWidth
                value={reasonForNotCalling ?? ""}
                onChange={(event) => {
                  setReasonForNotCalling(event.target.value);
                }}
                helperText="Required"
                sx={{ mt: 1 }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  dispatch(
                    updateMessage({
                      messageId: message.message_id,
                      callDetails: {
                        status: "not_called",
                        reason_for_not_calling: reasonForNotCalling as string,
                      },
                    }),
                  )
                    .unwrap()
                    .then(() => {
                      setReasonForNotCalling(null);
                    });
                }}
                disabled={isBlank(reasonForNotCalling)}
              >
                Done
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}
