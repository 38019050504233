import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  EquipmentType as EquipmentType2,
  type EquipmentObject,
  type ExerciseGroup,
  type SetHistory,
} from "@trainwell/features/legacy";
import type { Expression } from "fuse.js";
import { useMemo, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import SearchField from "src/components/misc/SearchField";
import { useAppSelector } from "src/hooks/stateHooks";
import { exerciseGroups, exerciseGroupsFuse } from "src/lib/exercises";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import ExerciseFilterButton from "./ExerciseFilterButton";
import ExerciseSelectCell from "./ExerciseSelectCell";
import type { ExerciseSort } from "./ExerciseSortButton";
import ExerciseSortButton from "./ExerciseSortButton";

export default function ExerciseSelector() {
  const selectedEquipmentKeys = useAppSelector(
    (state) =>
      !state.client.client
        ? []
        : (
            Object.keys(
              state.client.client.equipment_detailed,
            ) as (keyof typeof EquipmentType2)[]
          ).filter((key) => state.client.client!.equipment_detailed[key]),
    shallowEqual,
  );
  const latestSets = useAppSelector(
    (state) => state.client.client?.latest_sets,
    shallowEqual,
  );
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);

  const trainer = useAppSelector((state) => state.trainer.trainer);

  const [search, setSearch] = useState("");
  const [sortMode, setSortMode] = useState<ExerciseSort>("name");
  const [filteredExerciseGroupIds, setFilteredExerciseGroupIds] = useState<
    string[]
  >([]);

  const filteredExerciseGroups = useMemo(() => {
    let list = exerciseGroups.filter((g) =>
      filteredExerciseGroupIds.includes(g.picker_group_id),
    );

    if (search !== "") {
      let refinedSearch = search;

      // Expand exercise shortcuts

      refinedSearch = refinedSearch
        .toLowerCase()
        .split(",")
        .map((t) => t.trim())
        .map((t) => {
          if (t === "mb") {
            return "medicine ball";
          } else if (t === "kb") {
            return "kettlebell";
          } else if (t === "db") {
            return "dumbbell";
          } else if (t === "bb") {
            return "barbell";
          }

          return t;
        })
        .join(",")
        .split(" ")
        .map((t) => {
          if (t === "mb") {
            return "medicine ball";
          } else if (t === "kb") {
            return "kettlebell";
          } else if (t === "db") {
            return "dumbbell";
          } else if (t === "bb") {
            return "barbell";
          }

          return t;
        })
        .join(" ");

      refinedSearch = refinedSearch.replaceAll("mb,", "medicine ball,");
      refinedSearch = refinedSearch.replaceAll("kb,", "kettlebell,");
      refinedSearch = refinedSearch.replaceAll("db,", "dumbbell,");
      refinedSearch = refinedSearch.replaceAll("bb,", "barbell,");

      // Handle AND and OR

      const andTerms = refinedSearch
        .split(",")
        .map((t) => t.trim())
        .filter((t) => !t.includes(" or "));

      const orTerms = refinedSearch
        .split(",")
        .map((t) => t.trim())
        .filter((t) => t.includes(" or "))
        .join(" ")
        .split(" or ")
        .map((t) => t.trim());

      const searchExpression: Expression = {
        $and: [
          ...andTerms.map((t) => {
            return { tags: t };
          }),
        ],
      };

      if (orTerms.length >= 2) {
        searchExpression.$and?.push({
          $or: [
            ...orTerms.map((t) => {
              return { tags: t };
            }),
          ],
        });
      }

      // Useful logging to debug search
      // console.log(`Search: ${search}`);
      // console.log(`Search (refined): ${refinedSearch}`);
      // console.log(
      //   `Search expression: ${JSON.stringify(searchExpression, null, 1)}`,
      // );

      let fusedItems = exerciseGroupsFuse.search(searchExpression);

      const ids = list.map((item) => item.picker_group_id);

      fusedItems = fusedItems.filter((fusedItem) =>
        ids.includes(fusedItem.item.picker_group_id),
      );

      // console.log(fusedItems);

      list = fusedItems.map((fusedItem) => fusedItem.item);

      const favoriteExercises = trainer?.favorite_exercise_ids ?? [];

      list.sort((a, b) => {
        const isAFavorite = favoriteExercises.includes(a.picker_group_id);
        const isBFavorite = favoriteExercises.includes(b.picker_group_id);
        if (isAFavorite && !isBFavorite) {
          return -1;
        } else if (!isAFavorite && isBFavorite) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      const favoriteExercises = trainer?.favorite_exercise_ids ?? [];

      if (sortMode === "last_used") {
        list.sort((a, b) => {
          const isAFavorite = favoriteExercises.includes(a.picker_group_id);
          const isBFavorite = favoriteExercises.includes(b.picker_group_id);
          if (isAFavorite && !isBFavorite) {
            return -1;
          } else if (!isAFavorite && isBFavorite) {
            return 1;
          }

          const lastUsedDateA = latestSets
            ? workoutLib.exercises.getLastUsedDate({
                client: { latest_sets: latestSets },
                exerciseSourceId: a.exercises[0].id,
              })
            : undefined;
          const lastUsedDateB = latestSets
            ? workoutLib.exercises.getLastUsedDate({
                client: { latest_sets: latestSets },
                exerciseSourceId: b.exercises[0].id,
              })
            : undefined;

          if (!lastUsedDateA && lastUsedDateB) {
            return 1;
          } else if (lastUsedDateA && !lastUsedDateB) {
            return -1;
          } else if (!lastUsedDateA && !lastUsedDateB) {
            return 0;
          } else {
            return lastUsedDateB!.getTime() - lastUsedDateA!.getTime();
          }
        });
      } else if (sortMode === "name") {
        list.sort((a, b) => {
          const isAFavorite = favoriteExercises.includes(a.picker_group_id);
          const isBFavorite = favoriteExercises.includes(b.picker_group_id);
          if (isAFavorite && !isBFavorite) {
            return -1;
          } else if (!isAFavorite && isBFavorite) {
            return 1;
          }

          return a.picker_group_id.localeCompare(b.picker_group_id);
        });
      }
    }

    return list;
  }, [
    search,
    filteredExerciseGroupIds,
    sortMode,
    latestSets,
    trainer?.favorite_exercise_ids,
  ]);

  const fakeEquipmentDetailed = selectedEquipmentKeys.reduce<EquipmentObject>(
    (o, key) => ({
      ...o,
      [key]: {
        values: [],
        notes: null,
        photo_url: null,
      },
    }),
    {},
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.default,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.background.default,
          p: 1,
        }}
      >
        <Box>
          <Typography variant="h3">Exercises</Typography>
          <Typography variant="overline">
            {filteredExerciseGroups.length} / {exerciseGroups.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ExerciseSortButton
            value={sortMode}
            onChange={(newSort) => {
              setSortMode(newSort);
            }}
            disabled={search !== ""}
            sx={{ mr: 0.5 }}
          />
          <ExerciseFilterButton
            onChange={(newFilteredExerciseGroupIds) => {
              setFilteredExerciseGroupIds(newFilteredExerciseGroupIds);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          pb: 1,
          px: 1,
          backgroundColor: (theme) => theme.palette.background.default,
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
        />
        <Tooltip
          title={
            <>
              <Typography variant="h3" sx={{ mb: 1 }}>
                Search tips
              </Typography>
              <Typography>
                Use commas to seperate different searches, and &apos;or&apos; to
                match anything, ex:{" "}
                <b>
                  <i>biceps or triceps, db</i>
                </b>{" "}
                will look for anything that works our biceps or triceps that
                also uses dumbbells.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                You can search names, equipment, and body parts.
              </Typography>
              <Typography sx={{ fontWeight: "bold", mt: 1 }}>
                Shortcuts
              </Typography>
              <Typography>db = dumbbell</Typography>
              <Typography>kb = kettlebell</Typography>
              <Typography>bb = barbell</Typography>
              <Typography>mb = medicine ball</Typography>
            </>
          }
        >
          <IconButton size="small" sx={{ ml: 0.5 }}>
            <HelpOutlineRoundedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <VirtualList
          exerciseGroups={filteredExerciseGroups}
          latestSets={latestSets}
          isTemplate={isTemplate}
          equipment={fakeEquipmentDetailed}
        />
      </Box>
    </Box>
  );
}

interface VirtualListProps {
  exerciseGroups: ExerciseGroup[];
  latestSets: Record<string, SetHistory> | undefined;
  isTemplate: boolean;
  equipment: EquipmentObject;
}

function VirtualList({
  exerciseGroups,
  latestSets,
  isTemplate,
  equipment,
}: VirtualListProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: exerciseGroups.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 90,
    getItemKey: (index) => exerciseGroups[index].picker_group_id,
    overscan: 5,
  });
  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={parentRef}
      style={{
        height: "100%",
        overflow: "auto",
        contain: "strict",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map((virtualItem) => {
            const exerciseGroup = exerciseGroups[virtualItem.index];

            const exerciseMasterId =
              workoutLib.exerciseGroups.getExerciseFromGroupId(
                exerciseGroup,
                isTemplate
                  ? undefined
                  : {
                      equipment_detailed: equipment,
                      latest_sets: latestSets,
                    },
                undefined,
              );

            return (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
              >
                <ExerciseSelectCell
                  exerciseMasterID={exerciseMasterId}
                  exerciseGroupID={exerciseGroup.picker_group_id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
