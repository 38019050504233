import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import HouseboatRoundedIcon from "@mui/icons-material/HouseboatRounded";
import NoteAltRoundedIcon from "@mui/icons-material/NoteAltRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import VolunteerActivismRoundedIcon from "@mui/icons-material/VolunteerActivismRounded";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Outlet, useLocation } from "@tanstack/react-router";

const drawerWidth = 240;

export default function ProfileLayout() {
  const open = true;

  return (
    <Box sx={{ display: "flex", maxHeight: "100vh" }}>
      {open && (
        <Box
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            borderRight: 1,
            borderColor: "divider",
            mt: "38px",
            overflowY: "auto",
            height: "calc(100vh - 38px)",
          }}
        >
          <List sx={{ mx: 1 }}>
            <ListItem
              path={"/profile"}
              label={"Profile"}
              icon={<PersonRoundedIcon />}
            />
            <ListItem
              path={"/profile/settings"}
              label={"Settings"}
              icon={<SettingsRoundedIcon />}
            />
            <ListItem
              path={"/profile/client-load"}
              label={"Client load"}
              icon={<GroupsRoundedIcon />}
            />
            <ListItem
              path={"/profile/note-templates"}
              label={"Note templates"}
              icon={<NoteAltRoundedIcon />}
            />
            <ListItem
              path={"/profile/message-templates"}
              label={"Message templates"}
              icon={<ChatBubbleRoundedIcon />}
            />
            <ListItem
              path={"/profile/vacations"}
              label={"Vacations"}
              icon={<HouseboatRoundedIcon />}
            />
            <ListItem
              path={"/profile/calendar"}
              label={"Calendar"}
              icon={<CalendarMonthRoundedIcon />}
            />
            <ListItem
              path={"/profile/metrics"}
              label={"Metrics"}
              icon={<AssessmentRoundedIcon />}
            />
            <ListItem
              path={"/profile/compensation"}
              label={"Compensation"}
              icon={<AttachMoneyRoundedIcon />}
            />
            <ListItem
              path={"/profile/nice-messages"}
              label={"Nice messages"}
              icon={<VolunteerActivismRoundedIcon />}
            />
          </List>
        </Box>
      )}
      <Box component={"main"} sx={{ overflow: "hidden", flex: 1 }}>
        <Toolbar />
        <Box
          sx={{
            height: "100%",
            overflowY: "auto",
            pb: 8,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

interface ListItemProps {
  path: string;
  label: string;
  icon: React.ReactNode;
}

function ListItem({ icon, label, path }: ListItemProps) {
  const location = useLocation();
  const selectred = location.pathname === path;

  return (
    <ListItemButton href={path} selected={selectred}>
      <ListItemIcon
        sx={{
          color: (theme) =>
            selectred ? theme.palette.primary.main : undefined,
          minWidth: "48px",
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={label}
        slotProps={{
          primary: {
            sx: {
              fontWeight: selectred ? "bold" : undefined,
              color: (theme) =>
                selectred ? theme.palette.primary.main : undefined,
            },
          },
        }}
      />
    </ListItemButton>
  );
}
