import { configureStore } from "@reduxjs/toolkit";
import actionItemsReducer from "src/slices/actionItemSlice";
import appReducer from "src/slices/appSlice";
import chatReducer from "src/slices/chatSlice";
import clientReducer from "src/slices/clientSlice";
import clientsReducer from "src/slices/clientsSlice";
import phaseTemplatesReducer from "src/slices/phaseTemplatesSlice";
import phasesReducer from "src/slices/phasesSlice";
import templatesReducer from "src/slices/templatesSlice";
import ticketsReducer from "src/slices/ticketsSlice";
import trainerEditReducer from "src/slices/trainerEditSlice";
import trainerReducer from "src/slices/trainerSlice";
import trainersReducer from "src/slices/trainersSlice";
import workoutBuilderReducer from "src/slices/workoutBuilderSlice";
import workoutReducer from "src/slices/workoutSlice";

import { logApi } from "./api/logApi";

export const store = configureStore({
  reducer: {
    app: appReducer,
    trainer: trainerReducer,
    clients: clientsReducer,
    client: clientReducer,
    chat: chatReducer,
    workout: workoutReducer,
    actionItems: actionItemsReducer,
    templates: templatesReducer,
    trainers: trainersReducer,
    trainerEdit: trainerEditReducer,
    tickets: ticketsReducer,
    phaseTemplates: phaseTemplatesReducer,
    workoutBuilder: workoutBuilderReducer,
    phases: phasesReducer,
    [logApi.reducerPath]: logApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
