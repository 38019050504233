import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import type { TemplateLibraryFolder } from "@trainwell/features/legacy";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import {
  createTemplateLibraryFolder,
  updateTemplateLibraryFolder,
} from "src/slices/phaseTemplatesSlice";

interface Props {
  open: boolean;
  onClose: () => void;
  parentFolderId?: string;
  defaultPhaseTemplateFolder?: TemplateLibraryFolder;
  trainerId: string;
}

export default function FolderEditDialog({
  open,
  onClose,
  parentFolderId,
  defaultPhaseTemplateFolder,
  trainerId,
}: Props) {
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setName(defaultPhaseTemplateFolder?.name ?? "");
    setDescription(defaultPhaseTemplateFolder?.description ?? "");
  }, [open, defaultPhaseTemplateFolder]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (saving) {
          return;
        }

        onClose();
      }}
    >
      <DialogTitle>
        {defaultPhaseTemplateFolder ? "Edit" : "New"} folder
      </DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        <Stack spacing={2} sx={{ pt: 1 }}>
          <TextField
            label="Name"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            sx={{ width: "100%" }}
          />
          <TextField
            label="Description"
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            sx={{ width: "100%" }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
          disabled={saving}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!name}
          autoFocus
          onClick={() => {
            if (!name) {
              return;
            }

            setSaving(true);

            if (defaultPhaseTemplateFolder) {
              dispatch(
                updateTemplateLibraryFolder({
                  id: defaultPhaseTemplateFolder._id,
                  name,
                  description,
                }),
              )
                .unwrap()
                .then(() => {
                  onClose();
                  setSaving(false);
                })
                .catch(() => {
                  setSaving(false);
                  enqueueSnackbar("Error editing folder", { variant: "error" });
                });
            } else {
              dispatch(
                createTemplateLibraryFolder({
                  name,
                  description,
                  parentFolderId,
                  trainerId,
                }),
              )
                .unwrap()
                .then(() => {
                  onClose();
                  setSaving(false);
                })
                .catch(() => {
                  setSaving(false);
                  enqueueSnackbar("Error creating folder", {
                    variant: "error",
                  });
                });
            }
          }}
          loading={saving}
        >
          {defaultPhaseTemplateFolder ? "Edit" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
