/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LogoutImport } from './routes/logout'
import { Route as LoginImport } from './routes/login'
import { Route as AccessDeniedImport } from './routes/access-denied'
import { Route as LayoutRouteImport } from './routes/_layout/route'
import { Route as LayoutIndexImport } from './routes/_layout/index'
import { Route as LayoutSetupAccountImport } from './routes/_layout/setup-account'
import { Route as LayoutExitGhostModeImport } from './routes/_layout/exit-ghost-mode'
import { Route as LayoutChatImport } from './routes/_layout/chat'
import { Route as LayoutAuditsImport } from './routes/_layout/audits'
import { Route as LayoutTemplateLibraryRouteImport } from './routes/_layout/template-library/route'
import { Route as LayoutProfileRouteImport } from './routes/_layout/profile/route'
import { Route as LayoutTemplateLibraryIndexImport } from './routes/_layout/template-library/index'
import { Route as LayoutProfileIndexImport } from './routes/_layout/profile/index'
import { Route as LayoutProfileVacationsImport } from './routes/_layout/profile/vacations'
import { Route as LayoutProfileSettingsImport } from './routes/_layout/profile/settings'
import { Route as LayoutProfileNoteTemplatesImport } from './routes/_layout/profile/note-templates'
import { Route as LayoutProfileNiceMessagesImport } from './routes/_layout/profile/nice-messages'
import { Route as LayoutProfileMetricsImport } from './routes/_layout/profile/metrics'
import { Route as LayoutProfileMessageTemplatesImport } from './routes/_layout/profile/message-templates'
import { Route as LayoutProfileCompensationImport } from './routes/_layout/profile/compensation'
import { Route as LayoutProfileClientLoadImport } from './routes/_layout/profile/client-load'
import { Route as LayoutProfileCalendarImport } from './routes/_layout/profile/calendar'
import { Route as LayoutAuditsAuditIdImport } from './routes/_layout/audits.$auditId'
import { Route as LayoutClientsUserIdRouteImport } from './routes/_layout/clients.$userId/route'
import { Route as LayoutClientsUserIdIndexImport } from './routes/_layout/clients.$userId/index'
import { Route as LayoutTemplateLibraryTagsTagIdImport } from './routes/_layout/template-library/tags.$tagId'
import { Route as LayoutTemplateLibraryFoldersPhaseTemplateFolderIdImport } from './routes/_layout/template-library/folders.$phaseTemplateFolderId'
import { Route as LayoutAdminTestsTestIdImport } from './routes/_layout/admin/tests.$testId'
import { Route as LayoutAdminInfluencersInfluencerIdImport } from './routes/_layout/admin/influencers.$influencerId'
import { Route as LayoutClientsUserIdWorkoutsWorkoutIdImport } from './routes/_layout/clients.$userId/workouts.$workoutId'
import { Route as LayoutClientsUserIdLogsLogIdImport } from './routes/_layout/clients.$userId/logs.$logId'
import { Route as LayoutAdminCompanyTeamTrainerIdImport } from './routes/_layout/admin/company.team.$trainerId'
import { Route as LayoutAdminCompanyClientsUserIdImport } from './routes/_layout/admin/company.clients.$userId'

// Create Virtual Routes

const LayoutAdminRouteLazyImport = createFileRoute('/_layout/admin')()
const LayoutAdminIndexLazyImport = createFileRoute('/_layout/admin/')()
const LayoutAdminVacationsLazyImport = createFileRoute(
  '/_layout/admin/vacations',
)()
const LayoutAdminVacationBudgetLazyImport = createFileRoute(
  '/_layout/admin/vacation-budget',
)()
const LayoutAdminTrainerMetricsLazyImport = createFileRoute(
  '/_layout/admin/trainer-metrics',
)()
const LayoutAdminTrainerAvailabilityLazyImport = createFileRoute(
  '/_layout/admin/trainer-availability',
)()
const LayoutAdminTestsLazyImport = createFileRoute('/_layout/admin/tests')()
const LayoutAdminShortUrlsLazyImport = createFileRoute(
  '/_layout/admin/short-urls',
)()
const LayoutAdminRandomClientsLazyImport = createFileRoute(
  '/_layout/admin/random-clients',
)()
const LayoutAdminProductMetricsLazyImport = createFileRoute(
  '/_layout/admin/product-metrics',
)()
const LayoutAdminLeadFunnelLazyImport = createFileRoute(
  '/_layout/admin/lead-funnel',
)()
const LayoutAdminInfluencersLazyImport = createFileRoute(
  '/_layout/admin/influencers',
)()
const LayoutAdminDataCleanerLazyImport = createFileRoute(
  '/_layout/admin/data-cleaner',
)()
const LayoutAdminClientFunnelLazyImport = createFileRoute(
  '/_layout/admin/client-funnel',
)()
const LayoutAdminCampaignsLazyImport = createFileRoute(
  '/_layout/admin/campaigns',
)()
const LayoutAdminAuditProgrammingLazyImport = createFileRoute(
  '/_layout/admin/audit-programming',
)()
const LayoutAdminAuditMessagingLazyImport = createFileRoute(
  '/_layout/admin/audit-messaging',
)()
const LayoutAdminAuditCohortsLazyImport = createFileRoute(
  '/_layout/admin/audit-cohorts',
)()
const LayoutAdminApiDocsPythonLazyImport = createFileRoute(
  '/_layout/admin/api-docs-python',
)()
const LayoutAdminApiDocsNodejsLazyImport = createFileRoute(
  '/_layout/admin/api-docs-nodejs',
)()
const LayoutAdminTicketsTechissueLazyImport = createFileRoute(
  '/_layout/admin/tickets/tech_issue',
)()
const LayoutAdminTicketsCustomersupportLazyImport = createFileRoute(
  '/_layout/admin/tickets/customer_support',
)()
const LayoutAdminProductMetricsProductMetricIdLazyImport = createFileRoute(
  '/_layout/admin/product-metrics/$productMetricId',
)()
const LayoutAdminCompanyVacationsLazyImport = createFileRoute(
  '/_layout/admin/company/vacations',
)()
const LayoutAdminCompanyTeamLazyImport = createFileRoute(
  '/_layout/admin/company/team',
)()
const LayoutAdminCompanySettingsLazyImport = createFileRoute(
  '/_layout/admin/company/settings',
)()
const LayoutAdminCompanyMetricsLazyImport = createFileRoute(
  '/_layout/admin/company/metrics',
)()
const LayoutAdminCompanyClientsLazyImport = createFileRoute(
  '/_layout/admin/company/clients',
)()
const LayoutAdminCompanyCandidatesLazyImport = createFileRoute(
  '/_layout/admin/company/candidates',
)()
const LayoutAdminCampaignsCampaignIdLazyImport = createFileRoute(
  '/_layout/admin/campaigns/$campaignId',
)()
const LayoutAdminTicketsAllTicketIdLazyImport = createFileRoute(
  '/_layout/admin/tickets/all/$ticketId',
)()
const LayoutAdminCompanyCandidatesTrainerIdLazyImport = createFileRoute(
  '/_layout/admin/company/candidates/$trainerId',
)()

// Create/Update Routes

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AccessDeniedRoute = AccessDeniedImport.update({
  id: '/access-denied',
  path: '/access-denied',
  getParentRoute: () => rootRoute,
} as any)

const LayoutRouteRoute = LayoutRouteImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const LayoutIndexRoute = LayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutRouteRoute,
} as any)

const LayoutAdminRouteLazyRoute = LayoutAdminRouteLazyImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => LayoutRouteRoute,
} as any).lazy(() =>
  import('./routes/_layout/admin/route.lazy').then((d) => d.Route),
)

const LayoutSetupAccountRoute = LayoutSetupAccountImport.update({
  id: '/setup-account',
  path: '/setup-account',
  getParentRoute: () => LayoutRouteRoute,
} as any)

const LayoutExitGhostModeRoute = LayoutExitGhostModeImport.update({
  id: '/exit-ghost-mode',
  path: '/exit-ghost-mode',
  getParentRoute: () => LayoutRouteRoute,
} as any)

const LayoutChatRoute = LayoutChatImport.update({
  id: '/chat',
  path: '/chat',
  getParentRoute: () => LayoutRouteRoute,
} as any)

const LayoutAuditsRoute = LayoutAuditsImport.update({
  id: '/audits',
  path: '/audits',
  getParentRoute: () => LayoutRouteRoute,
} as any)

const LayoutTemplateLibraryRouteRoute = LayoutTemplateLibraryRouteImport.update(
  {
    id: '/template-library',
    path: '/template-library',
    getParentRoute: () => LayoutRouteRoute,
  } as any,
)

const LayoutProfileRouteRoute = LayoutProfileRouteImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => LayoutRouteRoute,
} as any)

const LayoutAdminIndexLazyRoute = LayoutAdminIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutAdminRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout/admin/index.lazy').then((d) => d.Route),
)

const LayoutTemplateLibraryIndexRoute = LayoutTemplateLibraryIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => LayoutTemplateLibraryRouteRoute,
  } as any,
)

const LayoutProfileIndexRoute = LayoutProfileIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutProfileRouteRoute,
} as any)

const LayoutAdminVacationsLazyRoute = LayoutAdminVacationsLazyImport.update({
  id: '/vacations',
  path: '/vacations',
  getParentRoute: () => LayoutAdminRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout/admin/vacations.lazy').then((d) => d.Route),
)

const LayoutAdminVacationBudgetLazyRoute =
  LayoutAdminVacationBudgetLazyImport.update({
    id: '/vacation-budget',
    path: '/vacation-budget',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/vacation-budget.lazy').then((d) => d.Route),
  )

const LayoutAdminTrainerMetricsLazyRoute =
  LayoutAdminTrainerMetricsLazyImport.update({
    id: '/trainer-metrics',
    path: '/trainer-metrics',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/trainer-metrics.lazy').then((d) => d.Route),
  )

const LayoutAdminTrainerAvailabilityLazyRoute =
  LayoutAdminTrainerAvailabilityLazyImport.update({
    id: '/trainer-availability',
    path: '/trainer-availability',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/trainer-availability.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminTestsLazyRoute = LayoutAdminTestsLazyImport.update({
  id: '/tests',
  path: '/tests',
  getParentRoute: () => LayoutAdminRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout/admin/tests.lazy').then((d) => d.Route),
)

const LayoutAdminShortUrlsLazyRoute = LayoutAdminShortUrlsLazyImport.update({
  id: '/short-urls',
  path: '/short-urls',
  getParentRoute: () => LayoutAdminRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout/admin/short-urls.lazy').then((d) => d.Route),
)

const LayoutAdminRandomClientsLazyRoute =
  LayoutAdminRandomClientsLazyImport.update({
    id: '/random-clients',
    path: '/random-clients',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/random-clients.lazy').then((d) => d.Route),
  )

const LayoutAdminProductMetricsLazyRoute =
  LayoutAdminProductMetricsLazyImport.update({
    id: '/product-metrics',
    path: '/product-metrics',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/product-metrics.lazy').then((d) => d.Route),
  )

const LayoutAdminLeadFunnelLazyRoute = LayoutAdminLeadFunnelLazyImport.update({
  id: '/lead-funnel',
  path: '/lead-funnel',
  getParentRoute: () => LayoutAdminRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout/admin/lead-funnel.lazy').then((d) => d.Route),
)

const LayoutAdminInfluencersLazyRoute = LayoutAdminInfluencersLazyImport.update(
  {
    id: '/influencers',
    path: '/influencers',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any,
).lazy(() =>
  import('./routes/_layout/admin/influencers.lazy').then((d) => d.Route),
)

const LayoutAdminDataCleanerLazyRoute = LayoutAdminDataCleanerLazyImport.update(
  {
    id: '/data-cleaner',
    path: '/data-cleaner',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any,
).lazy(() =>
  import('./routes/_layout/admin/data-cleaner.lazy').then((d) => d.Route),
)

const LayoutAdminClientFunnelLazyRoute =
  LayoutAdminClientFunnelLazyImport.update({
    id: '/client-funnel',
    path: '/client-funnel',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/client-funnel.lazy').then((d) => d.Route),
  )

const LayoutAdminCampaignsLazyRoute = LayoutAdminCampaignsLazyImport.update({
  id: '/campaigns',
  path: '/campaigns',
  getParentRoute: () => LayoutAdminRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout/admin/campaigns.lazy').then((d) => d.Route),
)

const LayoutAdminAuditProgrammingLazyRoute =
  LayoutAdminAuditProgrammingLazyImport.update({
    id: '/audit-programming',
    path: '/audit-programming',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/audit-programming.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminAuditMessagingLazyRoute =
  LayoutAdminAuditMessagingLazyImport.update({
    id: '/audit-messaging',
    path: '/audit-messaging',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/audit-messaging.lazy').then((d) => d.Route),
  )

const LayoutAdminAuditCohortsLazyRoute =
  LayoutAdminAuditCohortsLazyImport.update({
    id: '/audit-cohorts',
    path: '/audit-cohorts',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/audit-cohorts.lazy').then((d) => d.Route),
  )

const LayoutAdminApiDocsPythonLazyRoute =
  LayoutAdminApiDocsPythonLazyImport.update({
    id: '/api-docs-python',
    path: '/api-docs-python',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/api-docs-python.lazy').then((d) => d.Route),
  )

const LayoutAdminApiDocsNodejsLazyRoute =
  LayoutAdminApiDocsNodejsLazyImport.update({
    id: '/api-docs-nodejs',
    path: '/api-docs-nodejs',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/api-docs-nodejs.lazy').then((d) => d.Route),
  )

const LayoutProfileVacationsRoute = LayoutProfileVacationsImport.update({
  id: '/vacations',
  path: '/vacations',
  getParentRoute: () => LayoutProfileRouteRoute,
} as any)

const LayoutProfileSettingsRoute = LayoutProfileSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => LayoutProfileRouteRoute,
} as any)

const LayoutProfileNoteTemplatesRoute = LayoutProfileNoteTemplatesImport.update(
  {
    id: '/note-templates',
    path: '/note-templates',
    getParentRoute: () => LayoutProfileRouteRoute,
  } as any,
)

const LayoutProfileNiceMessagesRoute = LayoutProfileNiceMessagesImport.update({
  id: '/nice-messages',
  path: '/nice-messages',
  getParentRoute: () => LayoutProfileRouteRoute,
} as any)

const LayoutProfileMetricsRoute = LayoutProfileMetricsImport.update({
  id: '/metrics',
  path: '/metrics',
  getParentRoute: () => LayoutProfileRouteRoute,
} as any)

const LayoutProfileMessageTemplatesRoute =
  LayoutProfileMessageTemplatesImport.update({
    id: '/message-templates',
    path: '/message-templates',
    getParentRoute: () => LayoutProfileRouteRoute,
  } as any)

const LayoutProfileCompensationRoute = LayoutProfileCompensationImport.update({
  id: '/compensation',
  path: '/compensation',
  getParentRoute: () => LayoutProfileRouteRoute,
} as any)

const LayoutProfileClientLoadRoute = LayoutProfileClientLoadImport.update({
  id: '/client-load',
  path: '/client-load',
  getParentRoute: () => LayoutProfileRouteRoute,
} as any)

const LayoutProfileCalendarRoute = LayoutProfileCalendarImport.update({
  id: '/calendar',
  path: '/calendar',
  getParentRoute: () => LayoutProfileRouteRoute,
} as any)

const LayoutAuditsAuditIdRoute = LayoutAuditsAuditIdImport.update({
  id: '/$auditId',
  path: '/$auditId',
  getParentRoute: () => LayoutAuditsRoute,
} as any)

const LayoutClientsUserIdRouteRoute = LayoutClientsUserIdRouteImport.update({
  id: '/clients/$userId',
  path: '/clients/$userId',
  getParentRoute: () => LayoutRouteRoute,
} as any)

const LayoutClientsUserIdIndexRoute = LayoutClientsUserIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutClientsUserIdRouteRoute,
} as any)

const LayoutAdminTicketsTechissueLazyRoute =
  LayoutAdminTicketsTechissueLazyImport.update({
    id: '/tickets/tech_issue',
    path: '/tickets/tech_issue',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/tickets.tech_issue.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminTicketsCustomersupportLazyRoute =
  LayoutAdminTicketsCustomersupportLazyImport.update({
    id: '/tickets/customer_support',
    path: '/tickets/customer_support',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/tickets.customer_support.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminProductMetricsProductMetricIdLazyRoute =
  LayoutAdminProductMetricsProductMetricIdLazyImport.update({
    id: '/$productMetricId',
    path: '/$productMetricId',
    getParentRoute: () => LayoutAdminProductMetricsLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/product-metrics.$productMetricId.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminCompanyVacationsLazyRoute =
  LayoutAdminCompanyVacationsLazyImport.update({
    id: '/company/vacations',
    path: '/company/vacations',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/company.vacations.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminCompanyTeamLazyRoute = LayoutAdminCompanyTeamLazyImport.update(
  {
    id: '/company/team',
    path: '/company/team',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any,
).lazy(() =>
  import('./routes/_layout/admin/company.team.lazy').then((d) => d.Route),
)

const LayoutAdminCompanySettingsLazyRoute =
  LayoutAdminCompanySettingsLazyImport.update({
    id: '/company/settings',
    path: '/company/settings',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/company.settings.lazy').then((d) => d.Route),
  )

const LayoutAdminCompanyMetricsLazyRoute =
  LayoutAdminCompanyMetricsLazyImport.update({
    id: '/company/metrics',
    path: '/company/metrics',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/company.metrics.lazy').then((d) => d.Route),
  )

const LayoutAdminCompanyClientsLazyRoute =
  LayoutAdminCompanyClientsLazyImport.update({
    id: '/company/clients',
    path: '/company/clients',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/company.clients.lazy').then((d) => d.Route),
  )

const LayoutAdminCompanyCandidatesLazyRoute =
  LayoutAdminCompanyCandidatesLazyImport.update({
    id: '/company/candidates',
    path: '/company/candidates',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/company.candidates.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminCampaignsCampaignIdLazyRoute =
  LayoutAdminCampaignsCampaignIdLazyImport.update({
    id: '/$campaignId',
    path: '/$campaignId',
    getParentRoute: () => LayoutAdminCampaignsLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/campaigns.$campaignId.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutTemplateLibraryTagsTagIdRoute =
  LayoutTemplateLibraryTagsTagIdImport.update({
    id: '/tags/$tagId',
    path: '/tags/$tagId',
    getParentRoute: () => LayoutTemplateLibraryRouteRoute,
  } as any)

const LayoutTemplateLibraryFoldersPhaseTemplateFolderIdRoute =
  LayoutTemplateLibraryFoldersPhaseTemplateFolderIdImport.update({
    id: '/folders/$phaseTemplateFolderId',
    path: '/folders/$phaseTemplateFolderId',
    getParentRoute: () => LayoutTemplateLibraryRouteRoute,
  } as any)

const LayoutAdminTestsTestIdRoute = LayoutAdminTestsTestIdImport.update({
  id: '/$testId',
  path: '/$testId',
  getParentRoute: () => LayoutAdminTestsLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout/admin/tests.$testId.lazy').then((d) => d.Route),
)

const LayoutAdminInfluencersInfluencerIdRoute =
  LayoutAdminInfluencersInfluencerIdImport.update({
    id: '/$influencerId',
    path: '/$influencerId',
    getParentRoute: () => LayoutAdminInfluencersLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/influencers.$influencerId.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminTicketsAllTicketIdLazyRoute =
  LayoutAdminTicketsAllTicketIdLazyImport.update({
    id: '/tickets/all/$ticketId',
    path: '/tickets/all/$ticketId',
    getParentRoute: () => LayoutAdminRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/tickets.all.$ticketId.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminCompanyCandidatesTrainerIdLazyRoute =
  LayoutAdminCompanyCandidatesTrainerIdLazyImport.update({
    id: '/$trainerId',
    path: '/$trainerId',
    getParentRoute: () => LayoutAdminCompanyCandidatesLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/company.candidates.$trainerId.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutClientsUserIdWorkoutsWorkoutIdRoute =
  LayoutClientsUserIdWorkoutsWorkoutIdImport.update({
    id: '/workouts/$workoutId',
    path: '/workouts/$workoutId',
    getParentRoute: () => LayoutClientsUserIdRouteRoute,
  } as any)

const LayoutClientsUserIdLogsLogIdRoute =
  LayoutClientsUserIdLogsLogIdImport.update({
    id: '/logs/$logId',
    path: '/logs/$logId',
    getParentRoute: () => LayoutClientsUserIdRouteRoute,
  } as any)

const LayoutAdminCompanyTeamTrainerIdRoute =
  LayoutAdminCompanyTeamTrainerIdImport.update({
    id: '/$trainerId',
    path: '/$trainerId',
    getParentRoute: () => LayoutAdminCompanyTeamLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/company.team.$trainerId.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdminCompanyClientsUserIdRoute =
  LayoutAdminCompanyClientsUserIdImport.update({
    id: '/$userId',
    path: '/$userId',
    getParentRoute: () => LayoutAdminCompanyClientsLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout/admin/company.clients.$userId.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutRouteImport
      parentRoute: typeof rootRoute
    }
    '/access-denied': {
      id: '/access-denied'
      path: '/access-denied'
      fullPath: '/access-denied'
      preLoaderRoute: typeof AccessDeniedImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/_layout/profile': {
      id: '/_layout/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof LayoutProfileRouteImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/template-library': {
      id: '/_layout/template-library'
      path: '/template-library'
      fullPath: '/template-library'
      preLoaderRoute: typeof LayoutTemplateLibraryRouteImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/audits': {
      id: '/_layout/audits'
      path: '/audits'
      fullPath: '/audits'
      preLoaderRoute: typeof LayoutAuditsImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/chat': {
      id: '/_layout/chat'
      path: '/chat'
      fullPath: '/chat'
      preLoaderRoute: typeof LayoutChatImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/exit-ghost-mode': {
      id: '/_layout/exit-ghost-mode'
      path: '/exit-ghost-mode'
      fullPath: '/exit-ghost-mode'
      preLoaderRoute: typeof LayoutExitGhostModeImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/setup-account': {
      id: '/_layout/setup-account'
      path: '/setup-account'
      fullPath: '/setup-account'
      preLoaderRoute: typeof LayoutSetupAccountImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/admin': {
      id: '/_layout/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof LayoutAdminRouteLazyImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/': {
      id: '/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutIndexImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/clients/$userId': {
      id: '/_layout/clients/$userId'
      path: '/clients/$userId'
      fullPath: '/clients/$userId'
      preLoaderRoute: typeof LayoutClientsUserIdRouteImport
      parentRoute: typeof LayoutRouteImport
    }
    '/_layout/audits/$auditId': {
      id: '/_layout/audits/$auditId'
      path: '/$auditId'
      fullPath: '/audits/$auditId'
      preLoaderRoute: typeof LayoutAuditsAuditIdImport
      parentRoute: typeof LayoutAuditsImport
    }
    '/_layout/profile/calendar': {
      id: '/_layout/profile/calendar'
      path: '/calendar'
      fullPath: '/profile/calendar'
      preLoaderRoute: typeof LayoutProfileCalendarImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/profile/client-load': {
      id: '/_layout/profile/client-load'
      path: '/client-load'
      fullPath: '/profile/client-load'
      preLoaderRoute: typeof LayoutProfileClientLoadImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/profile/compensation': {
      id: '/_layout/profile/compensation'
      path: '/compensation'
      fullPath: '/profile/compensation'
      preLoaderRoute: typeof LayoutProfileCompensationImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/profile/message-templates': {
      id: '/_layout/profile/message-templates'
      path: '/message-templates'
      fullPath: '/profile/message-templates'
      preLoaderRoute: typeof LayoutProfileMessageTemplatesImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/profile/metrics': {
      id: '/_layout/profile/metrics'
      path: '/metrics'
      fullPath: '/profile/metrics'
      preLoaderRoute: typeof LayoutProfileMetricsImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/profile/nice-messages': {
      id: '/_layout/profile/nice-messages'
      path: '/nice-messages'
      fullPath: '/profile/nice-messages'
      preLoaderRoute: typeof LayoutProfileNiceMessagesImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/profile/note-templates': {
      id: '/_layout/profile/note-templates'
      path: '/note-templates'
      fullPath: '/profile/note-templates'
      preLoaderRoute: typeof LayoutProfileNoteTemplatesImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/profile/settings': {
      id: '/_layout/profile/settings'
      path: '/settings'
      fullPath: '/profile/settings'
      preLoaderRoute: typeof LayoutProfileSettingsImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/profile/vacations': {
      id: '/_layout/profile/vacations'
      path: '/vacations'
      fullPath: '/profile/vacations'
      preLoaderRoute: typeof LayoutProfileVacationsImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/admin/api-docs-nodejs': {
      id: '/_layout/admin/api-docs-nodejs'
      path: '/api-docs-nodejs'
      fullPath: '/admin/api-docs-nodejs'
      preLoaderRoute: typeof LayoutAdminApiDocsNodejsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/api-docs-python': {
      id: '/_layout/admin/api-docs-python'
      path: '/api-docs-python'
      fullPath: '/admin/api-docs-python'
      preLoaderRoute: typeof LayoutAdminApiDocsPythonLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/audit-cohorts': {
      id: '/_layout/admin/audit-cohorts'
      path: '/audit-cohorts'
      fullPath: '/admin/audit-cohorts'
      preLoaderRoute: typeof LayoutAdminAuditCohortsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/audit-messaging': {
      id: '/_layout/admin/audit-messaging'
      path: '/audit-messaging'
      fullPath: '/admin/audit-messaging'
      preLoaderRoute: typeof LayoutAdminAuditMessagingLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/audit-programming': {
      id: '/_layout/admin/audit-programming'
      path: '/audit-programming'
      fullPath: '/admin/audit-programming'
      preLoaderRoute: typeof LayoutAdminAuditProgrammingLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/campaigns': {
      id: '/_layout/admin/campaigns'
      path: '/campaigns'
      fullPath: '/admin/campaigns'
      preLoaderRoute: typeof LayoutAdminCampaignsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/client-funnel': {
      id: '/_layout/admin/client-funnel'
      path: '/client-funnel'
      fullPath: '/admin/client-funnel'
      preLoaderRoute: typeof LayoutAdminClientFunnelLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/data-cleaner': {
      id: '/_layout/admin/data-cleaner'
      path: '/data-cleaner'
      fullPath: '/admin/data-cleaner'
      preLoaderRoute: typeof LayoutAdminDataCleanerLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/influencers': {
      id: '/_layout/admin/influencers'
      path: '/influencers'
      fullPath: '/admin/influencers'
      preLoaderRoute: typeof LayoutAdminInfluencersLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/lead-funnel': {
      id: '/_layout/admin/lead-funnel'
      path: '/lead-funnel'
      fullPath: '/admin/lead-funnel'
      preLoaderRoute: typeof LayoutAdminLeadFunnelLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/product-metrics': {
      id: '/_layout/admin/product-metrics'
      path: '/product-metrics'
      fullPath: '/admin/product-metrics'
      preLoaderRoute: typeof LayoutAdminProductMetricsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/random-clients': {
      id: '/_layout/admin/random-clients'
      path: '/random-clients'
      fullPath: '/admin/random-clients'
      preLoaderRoute: typeof LayoutAdminRandomClientsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/short-urls': {
      id: '/_layout/admin/short-urls'
      path: '/short-urls'
      fullPath: '/admin/short-urls'
      preLoaderRoute: typeof LayoutAdminShortUrlsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/tests': {
      id: '/_layout/admin/tests'
      path: '/tests'
      fullPath: '/admin/tests'
      preLoaderRoute: typeof LayoutAdminTestsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/trainer-availability': {
      id: '/_layout/admin/trainer-availability'
      path: '/trainer-availability'
      fullPath: '/admin/trainer-availability'
      preLoaderRoute: typeof LayoutAdminTrainerAvailabilityLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/trainer-metrics': {
      id: '/_layout/admin/trainer-metrics'
      path: '/trainer-metrics'
      fullPath: '/admin/trainer-metrics'
      preLoaderRoute: typeof LayoutAdminTrainerMetricsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/vacation-budget': {
      id: '/_layout/admin/vacation-budget'
      path: '/vacation-budget'
      fullPath: '/admin/vacation-budget'
      preLoaderRoute: typeof LayoutAdminVacationBudgetLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/vacations': {
      id: '/_layout/admin/vacations'
      path: '/vacations'
      fullPath: '/admin/vacations'
      preLoaderRoute: typeof LayoutAdminVacationsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/profile/': {
      id: '/_layout/profile/'
      path: '/'
      fullPath: '/profile/'
      preLoaderRoute: typeof LayoutProfileIndexImport
      parentRoute: typeof LayoutProfileRouteImport
    }
    '/_layout/template-library/': {
      id: '/_layout/template-library/'
      path: '/'
      fullPath: '/template-library/'
      preLoaderRoute: typeof LayoutTemplateLibraryIndexImport
      parentRoute: typeof LayoutTemplateLibraryRouteImport
    }
    '/_layout/admin/': {
      id: '/_layout/admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof LayoutAdminIndexLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/influencers/$influencerId': {
      id: '/_layout/admin/influencers/$influencerId'
      path: '/$influencerId'
      fullPath: '/admin/influencers/$influencerId'
      preLoaderRoute: typeof LayoutAdminInfluencersInfluencerIdImport
      parentRoute: typeof LayoutAdminInfluencersLazyImport
    }
    '/_layout/admin/tests/$testId': {
      id: '/_layout/admin/tests/$testId'
      path: '/$testId'
      fullPath: '/admin/tests/$testId'
      preLoaderRoute: typeof LayoutAdminTestsTestIdImport
      parentRoute: typeof LayoutAdminTestsLazyImport
    }
    '/_layout/template-library/folders/$phaseTemplateFolderId': {
      id: '/_layout/template-library/folders/$phaseTemplateFolderId'
      path: '/folders/$phaseTemplateFolderId'
      fullPath: '/template-library/folders/$phaseTemplateFolderId'
      preLoaderRoute: typeof LayoutTemplateLibraryFoldersPhaseTemplateFolderIdImport
      parentRoute: typeof LayoutTemplateLibraryRouteImport
    }
    '/_layout/template-library/tags/$tagId': {
      id: '/_layout/template-library/tags/$tagId'
      path: '/tags/$tagId'
      fullPath: '/template-library/tags/$tagId'
      preLoaderRoute: typeof LayoutTemplateLibraryTagsTagIdImport
      parentRoute: typeof LayoutTemplateLibraryRouteImport
    }
    '/_layout/admin/campaigns/$campaignId': {
      id: '/_layout/admin/campaigns/$campaignId'
      path: '/$campaignId'
      fullPath: '/admin/campaigns/$campaignId'
      preLoaderRoute: typeof LayoutAdminCampaignsCampaignIdLazyImport
      parentRoute: typeof LayoutAdminCampaignsLazyImport
    }
    '/_layout/admin/company/candidates': {
      id: '/_layout/admin/company/candidates'
      path: '/company/candidates'
      fullPath: '/admin/company/candidates'
      preLoaderRoute: typeof LayoutAdminCompanyCandidatesLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/company/clients': {
      id: '/_layout/admin/company/clients'
      path: '/company/clients'
      fullPath: '/admin/company/clients'
      preLoaderRoute: typeof LayoutAdminCompanyClientsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/company/metrics': {
      id: '/_layout/admin/company/metrics'
      path: '/company/metrics'
      fullPath: '/admin/company/metrics'
      preLoaderRoute: typeof LayoutAdminCompanyMetricsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/company/settings': {
      id: '/_layout/admin/company/settings'
      path: '/company/settings'
      fullPath: '/admin/company/settings'
      preLoaderRoute: typeof LayoutAdminCompanySettingsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/company/team': {
      id: '/_layout/admin/company/team'
      path: '/company/team'
      fullPath: '/admin/company/team'
      preLoaderRoute: typeof LayoutAdminCompanyTeamLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/company/vacations': {
      id: '/_layout/admin/company/vacations'
      path: '/company/vacations'
      fullPath: '/admin/company/vacations'
      preLoaderRoute: typeof LayoutAdminCompanyVacationsLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/product-metrics/$productMetricId': {
      id: '/_layout/admin/product-metrics/$productMetricId'
      path: '/$productMetricId'
      fullPath: '/admin/product-metrics/$productMetricId'
      preLoaderRoute: typeof LayoutAdminProductMetricsProductMetricIdLazyImport
      parentRoute: typeof LayoutAdminProductMetricsLazyImport
    }
    '/_layout/admin/tickets/customer_support': {
      id: '/_layout/admin/tickets/customer_support'
      path: '/tickets/customer_support'
      fullPath: '/admin/tickets/customer_support'
      preLoaderRoute: typeof LayoutAdminTicketsCustomersupportLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/admin/tickets/tech_issue': {
      id: '/_layout/admin/tickets/tech_issue'
      path: '/tickets/tech_issue'
      fullPath: '/admin/tickets/tech_issue'
      preLoaderRoute: typeof LayoutAdminTicketsTechissueLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
    '/_layout/clients/$userId/': {
      id: '/_layout/clients/$userId/'
      path: '/'
      fullPath: '/clients/$userId/'
      preLoaderRoute: typeof LayoutClientsUserIdIndexImport
      parentRoute: typeof LayoutClientsUserIdRouteImport
    }
    '/_layout/admin/company/clients/$userId': {
      id: '/_layout/admin/company/clients/$userId'
      path: '/$userId'
      fullPath: '/admin/company/clients/$userId'
      preLoaderRoute: typeof LayoutAdminCompanyClientsUserIdImport
      parentRoute: typeof LayoutAdminCompanyClientsLazyImport
    }
    '/_layout/admin/company/team/$trainerId': {
      id: '/_layout/admin/company/team/$trainerId'
      path: '/$trainerId'
      fullPath: '/admin/company/team/$trainerId'
      preLoaderRoute: typeof LayoutAdminCompanyTeamTrainerIdImport
      parentRoute: typeof LayoutAdminCompanyTeamLazyImport
    }
    '/_layout/clients/$userId/logs/$logId': {
      id: '/_layout/clients/$userId/logs/$logId'
      path: '/logs/$logId'
      fullPath: '/clients/$userId/logs/$logId'
      preLoaderRoute: typeof LayoutClientsUserIdLogsLogIdImport
      parentRoute: typeof LayoutClientsUserIdRouteImport
    }
    '/_layout/clients/$userId/workouts/$workoutId': {
      id: '/_layout/clients/$userId/workouts/$workoutId'
      path: '/workouts/$workoutId'
      fullPath: '/clients/$userId/workouts/$workoutId'
      preLoaderRoute: typeof LayoutClientsUserIdWorkoutsWorkoutIdImport
      parentRoute: typeof LayoutClientsUserIdRouteImport
    }
    '/_layout/admin/company/candidates/$trainerId': {
      id: '/_layout/admin/company/candidates/$trainerId'
      path: '/$trainerId'
      fullPath: '/admin/company/candidates/$trainerId'
      preLoaderRoute: typeof LayoutAdminCompanyCandidatesTrainerIdLazyImport
      parentRoute: typeof LayoutAdminCompanyCandidatesLazyImport
    }
    '/_layout/admin/tickets/all/$ticketId': {
      id: '/_layout/admin/tickets/all/$ticketId'
      path: '/tickets/all/$ticketId'
      fullPath: '/admin/tickets/all/$ticketId'
      preLoaderRoute: typeof LayoutAdminTicketsAllTicketIdLazyImport
      parentRoute: typeof LayoutAdminRouteLazyImport
    }
  }
}

// Create and export the route tree

interface LayoutProfileRouteRouteChildren {
  LayoutProfileCalendarRoute: typeof LayoutProfileCalendarRoute
  LayoutProfileClientLoadRoute: typeof LayoutProfileClientLoadRoute
  LayoutProfileCompensationRoute: typeof LayoutProfileCompensationRoute
  LayoutProfileMessageTemplatesRoute: typeof LayoutProfileMessageTemplatesRoute
  LayoutProfileMetricsRoute: typeof LayoutProfileMetricsRoute
  LayoutProfileNiceMessagesRoute: typeof LayoutProfileNiceMessagesRoute
  LayoutProfileNoteTemplatesRoute: typeof LayoutProfileNoteTemplatesRoute
  LayoutProfileSettingsRoute: typeof LayoutProfileSettingsRoute
  LayoutProfileVacationsRoute: typeof LayoutProfileVacationsRoute
  LayoutProfileIndexRoute: typeof LayoutProfileIndexRoute
}

const LayoutProfileRouteRouteChildren: LayoutProfileRouteRouteChildren = {
  LayoutProfileCalendarRoute: LayoutProfileCalendarRoute,
  LayoutProfileClientLoadRoute: LayoutProfileClientLoadRoute,
  LayoutProfileCompensationRoute: LayoutProfileCompensationRoute,
  LayoutProfileMessageTemplatesRoute: LayoutProfileMessageTemplatesRoute,
  LayoutProfileMetricsRoute: LayoutProfileMetricsRoute,
  LayoutProfileNiceMessagesRoute: LayoutProfileNiceMessagesRoute,
  LayoutProfileNoteTemplatesRoute: LayoutProfileNoteTemplatesRoute,
  LayoutProfileSettingsRoute: LayoutProfileSettingsRoute,
  LayoutProfileVacationsRoute: LayoutProfileVacationsRoute,
  LayoutProfileIndexRoute: LayoutProfileIndexRoute,
}

const LayoutProfileRouteRouteWithChildren =
  LayoutProfileRouteRoute._addFileChildren(LayoutProfileRouteRouteChildren)

interface LayoutTemplateLibraryRouteRouteChildren {
  LayoutTemplateLibraryIndexRoute: typeof LayoutTemplateLibraryIndexRoute
  LayoutTemplateLibraryFoldersPhaseTemplateFolderIdRoute: typeof LayoutTemplateLibraryFoldersPhaseTemplateFolderIdRoute
  LayoutTemplateLibraryTagsTagIdRoute: typeof LayoutTemplateLibraryTagsTagIdRoute
}

const LayoutTemplateLibraryRouteRouteChildren: LayoutTemplateLibraryRouteRouteChildren =
  {
    LayoutTemplateLibraryIndexRoute: LayoutTemplateLibraryIndexRoute,
    LayoutTemplateLibraryFoldersPhaseTemplateFolderIdRoute:
      LayoutTemplateLibraryFoldersPhaseTemplateFolderIdRoute,
    LayoutTemplateLibraryTagsTagIdRoute: LayoutTemplateLibraryTagsTagIdRoute,
  }

const LayoutTemplateLibraryRouteRouteWithChildren =
  LayoutTemplateLibraryRouteRoute._addFileChildren(
    LayoutTemplateLibraryRouteRouteChildren,
  )

interface LayoutAuditsRouteChildren {
  LayoutAuditsAuditIdRoute: typeof LayoutAuditsAuditIdRoute
}

const LayoutAuditsRouteChildren: LayoutAuditsRouteChildren = {
  LayoutAuditsAuditIdRoute: LayoutAuditsAuditIdRoute,
}

const LayoutAuditsRouteWithChildren = LayoutAuditsRoute._addFileChildren(
  LayoutAuditsRouteChildren,
)

interface LayoutAdminCampaignsLazyRouteChildren {
  LayoutAdminCampaignsCampaignIdLazyRoute: typeof LayoutAdminCampaignsCampaignIdLazyRoute
}

const LayoutAdminCampaignsLazyRouteChildren: LayoutAdminCampaignsLazyRouteChildren =
  {
    LayoutAdminCampaignsCampaignIdLazyRoute:
      LayoutAdminCampaignsCampaignIdLazyRoute,
  }

const LayoutAdminCampaignsLazyRouteWithChildren =
  LayoutAdminCampaignsLazyRoute._addFileChildren(
    LayoutAdminCampaignsLazyRouteChildren,
  )

interface LayoutAdminInfluencersLazyRouteChildren {
  LayoutAdminInfluencersInfluencerIdRoute: typeof LayoutAdminInfluencersInfluencerIdRoute
}

const LayoutAdminInfluencersLazyRouteChildren: LayoutAdminInfluencersLazyRouteChildren =
  {
    LayoutAdminInfluencersInfluencerIdRoute:
      LayoutAdminInfluencersInfluencerIdRoute,
  }

const LayoutAdminInfluencersLazyRouteWithChildren =
  LayoutAdminInfluencersLazyRoute._addFileChildren(
    LayoutAdminInfluencersLazyRouteChildren,
  )

interface LayoutAdminProductMetricsLazyRouteChildren {
  LayoutAdminProductMetricsProductMetricIdLazyRoute: typeof LayoutAdminProductMetricsProductMetricIdLazyRoute
}

const LayoutAdminProductMetricsLazyRouteChildren: LayoutAdminProductMetricsLazyRouteChildren =
  {
    LayoutAdminProductMetricsProductMetricIdLazyRoute:
      LayoutAdminProductMetricsProductMetricIdLazyRoute,
  }

const LayoutAdminProductMetricsLazyRouteWithChildren =
  LayoutAdminProductMetricsLazyRoute._addFileChildren(
    LayoutAdminProductMetricsLazyRouteChildren,
  )

interface LayoutAdminTestsLazyRouteChildren {
  LayoutAdminTestsTestIdRoute: typeof LayoutAdminTestsTestIdRoute
}

const LayoutAdminTestsLazyRouteChildren: LayoutAdminTestsLazyRouteChildren = {
  LayoutAdminTestsTestIdRoute: LayoutAdminTestsTestIdRoute,
}

const LayoutAdminTestsLazyRouteWithChildren =
  LayoutAdminTestsLazyRoute._addFileChildren(LayoutAdminTestsLazyRouteChildren)

interface LayoutAdminCompanyCandidatesLazyRouteChildren {
  LayoutAdminCompanyCandidatesTrainerIdLazyRoute: typeof LayoutAdminCompanyCandidatesTrainerIdLazyRoute
}

const LayoutAdminCompanyCandidatesLazyRouteChildren: LayoutAdminCompanyCandidatesLazyRouteChildren =
  {
    LayoutAdminCompanyCandidatesTrainerIdLazyRoute:
      LayoutAdminCompanyCandidatesTrainerIdLazyRoute,
  }

const LayoutAdminCompanyCandidatesLazyRouteWithChildren =
  LayoutAdminCompanyCandidatesLazyRoute._addFileChildren(
    LayoutAdminCompanyCandidatesLazyRouteChildren,
  )

interface LayoutAdminCompanyClientsLazyRouteChildren {
  LayoutAdminCompanyClientsUserIdRoute: typeof LayoutAdminCompanyClientsUserIdRoute
}

const LayoutAdminCompanyClientsLazyRouteChildren: LayoutAdminCompanyClientsLazyRouteChildren =
  {
    LayoutAdminCompanyClientsUserIdRoute: LayoutAdminCompanyClientsUserIdRoute,
  }

const LayoutAdminCompanyClientsLazyRouteWithChildren =
  LayoutAdminCompanyClientsLazyRoute._addFileChildren(
    LayoutAdminCompanyClientsLazyRouteChildren,
  )

interface LayoutAdminCompanyTeamLazyRouteChildren {
  LayoutAdminCompanyTeamTrainerIdRoute: typeof LayoutAdminCompanyTeamTrainerIdRoute
}

const LayoutAdminCompanyTeamLazyRouteChildren: LayoutAdminCompanyTeamLazyRouteChildren =
  {
    LayoutAdminCompanyTeamTrainerIdRoute: LayoutAdminCompanyTeamTrainerIdRoute,
  }

const LayoutAdminCompanyTeamLazyRouteWithChildren =
  LayoutAdminCompanyTeamLazyRoute._addFileChildren(
    LayoutAdminCompanyTeamLazyRouteChildren,
  )

interface LayoutAdminRouteLazyRouteChildren {
  LayoutAdminApiDocsNodejsLazyRoute: typeof LayoutAdminApiDocsNodejsLazyRoute
  LayoutAdminApiDocsPythonLazyRoute: typeof LayoutAdminApiDocsPythonLazyRoute
  LayoutAdminAuditCohortsLazyRoute: typeof LayoutAdminAuditCohortsLazyRoute
  LayoutAdminAuditMessagingLazyRoute: typeof LayoutAdminAuditMessagingLazyRoute
  LayoutAdminAuditProgrammingLazyRoute: typeof LayoutAdminAuditProgrammingLazyRoute
  LayoutAdminCampaignsLazyRoute: typeof LayoutAdminCampaignsLazyRouteWithChildren
  LayoutAdminClientFunnelLazyRoute: typeof LayoutAdminClientFunnelLazyRoute
  LayoutAdminDataCleanerLazyRoute: typeof LayoutAdminDataCleanerLazyRoute
  LayoutAdminInfluencersLazyRoute: typeof LayoutAdminInfluencersLazyRouteWithChildren
  LayoutAdminLeadFunnelLazyRoute: typeof LayoutAdminLeadFunnelLazyRoute
  LayoutAdminProductMetricsLazyRoute: typeof LayoutAdminProductMetricsLazyRouteWithChildren
  LayoutAdminRandomClientsLazyRoute: typeof LayoutAdminRandomClientsLazyRoute
  LayoutAdminShortUrlsLazyRoute: typeof LayoutAdminShortUrlsLazyRoute
  LayoutAdminTestsLazyRoute: typeof LayoutAdminTestsLazyRouteWithChildren
  LayoutAdminTrainerAvailabilityLazyRoute: typeof LayoutAdminTrainerAvailabilityLazyRoute
  LayoutAdminTrainerMetricsLazyRoute: typeof LayoutAdminTrainerMetricsLazyRoute
  LayoutAdminVacationBudgetLazyRoute: typeof LayoutAdminVacationBudgetLazyRoute
  LayoutAdminVacationsLazyRoute: typeof LayoutAdminVacationsLazyRoute
  LayoutAdminIndexLazyRoute: typeof LayoutAdminIndexLazyRoute
  LayoutAdminCompanyCandidatesLazyRoute: typeof LayoutAdminCompanyCandidatesLazyRouteWithChildren
  LayoutAdminCompanyClientsLazyRoute: typeof LayoutAdminCompanyClientsLazyRouteWithChildren
  LayoutAdminCompanyMetricsLazyRoute: typeof LayoutAdminCompanyMetricsLazyRoute
  LayoutAdminCompanySettingsLazyRoute: typeof LayoutAdminCompanySettingsLazyRoute
  LayoutAdminCompanyTeamLazyRoute: typeof LayoutAdminCompanyTeamLazyRouteWithChildren
  LayoutAdminCompanyVacationsLazyRoute: typeof LayoutAdminCompanyVacationsLazyRoute
  LayoutAdminTicketsCustomersupportLazyRoute: typeof LayoutAdminTicketsCustomersupportLazyRoute
  LayoutAdminTicketsTechissueLazyRoute: typeof LayoutAdminTicketsTechissueLazyRoute
  LayoutAdminTicketsAllTicketIdLazyRoute: typeof LayoutAdminTicketsAllTicketIdLazyRoute
}

const LayoutAdminRouteLazyRouteChildren: LayoutAdminRouteLazyRouteChildren = {
  LayoutAdminApiDocsNodejsLazyRoute: LayoutAdminApiDocsNodejsLazyRoute,
  LayoutAdminApiDocsPythonLazyRoute: LayoutAdminApiDocsPythonLazyRoute,
  LayoutAdminAuditCohortsLazyRoute: LayoutAdminAuditCohortsLazyRoute,
  LayoutAdminAuditMessagingLazyRoute: LayoutAdminAuditMessagingLazyRoute,
  LayoutAdminAuditProgrammingLazyRoute: LayoutAdminAuditProgrammingLazyRoute,
  LayoutAdminCampaignsLazyRoute: LayoutAdminCampaignsLazyRouteWithChildren,
  LayoutAdminClientFunnelLazyRoute: LayoutAdminClientFunnelLazyRoute,
  LayoutAdminDataCleanerLazyRoute: LayoutAdminDataCleanerLazyRoute,
  LayoutAdminInfluencersLazyRoute: LayoutAdminInfluencersLazyRouteWithChildren,
  LayoutAdminLeadFunnelLazyRoute: LayoutAdminLeadFunnelLazyRoute,
  LayoutAdminProductMetricsLazyRoute:
    LayoutAdminProductMetricsLazyRouteWithChildren,
  LayoutAdminRandomClientsLazyRoute: LayoutAdminRandomClientsLazyRoute,
  LayoutAdminShortUrlsLazyRoute: LayoutAdminShortUrlsLazyRoute,
  LayoutAdminTestsLazyRoute: LayoutAdminTestsLazyRouteWithChildren,
  LayoutAdminTrainerAvailabilityLazyRoute:
    LayoutAdminTrainerAvailabilityLazyRoute,
  LayoutAdminTrainerMetricsLazyRoute: LayoutAdminTrainerMetricsLazyRoute,
  LayoutAdminVacationBudgetLazyRoute: LayoutAdminVacationBudgetLazyRoute,
  LayoutAdminVacationsLazyRoute: LayoutAdminVacationsLazyRoute,
  LayoutAdminIndexLazyRoute: LayoutAdminIndexLazyRoute,
  LayoutAdminCompanyCandidatesLazyRoute:
    LayoutAdminCompanyCandidatesLazyRouteWithChildren,
  LayoutAdminCompanyClientsLazyRoute:
    LayoutAdminCompanyClientsLazyRouteWithChildren,
  LayoutAdminCompanyMetricsLazyRoute: LayoutAdminCompanyMetricsLazyRoute,
  LayoutAdminCompanySettingsLazyRoute: LayoutAdminCompanySettingsLazyRoute,
  LayoutAdminCompanyTeamLazyRoute: LayoutAdminCompanyTeamLazyRouteWithChildren,
  LayoutAdminCompanyVacationsLazyRoute: LayoutAdminCompanyVacationsLazyRoute,
  LayoutAdminTicketsCustomersupportLazyRoute:
    LayoutAdminTicketsCustomersupportLazyRoute,
  LayoutAdminTicketsTechissueLazyRoute: LayoutAdminTicketsTechissueLazyRoute,
  LayoutAdminTicketsAllTicketIdLazyRoute:
    LayoutAdminTicketsAllTicketIdLazyRoute,
}

const LayoutAdminRouteLazyRouteWithChildren =
  LayoutAdminRouteLazyRoute._addFileChildren(LayoutAdminRouteLazyRouteChildren)

interface LayoutClientsUserIdRouteRouteChildren {
  LayoutClientsUserIdIndexRoute: typeof LayoutClientsUserIdIndexRoute
  LayoutClientsUserIdLogsLogIdRoute: typeof LayoutClientsUserIdLogsLogIdRoute
  LayoutClientsUserIdWorkoutsWorkoutIdRoute: typeof LayoutClientsUserIdWorkoutsWorkoutIdRoute
}

const LayoutClientsUserIdRouteRouteChildren: LayoutClientsUserIdRouteRouteChildren =
  {
    LayoutClientsUserIdIndexRoute: LayoutClientsUserIdIndexRoute,
    LayoutClientsUserIdLogsLogIdRoute: LayoutClientsUserIdLogsLogIdRoute,
    LayoutClientsUserIdWorkoutsWorkoutIdRoute:
      LayoutClientsUserIdWorkoutsWorkoutIdRoute,
  }

const LayoutClientsUserIdRouteRouteWithChildren =
  LayoutClientsUserIdRouteRoute._addFileChildren(
    LayoutClientsUserIdRouteRouteChildren,
  )

interface LayoutRouteRouteChildren {
  LayoutProfileRouteRoute: typeof LayoutProfileRouteRouteWithChildren
  LayoutTemplateLibraryRouteRoute: typeof LayoutTemplateLibraryRouteRouteWithChildren
  LayoutAuditsRoute: typeof LayoutAuditsRouteWithChildren
  LayoutChatRoute: typeof LayoutChatRoute
  LayoutExitGhostModeRoute: typeof LayoutExitGhostModeRoute
  LayoutSetupAccountRoute: typeof LayoutSetupAccountRoute
  LayoutAdminRouteLazyRoute: typeof LayoutAdminRouteLazyRouteWithChildren
  LayoutIndexRoute: typeof LayoutIndexRoute
  LayoutClientsUserIdRouteRoute: typeof LayoutClientsUserIdRouteRouteWithChildren
}

const LayoutRouteRouteChildren: LayoutRouteRouteChildren = {
  LayoutProfileRouteRoute: LayoutProfileRouteRouteWithChildren,
  LayoutTemplateLibraryRouteRoute: LayoutTemplateLibraryRouteRouteWithChildren,
  LayoutAuditsRoute: LayoutAuditsRouteWithChildren,
  LayoutChatRoute: LayoutChatRoute,
  LayoutExitGhostModeRoute: LayoutExitGhostModeRoute,
  LayoutSetupAccountRoute: LayoutSetupAccountRoute,
  LayoutAdminRouteLazyRoute: LayoutAdminRouteLazyRouteWithChildren,
  LayoutIndexRoute: LayoutIndexRoute,
  LayoutClientsUserIdRouteRoute: LayoutClientsUserIdRouteRouteWithChildren,
}

const LayoutRouteRouteWithChildren = LayoutRouteRoute._addFileChildren(
  LayoutRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof LayoutRouteRouteWithChildren
  '/access-denied': typeof AccessDeniedRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/profile': typeof LayoutProfileRouteRouteWithChildren
  '/template-library': typeof LayoutTemplateLibraryRouteRouteWithChildren
  '/audits': typeof LayoutAuditsRouteWithChildren
  '/chat': typeof LayoutChatRoute
  '/exit-ghost-mode': typeof LayoutExitGhostModeRoute
  '/setup-account': typeof LayoutSetupAccountRoute
  '/admin': typeof LayoutAdminRouteLazyRouteWithChildren
  '/': typeof LayoutIndexRoute
  '/clients/$userId': typeof LayoutClientsUserIdRouteRouteWithChildren
  '/audits/$auditId': typeof LayoutAuditsAuditIdRoute
  '/profile/calendar': typeof LayoutProfileCalendarRoute
  '/profile/client-load': typeof LayoutProfileClientLoadRoute
  '/profile/compensation': typeof LayoutProfileCompensationRoute
  '/profile/message-templates': typeof LayoutProfileMessageTemplatesRoute
  '/profile/metrics': typeof LayoutProfileMetricsRoute
  '/profile/nice-messages': typeof LayoutProfileNiceMessagesRoute
  '/profile/note-templates': typeof LayoutProfileNoteTemplatesRoute
  '/profile/settings': typeof LayoutProfileSettingsRoute
  '/profile/vacations': typeof LayoutProfileVacationsRoute
  '/admin/api-docs-nodejs': typeof LayoutAdminApiDocsNodejsLazyRoute
  '/admin/api-docs-python': typeof LayoutAdminApiDocsPythonLazyRoute
  '/admin/audit-cohorts': typeof LayoutAdminAuditCohortsLazyRoute
  '/admin/audit-messaging': typeof LayoutAdminAuditMessagingLazyRoute
  '/admin/audit-programming': typeof LayoutAdminAuditProgrammingLazyRoute
  '/admin/campaigns': typeof LayoutAdminCampaignsLazyRouteWithChildren
  '/admin/client-funnel': typeof LayoutAdminClientFunnelLazyRoute
  '/admin/data-cleaner': typeof LayoutAdminDataCleanerLazyRoute
  '/admin/influencers': typeof LayoutAdminInfluencersLazyRouteWithChildren
  '/admin/lead-funnel': typeof LayoutAdminLeadFunnelLazyRoute
  '/admin/product-metrics': typeof LayoutAdminProductMetricsLazyRouteWithChildren
  '/admin/random-clients': typeof LayoutAdminRandomClientsLazyRoute
  '/admin/short-urls': typeof LayoutAdminShortUrlsLazyRoute
  '/admin/tests': typeof LayoutAdminTestsLazyRouteWithChildren
  '/admin/trainer-availability': typeof LayoutAdminTrainerAvailabilityLazyRoute
  '/admin/trainer-metrics': typeof LayoutAdminTrainerMetricsLazyRoute
  '/admin/vacation-budget': typeof LayoutAdminVacationBudgetLazyRoute
  '/admin/vacations': typeof LayoutAdminVacationsLazyRoute
  '/profile/': typeof LayoutProfileIndexRoute
  '/template-library/': typeof LayoutTemplateLibraryIndexRoute
  '/admin/': typeof LayoutAdminIndexLazyRoute
  '/admin/influencers/$influencerId': typeof LayoutAdminInfluencersInfluencerIdRoute
  '/admin/tests/$testId': typeof LayoutAdminTestsTestIdRoute
  '/template-library/folders/$phaseTemplateFolderId': typeof LayoutTemplateLibraryFoldersPhaseTemplateFolderIdRoute
  '/template-library/tags/$tagId': typeof LayoutTemplateLibraryTagsTagIdRoute
  '/admin/campaigns/$campaignId': typeof LayoutAdminCampaignsCampaignIdLazyRoute
  '/admin/company/candidates': typeof LayoutAdminCompanyCandidatesLazyRouteWithChildren
  '/admin/company/clients': typeof LayoutAdminCompanyClientsLazyRouteWithChildren
  '/admin/company/metrics': typeof LayoutAdminCompanyMetricsLazyRoute
  '/admin/company/settings': typeof LayoutAdminCompanySettingsLazyRoute
  '/admin/company/team': typeof LayoutAdminCompanyTeamLazyRouteWithChildren
  '/admin/company/vacations': typeof LayoutAdminCompanyVacationsLazyRoute
  '/admin/product-metrics/$productMetricId': typeof LayoutAdminProductMetricsProductMetricIdLazyRoute
  '/admin/tickets/customer_support': typeof LayoutAdminTicketsCustomersupportLazyRoute
  '/admin/tickets/tech_issue': typeof LayoutAdminTicketsTechissueLazyRoute
  '/clients/$userId/': typeof LayoutClientsUserIdIndexRoute
  '/admin/company/clients/$userId': typeof LayoutAdminCompanyClientsUserIdRoute
  '/admin/company/team/$trainerId': typeof LayoutAdminCompanyTeamTrainerIdRoute
  '/clients/$userId/logs/$logId': typeof LayoutClientsUserIdLogsLogIdRoute
  '/clients/$userId/workouts/$workoutId': typeof LayoutClientsUserIdWorkoutsWorkoutIdRoute
  '/admin/company/candidates/$trainerId': typeof LayoutAdminCompanyCandidatesTrainerIdLazyRoute
  '/admin/tickets/all/$ticketId': typeof LayoutAdminTicketsAllTicketIdLazyRoute
}

export interface FileRoutesByTo {
  '/access-denied': typeof AccessDeniedRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/audits': typeof LayoutAuditsRouteWithChildren
  '/chat': typeof LayoutChatRoute
  '/exit-ghost-mode': typeof LayoutExitGhostModeRoute
  '/setup-account': typeof LayoutSetupAccountRoute
  '/': typeof LayoutIndexRoute
  '/audits/$auditId': typeof LayoutAuditsAuditIdRoute
  '/profile/calendar': typeof LayoutProfileCalendarRoute
  '/profile/client-load': typeof LayoutProfileClientLoadRoute
  '/profile/compensation': typeof LayoutProfileCompensationRoute
  '/profile/message-templates': typeof LayoutProfileMessageTemplatesRoute
  '/profile/metrics': typeof LayoutProfileMetricsRoute
  '/profile/nice-messages': typeof LayoutProfileNiceMessagesRoute
  '/profile/note-templates': typeof LayoutProfileNoteTemplatesRoute
  '/profile/settings': typeof LayoutProfileSettingsRoute
  '/profile/vacations': typeof LayoutProfileVacationsRoute
  '/admin/api-docs-nodejs': typeof LayoutAdminApiDocsNodejsLazyRoute
  '/admin/api-docs-python': typeof LayoutAdminApiDocsPythonLazyRoute
  '/admin/audit-cohorts': typeof LayoutAdminAuditCohortsLazyRoute
  '/admin/audit-messaging': typeof LayoutAdminAuditMessagingLazyRoute
  '/admin/audit-programming': typeof LayoutAdminAuditProgrammingLazyRoute
  '/admin/campaigns': typeof LayoutAdminCampaignsLazyRouteWithChildren
  '/admin/client-funnel': typeof LayoutAdminClientFunnelLazyRoute
  '/admin/data-cleaner': typeof LayoutAdminDataCleanerLazyRoute
  '/admin/influencers': typeof LayoutAdminInfluencersLazyRouteWithChildren
  '/admin/lead-funnel': typeof LayoutAdminLeadFunnelLazyRoute
  '/admin/product-metrics': typeof LayoutAdminProductMetricsLazyRouteWithChildren
  '/admin/random-clients': typeof LayoutAdminRandomClientsLazyRoute
  '/admin/short-urls': typeof LayoutAdminShortUrlsLazyRoute
  '/admin/tests': typeof LayoutAdminTestsLazyRouteWithChildren
  '/admin/trainer-availability': typeof LayoutAdminTrainerAvailabilityLazyRoute
  '/admin/trainer-metrics': typeof LayoutAdminTrainerMetricsLazyRoute
  '/admin/vacation-budget': typeof LayoutAdminVacationBudgetLazyRoute
  '/admin/vacations': typeof LayoutAdminVacationsLazyRoute
  '/profile': typeof LayoutProfileIndexRoute
  '/template-library': typeof LayoutTemplateLibraryIndexRoute
  '/admin': typeof LayoutAdminIndexLazyRoute
  '/admin/influencers/$influencerId': typeof LayoutAdminInfluencersInfluencerIdRoute
  '/admin/tests/$testId': typeof LayoutAdminTestsTestIdRoute
  '/template-library/folders/$phaseTemplateFolderId': typeof LayoutTemplateLibraryFoldersPhaseTemplateFolderIdRoute
  '/template-library/tags/$tagId': typeof LayoutTemplateLibraryTagsTagIdRoute
  '/admin/campaigns/$campaignId': typeof LayoutAdminCampaignsCampaignIdLazyRoute
  '/admin/company/candidates': typeof LayoutAdminCompanyCandidatesLazyRouteWithChildren
  '/admin/company/clients': typeof LayoutAdminCompanyClientsLazyRouteWithChildren
  '/admin/company/metrics': typeof LayoutAdminCompanyMetricsLazyRoute
  '/admin/company/settings': typeof LayoutAdminCompanySettingsLazyRoute
  '/admin/company/team': typeof LayoutAdminCompanyTeamLazyRouteWithChildren
  '/admin/company/vacations': typeof LayoutAdminCompanyVacationsLazyRoute
  '/admin/product-metrics/$productMetricId': typeof LayoutAdminProductMetricsProductMetricIdLazyRoute
  '/admin/tickets/customer_support': typeof LayoutAdminTicketsCustomersupportLazyRoute
  '/admin/tickets/tech_issue': typeof LayoutAdminTicketsTechissueLazyRoute
  '/clients/$userId': typeof LayoutClientsUserIdIndexRoute
  '/admin/company/clients/$userId': typeof LayoutAdminCompanyClientsUserIdRoute
  '/admin/company/team/$trainerId': typeof LayoutAdminCompanyTeamTrainerIdRoute
  '/clients/$userId/logs/$logId': typeof LayoutClientsUserIdLogsLogIdRoute
  '/clients/$userId/workouts/$workoutId': typeof LayoutClientsUserIdWorkoutsWorkoutIdRoute
  '/admin/company/candidates/$trainerId': typeof LayoutAdminCompanyCandidatesTrainerIdLazyRoute
  '/admin/tickets/all/$ticketId': typeof LayoutAdminTicketsAllTicketIdLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteRouteWithChildren
  '/access-denied': typeof AccessDeniedRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/_layout/profile': typeof LayoutProfileRouteRouteWithChildren
  '/_layout/template-library': typeof LayoutTemplateLibraryRouteRouteWithChildren
  '/_layout/audits': typeof LayoutAuditsRouteWithChildren
  '/_layout/chat': typeof LayoutChatRoute
  '/_layout/exit-ghost-mode': typeof LayoutExitGhostModeRoute
  '/_layout/setup-account': typeof LayoutSetupAccountRoute
  '/_layout/admin': typeof LayoutAdminRouteLazyRouteWithChildren
  '/_layout/': typeof LayoutIndexRoute
  '/_layout/clients/$userId': typeof LayoutClientsUserIdRouteRouteWithChildren
  '/_layout/audits/$auditId': typeof LayoutAuditsAuditIdRoute
  '/_layout/profile/calendar': typeof LayoutProfileCalendarRoute
  '/_layout/profile/client-load': typeof LayoutProfileClientLoadRoute
  '/_layout/profile/compensation': typeof LayoutProfileCompensationRoute
  '/_layout/profile/message-templates': typeof LayoutProfileMessageTemplatesRoute
  '/_layout/profile/metrics': typeof LayoutProfileMetricsRoute
  '/_layout/profile/nice-messages': typeof LayoutProfileNiceMessagesRoute
  '/_layout/profile/note-templates': typeof LayoutProfileNoteTemplatesRoute
  '/_layout/profile/settings': typeof LayoutProfileSettingsRoute
  '/_layout/profile/vacations': typeof LayoutProfileVacationsRoute
  '/_layout/admin/api-docs-nodejs': typeof LayoutAdminApiDocsNodejsLazyRoute
  '/_layout/admin/api-docs-python': typeof LayoutAdminApiDocsPythonLazyRoute
  '/_layout/admin/audit-cohorts': typeof LayoutAdminAuditCohortsLazyRoute
  '/_layout/admin/audit-messaging': typeof LayoutAdminAuditMessagingLazyRoute
  '/_layout/admin/audit-programming': typeof LayoutAdminAuditProgrammingLazyRoute
  '/_layout/admin/campaigns': typeof LayoutAdminCampaignsLazyRouteWithChildren
  '/_layout/admin/client-funnel': typeof LayoutAdminClientFunnelLazyRoute
  '/_layout/admin/data-cleaner': typeof LayoutAdminDataCleanerLazyRoute
  '/_layout/admin/influencers': typeof LayoutAdminInfluencersLazyRouteWithChildren
  '/_layout/admin/lead-funnel': typeof LayoutAdminLeadFunnelLazyRoute
  '/_layout/admin/product-metrics': typeof LayoutAdminProductMetricsLazyRouteWithChildren
  '/_layout/admin/random-clients': typeof LayoutAdminRandomClientsLazyRoute
  '/_layout/admin/short-urls': typeof LayoutAdminShortUrlsLazyRoute
  '/_layout/admin/tests': typeof LayoutAdminTestsLazyRouteWithChildren
  '/_layout/admin/trainer-availability': typeof LayoutAdminTrainerAvailabilityLazyRoute
  '/_layout/admin/trainer-metrics': typeof LayoutAdminTrainerMetricsLazyRoute
  '/_layout/admin/vacation-budget': typeof LayoutAdminVacationBudgetLazyRoute
  '/_layout/admin/vacations': typeof LayoutAdminVacationsLazyRoute
  '/_layout/profile/': typeof LayoutProfileIndexRoute
  '/_layout/template-library/': typeof LayoutTemplateLibraryIndexRoute
  '/_layout/admin/': typeof LayoutAdminIndexLazyRoute
  '/_layout/admin/influencers/$influencerId': typeof LayoutAdminInfluencersInfluencerIdRoute
  '/_layout/admin/tests/$testId': typeof LayoutAdminTestsTestIdRoute
  '/_layout/template-library/folders/$phaseTemplateFolderId': typeof LayoutTemplateLibraryFoldersPhaseTemplateFolderIdRoute
  '/_layout/template-library/tags/$tagId': typeof LayoutTemplateLibraryTagsTagIdRoute
  '/_layout/admin/campaigns/$campaignId': typeof LayoutAdminCampaignsCampaignIdLazyRoute
  '/_layout/admin/company/candidates': typeof LayoutAdminCompanyCandidatesLazyRouteWithChildren
  '/_layout/admin/company/clients': typeof LayoutAdminCompanyClientsLazyRouteWithChildren
  '/_layout/admin/company/metrics': typeof LayoutAdminCompanyMetricsLazyRoute
  '/_layout/admin/company/settings': typeof LayoutAdminCompanySettingsLazyRoute
  '/_layout/admin/company/team': typeof LayoutAdminCompanyTeamLazyRouteWithChildren
  '/_layout/admin/company/vacations': typeof LayoutAdminCompanyVacationsLazyRoute
  '/_layout/admin/product-metrics/$productMetricId': typeof LayoutAdminProductMetricsProductMetricIdLazyRoute
  '/_layout/admin/tickets/customer_support': typeof LayoutAdminTicketsCustomersupportLazyRoute
  '/_layout/admin/tickets/tech_issue': typeof LayoutAdminTicketsTechissueLazyRoute
  '/_layout/clients/$userId/': typeof LayoutClientsUserIdIndexRoute
  '/_layout/admin/company/clients/$userId': typeof LayoutAdminCompanyClientsUserIdRoute
  '/_layout/admin/company/team/$trainerId': typeof LayoutAdminCompanyTeamTrainerIdRoute
  '/_layout/clients/$userId/logs/$logId': typeof LayoutClientsUserIdLogsLogIdRoute
  '/_layout/clients/$userId/workouts/$workoutId': typeof LayoutClientsUserIdWorkoutsWorkoutIdRoute
  '/_layout/admin/company/candidates/$trainerId': typeof LayoutAdminCompanyCandidatesTrainerIdLazyRoute
  '/_layout/admin/tickets/all/$ticketId': typeof LayoutAdminTicketsAllTicketIdLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/access-denied'
    | '/login'
    | '/logout'
    | '/profile'
    | '/template-library'
    | '/audits'
    | '/chat'
    | '/exit-ghost-mode'
    | '/setup-account'
    | '/admin'
    | '/'
    | '/clients/$userId'
    | '/audits/$auditId'
    | '/profile/calendar'
    | '/profile/client-load'
    | '/profile/compensation'
    | '/profile/message-templates'
    | '/profile/metrics'
    | '/profile/nice-messages'
    | '/profile/note-templates'
    | '/profile/settings'
    | '/profile/vacations'
    | '/admin/api-docs-nodejs'
    | '/admin/api-docs-python'
    | '/admin/audit-cohorts'
    | '/admin/audit-messaging'
    | '/admin/audit-programming'
    | '/admin/campaigns'
    | '/admin/client-funnel'
    | '/admin/data-cleaner'
    | '/admin/influencers'
    | '/admin/lead-funnel'
    | '/admin/product-metrics'
    | '/admin/random-clients'
    | '/admin/short-urls'
    | '/admin/tests'
    | '/admin/trainer-availability'
    | '/admin/trainer-metrics'
    | '/admin/vacation-budget'
    | '/admin/vacations'
    | '/profile/'
    | '/template-library/'
    | '/admin/'
    | '/admin/influencers/$influencerId'
    | '/admin/tests/$testId'
    | '/template-library/folders/$phaseTemplateFolderId'
    | '/template-library/tags/$tagId'
    | '/admin/campaigns/$campaignId'
    | '/admin/company/candidates'
    | '/admin/company/clients'
    | '/admin/company/metrics'
    | '/admin/company/settings'
    | '/admin/company/team'
    | '/admin/company/vacations'
    | '/admin/product-metrics/$productMetricId'
    | '/admin/tickets/customer_support'
    | '/admin/tickets/tech_issue'
    | '/clients/$userId/'
    | '/admin/company/clients/$userId'
    | '/admin/company/team/$trainerId'
    | '/clients/$userId/logs/$logId'
    | '/clients/$userId/workouts/$workoutId'
    | '/admin/company/candidates/$trainerId'
    | '/admin/tickets/all/$ticketId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/access-denied'
    | '/login'
    | '/logout'
    | '/audits'
    | '/chat'
    | '/exit-ghost-mode'
    | '/setup-account'
    | '/'
    | '/audits/$auditId'
    | '/profile/calendar'
    | '/profile/client-load'
    | '/profile/compensation'
    | '/profile/message-templates'
    | '/profile/metrics'
    | '/profile/nice-messages'
    | '/profile/note-templates'
    | '/profile/settings'
    | '/profile/vacations'
    | '/admin/api-docs-nodejs'
    | '/admin/api-docs-python'
    | '/admin/audit-cohorts'
    | '/admin/audit-messaging'
    | '/admin/audit-programming'
    | '/admin/campaigns'
    | '/admin/client-funnel'
    | '/admin/data-cleaner'
    | '/admin/influencers'
    | '/admin/lead-funnel'
    | '/admin/product-metrics'
    | '/admin/random-clients'
    | '/admin/short-urls'
    | '/admin/tests'
    | '/admin/trainer-availability'
    | '/admin/trainer-metrics'
    | '/admin/vacation-budget'
    | '/admin/vacations'
    | '/profile'
    | '/template-library'
    | '/admin'
    | '/admin/influencers/$influencerId'
    | '/admin/tests/$testId'
    | '/template-library/folders/$phaseTemplateFolderId'
    | '/template-library/tags/$tagId'
    | '/admin/campaigns/$campaignId'
    | '/admin/company/candidates'
    | '/admin/company/clients'
    | '/admin/company/metrics'
    | '/admin/company/settings'
    | '/admin/company/team'
    | '/admin/company/vacations'
    | '/admin/product-metrics/$productMetricId'
    | '/admin/tickets/customer_support'
    | '/admin/tickets/tech_issue'
    | '/clients/$userId'
    | '/admin/company/clients/$userId'
    | '/admin/company/team/$trainerId'
    | '/clients/$userId/logs/$logId'
    | '/clients/$userId/workouts/$workoutId'
    | '/admin/company/candidates/$trainerId'
    | '/admin/tickets/all/$ticketId'
  id:
    | '__root__'
    | '/_layout'
    | '/access-denied'
    | '/login'
    | '/logout'
    | '/_layout/profile'
    | '/_layout/template-library'
    | '/_layout/audits'
    | '/_layout/chat'
    | '/_layout/exit-ghost-mode'
    | '/_layout/setup-account'
    | '/_layout/admin'
    | '/_layout/'
    | '/_layout/clients/$userId'
    | '/_layout/audits/$auditId'
    | '/_layout/profile/calendar'
    | '/_layout/profile/client-load'
    | '/_layout/profile/compensation'
    | '/_layout/profile/message-templates'
    | '/_layout/profile/metrics'
    | '/_layout/profile/nice-messages'
    | '/_layout/profile/note-templates'
    | '/_layout/profile/settings'
    | '/_layout/profile/vacations'
    | '/_layout/admin/api-docs-nodejs'
    | '/_layout/admin/api-docs-python'
    | '/_layout/admin/audit-cohorts'
    | '/_layout/admin/audit-messaging'
    | '/_layout/admin/audit-programming'
    | '/_layout/admin/campaigns'
    | '/_layout/admin/client-funnel'
    | '/_layout/admin/data-cleaner'
    | '/_layout/admin/influencers'
    | '/_layout/admin/lead-funnel'
    | '/_layout/admin/product-metrics'
    | '/_layout/admin/random-clients'
    | '/_layout/admin/short-urls'
    | '/_layout/admin/tests'
    | '/_layout/admin/trainer-availability'
    | '/_layout/admin/trainer-metrics'
    | '/_layout/admin/vacation-budget'
    | '/_layout/admin/vacations'
    | '/_layout/profile/'
    | '/_layout/template-library/'
    | '/_layout/admin/'
    | '/_layout/admin/influencers/$influencerId'
    | '/_layout/admin/tests/$testId'
    | '/_layout/template-library/folders/$phaseTemplateFolderId'
    | '/_layout/template-library/tags/$tagId'
    | '/_layout/admin/campaigns/$campaignId'
    | '/_layout/admin/company/candidates'
    | '/_layout/admin/company/clients'
    | '/_layout/admin/company/metrics'
    | '/_layout/admin/company/settings'
    | '/_layout/admin/company/team'
    | '/_layout/admin/company/vacations'
    | '/_layout/admin/product-metrics/$productMetricId'
    | '/_layout/admin/tickets/customer_support'
    | '/_layout/admin/tickets/tech_issue'
    | '/_layout/clients/$userId/'
    | '/_layout/admin/company/clients/$userId'
    | '/_layout/admin/company/team/$trainerId'
    | '/_layout/clients/$userId/logs/$logId'
    | '/_layout/clients/$userId/workouts/$workoutId'
    | '/_layout/admin/company/candidates/$trainerId'
    | '/_layout/admin/tickets/all/$ticketId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRouteRoute: typeof LayoutRouteRouteWithChildren
  AccessDeniedRoute: typeof AccessDeniedRoute
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRouteRoute: LayoutRouteRouteWithChildren,
  AccessDeniedRoute: AccessDeniedRoute,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/access-denied",
        "/login",
        "/logout"
      ]
    },
    "/_layout": {
      "filePath": "_layout/route.tsx",
      "children": [
        "/_layout/profile",
        "/_layout/template-library",
        "/_layout/audits",
        "/_layout/chat",
        "/_layout/exit-ghost-mode",
        "/_layout/setup-account",
        "/_layout/admin",
        "/_layout/",
        "/_layout/clients/$userId"
      ]
    },
    "/access-denied": {
      "filePath": "access-denied.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/_layout/profile": {
      "filePath": "_layout/profile/route.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/profile/calendar",
        "/_layout/profile/client-load",
        "/_layout/profile/compensation",
        "/_layout/profile/message-templates",
        "/_layout/profile/metrics",
        "/_layout/profile/nice-messages",
        "/_layout/profile/note-templates",
        "/_layout/profile/settings",
        "/_layout/profile/vacations",
        "/_layout/profile/"
      ]
    },
    "/_layout/template-library": {
      "filePath": "_layout/template-library/route.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/template-library/",
        "/_layout/template-library/folders/$phaseTemplateFolderId",
        "/_layout/template-library/tags/$tagId"
      ]
    },
    "/_layout/audits": {
      "filePath": "_layout/audits.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/audits/$auditId"
      ]
    },
    "/_layout/chat": {
      "filePath": "_layout/chat.tsx",
      "parent": "/_layout"
    },
    "/_layout/exit-ghost-mode": {
      "filePath": "_layout/exit-ghost-mode.tsx",
      "parent": "/_layout"
    },
    "/_layout/setup-account": {
      "filePath": "_layout/setup-account.tsx",
      "parent": "/_layout"
    },
    "/_layout/admin": {
      "filePath": "_layout/admin/route.lazy.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/admin/api-docs-nodejs",
        "/_layout/admin/api-docs-python",
        "/_layout/admin/audit-cohorts",
        "/_layout/admin/audit-messaging",
        "/_layout/admin/audit-programming",
        "/_layout/admin/campaigns",
        "/_layout/admin/client-funnel",
        "/_layout/admin/data-cleaner",
        "/_layout/admin/influencers",
        "/_layout/admin/lead-funnel",
        "/_layout/admin/product-metrics",
        "/_layout/admin/random-clients",
        "/_layout/admin/short-urls",
        "/_layout/admin/tests",
        "/_layout/admin/trainer-availability",
        "/_layout/admin/trainer-metrics",
        "/_layout/admin/vacation-budget",
        "/_layout/admin/vacations",
        "/_layout/admin/",
        "/_layout/admin/company/candidates",
        "/_layout/admin/company/clients",
        "/_layout/admin/company/metrics",
        "/_layout/admin/company/settings",
        "/_layout/admin/company/team",
        "/_layout/admin/company/vacations",
        "/_layout/admin/tickets/customer_support",
        "/_layout/admin/tickets/tech_issue",
        "/_layout/admin/tickets/all/$ticketId"
      ]
    },
    "/_layout/": {
      "filePath": "_layout/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/clients/$userId": {
      "filePath": "_layout/clients.$userId/route.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/clients/$userId/",
        "/_layout/clients/$userId/logs/$logId",
        "/_layout/clients/$userId/workouts/$workoutId"
      ]
    },
    "/_layout/audits/$auditId": {
      "filePath": "_layout/audits.$auditId.tsx",
      "parent": "/_layout/audits"
    },
    "/_layout/profile/calendar": {
      "filePath": "_layout/profile/calendar.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/profile/client-load": {
      "filePath": "_layout/profile/client-load.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/profile/compensation": {
      "filePath": "_layout/profile/compensation.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/profile/message-templates": {
      "filePath": "_layout/profile/message-templates.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/profile/metrics": {
      "filePath": "_layout/profile/metrics.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/profile/nice-messages": {
      "filePath": "_layout/profile/nice-messages.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/profile/note-templates": {
      "filePath": "_layout/profile/note-templates.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/profile/settings": {
      "filePath": "_layout/profile/settings.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/profile/vacations": {
      "filePath": "_layout/profile/vacations.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/admin/api-docs-nodejs": {
      "filePath": "_layout/admin/api-docs-nodejs.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/api-docs-python": {
      "filePath": "_layout/admin/api-docs-python.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/audit-cohorts": {
      "filePath": "_layout/admin/audit-cohorts.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/audit-messaging": {
      "filePath": "_layout/admin/audit-messaging.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/audit-programming": {
      "filePath": "_layout/admin/audit-programming.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/campaigns": {
      "filePath": "_layout/admin/campaigns.lazy.tsx",
      "parent": "/_layout/admin",
      "children": [
        "/_layout/admin/campaigns/$campaignId"
      ]
    },
    "/_layout/admin/client-funnel": {
      "filePath": "_layout/admin/client-funnel.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/data-cleaner": {
      "filePath": "_layout/admin/data-cleaner.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/influencers": {
      "filePath": "_layout/admin/influencers.lazy.tsx",
      "parent": "/_layout/admin",
      "children": [
        "/_layout/admin/influencers/$influencerId"
      ]
    },
    "/_layout/admin/lead-funnel": {
      "filePath": "_layout/admin/lead-funnel.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/product-metrics": {
      "filePath": "_layout/admin/product-metrics.lazy.tsx",
      "parent": "/_layout/admin",
      "children": [
        "/_layout/admin/product-metrics/$productMetricId"
      ]
    },
    "/_layout/admin/random-clients": {
      "filePath": "_layout/admin/random-clients.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/short-urls": {
      "filePath": "_layout/admin/short-urls.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/tests": {
      "filePath": "_layout/admin/tests.lazy.tsx",
      "parent": "/_layout/admin",
      "children": [
        "/_layout/admin/tests/$testId"
      ]
    },
    "/_layout/admin/trainer-availability": {
      "filePath": "_layout/admin/trainer-availability.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/trainer-metrics": {
      "filePath": "_layout/admin/trainer-metrics.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/vacation-budget": {
      "filePath": "_layout/admin/vacation-budget.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/vacations": {
      "filePath": "_layout/admin/vacations.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/profile/": {
      "filePath": "_layout/profile/index.tsx",
      "parent": "/_layout/profile"
    },
    "/_layout/template-library/": {
      "filePath": "_layout/template-library/index.tsx",
      "parent": "/_layout/template-library"
    },
    "/_layout/admin/": {
      "filePath": "_layout/admin/index.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/influencers/$influencerId": {
      "filePath": "_layout/admin/influencers.$influencerId.tsx",
      "parent": "/_layout/admin/influencers"
    },
    "/_layout/admin/tests/$testId": {
      "filePath": "_layout/admin/tests.$testId.tsx",
      "parent": "/_layout/admin/tests"
    },
    "/_layout/template-library/folders/$phaseTemplateFolderId": {
      "filePath": "_layout/template-library/folders.$phaseTemplateFolderId.tsx",
      "parent": "/_layout/template-library"
    },
    "/_layout/template-library/tags/$tagId": {
      "filePath": "_layout/template-library/tags.$tagId.tsx",
      "parent": "/_layout/template-library"
    },
    "/_layout/admin/campaigns/$campaignId": {
      "filePath": "_layout/admin/campaigns.$campaignId.lazy.tsx",
      "parent": "/_layout/admin/campaigns"
    },
    "/_layout/admin/company/candidates": {
      "filePath": "_layout/admin/company.candidates.lazy.tsx",
      "parent": "/_layout/admin",
      "children": [
        "/_layout/admin/company/candidates/$trainerId"
      ]
    },
    "/_layout/admin/company/clients": {
      "filePath": "_layout/admin/company.clients.lazy.tsx",
      "parent": "/_layout/admin",
      "children": [
        "/_layout/admin/company/clients/$userId"
      ]
    },
    "/_layout/admin/company/metrics": {
      "filePath": "_layout/admin/company.metrics.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/company/settings": {
      "filePath": "_layout/admin/company.settings.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/company/team": {
      "filePath": "_layout/admin/company.team.lazy.tsx",
      "parent": "/_layout/admin",
      "children": [
        "/_layout/admin/company/team/$trainerId"
      ]
    },
    "/_layout/admin/company/vacations": {
      "filePath": "_layout/admin/company.vacations.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/product-metrics/$productMetricId": {
      "filePath": "_layout/admin/product-metrics.$productMetricId.lazy.tsx",
      "parent": "/_layout/admin/product-metrics"
    },
    "/_layout/admin/tickets/customer_support": {
      "filePath": "_layout/admin/tickets.customer_support.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/admin/tickets/tech_issue": {
      "filePath": "_layout/admin/tickets.tech_issue.lazy.tsx",
      "parent": "/_layout/admin"
    },
    "/_layout/clients/$userId/": {
      "filePath": "_layout/clients.$userId/index.tsx",
      "parent": "/_layout/clients/$userId"
    },
    "/_layout/admin/company/clients/$userId": {
      "filePath": "_layout/admin/company.clients.$userId.tsx",
      "parent": "/_layout/admin/company/clients"
    },
    "/_layout/admin/company/team/$trainerId": {
      "filePath": "_layout/admin/company.team.$trainerId.tsx",
      "parent": "/_layout/admin/company/team"
    },
    "/_layout/clients/$userId/logs/$logId": {
      "filePath": "_layout/clients.$userId/logs.$logId.tsx",
      "parent": "/_layout/clients/$userId"
    },
    "/_layout/clients/$userId/workouts/$workoutId": {
      "filePath": "_layout/clients.$userId/workouts.$workoutId.tsx",
      "parent": "/_layout/clients/$userId"
    },
    "/_layout/admin/company/candidates/$trainerId": {
      "filePath": "_layout/admin/company.candidates.$trainerId.lazy.tsx",
      "parent": "/_layout/admin/company/candidates"
    },
    "/_layout/admin/tickets/all/$ticketId": {
      "filePath": "_layout/admin/tickets.all.$ticketId.lazy.tsx",
      "parent": "/_layout/admin"
    }
  }
}
ROUTE_MANIFEST_END */
