import { Avatar, AvatarGroup, Box, Card, type CardProps } from "@mui/material";
import { type ClientMealLog } from "@trainwell/features/nutrition";
import { useAppSelector } from "src/hooks/stateHooks";
import { ScoreStack } from "./shared/ScoreStack";

interface MealLogCardProps extends CardProps {
  log: ClientMealLog;
}

export default function MealLogCard({ log, sx, ...rest }: MealLogCardProps) {
  const clientHeadshot = useAppSelector(
    (state) => state.client.client?.headshot_url,
  );
  const trainerHeadshot = useAppSelector(
    (state) => state.trainer.trainer?.headshot_url,
  );

  return (
    <Card
      variant="outlined"
      sx={{
        margin: 0,
        borderRadius: 0.5,
        overflow: "clip",
        bgcolor: (theme) => theme.palette.background.default,
        cursor: "pointer",
        ...sx,
      }}
      {...rest}
    >
      <Box sx={{ position: "relative", width: "100%", height: "140px" }}>
        <Avatar
          src={log.image_url}
          variant="square"
          sx={{ width: "100%", height: "100%" }}
        />
        <AvatarGroup sx={{ position: "absolute", bottom: "4px", right: "4px" }}>
          {log.client_note && (
            <Avatar
              src={clientHeadshot}
              variant="circular"
              sx={{ width: "20px", height: "20px" }}
            />
          )}
          {log.trainer_note && (
            <Avatar
              src={trainerHeadshot}
              variant="circular"
              sx={{ width: "20px", height: "20px" }}
            />
          )}
        </AvatarGroup>
      </Box>
      <ScoreStack log={log} size="sm" sx={{ padding: 1.5 }} />
    </Card>
  );
}
