import type { SxProps, Theme } from "@mui/material";
import { Box, Button, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useState } from "react";

interface Props {
  label: string;
  value?: Date;
  overrideContentValue?: Date;
  content?: string;
  onSave: (newValue: Date | null) => void;
  allowNull?: boolean;
  sx?: SxProps<Theme>;
  minDate?: Date;
}

export default function SmartEditDateField({
  label,
  value,
  overrideContentValue,
  content,
  onSave,
  allowNull,
  sx = [],
  minDate,
}: Props) {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState<Date | null>(
    value ?? (allowNull ? null : new Date()),
  );

  function save() {
    onSave(newValue);
    setIsEditing(false);
  }

  return (
    <Box
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
        <Typography variant="h6">{label}</Typography>
        <Box
          sx={{
            opacity: isHovering || isEditing ? undefined : 0,
            pointerEvents: isHovering || isEditing ? undefined : "none",
          }}
        >
          <Button
            variant={isEditing ? "contained" : "text"}
            size="small"
            sx={{
              ml: 1,
              lineHeight: 1.25,
              px: 1,
              py: 0.5,
              minWidth: "0px",
            }}
            onClick={() => {
              if (isEditing) {
                save();
              } else {
                setIsEditing(true);
              }
            }}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              size="small"
              sx={{
                ml: 1,
                lineHeight: 1.25,
                px: 1,
                py: 0.5,
                minWidth: "0px",
              }}
              onClick={() => {
                setIsEditing(false);
                setNewValue(value ?? new Date());
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Box>
      {isEditing ? (
        <DatePicker
          value={newValue}
          onChange={(passedValue) => {
            if (allowNull) {
              setNewValue(passedValue);
            } else {
              setNewValue(passedValue ?? new Date());
            }
          }}
          slotProps={{
            textField: {
              size: "small",
              onKeyDown: (event) => {
                if (event.key === "Enter") {
                  save();
                }
              },
            },
          }}
          minDate={minDate}
        />
      ) : (
        <Typography>
          {content ??
            (value && format(overrideContentValue ?? value, "MMMM do, yyyy"))}
        </Typography>
      )}
    </Box>
  );
}
