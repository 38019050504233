import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import {
  getVacationUnits,
  getVacationUnitsForWeek,
  useVacationBudget,
  type Vacation,
  type VacationUnits,
} from "@trainwell/features/vacations";
import { addDays, endOfYear, format, startOfYear } from "date-fns";
import { getDateFromDatabase } from "src/lib/date";

interface Props {
  sx?: SxProps<Theme>;
  startDate?: string;
  endDate?: string;
  trainerId: string;
  trainerTimezone: string;
  proposedVacationType?: Vacation["type"];
  proposedVacationStartDate?: string;
  proposedVacationEndDate?: string;
}

export function VacationBudget({
  sx = [],
  startDate,
  endDate,
  trainerId,
  trainerTimezone,
  proposedVacationType,
  proposedVacationStartDate,
  proposedVacationEndDate,
}: Props) {
  const {
    data: vacationBudget,
    isPending,
    isError,
  } = useVacationBudget({
    filter: {
      start_date: startDate,
      end_date: endDate,
    },
  });
  const {
    data: yearVacations,
    isPending: isPendingYear,
    isError: isErrorYear,
  } = useVacationBudget({
    filter: {
      start_date: startOfYear(new Date()).toISOString(),
      end_date: endOfYear(new Date()).toISOString(),
    },
  });

  if (isPending || isPendingYear) {
    return <CircularProgress />;
  }

  if (isError || isErrorYear) {
    return (
      <Typography>There was an error loading the vacation budget</Typography>
    );
  }

  // accumulate all vacation units for the specified trainer
  const trainerVacationUnitsYear: VacationUnits = yearVacations
    .map((week) => week.trainers)
    .flat()
    .filter((trainer) => trainer.trainer_id === trainerId)
    .reduce(
      (acc, trainer) => ({
        pto: acc.pto + trainer.vacation_units.pto,
        pto_buffer: acc.pto_buffer + trainer.vacation_units.pto_buffer,
        wto: acc.wto + trainer.vacation_units.wto,
        sick: acc.sick + trainer.vacation_units.sick,
        review_period: acc.review_period + trainer.vacation_units.review_period,
        holidays: acc.holidays + trainer.vacation_units.holidays,
        total: acc.total + trainer.vacation_units.total,
      }),
      {
        pto: 0,
        pto_buffer: 0,
        wto: 0,
        sick: 0,
        review_period: 0,
        holidays: 0,
        total: 0,
      },
    );
  const totalTrainerVacationUnitsYear = trainerVacationUnitsYear.total;
  const trainerVacationUnitsDifference =
    proposedVacationType && proposedVacationStartDate && proposedVacationEndDate
      ? getVacationUnits({
          vacation: {
            type: proposedVacationType,
            date_start: proposedVacationStartDate,
            date_end: proposedVacationEndDate,
          },
          windowStartDate: startOfYear(new Date()),
          windowEndDate: endOfYear(new Date()),
          timezone: trainerTimezone,
        })
      : null;

  const uniqueTrainers = yearVacations.at(0)?.trainers.length || 1;
  const companyVacationUnitsYear = yearVacations
    .map((week) => week.trainers)
    .flat()
    .reduce(
      (acc, trainer) => ({
        pto: acc.pto + trainer.vacation_units.pto,
        pto_buffer: acc.pto_buffer + trainer.vacation_units.pto_buffer,
        wto: acc.wto + trainer.vacation_units.wto,
        sick: acc.sick + trainer.vacation_units.sick,
        review_period: acc.review_period + trainer.vacation_units.review_period,
        holidays: acc.holidays + trainer.vacation_units.holidays,
        total: acc.total + trainer.vacation_units.total,
      }),
      {
        pto: 0,
        pto_buffer: 0,
        wto: 0,
        sick: 0,
        review_period: 0,
        holidays: 0,
        total: 0,
      },
    );
  const totalCompanyVacationUnitsYear = companyVacationUnitsYear.total;
  const averageVacationUnitsPerTrainer = Math.round(
    totalCompanyVacationUnitsYear / uniqueTrainers,
  );

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h3">Vacation budget</Typography>
        <Tooltip
          disableInteractive
          placement="bottom-start"
          title={
            <>
              <Typography>
                Counts the total number of vacations units used each week.
                Sundays always count as 0 units.
              </Typography>
              <Typography>A day of PTO is 1 unit</Typography>
              <Typography>A day of PTO buffer is 0.25 units</Typography>
              <Typography>A day of WTO is 0.5 units</Typography>
              <Typography>A day of sick time is 1 unit</Typography>
              <Typography>A day of review period is 0.5 units</Typography>
              <Typography>A holiday is 1 unit</Typography>
            </>
          }
        >
          <IconButton size="small">
            <HelpOutlineRoundedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
      <Card
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Total vacation units this year
        </Typography>
        <Tooltip
          disableInteractive
          title={
            <>
              <Typography sx={{ mb: 1 }}>
                Total vacation units for the year for this trainer
              </Typography>
              <Typography>
                PTO: {trainerVacationUnitsYear.pto}
                {trainerVacationUnitsDifference?.pto ? (
                  <b> (+{trainerVacationUnitsDifference.pto})</b>
                ) : (
                  ""
                )}
              </Typography>
              <Typography>
                PTO buffer: {trainerVacationUnitsYear.pto_buffer}
                {trainerVacationUnitsDifference?.pto_buffer ? (
                  <b> (+{trainerVacationUnitsDifference.pto_buffer})</b>
                ) : (
                  ""
                )}
              </Typography>
              <Typography>
                WTO: {trainerVacationUnitsYear.wto}
                {trainerVacationUnitsDifference?.wto ? (
                  <b> (+{trainerVacationUnitsDifference.wto})</b>
                ) : (
                  ""
                )}
              </Typography>
              <Typography>
                Sick time: {trainerVacationUnitsYear.sick}
                {trainerVacationUnitsDifference?.sick ? (
                  <b> (+{trainerVacationUnitsDifference.sick})</b>
                ) : (
                  ""
                )}
              </Typography>
              <Typography>
                Review periods: {trainerVacationUnitsYear.review_period}
                {trainerVacationUnitsDifference?.review_period ? (
                  <b> (+{trainerVacationUnitsDifference.review_period})</b>
                ) : (
                  ""
                )}
              </Typography>
              <Typography>
                Holidays: {trainerVacationUnitsYear.holidays}
              </Typography>
            </>
          }
        >
          <Typography>Trainer: {totalTrainerVacationUnitsYear}</Typography>
        </Tooltip>
        <Tooltip
          disableInteractive
          title={
            <>
              <Typography sx={{ mb: 1 }}>
                Average vacation units for the year across the company
              </Typography>
              <Typography>
                Average PTO:{" "}
                {Math.round(companyVacationUnitsYear.pto / uniqueTrainers)}
              </Typography>
              <Typography>
                Average PTO buffer:{" "}
                {Math.round(
                  companyVacationUnitsYear.pto_buffer / uniqueTrainers,
                )}
              </Typography>
              <Typography>
                Average WTO:{" "}
                {Math.round(companyVacationUnitsYear.wto / uniqueTrainers)}
              </Typography>
              <Typography>
                Average sick time:{" "}
                {Math.round(companyVacationUnitsYear.sick / uniqueTrainers)}
              </Typography>
              <Typography>
                Average review periods:{" "}
                {Math.round(
                  companyVacationUnitsYear.review_period / uniqueTrainers,
                )}
              </Typography>
              <Typography>
                Average holidays:{" "}
                {Math.round(companyVacationUnitsYear.holidays / uniqueTrainers)}
              </Typography>
            </>
          }
        >
          <Typography>
            Company average: {averageVacationUnitsPerTrainer}
          </Typography>
        </Tooltip>
      </Card>
      <Stack spacing={2} alignItems={"flex-start"}>
        {vacationBudget.map((week) => {
          const totalPto = week.trainers.reduce(
            (acc, trainer) => acc + trainer.vacation_units.pto,
            0,
          );
          const totalPtoBuffer = week.trainers.reduce(
            (acc, trainer) => acc + trainer.vacation_units.pto_buffer,
            0,
          );
          const totalWto = week.trainers.reduce(
            (acc, trainer) => acc + trainer.vacation_units.wto,
            0,
          );
          const totalSick = week.trainers.reduce(
            (acc, trainer) => acc + trainer.vacation_units.sick,
            0,
          );
          const totalReviewPeriod = week.trainers.reduce(
            (acc, trainer) => acc + trainer.vacation_units.review_period,
            0,
          );
          const totalHolidays = week.trainers.reduce(
            (acc, trainer) => acc + trainer.vacation_units.holidays,
            0,
          );

          const totalVacations =
            totalPto +
            totalPtoBuffer +
            totalWto +
            totalSick +
            totalReviewPeriod +
            totalHolidays;

          const proposedVacationUnits =
            proposedVacationType &&
            proposedVacationStartDate &&
            proposedVacationEndDate
              ? getVacationUnitsForWeek({
                  vacation: {
                    type: proposedVacationType,
                    date_start: proposedVacationStartDate,
                    date_end: proposedVacationEndDate,
                  },
                  weekDate: week.starting_sunday,
                  timezone: trainerTimezone,
                })
              : null;

          const totalProposedVacationUnits =
            proposedVacationUnits?.total ?? null;

          const overBudget =
            totalVacations + (totalProposedVacationUnits ?? 0) > week.budget;

          return (
            <Box key={week.starting_sunday}>
              <Typography variant="h6">
                Week of{" "}
                {format(getDateFromDatabase(week.starting_sunday), "MMMM do")} -{" "}
                {format(
                  addDays(getDateFromDatabase(week.starting_sunday), 6),
                  "do",
                )}
              </Typography>
              <Tooltip
                disableInteractive
                title={
                  <>
                    <Typography sx={{ mb: 1 }}>
                      Total vacation units for across the company this week
                    </Typography>
                    <Typography>
                      PTO: {totalPto}
                      {proposedVacationUnits?.pto ? (
                        <b> (+{proposedVacationUnits.pto})</b>
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Typography>
                      PTO buffer: {totalPtoBuffer}
                      {proposedVacationUnits?.pto_buffer ? (
                        <b> (+{proposedVacationUnits.pto_buffer})</b>
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Typography>
                      WTO: {totalWto}
                      {proposedVacationUnits?.wto ? (
                        <b> (+{proposedVacationUnits.wto})</b>
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Typography>
                      Sick time: {totalSick}
                      {proposedVacationUnits?.sick ? (
                        <b> (+{proposedVacationUnits.sick})</b>
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Typography>
                      Review periods: {totalReviewPeriod}
                      {proposedVacationUnits?.review_period ? (
                        <b> (+{proposedVacationUnits.review_period})</b>
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Typography>Holidays: {totalHolidays}</Typography>
                  </>
                }
              >
                <Typography>
                  Company vacation units: {totalVacations} / {week.budget}
                  {totalProposedVacationUnits ? (
                    <Typography
                      component={"span"}
                      sx={{
                        fontWeight: "bold",
                        color: (theme) =>
                          overBudget ? theme.palette.error.main : undefined,
                      }}
                    >
                      {" "}
                      (+{totalProposedVacationUnits})
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              </Tooltip>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}
