import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import type { CoachMetricTemplate } from "@trainwell/features/legacy";
import React, { useEffect, useState } from "react";
import type Metric from "src/interfaces/Metric";
import { round } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import MetricModule from "./MetricModule";
import type { CoachMetricDetails } from "./MetricsPage";
import { TopLevelMetric } from "./TopLevelMetric";

const styles: Record<string, React.CSSProperties> = {
  metricsGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    gap: "1em",
  },
  metric: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  trainerName: {
    fontSize: "17px",
    marginLeft: "0.5em",
  },
  horizStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: "1em",
    width: "200px",
  },
  headshot: {
    height: "50px",
    width: "50px",
    borderRadius: "25px",
  },
};

interface Props {
  coachMetricDetails: CoachMetricDetails;
  templates: CoachMetricTemplate[];
  companyMetrics: any;
  allCompanyMetrics: Metric[];
  window: number;
  sortedSubmoduleMetrics: any;
  showAdminMetrics: boolean;
  detailedMetricsKeys: string[];
  admin: boolean;
}

export function TrainerMetricsAccordion({
  coachMetricDetails,
  templates,
  companyMetrics,
  allCompanyMetrics,
  window,
  sortedSubmoduleMetrics,
  showAdminMetrics,
  detailedMetricsKeys,
  admin,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [allMetrics, setAllMetrics] = useState<any[]>();
  const { trainer, failedToLoad, metricsSnapshot, rankSum } =
    coachMetricDetails;

  useEffect(() => {
    if (isExpanded) {
      api.coachMetrics
        .getDetailedMetrics(
          trainer.trainer_id,
          coachMetricDetails.isCohort ?? false,
          coachMetricDetails.isTest ?? false,
          detailedMetricsKeys,
        )
        .then((fetchedCoachMetrics) => {
          setAllMetrics(fetchedCoachMetrics);
        });
    }
  }, [isExpanded, trainer.trainer_id]);

  function getPrecision(templates: CoachMetricTemplate[], metricPath: string) {
    let template;
    for (const moduleTemplate of templates) {
      for (const submoduleTemplate of moduleTemplate.submodules) {
        if (submoduleTemplate.metric === metricPath) {
          template = submoduleTemplate;
          break;
        }
      }
      if (template !== undefined) {
        break;
      }
    }

    if (template === undefined) {
      return 0;
    }

    return template.precision;
  }

  if (!metricsSnapshot && failedToLoad) {
    return (
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <ErrorRoundedIcon
          sx={{ color: (theme) => theme.palette.text.secondary }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          No metrics found
        </Typography>
      </Paper>
    );
  } else if (!metricsSnapshot) {
    return (
      <Paper square elevation={0} sx={{ p: 2 }}>
        <Grid container>
          <Grid size={6} sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={trainer.headshot_url}
              style={styles.headshot}
              alt={trainer.full_name}
            />
            <Typography variant="h4" sx={{ ml: 2 }}>
              {trainer.full_name}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography style={{ fontWeight: "bold" }}>
              No Metrics Yet
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  let retainedSixMonthsPercent = round(
    metricsSnapshot.users.percent_retained_6_months,
    getPrecision(templates, "users.percent_retained_6_months"),
  ).toString();

  let retainedOneMonthPercent = round(
    metricsSnapshot.users.percent_retained_1_months,
  ).toString();
  let retainedThreeMonthsPercent = round(
    metricsSnapshot.users.percent_retained_3_months,
  ).toString();

  if (retainedOneMonthPercent === "0") {
    retainedOneMonthPercent = "--";
  }
  if (retainedThreeMonthsPercent === "0") {
    retainedThreeMonthsPercent = "--";
  }
  if (retainedSixMonthsPercent === "0") {
    retainedSixMonthsPercent = "--";
  }

  const paidClients = metricsSnapshot.users.count_paying;

  const trialConversionPercent = metricsSnapshot.trial_conversions
    ?.percent_paid_from_signed_up_month
    ? round(metricsSnapshot.trial_conversions.percent_paid_from_signed_up_month)
    : null;

  const filteredTemplates = showAdminMetrics
    ? templates
    : templates.filter(
        (metricTemplate) =>
          metricTemplate.module_title === "Users" ||
          metricTemplate.module_title === "Workouts" ||
          metricTemplate.module_title === "Conversions",
      );

  if (!showAdminMetrics) {
    filteredTemplates.map((metricTemplate) => {
      if (metricTemplate.module_title === "Conversions") {
        const allowedNames = [
          "Trial Conversion Month",
          "Start Trial -> Schedule Call",
          "Schedule Call -> Onboard",
          "Onboard -> Do Habit",
          "Onboard -> Pay",
          "Clients in Trial Conversion Window Month",
        ];

        metricTemplate.submodules = metricTemplate.submodules.filter(
          (submodule) => allowedNames.includes(submodule.metric_title),
        );
      }
    });
  }

  return (
    <Accordion
      slotProps={{ transition: { unmountOnExit: true } }}
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      <AccordionSummary>
        <Grid
          container
          sx={{
            flex: 1,
          }}
        >
          <Grid size={"auto"}>
            <Box sx={{ width: "200px", display: "flex", alignItems: "center" }}>
              <img
                src={trainer.headshot_url}
                style={styles.headshot}
                alt={trainer.full_name}
              />
              {metricsSnapshot.training.is_active ? (
                <Box
                  sx={{
                    position: "absolute",
                    top: "46px",
                    left: "50px",
                    padding: "6px 6px",
                    borderRadius: "50%",
                    background: (theme) => theme.palette.success.main,
                    border: 2,
                    borderColor: (theme) => theme.palette.background.paper,
                  }}
                  id={`active-circle-${trainer.trainer_id}`}
                />
              ) : (
                <span></span>
              )}
              <Typography variant="h4" sx={{ ml: 2 }}>
                {trainer.full_name}
              </Typography>
            </Box>
          </Grid>
          <Grid size={"grow"} style={styles.metric}>
            <TopLevelMetric
              value={
                trialConversionPercent === null
                  ? "--"
                  : trialConversionPercent?.toString()
              }
              type="percent"
            />
          </Grid>
          <Grid size={"grow"} style={styles.metric}>
            <TopLevelMetric
              value={metricsSnapshot.response_times.median_questions}
              type="duration"
            />
          </Grid>
          <Grid size={"grow"} style={styles.metric}>
            <TopLevelMetric
              value={Math.round(
                metricsSnapshot.habits.daily_workout_completion_week_active,
              ).toString()}
              type="percent"
            />
          </Grid>
          <Grid size={"grow"} style={styles.metric}>
            <TopLevelMetric value={retainedOneMonthPercent} type="percent" />
          </Grid>
          <Grid size={"grow"} style={styles.metric}>
            <TopLevelMetric value={retainedThreeMonthsPercent} type="percent" />
          </Grid>
          <Grid size={"grow"} style={styles.metric}>
            <TopLevelMetric value={retainedSixMonthsPercent} type="percent" />
          </Grid>
          <Grid size={"grow"} style={styles.metric}>
            <TopLevelMetric value={paidClients} type="count" />
          </Grid>
          {showAdminMetrics && (
            <Grid size={"grow"} style={styles.metric}>
              <TopLevelMetric value={rankSum.toString()} type="none" />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {allMetrics ? (
          <Stack spacing={4} direction={"column"}>
            {filteredTemplates.map((template, i) => (
              <MetricModule
                key={i}
                metricSnapshot={metricsSnapshot}
                coachMetrics={allMetrics}
                template={template}
                companyMetrics={companyMetrics}
                allCompanyMetrics={allCompanyMetrics}
                window={window}
                sortedSubmoduleMetrics={sortedSubmoduleMetrics}
                trainerName={trainer.first_name ?? "tw"}
                admin={admin}
              />
            ))}
          </Stack>
        ) : (
          <CircularProgress />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
