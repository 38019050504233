import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  Avatar,
  Box,
  CardActionArea,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import WorkoutPreviewPopover from "src/features/client/phase-column/WorkoutPreviewPopover";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectTemplateById } from "src/slices/templatesSlice";
import { openWorkoutTemplate } from "src/slices/workoutBuilderSlice";

interface Props {
  workoutTemplateId: string;
  isLast?: boolean;
  isFirst?: boolean;
  onDelete: () => void;
  onCopy: () => void;
  disabled?: boolean;
  dayIndex: number;
  index: number;
}

export function WorkoutTemplateDraggable({
  workoutTemplateId,
  isLast,
  isFirst,
  onDelete,
  onCopy,
  disabled,
  dayIndex,
  index,
}: Props) {
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const workoutTemplate = useAppSelector((state) =>
    selectTemplateById(state, workoutTemplateId),
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "phase_template_workout_template",
      workoutTemplateId: workoutTemplateId,
      dayIndex: dayIndex,
      index: index,
    };

    return combine(
      draggable({
        element: element,
        getInitialData: () => data,
        onDragStart: () => {
          setDragging(true);
        },
        onDrop: () => {
          setDragging(false);
        },
        canDrag: () => !disabled,
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            source.data.type === "phase_template_workout_template" ||
            source.data.type === "workout_template"
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            setClosestEdge(closestEdge);

            return;
          }

          if (source.data.dayIndex !== dayIndex) {
            setClosestEdge(closestEdge);

            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [index, dayIndex, workoutTemplateId, disabled]);

  if (!workoutTemplate) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Error</Typography>
        <IconButton
          size="small"
          color="error"
          onClick={(event) => {
            event.stopPropagation();

            onDelete();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <DeleteRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.5 : 1,
          }}
        >
          <WorkoutTemplateCell
            workoutTemplateId={workoutTemplateId}
            isLast={isLast}
            isFirst={isFirst}
            onDelete={onDelete}
            onCopy={onCopy}
            isDragging={dragging}
            disabled={disabled}
          />
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview
              text={workoutTemplate?.name ?? "Workout template"}
              icon={
                <Avatar
                  sx={{
                    backgroundColor: (theme) => theme.palette.blue.main,
                    width: 20,
                    height: 20,
                    borderRadius: "6px",
                  }}
                >
                  <FitnessCenterRoundedIcon
                    sx={{
                      fontSize: 16,
                    }}
                  />
                </Avatar>
              }
            />,
            previewContainer,
          )
        : null}
    </>
  );
}

interface CellProps {
  workoutTemplateId: string;
  isLast?: boolean;
  isFirst?: boolean;
  onDelete: () => void;
  onCopy: () => void;
  isDragging?: boolean;
  disabled?: boolean;
}

function WorkoutTemplateCell({
  workoutTemplateId,
  isLast,
  isFirst,
  onDelete,
  onCopy,
  isDragging,
  disabled,
}: CellProps) {
  const dispatch = useAppDispatch();
  const workoutTemplate = useAppSelector((state) =>
    selectTemplateById(state, workoutTemplateId),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  if (!workoutTemplate) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Error</Typography>
        <IconButton
          size="small"
          color="error"
          onClick={(event) => {
            event.stopPropagation();

            onDelete();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <DeleteRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <CardActionArea
        disabled={isDragging}
        onContextMenu={(event) => {
          event.preventDefault();

          setAnchorEl(event.currentTarget);
        }}
        onClick={
          disabled
            ? undefined
            : () => {
                dispatch(openWorkoutTemplate(workoutTemplateId));
              }
        }
        sx={{
          borderTopLeftRadius: isFirst ? 12 : 0,
          borderTopRightRadius: isFirst ? 12 : 0,
          borderBottomLeftRadius: isLast ? 12 : 0,
          borderBottomRightRadius: isLast ? 12 : 0,
          overflow: "hidden",
          borderBottom: isLast ? 0 : 1,
          opacity: isDragging ? 0.4 : undefined,
          borderColor: "divider",
          backgroundColor: (theme) => theme.palette.background.paper,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", px: 1, py: 0.5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>{workoutTemplate.name}</Typography>
            {!disabled && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    setMoreAnchorEl(event.currentTarget);
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                >
                  <MoreVertRoundedIcon fontSize="inherit" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </CardActionArea>
      <WorkoutPreviewPopover
        templateId={workoutTemplateId}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      />
      <Menu
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(moreAnchorEl)}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            onCopy();
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a copy" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete();
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
